// Angular and RJX Imports
// =========================================================
import {
  Component,
  type AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  HostListener
} from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ICellEditorAngularComp } from 'ag-grid-angular'
import 'ag-grid-enterprise'
import { HttpClient } from '@angular/common/http'
import { type GridApi } from 'ag-grid-community'

@Component({
  selector: 'auto-complete',
  // encapsulation: ViewEncapsulation.None, // This was causing UI issues with other components that used forms
  host: {
    style: `position: absolute;
					left: 0px;
					top: 0px;
                    background-color: transparent;
                    z-index: 3000;
                    `
  },
  templateUrl: './auto-selection-cell-renderer.html',
  styleUrls: ['./auto-selection-cell-renderer.scss']
})
export class AutoSelectionCellRenderer
implements ICellEditorAngularComp, AfterViewInit {
  // variables for agGrid
  public params: any
  private gridApi: GridApi
  public rowData: any
  private orgRowData: any
  public columnDefs: [{}]
  public rowSelection: string = 'single'

  // variables for component
  public returnObject: boolean
  public cellValue: string
  public inputValue: string
  public gridHeight: number = 175
  public gridWidth: number = 375
  public propertyName: string
  public isCanceled: boolean = true
  public selectedObject: any = {}

  @ViewChild('input') input: ElementRef

  constructor (private readonly httpClient: HttpClient) {}

  ngAfterViewInit () {
    window.setTimeout(() => {
      if (this.inputValue === this.cellValue) {
        this.input.nativeElement.select()
      } else {
        this.input.nativeElement.focus()
      }
    })
  }

  // ICellEditorAngularComp functions
  agInit (params: any): void {
    this.params = params
    console.log('paramsssssss: ', params)
    const colId = params.propertyRendered
    const autoSelectOptions =
            params.context.componentParent.autoSelectOptions[colId]
    const rowData =
            autoSelectOptions.length === 0
              ? []
              : autoSelectOptions.map((opt, i) => {
                return { id: i, [colId]: opt }
              })
    params.colDef.cellEditorParams.rowData = rowData

    this.orgRowData = [...rowData]
    this.rowData = rowData

    if (params.gridHeight) this.gridHeight = params.gridHeight
    if (params.gridWidth) this.gridWidth = params.gridWidth

    this.columnDefs = params.columnDefs
    this.propertyName = colId
    this.cellValue = params.value[this.propertyName]
    this.returnObject = params.returnObject

    console.log('auto complete params: ', params)

    if (!params.charPress) {
      if (this.cellValue) this.inputValue = this.cellValue
    } else {
      this.inputValue = params.charPress
    }
  }

  getValue (): any {
    // console.log("this.returnObject: ", this.returnObject)
    // console.log("this.returnObject 2: ", this.selectedObject[this.propertyName])
    // console.log("this.returnObject 3: ", this.selectedObject)

    if (!this.returnObject) return this.selectedObject[this.propertyName]
    return this.selectedObject
  }

  isPopup (): boolean {
    return true
  }

  isCancelAfterEnd (): boolean {
    return this.isCanceled
  }

  // ag-Grid functions
  onGridReady (params) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
    console.log('property name: ', this.propertyName)
  }

  // component functions
  rowClicked (params) {
    this.selectedObject = params.data
    this.isCanceled = false
    this.params.api.stopEditing()
  }

  rowConfirmed () {
    if (this.gridApi.getSelectedRows()[0]) {
      this.selectedObject = this.gridApi.getSelectedRows()[0]
      this.isCanceled = false
    }
    this.params.api.stopEditing()
  }

  @HostListener('keydown', ['$event'])
  onKeydown (event) {
    event.stopPropagation()
    console.log('Auto select event: ', event)
    if (event.key === 'Escape') {
      this.params.api.stopEditing()
      return false
    }
    if (event.key === 'Enter' || event.key === 'Tab') {
      this.rowConfirmed()
      this.navigateMainGrid()
      return false
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      this.navigateGrid()
      return false
    }
  }

  async updateFilter (event?) {
    // try {
    // let filteredRows = [];
    console.log('Column Input Val: ', this.inputValue)

    if (
      this.inputValue === '' ||
            this.inputValue === null ||
            this.inputValue === undefined
    ) {
      this.gridApi.setRowData(this.orgRowData)
    } else {
      const filteredRows = this.orgRowData.filter(data =>
        data[this.propertyName].includes(this.inputValue)
      )
      this.gridApi.setRowData(filteredRows)
    }
    // } catch (error) {

    // } finally {

    // }
  }

  navigateGrid () {
    if (
      this.gridApi.getFocusedCell() === null ||
            this.gridApi.getDisplayedRowAtIndex(
              this.gridApi.getFocusedCell().rowIndex
            ) === null
    ) {
      // check if no cell has focus, or if focused cell is filtered
      this.gridApi.setFocusedCell(
        this.gridApi.getDisplayedRowAtIndex(0).rowIndex,
        this.propertyName
      )
      this.gridApi
        .getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex)
        .setSelected(true)
    } else {
      this.gridApi.setFocusedCell(
        this.gridApi.getFocusedCell().rowIndex,
        this.propertyName
      )
      this.gridApi
        .getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex)
        .setSelected(true)
    }
  }

  navigateMainGrid () {
    this.params.api.tabToNextCell()
    console.log('focusssed cell: ', this.params.api.getFocusedCell())

    // if(this.params.api.getFocusedCell() == null || this.params.api.getDisplayedRowAtIndex(this.params.api.getFocusedCell().rowIndex) == null) { // check if no cell has focus, or if focused cell is filtered
    // 	// this.gridApi.setFocusedCell(this.gridApi.getDisplayedRowAtIndex(0).rowIndex, this.propertyName);
    // 	// this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex).setSelected(true);
    // } else {
    // 	this.gridApi.setFocusedCell(this.gridApi.getFocusedCell().rowIndex, this.propertyName);
    // 	this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex).setSelected(true);
    // }
  }
}
