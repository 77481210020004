// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { ExecutePostAPI } from './execute_api-calls'
import { type SizeRangeAlterationsInterface } from '../interfaces/data-expected-from-backend/size-range-alternation-interface'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class SizeRangeAlterationsAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  async GetAvailableSizes (data: string[]) {
    // the api call path with the parameter string is needed
    const path = '/sizes/distro_available_sizes'
    const messages = {
      success: `---> API Call Successful: Retrieved All Available Sizes for: ${data}`,
      data,
      error: 'Error retrieving all available sizes for Size Range Alterations.'
    }
    return await ExecutePostAPI(path, this, { distro_ids: data }, messages)
  }

  async SubmitSizeAlterations (data: SizeRangeAlterationsInterface) {
    // the api call path with the parameter string is needed
    const path = '/size_distro/size_alterations'
    const messages = {
      success: 'Successfully submitted size range alterations.',
      error: 'Error submitting size range alterations.',
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }
}
