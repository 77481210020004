import {
  type JobCreationCustomSeasonsInterface,
  type JobCreationSeasonsInterface
} from './job-creation-interface'

export interface StoreIndexCreateEditJobInterface {
  job_name: string
  product_node: string
  buying_season: string
  prior_selling_seasons?: JobCreationSeasonsInterface[]
  params_changes: string[]
  custom_season?: JobCreationCustomSeasonsInterface
  use_aug_sales: boolean
  vg_count: number
}

export const DefaultStoreIndexCreateEditJobForm = {
  job_name: null,
  product_node: null,
  buying_season: null,
  prior_selling_seasons: null,
  params_changes: [],
  custom_season: {
    start: null,
    end: null
  },
  use_aug_sales: false,
  vg_count: null
}
