// Custom Imports
// =========================================================
import { FormatKey } from 'src/app/utils/global_functions'
import { GenerateDefaultColDefs } from '../02_global-settings/global-cell-formatter'
import { CellClassRules } from '../02_global-settings/cell-class-rules'
import moment from 'moment'

export interface StoreIndex_GridDataParams {
  mainColDefs: any[]
}

export async function StoreIndex_GenerateGridData (
  params: StoreIndex_GridDataParams
) {
  const {
    mainColDefs
    // detailColDefs
  } = params
  const GenerateCols = async (data: any[], gridToRender: string) => {
    const mappedCols = {}

    Object.entries(data).map(([colKey, colVal]) => {
      const colId = colVal.refData?.custom_field || FormatKey(colKey)

      const obj = {
        ...GenerateDefaultColDefs(colKey, { colId, ...colVal })
      }
      Object.assign(mappedCols, {
        [obj.colId]: StoreIndex_customCellFormatting(obj, gridToRender)
      })
    })

    return Object.values(mappedCols)
  }

  const getResults = async () => {
    const mainCols = await GenerateCols(mainColDefs, 'main')

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    return data
  }
  return await getResults()
}

export const StoreIndex_customCellFormatting = (col, gridToRender) => {
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      // console.log('col.colId', col.colId)
      switch (col.colId) {
        case 'output':
          return {
            cellRendererParams: params => {
              return {
                disabled:
                                    params.data.status === 'RUNNING' ||
                                    params.data.status === 'FAILED' ||
                                    params.data.status === 'PENDING',
                disabledKeys: ['status'],
                toolTipMessage: `${
                                    params.data.is_editable &&
                                    params.data.status !== 'APPROVED'
                                        ? 'View/Edit'
                                        : 'View'
                                } Job Output: ${params.data.job_name}`,
                onClick: params =>
                  params.context.onOpenOutputModal(params)
              }
            }
          }
        case 'edit':
          return {
            cellRendererParams: params => {
              return {
                disabled: params.data.status === 'RUNNING',
                disabledKeys: ['status'],
                toolTipMessage: `${
                                    params.data.is_editable &&
                                    (params.data.status === 'GENERATED' ||
                                        params.data.status === 'FAILED' ||
                                        params.data.status === 'PENDING')
                                        ? 'View/Edit'
                                        : 'View'
                                } Job: ${params.data.job_name}`,
                onClick: params =>
                  params.context.onOpenEditJobModal(params)
              }
            }
          }
      }
    }
  }
  const updatedCol = {
    ...col,
    ...getParams(),
    cellClassRules: {
      ...CellClassRules,
      'cell-warning': params => {
        const val = params.value
          ? params.value.toString().toLowerCase()
          : params.value
        // console.log("highlight cell: ", params)
        return col.colId === 'status' && val === 'failed'
      },
      'cell-alert': params => {
        const val = params.value
          ? params.value.toString().toLowerCase()
          : params.value
        return (
          col.colId === 'status' && val === 'completed with errors'
        )
      }
    }
  }
  return updatedCol
}
