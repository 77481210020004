// Angular and RJX Imports
// =========================================================
import { Component, type OnInit, type OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
// JSON Data
// =========================================================
import $executionSizeDistroJSON from '../../../05_ag-grid-configs/01_json-grid-configs/colDefs-main-size-distro-job-execution.json'
// Ag Grid Imports
// =========================================================
import {
  type GridReadyEvent,
  type GridApi,
  type ColumnApi,
  type FilterChangedEvent
} from 'ag-grid-community'
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { CustomGridOptions } from '../../../05_ag-grid-configs/02_global-settings/grid-options'
import { JobExceptions_GenerateGridData } from '../../../05_ag-grid-configs/04_generate-colDefs/job-exceptions-colDefs'
import { SizeDistroExceptionsAPIs } from 'src/app/core/apis/size-distro-exceptions_api-calls'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'

@Component({
  selector: 'app-job-exceptions',
  templateUrl: './job-exceptions.component.html',
  styleUrls: ['./job-exceptions.component.scss']
})
export class JobExceptionsComponent implements OnInit, OnDestroy {
  // Ag Grid Configuration
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: 'Size Distro Execution - Job Exceptions',
      deleteExceptions: async params =>
        await this.deleteExceptions(params),
      providers: [SizeDistroExceptionsAPIs]
    }
  }

  public executionSizeDistro = $executionSizeDistroJSON

  // Ag Grid Configuration
  gridApi: GridApi
  columnApi: ColumnApi
  gridOptions: any = {}
  isAgGridLoading: boolean = true
  rowData: any = []
  // Grid Data
  columnDefs: any[] = []

  constructor (
    private readonly ref: DynamicDialogRef,
    public dialogService: DialogService,
    private readonly sizeDistroExceptionsAPIs: SizeDistroExceptionsAPIs,
    private readonly router: Router,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    this.gridOptions = {
      ...this.customGridOptions,
      onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
      onFirstDataRendered: event => {
        const storedData = sessionStorage.getItem(this.router.url)

        if (storedData) {
          const data = JSON.parse(storedData)
          console.log('---> History.state before pushState:', data)
          const filterComponent =
                        this.gridOptions.api.getFilterInstance(data.key)
          filterComponent.setModel({ values: [data.data] })
          this.gridOptions.api.onFilterChanged()
          sessionStorage.removeItem(this.router.url)
        }
      },
      onFilterChanged: (event: FilterChangedEvent) => {
        console.log('-----> filter event changed: ', event)
      }
    }
  }

  ngOnInit () {
    // Get Grid Data
    this.getAllSizeDistroExceptions()
  }

  // Render AG Grid Column Definitions
  async renderGrid (mainColDefs, rowData) {
    const params = {
      mainColDefs
    }
    const gridData = await JobExceptions_GenerateGridData(params)
    if (gridData) {
      this.columnDefs = gridData.mainColDefs

      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          if (this.gridApi && !this.gridApi['destroyCalled']) {
            this.gridApi?.setColumnDefs(gridData.mainColDefs)
            this.gridApi.setRowData(rowData)
          }

          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  onGridReady (event: GridReadyEvent) {
    this.gridApi = event.api
    this.columnApi = event.columnApi
    this.isAgGridLoading = false
  }

  // Call GetAllSizeDistroExceptions API
  async getAllSizeDistroExceptions () {
    await this.sizeDistroExceptionsAPIs
      .GetAllSizeDistroExceptions()
      .then(res => {
        console.log('All Size Distro Exceptions: ', res)
        this.rowData = res
        // Render if data is available
        if (this.rowData) {
          this.renderGrid(this.executionSizeDistro[0], this.rowData)
        }
      })
  }

  // Calls DeleteExceptions
  async deleteExceptions (params) {
    this.gridApi.showLoadingOverlay()

    const response = await this.sizeDistroExceptionsAPIs.DeleteExceptions(
      params.data.styc_id,
      params.data.job_name
    )
    if (response.is_success) {
      // const index = this.rowData.findIndex(
      //   row =>
      //     row.styc_id === params.data.styc_id &&
      //               row.job_name === params.data.job_name
      // )
      // if (index !== -1) {
      //   this.rowData.splice(index, 1)
      // }
      // this.gridApi.setRowData(this.rowData)
      this.getAllSizeDistroExceptions()
    } else {
      this.gridApi.hideOverlay()
    }
  }

  ngOnDestroy () {
    // console.log('--> Destroying Component data')
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions()
      this.gridApi.expireValueCache()
      this.gridApi = null
    }
  }
}
