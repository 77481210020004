// Angular and RJX Imports
// =========================================================
import { Component, type OnDestroy, type OnInit } from '@angular/core'
// JSON Data
// =========================================================
import $uploadNewProductsJSON from '../../../05_ag-grid-configs/01_json-grid-configs/proxy-product-upload/colDefs-main-product-upload.json'
import $uploadNewAttributes from '../../../05_ag-grid-configs/01_json-grid-configs/proxy-product-upload/colDefs-main-attribute-family-upload.json'
// Custom Imports
// =========================================================
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { type ParameterSelectionOptionsInterface } from 'src/app/core/interfaces/data-expected-from-backend/parameter-selection-options'
import { GridFileUploadService } from 'src/app/core/services/grid-file-upload-service.service'
import { type GridFileUploadInterface } from 'src/app/core/interfaces/grid-file-upload-interface'
import { ProductUploadAPIs } from 'src/app/core/apis/product-upload_api-calls'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'

@Component({
  selector: 'app-product-upload',
  templateUrl: './product-upload.component.html',
  styleUrls: ['./product-upload.component.scss'],
  providers: [ProductUploadAPIs]
})
export class ProductUploadComponent implements OnInit, OnDestroy {
  public uploadNewProducts = $uploadNewProductsJSON
  public uploadNewAttributes = $uploadNewAttributes

  attributeFamilySelectOptions: any
  parentIdSelectOptions: any
  productSelectionOptions: ParameterSelectionOptionsInterface
  unavailableProdIds: any
  noDuplicates: any = {}
  checkDuplicatesAPIFunc = null
  canSubmitGridData: boolean = false
  processingData: boolean = false
  availableSizes: string[] = []
  activeGrid: string = null
  grid_1_title: string = 'Product Details'
  grid_2_title: string = 'Attribute Details'

  gridDetails: {
    'Product Details': GridFileUploadInterface
    'Attribute Details': GridFileUploadInterface
  } = {
      'Product Details': null,
      'Attribute Details': null
    }

  constructor (
    private readonly generalAPIs: GeneralAPIs,
    private readonly gridFileUploadService: GridFileUploadService,
    private readonly productUploadAPIs: ProductUploadAPIs,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    this.checkDuplicatesAPIFunc = this.generalAPIs.CheckProdIdDuplicates
    this.gridFileUploadService
      .getRowDataUpdates()
      .subscribe((res: GridFileUploadInterface) => {
        console.log(
                    `Product Upload Component - ${res.gridTitle} Grid: `,
                    res
        )
        if (res) {
          this.gridDetails[res.gridTitle] = res
          this.activeGrid = res.gridTitle
          console.log('---> Grid Details: ', this.gridDetails)
          if (
            !this.gridDetails[this.grid_1_title]
              ?.rowDataIncomplete ||
                        !this.gridDetails[this.grid_2_title]?.rowDataIncomplete
          ) {
            this.canSubmitGridData = false
          } else {
            this.canSubmitGridData = true
          }
        }
      })
  }

  ngOnInit () {
    this.getSelectionOptions()
    this.processingData = true
  }

  async getSelectionOptions () {
    try {
      // this.unavailableProdIds =
      //           await this.generalAPIs.GetListOfAllProdIds()
      this.productSelectionOptions =
                await this.generalAPIs.GetProductParameterSelectionOptions()
      this.availableSizes = await this.generalAPIs.GetAllSizesAndOrder()
    } finally {
      // Settings for the Attribute family grid
      this.attributeFamilySelectOptions = {
        attribute_family: this.productSelectionOptions.attribute_family
      }
      // Settings for the Product grid
      this.parentIdSelectOptions = {
        size_list: this.availableSizes
      }
      this.noDuplicates = {
        proxy_product_id: this.unavailableProdIds
      }
      this.processingData = false
      if (this.gridDetails[this.grid_1_title].agGrid) {
        this.gridDetails[this.grid_1_title].agGrid.showNoRowsOverlay()
      }
      if (this.gridDetails[this.grid_2_title].agGrid) {
        this.gridDetails[this.grid_2_title].agGrid.showNoRowsOverlay()
      }
    }
  }

  onCreateNewProduct ($event) {
    this.processingData = true
    const p_rowData = this.gridDetails[this.grid_1_title].rowData || []
    const p_hasRowData = p_rowData?.length > 0
    const a_rowData = this.gridDetails[this.grid_2_title].rowData || []
    const a_hasRowData = a_rowData?.length > 0
    if (p_hasRowData) {
      this.gridDetails[this.grid_1_title].agGrid.showLoadingOverlay()
    }
    if (a_hasRowData) {
      this.gridDetails[this.grid_2_title].agGrid.showLoadingOverlay()
    }

    // this.gridDetails[this.grid_2_title].agGrid.showLoadingOverlay();
    const sizes = 'eligible_sizes_(_|_)'
    const dataToSubmit = {
      product_table: p_hasRowData
        ? p_rowData.map(
          (res: { parent_product_id, proxy_product_id }) => {
            return {
              product_id: res.parent_product_id,
              proxy_product_id: res.proxy_product_id,
              proxy_product_sizes: res[sizes]
            }
          }
        )
        : [],
      attribute_table: a_hasRowData
        ? a_rowData.map(
          ({
            proxy_product_id,
            attribute_family,
            attribute_value
          }) => {
            return {
              proxy_product_id,
              attribute_family,
              attribute_value
            }
          }
        )
        : []
    }
    console.log('---> Data To Submit: ', dataToSubmit)

    this.productUploadAPIs.CreateNewProduct(dataToSubmit).then(res => {
      // Reset both grids if api call is successful
      if (res?.is_success) {
        console.log('---> create new product res: ', res)
        if (p_hasRowData) {
          this.gridDetails[this.grid_1_title].agGrid.setRowData([])
        }
        if (a_hasRowData) {
          this.gridDetails[this.grid_2_title].agGrid.setRowData([])
        }
        if (a_hasRowData || p_hasRowData) {
          this.processingData = true
          this.getSelectionOptions()
          this.gridDetails[
            this.grid_1_title
          ].agGrid.showLoadingOverlay()
          this.gridDetails[
            this.grid_2_title
          ].agGrid.showLoadingOverlay()
        }
      } else {
        if (p_hasRowData) {
          this.gridDetails[this.grid_1_title].agGrid.hideOverlay()
        }
        if (a_hasRowData) {
          this.gridDetails[this.grid_2_title].agGrid.hideOverlay()
        }
      }

      this.processingData = false
    })
  }

  ngOnDestroy () {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
  }
}
