// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Axios Imports
// =========================================================
import axios from 'axios'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import {
  ExecuteDeleteAPI,
  ExecuteGetAPI,
  ExecutePostAPI
} from './execute_api-calls'
import {
  type TreeDataParamsInterface,
  type DefaultParametersInputsInterface
} from '../interfaces/data-expected-from-backend/default-parameters-inputs-interface'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class SizeDistroDefaultParametersAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Get All Products Hierarchy Data
  async GetAllProducts (params: TreeDataParamsInterface) {
    const basePath = '/products'
    const compQuery =
            params?.queryString === '' ? '?' : `${params?.queryString}&`
    let prodFilters
    if ('selectedFilters' in params) {
      prodFilters =
                params?.selectedFilters === ''
                  ? ''
                  : `prod_filters=${params?.selectedFilters}`
    }
    // the api call path with the parameter string is needed
    // If no component was referenced i.e. default parameters -> only search for filters
    // const path = basePath + comp + `${comp === '' ? '?' : '&'}prod_filters=${params?.selectedFilters}`
    const path = basePath + compQuery + prodFilters

    const messages = {
      success: `---> API Call Successful: Retrieved Product Hierarchy for ${path}`,
      error: 'Error retrieving product hierarchy data.'
    }
    const allProductsData = await ExecuteGetAPI(path, this, messages).then(
      res => {
        return res
      }
    )
    console.log('allProductsData: ', allProductsData)
    if (allProductsData?.is_success) {
      return await axios
        .get(allProductsData.data, {})
        .then(response => {
          console.log('response: ', response)
          return response
        })
        .catch(error => {
          console.error(
            'Error retrieving all products data: ',
            error
          )
          this.toastAlert.showError('Error Loading Data')
          throw error
        })
    } else {
      console.error('Error retrieving all products file')
      this.toastAlert.showError('Error Loading Data')
      return {
        status: null,
        data: []
      }
    }
  }

  async GetProductTreeNode (
    params: TreeDataParamsInterface,
    product_id: string
  ) {
    const basePath = `/products/${product_id.replaceAll('/', '%2F')}`
    const compQuery =
            params?.queryString === '' ? '?' : `${params?.queryString}&`
    const prodFilters =
            params?.selectedFilters === ''
              ? ''
              : `prod_filters=${params?.selectedFilters}`
    // the api call path with the parameter string is needed
    // If no component was referenced i.e. default parameters -> only search for filters
    // const path = basePath + comp + `${comp === '' ? '?' : '&'}prod_filters=${params?.selectedFilters}`
    const path = basePath + compQuery + prodFilters

    const messages = {
      success: `---> API Call Successful: Retrieved Product Hierarchy for ${path}`,
      error: 'Error retrieving product hierarchy data.'
    }
    const allProductsData = await ExecuteGetAPI(path, this, messages).then(
      res => {
        return res
      }
    )
    console.log('allProductsData: ', allProductsData)
    if (allProductsData?.is_success) {
      return await axios
        .get(allProductsData.data, {})
        .then(response => {
          console.log('response: ', response)
          return response
        })
        .catch(error => {
          console.error(
            'Error retrieving all products data: ',
            error
          )
          this.toastAlert.showError('Error Loading Data')
          throw error
        })
    } else {
      console.error('Error retrieving all products file')
      this.toastAlert.showError('Error Loading Data')
      return {
        status: null,
        data: []
      }
    }
  }

  // Get Product Data By Id
  async GetProductDetailsByID (productID: string) {
    // the api call path with the parameter string is needed
    const path = `/products/parameters/${productID.replaceAll('/', '%2F')}`
    const messages = {
      success: `---> API Call Successful: Retrieved Product Details for product id: ${productID}`,
      error: `Error retrieving parameters for ${productID}.`
    }

    return await ExecuteGetAPI(path, this, messages)
  }

  // Get Proxy Product Attributes by Id
  async GetProxyProductAttributes (productID: string) {
    console.log('get proxy product: ', productID)
    // the api call path with the parameter string is needed
    const path = `/proxy/attr/${productID.replaceAll('/', '%2F')}`
    const messages = {
      success: `---> API Call Successful: Retrieved Proxy Product Attributes for ${productID}`,
      error: `Error retrieving proxy product attributes for ${productID}.`
    }

    return await ExecuteGetAPI(path, this, messages)
  }

  // Save Current Parameter changes
  async SaveParameterChanges (parameters: DefaultParametersInputsInterface) {
    const prodId = parameters.product_id
    // the api call path with the parameter string is needed
    const path = '/products/parameters'
    const messages = {
      success: `Successfully saved size distro parameters for ${prodId}.`,
      error: `Error saving size distro parameters for ${prodId}.`,
      showSuccessToast: true
    }
    return await ExecutePostAPI(path, this, parameters, messages)
  }

  // Override all child parameters
  async OverrideAllParameters (parameters: DefaultParametersInputsInterface) {
    const prodId = parameters.product_id
    // the api call path with the parameter string is needed
    const path = `/products/${prodId.replace(
            '/',
            '%2F'
        )}/children/parameters`
    const messages = {
      success: `Successfully overrode ${prodId}'s children's size distro parameters.`,
      error: `Error overriding ${prodId}'s children's size distro parameters.`,
      showSuccessToast: true
    }
    return await ExecuteDeleteAPI(path, this, messages)
  }

  // Revert Child Params to parent
  async RevertToInheritance (parameters: DefaultParametersInputsInterface) {
    const prodId = parameters.product_id
    // the api call path with the parameter string is needed
    const path = `/products/parameters/${prodId.replaceAll('/', '%2F')}`
    const messages = {
      success: `Successfully reverted ${prodId}'s size distro parameters to parent.`,
      error: `Error reverting ${prodId}'s size distro parameters to parent.`,
      showSuccessToast: true
    }
    return await ExecuteDeleteAPI(path, this, messages)
  }
}
