// Angular and RJX Imports
// =========================================================
import { Component, ViewChild } from '@angular/core'
// Angular Material Imports
// =========================================================
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxChange,
  type MatCheckboxDefaultOptions
} from '@angular/material/checkbox'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import {
  DefaultChartOptions,
  GenerateRandomColor
} from 'src/app/utils/global-chart-settings'
import { ReverseFormatKey } from 'src/app/utils/global_functions'

@Component({
  selector: 'app-drill-in-visualize-modal',
  templateUrl: './drill-in-visualize-modal.component.html',
  styleUrls: ['./drill-in-visualize-modal.component.scss']
  // providers: [
  //   {
  //     provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
  //     useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
  //   }
  // ]
})
export class DrillInVisualizeModalComponent {
  lineChartData: any = null
  lineChartOptions: any = null
  parentGridData: any
  sizeRange: string[] = []
  chartLabels: any
  displayAllChartData: boolean = true
  displayDemandData: boolean = true
  displaySalesData: boolean = true

  @ViewChild('chart') chart

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService
  ) {
    console.log('---> Inside Visualize Modal: ', this.config.data)
    const { data } = config
    this.sizeRange = data.componentParent?.dynamicColumns

    this.parentGridData = {
      ...data?.componentParent,
      rowData: data?.rowData
    }
  }

  ngOnInit () {
    this.configureLineChart()
  }

  configureLineChart () {
    const chartColors = []
    const getColors = () => {
      const color = GenerateRandomColor(chartColors)
      chartColors.push(color)
      return color
    }

    // **Note the legend associated with the dataset is custom -> if more data is added or changed the legend will need to be handled separately
    const dataset = this.parentGridData.rowData
      .flatMap(row => {
        const dataColor = getColors()
        return [
          {
            label: 'Demand: ' + row.row_id + '_' + row.styc_name,
            data: this.sizeRange.map(size => {
              return { y: row[size], x: size }
            }),
            fill: false,
            borderColor: dataColor,
            backgroundColor: dataColor,
            tension: 0.1
          },
          {
            label: 'Sales: ' + row.row_id + '_' + row.styc_name,
            data: this.sizeRange.map(size => {
              return { y: row.sales_metrics[size], x: size }
            }),
            fill: false,
            borderColor: dataColor,
            backgroundColor: dataColor,
            borderDash: [6, 2],
            tension: 0.1
          }
        ]
      })
      .sort((a, b) => {
        const aArray = a.label.split('_')
        const bArray = b.label.split('_')
        return (
          Number(`${aArray[0]}${aArray[1]}`) -
                    Number(`${bArray[0]}${bArray[1]}`)
        )
      })

    //  // Set Chart Data
    this.lineChartData = {
      datasets: dataset
    }
    const sizeRange = this.sizeRange
    // Set Chart Options
    this.lineChartOptions = {
      containerID: 'drill-in-chart',
      ...DefaultChartOptions,

      scales: {
        x: {
          type: 'category',
          labels: this.sizeRange,
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              const size = sizeRange[value]
              return isNaN(Number(size))
                ? size.toUpperCase()
                : size
            }
          }
        },
        y: {
          ticks: {
            beginAtZero: true
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          ...DefaultChartOptions.plugins.tooltip,
          callbacks: {
            ...DefaultChartOptions.plugins.tooltip.callbacks,
            title: context => {
              // console.log('--> title: ', context)
              const value = context[0].raw.x
              return `Size: ${
                                isNaN(Number(value))
                                    ? value.toUpperCase()
                                    : value
                            }`
            },
            label: context => {
              // console.log('---> Label: ', context)
              return `${context.dataset.label}: ${Number(
                                context.formattedValue
                            ).toFixed(2)}%`
            }
          }
        }
      }
    }
  }

  htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate: (chart, args, options) => {
      const getOrCreateLegendList = (chart, id) => {
        const drillInChart = document.getElementById('drill-in-chart')
        let listContainer = drillInChart.querySelector('ul')

        if (!listContainer) {
          listContainer = document.createElement('ul')
          listContainer.classList.add('custom-vertical-scroll')

          drillInChart.appendChild(listContainer)
        }

        return listContainer
      }

      const ul = getOrCreateLegendList(chart, options.containerID)

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove()
      }

      // Reuse the built-in legendItems generator
      const items =
                chart.options.plugins.legend.labels.generateLabels(chart)
      this.chartLabels = items

      items.forEach((item, i) => {
        // console.log('---> item.text: ', item)
        // When the datasets are created, they are grouped by a combination of Distro_Store ID_STYC Name -> within the grouping are two metrics that need to be displayed, Demand and Sales. The data for demand is initialized first.
        if (item.text.includes('Demand: ')) {
          const groupMetrics = ['Demand', 'Sales']
          // Creates the list item container to hold:
          // 1. Group id: Distro_Store ID_STYC Name
          // 2. Demand chart legend
          // 3. Sales chart legend
          const li = document.createElement('li')
          li.setAttribute('id', `list-item-${item.text}`) // the list ID is used to toggle the items visibility when using the external filter
          li.style.display = 'block'
          li.style.width = 'fit-content'

          // Container to hold the title = Group id: Distro_Store ID_STYC Name
          const titleContainer = document.createElement('div')
          titleContainer.setAttribute('id', `title-${item.text}`)
          titleContainer.style.color = item.fontColor
          titleContainer.style.margin = '0px'
          titleContainer.style.marginBottom = '5px'
          titleContainer.style.padding = '0px'
          titleContainer.style.whiteSpace = 'nowrap'
          titleContainer.style.textDecoration =
                        item.hidden && items[i + 1].hidden
                          ? 'line-through'
                          : ''
          // Create the title text element
          const title = document.createElement('p')
          title.innerHTML = item.text.replace('Demand: ', '')
          title.style.cursor = 'pointer'
          title.style.fontWeight = 'bold'

          // When the user clicks the title it should toggle the visibility for all metrics.
          // Each metric's visibility should be the same after clicking
          title.onclick = () => {
            const isHidden =
                            chart.isDatasetVisible(item.datasetIndex + 1) ||
                            chart.isDatasetVisible(item.datasetIndex)
            // Update the visibility for metric 1
            chart.setDatasetVisibility(
              item.datasetIndex,
              !isHidden
            )
            // Update the visibility for metric 2
            chart.setDatasetVisibility(
              item.datasetIndex + 1,
              !isHidden
            )
            chart.update()
          }

          titleContainer.appendChild(title)
          li.appendChild(titleContainer)
          // Loop though the metrics from the dataset to create a legend for each metric
          groupMetrics.forEach((metric, mIndex) => {
            const legendContainer = document.createElement('div')
            const isFirstMetric = metric === 'Demand'
            const id = isFirstMetric
              ? item.text
              : item.text.replace('Demand:', 'Sales:')
            const datasetIndex = isFirstMetric
              ? item.datasetIndex
              : item.datasetIndex + 1

            legendContainer.setAttribute('id', id)
            legendContainer.style.alignItems = 'center'
            legendContainer.style.cursor = 'pointer'
            legendContainer.style.display = 'flex'
            legendContainer.style.flexDirection = 'row'
            legendContainer.style.marginLeft = '10px'
            legendContainer.style.width = 'fit-content'
            // Clicking each legend should toggle their individual visibility
            legendContainer.onclick = event => {
              chart.setDatasetVisibility(
                datasetIndex,
                !chart.isDatasetVisible(datasetIndex)
              )
              chart.update()
            }

            // Line chart style reference (solid line for demand and dashed line for sales)
            const lineRef = document.createElement('span')
            lineRef.style.background = !isFirstMetric
              ? 'transparent'
              : this.lineChartData.datasets[item?.datasetIndex]
                .borderColor
            lineRef.style.display = 'inline-block'
            lineRef.style.flexShrink = '0px'
            lineRef.style.marginRight = '10px'
            lineRef.style.width = '20px'
            lineRef.style.border = 'none'
            lineRef.style.borderTop = !isFirstMetric
              ? `4px dotted ${
                                  this.lineChartData.datasets[
                                      item?.datasetIndex
                                  ].borderColor
                              }`
              : 'none'

            // Text label
            const metricName = document.createElement('p')
            metricName.setAttribute('id', `metric-${id}`)
            metricName.innerHTML = metric
            metricName.style.color = item.fontColor
            metricName.style.margin = '0px'
            metricName.style.padding = '0px'
            metricName.style.whiteSpace = 'nowrap'
            metricName.style.textDecoration = (
              isFirstMetric ? item.hidden : items[i + 1].hidden
            )
              ? 'line-through'
              : ''

            legendContainer.appendChild(lineRef)
            legendContainer.appendChild(metricName)
            li.appendChild(legendContainer)

            if (groupMetrics.length === mIndex + 1) {
              ul.style.height = 'calc(100% - 4px)'
              ul.appendChild(li)
            }
          })
        }
      })
    }
  }

  onTextSearchFilter (event) {
    console.log('---> Input Text Value: ', event.target.value)
    const toggleLegendVisibility = () => {
      this.chartLabels.forEach((item, i) => {
        const isFirstMetric = item.text.includes('Demand: ')
        const titleEl = document.getElementById(
                    `list-item-${item.text}`
        )

        const el = document.getElementById(item.text)
        if (
          !item.text.includes(event.target.value) &&
                    event.target.value !== ''
        ) {
          el.style.display = 'none'
          if (isFirstMetric) titleEl.style.display = 'none'
        } else {
          el.style.display = 'flex'
          if (isFirstMetric) titleEl.style.display = 'block'
        }
      })
    }

    if (
      event.code === 'Enter' &&
            event.target.value &&
            event.target.value !== ''
    ) {
      toggleLegendVisibility()
    } else if (event.target.value === '') {
      toggleLegendVisibility()
    }
  }

  onToggleDisplayChartLabels (dataToToggle: string) {
    console.log('---> Toggle Display Chart Labels: ', this[dataToToggle])
    const { chart } = this.chart
    const isToggleAll = dataToToggle === 'displayAllChartData'
    // item.text.includes('Demand: '))
    this.chartLabels.forEach((item, i) => {
      if (
        isToggleAll ||
                (item.text.includes('Demand') &&
                    dataToToggle === 'displayDemandData') ||
                (item.text.includes('Sales') &&
                    dataToToggle === 'displaySalesData')
      ) {
        chart.setDatasetVisibility(
          item.datasetIndex,
          this[dataToToggle]
        )
      }

      if (i + 1 === this.chartLabels.length) {
        this.chart.chart.update()
      }
    })
  }

  close () {
    if (this.ref) this.ref.close()
  }
}
