[
    {
        "Distro ID": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false,
            "sort": "desc"
        },
        "STYC Name": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        }
    }
]
