import {
  Component,
  type OnInit,
  ViewChild,
  type OnDestroy,
  HostListener
} from '@angular/core'
import { Router } from '@angular/router'
// JSON Data
// =========================================================
import $jobManagementJSON from '../../../05_ag-grid-configs/01_json-grid-configs/colDefs-main-job-management.json'
// Ag Grid Imports
// =========================================================
import {
  type GridReadyEvent,
  type GridApi,
  type ColumnApi,
  type SelectionChangedEvent,
  type RowDataUpdatedEvent
} from 'ag-grid-community'
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import {
  CustomGridOptions,
  DefaultGridContextMenu
} from '../../../05_ag-grid-configs/02_global-settings/grid-options'
import { JobManagement_GenerateGridData } from '../../../05_ag-grid-configs/04_generate-colDefs/job-management-colDefs'
import { ManagementJobsAPIs } from 'src/app/core/apis/job-management_api-calls'
import { JobManagementCreatorService } from 'src/app/core/services/job-management-creator-service.service'
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component'
import { FormatKey } from 'src/app/utils/global_functions'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { SideBarPanels } from 'src/app/05_ag-grid-configs/02_global-settings/grid-variables'
import { SaveGridState } from 'src/app/05_ag-grid-configs/02_global-settings/grid-functions-general'

@Component({
  selector: 'app-job-management',
  templateUrl: './job-management.component.html',
  styleUrls: ['./job-management.component.scss']
})
export class JobManagementComponent implements OnInit, OnDestroy {
  eventPointer: any
  private readonly sideBarPanels: any = SideBarPanels()
  gridName: string = 'size_distro_execution_job_management_view'
  // Ag Grid Configuration
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      allowSaveView: true,
      gridName: this.gridName,
      columnsToAutoSize: [
        'job_name',
        'channel',
        'top_product_node',
        'buying_season',
        'styc_ct',
        'last_modified',
        'status'
      ],
      pageTitle: 'Size Distro Execution - Job Management',
      // providers: [ManagementJobsAPIs],
      onEnterJobCreatorScreen: async (params, colId) =>
        await this.onEnterJobCreatorScreen(params, colId)
    }
  }

  public jobManagement = $jobManagementJSON

  // Ag Grid Configuration
  gridApi: GridApi
  columnApi: ColumnApi
  gridOptions: any = {}
  isAgGridLoading: boolean = true
  // Grid Data
  columnDefs: any[] = []
  selectedJobs: any[] = []
  storedGridSettings: any = {}

  // Enable and disable grid actions based on specific rules
  gridActionsEnabled = {
    run: false,
    delete: false
  }

  // Global Vars
  processingData: boolean = false

  @ViewChild('confirmPopup') confirmPopup: ConfirmPopupComponent

  constructor (
    private readonly ref: DynamicDialogRef,
    public dialogService: DialogService,
    private readonly managementJobsAPIs: ManagementJobsAPIs,
    private readonly jobManagementCreatorService: JobManagementCreatorService,
    private readonly router: Router,
    public triggerApiCallsService: TriggerApiCallsService,
    private readonly generalAPIs: GeneralAPIs
  ) {
    this.gridOptions = {
      ...this.customGridOptions,
      sideBar: {
        toolPanels: this.sideBarPanels.allPanels,
        ...this.sideBarPanels.defaultLayout
      },
      pagination: true,
      paginationAutoPageSize: true,
      statusBar: {}, // Hide the status bar because pagination is applied here
      getRowId: node => FormatKey(node?.data?.job_name),
      getContextMenuItems: params =>
        this.JobManagementGridContextMenu(params),
      onSelectionChanged: (event: SelectionChangedEvent) =>
        this.onSelectedJobChange(event),
      onRowDataUpdated: (event: RowDataUpdatedEvent) => {
        event.api.redrawRows()
      }
    }
    console.log('inside Job Management')
    // Event is fired when the user right-clicks on an element to open the context menu
    document.addEventListener('contextmenu', event => {
      if (event.button === 2) {
        this.eventPointer = event
      }
    })
  }

  ngOnInit (): void {}

  onGridReady (event: GridReadyEvent) {
    this.gridApi = event.api
    this.columnApi = event.columnApi
    this.isAgGridLoading = false
    this.gridApi.closeToolPanel()
    this.getAllJobs()
  }

  // Render AG Grid Column Definitions
  async renderGrid (mainColDefs, rowData) {
    this.selectedJobs = []
    const params = {
      mainColDefs
    }
    let gridData
    try {
      await this.getGridState()
      gridData = await JobManagement_GenerateGridData(params)
    } finally {
      this.columnDefs = gridData.mainColDefs

      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          if (this.gridApi && !this.gridApi['destroyCalled']) {
            this.gridApi?.setColumnDefs(gridData.mainColDefs)
            this.gridApi.setRowData(rowData)

            // Set the subscription for APSYNC
            this.managementJobsAPIs.JobGridDataSubscription(
              this.gridApi
            )
            // Apply the saved column state
            if (
              Object.keys(this.storedGridSettings).length > 0 &&
                            this.storedGridSettings[this.gridName]
            ) {
              const { colDefs, filters } =
                                this.storedGridSettings[this.gridName]
              // Apply the saved column state
              this.gridOptions?.columnApi?.applyColumnState({
                state: colDefs
              })
              // Apply the column order
              this.gridOptions?.columnApi?.moveColumns(
                colDefs.map(col => col.colId),
                0
              )
              // Apply the saved filter state
              this.gridOptions?.api?.setFilterModel(filters)
            }
          }
          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  // Get grid view
  async getGridState () {
    return await this.generalAPIs
      .GetGridView(this.gridOptions.context.gridName)
      .then(res => {
        this.storedGridSettings[this.gridOptions.context.gridName] =
                    res?.is_success
                      ? res.data
                      : {
                          colDefs: [],
                          filters: []
                        }
      })
  }

  onSelectedJobChange (event) {
    const rows = event.api.getSelectedNodes()
    const isRowSelected = rows.length > 0
    this.selectedJobs = rows

    console.log('row selection: changed: ', this.selectedJobs)
    this.gridActionsEnabled = {
      run: false,
      delete: false
    }
    if (isRowSelected) {
      const selectedStatuses = {
        running: false,
        failed: false,
        generated: false,
        completed_with_errors: false,
        pending: false,
        queued: false
      }
      // Update Tracked statuses
      rows.forEach((row, i) => {
        if (row.data) {
          const status = FormatKey(row?.data?.status)
          if (!selectedStatuses[status]) {
            selectedStatuses[status] = true
          }
        }
        if (rows.length === i + 1) {
          const { running, queued } = selectedStatuses
          const status = FormatKey(row?.data?.status)
          if (status === 'queued') {
            this.gridActionsEnabled = {
              run: queued,
              delete: !queued
            }
          } else {
            this.gridActionsEnabled = {
              run: !running,
              delete: !running
            }
          }

          console.log(
            'gridActions enabled: ',
            this.gridActionsEnabled
          )
        }
      })
    }
  }

  // Call getAllManagementJobs API
  getAllJobs () {
    this.managementJobsAPIs.GetAllJobs().then(res => {
      // Render if data is available
      if (res && res.length > 0) {
        this.renderGrid(this.jobManagement[0], res)
      }
    })
  }

  // Sets the context menu in the grid
  JobManagementGridContextMenu (params) {
    console.log('context menu: ', params)
    if (params.column) {
      const selectedNodes = params.api.getSelectedNodes()
      // console.log('selected cell: ', `${params.node.rowIndex}-${colId}`);
      // Delete Jobs Action
      const deleteJobsButton = [
        {
          name:
                        selectedNodes.length > 0
                          ? `Delete ${selectedNodes.length} Job${
                                  selectedNodes.length === 1 ? '' : 's'
                              }`
                          : 'Delete (No Jobs Selected)',
          action: async () =>
            await this.confirmPopup.confirm(
              this.eventPointer,
              'Delete Jobs'
            ),
          disabled: !this.gridActionsEnabled.delete,
          cssClasses: ['text-warn']
        }
      ]
      // Run Jobs Action
      const runJobsButton = [
        {
          name:
                        selectedNodes.length > 0
                          ? `Run ${selectedNodes.length} Job${
                                  selectedNodes.length === 1 ? '' : 's'
                              }`
                          : 'Run (No Jobs Selected)',
          action: async () =>
            await this.onRunJobs('context-menu-action'),
          disabled: !this.gridActionsEnabled.run,
          cssClasses: ['text-accent']
        }
      ]

      const result = [
        ...runJobsButton,
        ...deleteJobsButton,
        'separator',
        ...DefaultGridContextMenu(params)
      ]

      return result
    } else {
      return DefaultGridContextMenu(params)
    }
  }

  // Open Job creator screen
  async onEnterJobCreatorScreen (params: any, colId: string) {
    try {
      // Save Grid State
      await SaveGridState(this.gridOptions, this.gridName)
    } finally {
      if (colId != 'create') {
        this.jobManagementCreatorService.onSelectedJobDetails(params)
        this.router.navigateByUrl(
                    `size-distro-execution/job-management/${
                        colId === 'copy' ? 'copy-job' : 'view-edit-job'
                    }`
        )
      } else {
        this.router.navigateByUrl('size-distro-execution/job-creation')
      }
    }
  }

  // On Delete Jobs
  async onDeleteJobs (event) {
    console.log('delete Jobs button pressed: ', event)

    if (event.userInput === 'except') {
      if (event.command === 'Delete Jobs') {
        const jobsToDelete = this.selectedJobs.map(
          rowNode => rowNode.data.job_name
        )
        try {
          this.gridApi.showLoadingOverlay()
          // Save Grid State
          await SaveGridState(this.gridOptions, this.gridName)
        } finally {
          await this.managementJobsAPIs
            .DeleteJobs(jobsToDelete)
            .then(res => {
              if (
                res.is_success ||
                                (!res.is_success &&
                                    res.details.includes(
                                      'Successfully deleted jobs'
                                    ))
              ) {
                this.getAllJobs()
                this.selectedJobs = []
              } else {
                this.gridApi.hideOverlay()
              }
            })
        }
      }
    }
  }

  // Run Jobs
  async onRunJobs ($event) {
    const jobsToRun = this.selectedJobs.map(rowNode => {
      const rowData = this.gridApi.getRowNode(
        FormatKey(rowNode.data.job_name)
      )
      if (rowData) {
        this.gridApi.applyTransactionAsync({
          update: [
            {
              ...rowData.data,
              status: 'RUNNING'
            }
          ]
        })
      }
      return rowNode.data.job_name
    })
    try {
      // Save Grid State
      await SaveGridState(this.gridOptions, this.gridName)
    } finally {
      this.managementJobsAPIs.RunJobs(jobsToRun, true).then(res => {
        if (res?.is_success) {
          // this.getAllJobs()
          this.gridOptions.api.deselectAll()
          this.selectedJobs = []
        } else {
          this.gridApi.hideOverlay()
        }
      })
    }
  }

  @HostListener('unloaded')
  ngOnDestroy () {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions()
      this.gridApi.expireValueCache()
      this.gridApi = null
    }
  }
}
