<div class="main-ag-grid-container flex-column">
    <!-- Container for above grid actions / buttons -->

    <!-- Review Orders Container -->
    <mat-card class="h-100" id="review-orders-card-container">
        <mat-tab-group
            mat-align-tabs="center"
            color="accent"
            #changeEvent
            class="w-100"
            [selectedIndex]="selectedIndex"
            (selectedIndexChange)="onSelectedIndex($event)"
            preserveContent="true"
        >
            <!-- Main Navigation tabs -->
            <mat-tab
                *ngFor="let tab of tabs; let index = index"
                [label]="tab.field"
                [disabled]="(!rowsSelected || !canViewData) && index !== 0"
            >
                <!-- Tab Body -->
                <ng-template matTabContent>
                    <!-- Note: the order of params is very important: selectedIndex, inputLinesSelectedRows, outputGridData  -->
                    <app-ro-ag-grid
                        [importTabData]="tab"
                        [selectableStycIds]="selectableStycIds"
                        [selectedStycId]="selectedStycId"
                        [canViewData]="canViewData"
                        [selectedRowIndex]="selectedRowIndex"
                        (rowsSelectedEvent)="onRowsSelectedEvent($event)"
                        (onViewDataEvent)="onViewDataEvent($event)"
                        (updateCanViewDataStatus)="
                            updateCanViewDataStatus($event)
                        "
                        (updateSelectedRowIndex)="
                            updateSelectedRowIndex($event)
                        "
                        [selectedIndex]="{selectedIndex, tab, currentRowSelection: inputLinesSelectedRows}"
                        [inputLinesSelectedRows]="inputLinesSelectedRows"
                        [outputGridData]="outputGridData"
                        [packConfigs]="packConfigs"
                        class="w-100"
                    ></app-ro-ag-grid>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>
