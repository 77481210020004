<app-modal-header
    modalTitle="Size Distro Visualization"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div id="size-distro-vis-container" class="modal-content" [attr.size]="'large'">
    <div>
        <app-basic-headers
            [sectionName]="'Distro_Store ID_Styc Name'"
            style="width: 244px"
            color="lte"
        >
            <!-- Filter Icon Panel -->
            <button
                mat-icon-button
                style="
                    color: white;
                    font-size: 20px;
                    padding: 0px;
                    width: fit-content;
                    height: fit-content;
                    display: flex;
                "
                aria-label="Filter Options"
                #tooltip="matTooltip"
                matTooltip="Filter Options"
                matTooltipClass="below"
                [disableRipple]="true"
                (click)="filterPanel.toggle($event)"
            >
                <!-- (click)="filterPanel.toggle($event)" -->

                <mat-icon style="height: 21px">more_vert</mat-icon>
            </button>
            <!-- Filter Dialog -->
            <p-overlayPanel #filterPanel [dismissable]="false">
                <ng-template pTemplate="content">
                    <div>
                        <form style="width: 348px">
                            <mat-form-field
                                appearance="outline"
                                color="accent"
                                class="w-100"
                            >
                                <input
                                    type="text"
                                    matInput
                                    placeholder="Filter by Distro, Store ID, and/or STYC ID."
                                    (keyup)="onTextSearchFilter($event)"
                                />

                                <mat-icon matSuffix>search</mat-icon>
                                <mat-hint>Press 'Enter' to search.</mat-hint>
                            </mat-form-field>
                        </form>
                        <div id="chart-filtering-options-container">
                            <mat-checkbox
                                color="accent"
                                [(ngModel)]="displayAllChartData"
                                (ngModelChange)="
                                    onToggleDisplayChartLabels(
                                        'displayAllChartData'
                                    )
                                "
                            >
                                Display All
                            </mat-checkbox>
                            <mat-checkbox
                                color="accent"
                                [(ngModel)]="displayDemandData"
                                (ngModelChange)="
                                    onToggleDisplayChartLabels(
                                        'displayDemandData'
                                    )
                                "
                            >
                                Display Demand Data
                            </mat-checkbox>
                            <mat-checkbox
                                color="accent"
                                [(ngModel)]="displaySalesData"
                                (ngModelChange)="
                                    onToggleDisplayChartLabels(
                                        'displaySalesData'
                                    )
                                "
                            >
                                Display Sales Data
                            </mat-checkbox>
                        </div>
                        <div class="overlay-panel-footer-container">
                            <button
                                mat-button
                                color="warn"
                                (click)="filterPanel.hide()"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </ng-template>
            </p-overlayPanel>
        </app-basic-headers>
        <app-basic-headers
            [sectionName]="'Percentage By Size'"
            color="lte"
            style="flex: 2"
        ></app-basic-headers>
    </div>

    <p-chart
        #chart
        class="chart"
        id="drill-in-chart"
        type="line"
        [data]="lineChartData"
        [options]="lineChartOptions"
        [plugins]="[htmlLegendPlugin]"
    >
    </p-chart>
</div>
<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="false"
></app-modal-footer>
