// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { ExecutePostAPI } from './execute_api-calls'
import { type UploadProductsInterface } from '../interfaces/data-expected-from-backend/upload-products-interfaces'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class ProductUploadAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  async CreateNewProduct (data: UploadProductsInterface) {
    // the api call path with the parameter string is needed
    const path = '/products/upload'
    const product_table = data.product_table.length
    const attribute_table = data.attribute_table.length
    const getSuffix = a => (a > 1 ? 's' : '')
    const message = `${product_table} new product${getSuffix(
            product_table
        )} and ${attribute_table} attribute detail${getSuffix(
            attribute_table
        )}`
    const messages = {
      success: `Successfully uploaded ${message}.`,
      error: `Error uploading ${message}.`,
      showSuccessToast: true
    }
    return await ExecutePostAPI(path, this, data, messages)
  }
}
