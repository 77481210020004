// Angular Imports
// =========================================================
import { Component } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ITooltipAngularComp } from 'ag-grid-angular'

@Component({
  selector: 'app-grid-custom-tooltip',
  templateUrl: './grid-custom-tooltip.component.html',
  styleUrls: ['./grid-custom-tooltip.component.scss']
})
export class GridCustomTooltipComponent implements ITooltipAngularComp {
  params: any
  data: any

  message: string = ''
  configData: any[] = []

  agInit (params): void {
    this.params = params
    const value = params?.value
    // console.log('tool tip params: ', params)
    this.message = value && isNaN(value) ? value : Number(value).toFixed(2)
  }
}
