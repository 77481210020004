<div id="job-upload-container">
    <!-- Job  Upload -->
    <app-upload-grids
        class="job-upload-grid-container"
        [colDefJSONSettings]="jobUpload"
        [generateGridColumnsFunc]="'JobUpload_GenerateGridData'"
        gridTitle="Job Upload"
        [displayHeader]="false"
        [exportFileName]="'New Jobs'"
        [checkDuplicatesAPIFunc]="checkExistingAPIFunc"
        [autoSelectOptions]="selectionOptions"
        [uploadIsEnabled]="true"
        [processingData]="processingData"
        [selectionValues]="selectionValues"
    >
        <!-- Toolbar -->
        <!-- <div class="grid-toolbar"> -->
        <!-- Grid Header -->
        <div class="grid-title">
            <h3>Job Upload</h3>
        </div>
        <div class="grid-tool-panel-btn-container">
            <!-- Run Jobs -->
            <button
                mat-icon-button
                id="run-jobs-btn"
                color="accent"
                [disabled]="
                    gridDetails['Job Upload']?.rowData.length === 0 ||
                    gridDetails['Job Upload']?.rowDataIncomplete ||
                    processingData
                "
                #tooltip="matTooltip"
                matTooltip="Run Job(s)"
                matTooltipClass="below"
                (click)="uploadJobAPI(true)"
            >
                <mat-icon> sync </mat-icon>
            </button>
            <!-- Create Jobs -->
            <button
                mat-icon-button
                id="create-jobs-btn"
                color="accent"
                [disabled]="
                    gridDetails['Job Upload']?.rowData.length === 0 ||
                    gridDetails['Job Upload']?.rowDataIncomplete ||
                    processingData
                "
                #tooltip="matTooltip"
                matTooltip="Create Job(s)"
                matTooltipClass="below"
                (click)="uploadJobAPI(false)"
            >
                <mat-icon> library_add </mat-icon>
            </button>
        </div>
        <!-- </div> -->
    </app-upload-grids>
</div>
