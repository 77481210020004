<app-modal-header
    modalTitle="Size Breaks"
    [showCloseBtn]="false"
></app-modal-header>
<!-- Modal Content -->
<div
    class="modal-content h-100 w-100"
    [attr.loading]="loading"
    [attr.size]="'large'"
>
    <section *ngIf="!loading" class="primary-section-container h-100 w-100">
        <p-chart
            class="chart h-100"
            id="store-totals-charts"
            type="line"
            model="chartView.horizontalBarModel"
            [data]="data"
            [options]="options"
        >
        </p-chart>
    </section>
    <!-- Loading Component -->
    <app-loader *ngIf="loading" [loadingMessage]="loadingMessage"></app-loader>
</div>
<!-- Modal Footer -->
<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="false"
></app-modal-footer>
