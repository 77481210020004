// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { ExecutePostAPI } from './execute_api-calls'
import { type UploadJobsInterface } from '../interfaces/data-expected-from-backend/upload-jobs-interfaces'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class JobUploadAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Upload Jobs
  async JobUpload (data: UploadJobsInterface[], run_jobs = false) {
    // the api call path with the parameter string is needed
    const path = '/jobs/upload'
    const totalNumOfData = data.length
    const suffix = totalNumOfData > 1 ? 's' : ''
    const messages = {
      success: `Successfully uploaded ${totalNumOfData} size distro job${suffix}. Please refresh browser.`,
      error: `Error uploading ${totalNumOfData} size distro job${suffix}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(
      path,
      this,
      { jobs: data, run_jobs },
      messages
    )
  }
}
