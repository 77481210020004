<app-modal-header
    modalTitle="Size Distro Copy: New Product"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div
    class="modal-content"
    [attr.loading]="submittingData"
    [attr.size]="'small'"
>
    <section *ngIf="!submittingData" class="height-inherit">
        <app-basic-headers
            [sectionName]="'Product Hierarchy Selection'"
            color="lte"
        ></app-basic-headers>
        <div class="content-wrapper">
            <!-- [treeData]="treeData" -->

            <app-product-hierarchy
                [treeDataParams]="treeDataParams"
                [selectionMode]="'single'"
                [selectedValues]="defaultSelected"
                [filtersToDisplay]="[]"
                [showEligibleProxySizes]="false"
                [allowNodeDeletion]="true"
                (onNodeSelected)="onNodeSelected($event)"
            ></app-product-hierarchy>
        </div>
        <!-- Selection Footer -->
        <div class="selection-summary-text-container">
            <!-- Source -->
            <span>
                <p class="summary-title"><b>Source:</b></p>
                <p>
                    {{
                        selectedSource
                            ? selectedSource + '-' + selectedSTYC_name
                            : 'No Source Selected'
                    }}
                </p></span
            >
            <!-- Destination -->
            <span>
                <p class="summary-title"><b>Destination:</b></p>
                <p>
                    {{
                        selectedDestination?.label || 'No Destination Selected'
                    }}
                </p>
            </span>
        </div>
    </section>
    <!-- Loading Component -->
    <app-loader
        *ngIf="submittingData"
        [loadingMessage]="loadingMessage"
    ></app-loader>
</div>

<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="true"
    [closeDisabled]="submittingData"
    [disabled]="submittingData || !selectedSource || !selectedDestination"
    (update)="onSubmit($event)"
></app-modal-footer>
