// Angular and RJX Imports
// =========================================================
import { Component, type OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { cloneDeep } from 'lodash'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { JobCreationAPIs } from 'src/app/core/apis/job-creation_api-calls'
import { StoreIndexAPIs } from 'src/app/core/apis/store-index_api-calls'
import { StoreIndexCopyJobsFormSettings } from 'src/app/03_shared-components/forms/form-data/store-index/store-index-copy-job'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'

@Component({
  selector: 'app-copy-store-index-job-modal',
  templateUrl: './copy-store-index-job-modal.component.html',
  styleUrls: ['./copy-store-index-job-modal.component.scss']
})
export class CopyStoreIndexJobModalComponent implements OnInit {
  loading: boolean = true
  loadingMessage: string = 'loading'
  // Import the original form settings and track updates
  formData = new BehaviorSubject<any>(StoreIndexCopyJobsFormSettings)

  get _formData () {
    return this.formData.getValue()
  }

  ObjectKeys = Object.keys

  parentGridData: any
  selectedJobIds: string[] = []
  JobIdsToCopy: any = {}
  // Data to submit
  completedJobsCopy: any = {
    jobs_to_copy: [],
    prior_selling_season: null,
    buying_seasons: null
  }

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private readonly jobCreationAPIs: JobCreationAPIs,
    private readonly storeIndexAPIs: StoreIndexAPIs,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    console.log('---> New Buying Season Modal: ', config.data)
    this.parentGridData = {
      ...this.config?.data?.componentParent,
      rowData: config?.data.rowData
    }
    this.selectedJobIds =
            this.parentGridData?.rowData.map(data => {
              // FIXME: confirm unique identifier
              const id = data.job_name
              this.JobIdsToCopy[id] = {
                checked: true,
                season: data.buying_season
              }
              return id
            }) || []
    this.completedJobsCopy.jobs_to_copy = this.selectedJobIds
  }

  ngOnInit () {
    this.setSelectionOptions()
  }

  async setSelectionOptions () {
    const tempForm = this._formData
    this.loading = true

    // console.log('tempForm: ', tempForm)
    try {
      const allSelectionOptions =
                await this.jobCreationAPIs.GetAllJobSelectionOptions()
      console.log(
        '---> Seasons Selection OPtions: ',
        allSelectionOptions
      )
      if (allSelectionOptions) {
        // TODO: convert to global function -> multiple components use this code
        const getFilterDates = (dateCat: string) =>
          allSelectionOptions[dateCat]
            .filter(season => {
              const currentDate = new Date()
              const endDate = new Date(season.end_date)
              if (dateCat === 'prior_selling_seasons') {
                return endDate < currentDate
              }
              if (dateCat === 'buying_seasons') {
                return endDate > currentDate
              }
            })
            .map(season => season.season)

        // Update Form Settings
        await Object.keys(this._formData).forEach(field => {
          const f =
                        field === 'prior_selling_season'
                          ? 'prior_selling_seasons'
                          : field
          tempForm[field].selectionOptions = getFilterDates(f) || []
          tempForm[field].disabled = false
          tempForm[field].loading = false
        })
      }
    } catch (error) {
      console.error('Error Loading Selection Options')
    } finally {
      console.log('---> Form Settings Updated: ', tempForm)
      this.formData.next(tempForm)
      this.loading = false
    }
  }

  updateFormValue (form, index?) {
    const field = form.controlName
    const value = cloneDeep(form.value)

    console.log('---> Updating New Buying Season Form:', form)
    this.completedJobsCopy[field] = value
  }

  onUpdateJobIdSelection (event, selectedJob) {
    console.log('---> Job Selection Updated: ', selectedJob)
    this.JobIdsToCopy[selectedJob].checked =
            !this.JobIdsToCopy[selectedJob].checked
    this.completedJobsCopy.jobs_to_copy = Object.keys(
      this.JobIdsToCopy
    ).filter(job => this.JobIdsToCopy[job]['checked'])

    console.log('---> jobs_to_copy: ', this.completedJobsCopy.jobs_to_copy)
  }

  // Create Jobs
  async onCreateIndexJobs (event) {
    this.loadingMessage = 'submitting'
    this.loading = true

    console.log('---> Create Copy Job Index: ', this.completedJobsCopy)
    await this.storeIndexAPIs
      .CopyStoreIndexJob(this.completedJobsCopy)
      .then(res => {
        if (res?.is_success) {
          console.log('success')
          this.close({
            message: 'success',
            data: this.completedJobsCopy
          })
        } else {
          this.close()
        }
      })
  }

  close (data?: any) {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.ref) this.ref.close(data || null)
  }
}
