// Angular Imports
// =========================================================
import { Component, type OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
// AWS / Cognito Imports
// =========================================================
import { Auth } from 'aws-amplify'
// Angular Material Imports & Moment Imports
// =========================================================
import { MatMenuTrigger } from '@angular/material/menu'
import moment from 'moment'
// JSON Data
// =========================================================
import * as ApplicationSections from '../../core/json-data/application-sections.json'
// Custom Imports
// =========================================================
import { environment } from '../../../environments/environment'
import { ToastAlertComponent } from '../01_alerts/toast-alert/toast-alert.component'
import { SideNavPanelService } from 'src/app/core/services/side-nav-panel-service'
import { LastActiveService } from 'src/app/core/services/last-activity-service'
import { CognitoService } from 'src/app/core/services/auth/auth-cognito.service'

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger
  public userLastActive$

  environment = environment
  showFiller = false
  userFirstName = null
  userLastName = null
  expirationTime = null
  sessionEnding: boolean = false
  minsRemaining: string = '00'
  secsRemaining: string = '00'
  alertTime: string = ''
  ObjectKeys = Object.keys
  $ApplicationSections = ApplicationSections
  allSubScreens
  // activeRoute = this.router.url;
  defaultScreen = {
    name: 'Dashboard',
    description: null,
    section: null,
    route: '/size-intelligence-modules-dashboard'
  }

  currentScreen = this.defaultScreen
  expiration: any

  constructor (
    public router: Router,
    public cognitoService: CognitoService,
    public toastAlert: ToastAlertComponent,
    public sideNavPanelService: SideNavPanelService,
    public lastActiveService: LastActiveService
  ) {
    lastActiveService.lastActive$.subscribe(res => {
      this.userLastActive$ = res
    })

    // Initialize the session timer
    this.setSessionTimer()

    // Route tracking
    this.sideNavPanelService.getSideNavRoutes().subscribe(res => {
      console.log('Navigation changed: ', res)
      // Check if route changed and allSubScreens have value
      if (res !== this.currentScreen.route && this.allSubScreens) {
        const activeScreen = this.allSubScreens.find(
          screen => screen?.route && res === `/${screen?.route}`
        )
        // Update the current screen
        if (activeScreen) {
          this.currentScreen = activeScreen
        }
      }
    })
  }

  ngOnInit (): void {
    // console.log('application sections: ', this.$ApplicationSections[0])

    this.allSubScreens = this.getSubScreens()

    const activeSubScreen = this.allSubScreens.filter(
      screen =>
        screen.route &&
                screen.route !== '' &&
                screen.route !== '/size-intelligence-modules-dashboard' &&
                window.location.href.includes(screen.route)
    )

    console.log('Active Sub Screen: ', activeSubScreen)

    if (activeSubScreen.length > 0) {
      this.currentScreen = activeSubScreen[0]
    } else {
      this.currentScreen = this.defaultScreen
    }

    this.cognitoService.getUser().then(user => {
      console.log('User: ', user)
      // Grab the first name and last name from email and capitalize them
      const firstName = user.idToken.payload.email
        .split('@')[0]
        .split('.')[0]
      this.userFirstName = firstName.replace(/^\w/, c => c.toUpperCase())
      const lastName = user.idToken.payload.email
        .split('@')[0]
        .split('.')[1]
      this.userLastName = lastName.replace(/^\w/, c => c.toUpperCase())
      // Grab the session expiration time
      this.expirationTime = user.idToken.payload.exp
    })
  }

  getSubScreens () {
    // console.log('All Sub Screens Screen: ', this.allSubScreens)
    return this.$ApplicationSections[0].flatMap(cat =>
      cat.children.flatMap(section => {
        if (section.level === 'section') {
          return section.children.flatMap(screen => {
            return { ...screen, section: section.name }
          })
        } else {
          return { ...section, section: section.name }
        }
      })
    )
  }

  onScreenChanged (event, screen, section) {
    if (section === '/size-intelligence-modules-dashboard') {
      this.currentScreen = this.defaultScreen
    } else {
      this.currentScreen = {
        ...screen,
        section
      }
    }
  }

  onClickNav (path: string) {
    this.router.navigateByUrl(path)
  }

  onLogout (event) {
    this.cognitoService.signOut()
  }

  // Set a session timer
  setSessionTimer () {
    // console.log('---> User Activity', this.userLastActive$);
    this.cognitoService.getUser().then(user => {
      this.expirationTime =
                user?.idToken?.payload?.exp || moment(new Date()).seconds()

      const verifyUserSession = () => {
        const tokenExpiration = moment(
          new Date(this.expirationTime * 1000)
        )
        const currentTime = moment(new Date())
        const secondsRemaining = moment
          .duration(tokenExpiration.diff(currentTime))
          .asSeconds()
        const timeSinceActive = moment
          .duration(currentTime.diff(this.userLastActive$))
          .asSeconds()

        const alertIntervals = ['15', '10', '5', '1']
        const warnIntervals = ['10', '30']
        // warn the user that the session has under 5 minutes left
        this.sessionEnding = secondsRemaining <= 110

        if (secondsRemaining <= 900 && timeSinceActive <= 300) {
          console.log(
            '---> Refresh User Session: Time Since Active',
            timeSinceActive
          )
          this.cognitoService.refreshToken()
          this.setSessionTimer()
        } else if (secondsRemaining > 0) {
          const mins = Math.floor(secondsRemaining / 60).toString()
          const secs = Math.floor(secondsRemaining % 60).toString()
          const getValue = val =>
            val.length === 1 ? `0${val}` : val
          this.minsRemaining = getValue(mins)
          this.secsRemaining = getValue(secs)

          if (alertIntervals.includes(mins) || mins === '1') {
            if (this.alertTime !== mins) {
              if (this.alertTime !== '') this.toastAlert.clear()
              const alert = mins === '1' ? 'error' : 'warn'

              this.alertTime = mins
              this.toastAlert.showSessionEnding(
                                `Your session will expire in ${this.minsRemaining} minutes. Any unsaved changes will be lost.`,
                                alert,
                                true
              )
            }
          } else if (mins === '0' && warnIntervals.includes(secs)) {
            if (this.alertTime !== '') this.toastAlert.clear()

            if (this.alertTime !== secs) {
              this.toastAlert.showSessionEnding(
                                `Your session will expire in ${this.secsRemaining} seconds. Any unsaved changes will be lost. `,
                                'error',
                                false
              )
            }
          }
        } else if (secondsRemaining <= 0) {
          clearInterval(sessionTimer)
          this.cognitoService.clearUserDetails()
          Auth.signOut()
        }
      }
      const sessionTimer = setInterval(verifyUserSession, 1000)
    })
  }
}
