[
    {
        "Select": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "checkbox",
                "selectAll": true,
                "colSize": 50
            },
            "rowGroup": false,
            "hide": false,
            "maxWidth": 50,
            "suppressAutoSize": true
        },
        "Distro Details": {
            "headerName": "Distro Details",
            "marryChildren": true,
            "children": [
                {
                    "Distro Id": {
                        "editable": false,
                        "refData": {
                            "datatype": "string",
                            "showToolTip": false
                        },
                        "rowGroup": false,
                        "hide": false
                    },
                    "Buying Season": {
                        "editable": false,
                        "refData": {
                            "datatype": "string",
                            "showToolTip": false
                        },
                        "rowGroup": false,
                        "hide": false
                    },
                    "STYC ID": {
                        "editable": false,
                        "refData": {
                            "datatype": "string",
                            "showToolTip": false
                        },
                        "rowGroup": false,
                        "hide": false
                    },
                    "STYC Name": {
                        "editable": false,
                        "refData": {
                            "datatype": "string",
                            "showToolTip": false
                        },
                        "rowGroup": false,
                        "hide": false
                    },
                    "Store ID": {
                        "editable": false,
                        "refData": {
                            "datatype": "string",
                            "showToolTip": false
                        },
                        "rowGroup": false,
                        "hide": false,
                        "sort": "asc"
                    },
                    "Sales Units": {
                        "editable": false,
                        "refData": {
                            "datatype": "number",
                            "showToolTip": false,
                            "replaceZeros": true
                        },
                        "rowGroup": false,
                        "hide": false
                    },
                    "Source": {
                        "editable": false,
                        "refData": {
                            "datatype": "string",
                            "showToolTip": false
                        },
                        "rowGroup": false,
                        "hide": true
                    },
                    "Status": {
                        "editable": false,
                        "refData": {
                            "datatype": "string",
                            "showToolTip": false,
                            "flexSize": 2
                        },
                        "rowGroup": false,
                        "hide": true
                    },
                    "Edited": {
                        "editable": false,
                        "refData": {
                            "datatype": "boolean",
                            "showToolTip": false
                        },
                        "rowGroup": false,
                        "hide": true
                    },
                    "Last Modified": {
                        "editable": false,
                        "refData": {
                            "datatype": "date",
                            "showToolTip": false
                        },
                        "rowGroup": false,
                        "hide": true
                    },
                    "Last Modified By": {
                        "editable": false,
                        "refData": {
                            "datatype": "string",
                            "showToolTip": false,
                            "flexSize": 2
                        },
                        "rowGroup": false,
                        "hide": true
                    }
                }
            ],
            "refData": {
                "datatype": "string"
            }
        }
    }
]
