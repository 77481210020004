export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.

        window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.

        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
)
export const isCloudfront = Boolean(
  window.location.hostname.includes('.cloudfront.net')
)

export const redirectSignIn = awsconfig => {
  // Assuming you have two redirect URIs, and the first is for localhost and second is for production

  const localRedirectSignIn = awsconfig.oauth.redirectSignIn.split(',')[0]

  const productionRedirectSignIn = isCloudfront
    ? awsconfig.oauth.redirectSignIn.split(',')[1]
    : awsconfig.oauth.redirectSignIn.split(',')[2]

  const redirectSignIn = isLocalhost
    ? localRedirectSignIn
    : productionRedirectSignIn

  return redirectSignIn
}

export const redirectSignOut = awsconfig => {
  // Assuming you have two redirect URIs, and the first is for localhost and second is for production

  const localRedirectSignOut = awsconfig.oauth.redirectSignOut.split(',')[0]

  const productionRedirectSignOut = isCloudfront
    ? awsconfig.oauth.redirectSignOut.split(',')[1]
    : awsconfig.oauth.redirectSignOut.split(',')[2]

  const redirectSignOut = isLocalhost
    ? localRedirectSignOut
    : productionRedirectSignOut

  return redirectSignOut
}
