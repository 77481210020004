[
    {
        "JOB NAME": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "STYC ID": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "LAST MODIFIED": {
            "editable": false,
            "refData": {
                "datatype": "date"
            },
            "rowGroup": false,
            "hide": false,
            "sort": "desc"
        },
        "EXCEPTION DETAILS": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Delete": {
            "editable": false,
            "refData": {
                "datatype": "action-button|delete",
                "displayBlankHeader": true
            },
            "rowGroup": false,
            "hide": false
        }
    }
]
