import { type CartonizationSettingsInputsInterface } from './data-expected-from-backend/cartonization-settings-inputs-interface'

export interface ReviewOrdersParametersInterface
  extends CartonizationSettingsInputsInterface {
  job_id: number
  channel: string
  product_id: string
  row_id?: string
  id?: string
}

export interface UserInputLineSelectedInterface {
  rowsSelected: boolean
  data: UserInputLinesGridInterface
}
export interface UserInputLinesGridInterface {
  rows: any[]
  ids: any[]
  wasDetailSelected: boolean
}

export const DefaultUserInputLinesGrid = {
  rows: [],
  ids: [],
  wasDetailSelected: false
}

export interface ApproveOrdersInterface {
  job_ids: number[]
}

export interface MarkAsPendingReviewInterface {
  job_ids: number[]
}
export interface CalculateBreaksInterface {
  rows: RowIdsAndChannels[]
}

export interface ExportBreaksInterface {
  rows: RowIdsAndChannels[]
}

export interface RowIdsAndChannels {
  row_id: string
  channel: string
}
export interface ReviewOrdersOutputInterface {
  ids: number[]
}

export interface DeleteBuyGroupInterface {
  buy_group_id: string
}
