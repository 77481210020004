// Angular Imports
// =========================================================
import { NgModule } from '@angular/core'
import { type Routes, RouterModule } from '@angular/router'
// Custom Imports
// =========================================================
import { DashboardComponent } from './01_pages/00_dashboard/dashboard.component'
import { DefaultParametersComponent } from './01_pages/01_size-distro-strategy/default-parameters/default-parameters.component'
import { ProductUploadComponent } from './01_pages/01_size-distro-strategy/product-upload/product-upload.component'
import { JobCreationComponent } from './01_pages/02_size-distro-execution/job-creation/job-creation.component'
import { JobExceptionsComponent } from './01_pages/02_size-distro-execution/job-exceptions/job-exceptions.component'
import { JobManagementComponent } from './01_pages/02_size-distro-execution/job-management/job-management.component'
import { JobUploadComponent } from './01_pages/02_size-distro-execution/job-upload/job-upload.component'
import { UserManagementComponent } from './01_pages/03_admin/user-management/user-management.component'
import { SizeDistroManagementDetailGridComponent } from './01_pages/04_size-distro-management/size-distro-management-detail-grid/size-distro-management-detail-grid.component'
import { SizeDistroManagementSummaryComponent } from './01_pages/04_size-distro-management/size-distro-management-summary/size-distro-management-summary.component'
import { AuthGuard } from './core/services/auth/auth-guard'
import { StoreIndexComponent } from './01_pages/05_store-index/store-index.component'
import { ReviewOrdersComponent } from './01_pages/06_buying/review-orders/review-orders.component'
import { BuyingStrategyComponent } from './01_pages/06_buying/buying-strategy/buying-strategy.component'

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'size-intelligence-modules-dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  // Size Distro Strategy
  {
    path: 'size-distro-strategy/default-parameters',
    component: DefaultParametersComponent
  },
  {
    path: 'size-distro-strategy/product-creation',
    component: DefaultParametersComponent
  },
  {
    path: 'size-distro-strategy/product-upload',
    component: ProductUploadComponent
  },
  // Size Distro Execution
  {
    path: 'size-distro-execution/job-exceptions',
    component: JobExceptionsComponent
  },
  {
    path: 'size-distro-execution/job-management',
    component: JobManagementComponent
  },
  {
    path: 'size-distro-execution/job-management/copy-job',
    component: JobCreationComponent
  },
  {
    path: 'size-distro-execution/job-management/view-edit-job',
    component: JobCreationComponent
  },
  {
    path: 'size-distro-execution/job-creation',
    component: JobCreationComponent
  },
  {
    path: 'size-distro-execution/job-upload',
    component: JobUploadComponent
  },
  // User Management
  {
    path: 'admin/user-management',
    component: UserManagementComponent
  },
  {
    path: 'size-distro-management',
    component: SizeDistroManagementSummaryComponent
  },
  {
    path: 'size-distro-management/drill-in-size-summary',
    component: SizeDistroManagementDetailGridComponent
  },
  {
    path: 'store-index-execution/store-index-management',
    component: StoreIndexComponent
  },
  {
    path: 'buying/buying-strategy',
    component: BuyingStrategyComponent
  },
  {
    path: 'buying/review-orders',
    component: ReviewOrdersComponent
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
