// Angular and RJX Imports
// =========================================================
import { Component, type OnInit } from '@angular/core'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
import { type TreeNode } from 'primeng/api'
// Custom Imports
// =========================================================
import { SizeDistroManagementSummaryAPIs } from 'src/app/core/apis/size-distro-management-summary_api-calls'
import { type TreeDataParamsInterface } from 'src/app/core/interfaces/data-expected-from-backend/default-parameters-inputs-interface'

@Component({
  selector: 'app-distro-copy-new-product-modal',
  templateUrl: './distro-copy-new-product-modal.component.html',
  styleUrls: ['./distro-copy-new-product-modal.component.scss']
})
export class DistroCopyNewProductModalComponent implements OnInit {
  // Params for Product Hierarchy Section
  treeDataParams: TreeDataParamsInterface = null
  submittingData: boolean = false
  loadingMessage: string = 'submitting'
  selectedHierarchyNode: TreeNode = {}
  defaultSelected: TreeNode[] = []

  selectedSource: number = null
  selectedSTYC_name: string = null
  selectedDestination: any = null
  parentGridData: any

  constructor (
    public readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private readonly sizeDistroManagementSummaryAPIs: SizeDistroManagementSummaryAPIs
  ) {
    console.log(
      '---> Inside Size Distro Copy - New Product: ',
      this.config.data
    )
    this.parentGridData = {
      ...this.config?.data?.componentParent,
      rowData: config?.data.rowData
    }
  }

  ngOnInit () {
    // Set hierarchy tree data params
    this.treeDataParams = {
      queryString: '?for_distro_copy=true',
      selectedProd: null
    }
    const { distro_id, styc_name } = this.parentGridData.rowData[0]
    this.selectedSource = distro_id
    this.selectedSTYC_name = styc_name
  }

  // Load the hierarchy tree data
  // async getAllProducts () {
  //   try {
  //     console.log('---> Getting all Products')
  //     // this.treeDataParams = {
  //     //   queryString: '?for_distro_copy=true',
  //     //   selectedProd: null
  //     // };
  //   } finally {
  //     console.log('--> async function completed')
  //   }
  // }

  //  Hierarchy Node Selected
  async onNodeSelected (event) {
    console.log('Product Creation Node Selected: ', event)
    this.selectedHierarchyNode = event.node
    this.selectedDestination = {
      label: event.node.label,
      key: event.node.key
    }
  }

  async onSubmit (event) {
    console.log('---> Submitting New Product')
    console.log('---> Selected Source: ', this.selectedSource)
    console.log('---> Selected Destination: ', this.selectedDestination)
    this.submittingData = true

    const data = {
      distro_id: this.selectedSource,
      target: this.selectedDestination
    }

    await this.sizeDistroManagementSummaryAPIs
      .OnDistroCopyProduct(data)
      .then(res => {
        if (res?.is_success) {
          this.close({
            message: 'success',
            data
          })
        } else {
          this.submittingData = false
          this.close()
        }
      })
  }

  close (data?: any) {
    if (this.ref) this.ref.close(data || null)
  }
}
