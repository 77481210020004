// Angular and RJX Imports
// =========================================================
import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-basic-headers',
  templateUrl: './basic-headers.component.html',
  styleUrls: ['./basic-headers.component.scss']
})
export class BasicHeadersComponent {
  @Input() color: string = 'dark' // options = lte or dark

  textEllipsis: boolean = false
  @Input('textEllipsis')
  set _textEllipsis (ellipsis: boolean) {
    this.textEllipsis = ellipsis
  }

  sectionName: string = ''
  @Input('sectionName')
  set _sectionName (name: string) {
    this.sectionName = name
  }
}
