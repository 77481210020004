// Data object expected from the backend
export interface CartonizationSettingsInputsInterface {
  editable?: boolean
  parent_product_id?: string
  product_id?: string
  allow_cartons_in_flows: boolean
  allow_holes_in_cartons: boolean
  apply_mins_to_flows: boolean
  apply_maxes_to_flows: boolean
  allow_bulk_in_set: boolean
  bulk_multiple: number
  carton_sizes: string
  deviation_from_buy_qty: number
  every_size_in_every_pack: boolean
  min_units_per_styc_per_store: number
  max_units_per_styc_per_store: number
  min_by_size: number
  min_order_qty_for_each_pack: number
  number_of_cartons: number
}
export const DefaultCartonizationSettingsInputs = {
  allow_cartons_in_flows: false,
  allow_holes_in_cartons: false,
  apply_mins_to_flows: false,
  apply_maxes_to_flows: false,
  allow_bulk_in_set: false,
  bulk_multiple: 1,
  carton_sizes: null,
  deviation_from_buy_qty: 0,
  every_size_in_every_pack: false,
  min_units_per_styc_per_store: 0,
  max_units_per_styc_per_store: null,
  min_by_size: 0,
  min_order_qty_for_each_pack: 0,
  number_of_cartons: 1
}

export const GetCartonizationSettingsInputs = (editable: boolean) => {
  return {
    editable,
    ...DefaultCartonizationSettingsInputs
  }
}
