<div class="main-ag-grid-container">
    <!-- Product Upload -->
    <app-upload-grids
        class="upload-grids-container"
        [colDefJSONSettings]="uploadNewProducts"
        [generateGridColumnsFunc]="'ProductUpload_GenerateGridData'"
        [gridTitle]="grid_1_title"
        [displayHeader]="true"
        [exportFileName]="'New Product'"
        [autoSelectOptions]="parentIdSelectOptions"
        [checkDuplicatesAPIFunc]="checkDuplicatesAPIFunc"
        [uploadIsEnabled]="true"
        [processingData]="processingData"
        [autoSizeColumns]="true"
    >
    </app-upload-grids>
    <!-- Attribute Family Upload -->
    <app-upload-grids
        class="upload-grids-container"
        [colDefJSONSettings]="uploadNewAttributes"
        [generateGridColumnsFunc]="'AttributeUpload_GenerateGridData'"
        [gridTitle]="grid_2_title"
        [displayHeader]="true"
        [exportFileName]="'New Attribute Family'"
        [autoSelectOptions]="attributeFamilySelectOptions"
        [checkDuplicatesAPIFunc]="checkDuplicatesAPIFunc"
        [uploadIsEnabled]="
            gridDetails[grid_1_title]?.rowData.length > 0 &&
            !gridDetails[grid_1_title]?.rowDataIncomplete
        "
        [processingData]="processingData"
        [autoSizeColumns]="true"
    >
    </app-upload-grids>
</div>
<!-- Footer / Submit -->
<div class="product-upload-grids-footer-container">
    <button
        mat-raised-button
        color="accent"
        [disabled]="
            !gridDetails[grid_1_title] ||
            gridDetails[grid_1_title]?.rowData.length === 0 ||
            gridDetails[grid_1_title]?.rowDataIncomplete ||
            (gridDetails[grid_2_title]?.rowData.length > 0 &&
                gridDetails[grid_2_title]?.rowDataIncomplete)
        "
        (click)="onCreateNewProduct($event)"
    >
        Upload Product(s)
    </button>
</div>
