[
    {
        "Job ID": {
            "editable": false,
            "refData": {
                "datatype": "number"
            },
            "hide": false,
            "checkboxSelection": true
        },
        "Distro ID": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "hide": false
        },
        "Style-Color ID": {
            "editable": false,
            "refData": {
                "custom_field": "styc_id",
                "datatype": "string"
            },
            "hide": false
        },
        "Style-Color Name": {
            "editable": false,
            "refData": {
                "custom_field": "styc_nm",
                "datatype": "string"
            },
            "hide": false
        },
        "Size Range": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "showToolTip": true,
            "hide": false
        },
        "Store Distro": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "tooltipField": "store_distro"
            },
            "hide": false
        },
        "Delivery Date": {
            "editable": false,
            "refData": {
                "datatype": "date"
            },
            "hide": false
        },
        "Unit Qty": {
            "editable": false,
            "refData": {
                "custom_field": "quantity",
                "datatype": "number"
            },
            "hide": false
        },
        "Delivery Type": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "hide": false
        }
    },
    {
        "Delivery Date": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "date"
            },
            "hide": false,
            "rowGroup": false
        },
        "Delivery Type": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "string"
            },
            "hide": false,
            "rowGroup": false
        },
        "Delivery ID": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "string"
            },
            "hide": false,
            "rowGroup": false
        },
        "Carton ID": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "string",
                "showTooltip": true
            },
            "hide": false,
            "rowGroup": false
        },
        "Subclass Id": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "custom_field": "subclass_id",
                "datatype": "string",
                "showToolTip": true,
                "tooltipField": "subclass_id"
            },
            "hide": false
        },
        "Style-Color ID": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "custom_field": "styc_id",
                "datatype": "string"
            },
            "hide": false
        },
        "Total Carton Qty": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "custom_field": "pack_ttl",
                "datatype": "number"
            },
            "hide": false
        },
        "Carton Size": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "custom_field": "pack_size",
                "datatype": "number"
            },
            "hide": false
        }
    }
]
