export const StoreIndexCreateEditJobFormSettings = {
  job_name: {
    controlName: 'job_name',
    label: 'Job Name',
    placeholder: null,
    hint: 'Must be unique.',
    formType: 'input',
    datatype: 'string',
    selectMultiple: null,
    selectionOptions: null,
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    validatorOptions: {
      required: true,
      minLength: 3,
      checkForDuplicates: []
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  vg_count: {
    controlName: 'vg_count',
    label: 'Number of Volume Groups',
    // label: 'Set the Number of Volume Groups',
    formType: 'input',
    datatype: 'integer',
    selectMultiple: null,
    selectionOptions: null,
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false,
      min: 0,
      checkIsInteger: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  use_aug_sales: {
    controlName: 'use_aug_sales',
    label: 'Use Augmented Sales',
    formType: 'checkbox',
    datatype: 'boolean',
    selectMultiple: null,
    selectionOptions: null,
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: false,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  buying_season: {
    controlName: 'buying_season',
    label: 'Buying Season',
    formType: 'auto-selection',
    datatype: 'string',
    selectMultiple: false,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: '',
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  custom_season: {
    controlName: 'custom_season',
    label: 'Custom Season',
    hint: 'MM/DD/YYYY – MM/DD/YYYY',
    formType: 'date-range-picker',
    datatype: 'string',
    selectMultiple: false,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: {
      start: null,
      end: null
    },
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '25%'
    }
  },
  prior_selling_seasons: {
    controlName: 'prior_selling_seasons',
    label: 'Prior Selling Season(s)',
    formType: 'selection',
    datatype: 'array-objects',
    selectMultiple: true,
    selectionOptions: [],
    // displayKey: 'season',
    // valueKey: 'season',
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  season: {
    controlName: 'season',
    label: 'Season',
    placeholder: null,
    hint: null,
    formType: 'input',
    datatype: 'string',
    selectMultiple: false,
    selectionOptions: [],
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {}
  },
  weight: {
    controlName: 'weight',
    label: 'Weight',
    placeholder: null,
    hint: null,
    suffix: '%',
    formType: 'input',
    datatype: 'percentage',
    selectMultiple: null,
    selectionOptions: null,
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {}
  }
}
