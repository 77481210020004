<div
    class="flex-center-all"
    style="height: 40px"
    (mousewheel)="mouseWheelListener($event)"
    (DOMMouseScroll)="mouseWheelListener($event)"
>
    <div class="message-container">
        <span> {{loadingMessage}} </span>
        <div class="dot-flashing"></div>
    </div>
</div>
