// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { ExecuteGetAPI, ExecutePostAPI } from './execute_api-calls'
import { type UpdateUsersPermissionsInterface } from '../interfaces/data-expected-from-backend/user-management-interfaces'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class UserManagementAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // get a list of all user's emails
  async GetAllUsers () {
    // the api call path with the parameter string is needed
    const path = '/users/permissions'
    const messages = {
      success: '---> API Call Successful: Retrieved All Users',
      error: 'Error retrieving all users.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success && response.data.length > 0) {
      return response.data
    } else {
      return []
    }
  }

  // Save users
  async UpdateUsersPermissions (data: UpdateUsersPermissionsInterface) {
    // the api call path with the parameter string is needed
    const username = data.username
    const path = `/users/permissions/edit/${username}`
    const messages = {
      success: `Successfully updated permissions for ${data.email}.`,
      error: `Error updating permissions for ${data.email}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  // Get all products
  async GetAllProducts () {
    const path = '/users/permissions/edit'
    const messages = {
      success:
                '---> API Call Successful: Retrieved Retrieved Product Options',
      error: 'Error retrieving product options'
    }
    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success && response.data.length > 0) {
      return response.data.sort((a, b) => a.product_id - b.product_id)
    } else {
      return []
    }
  }
}
