// Angular and RJX Imports
// =========================================================
import { Component, type OnDestroy, type OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { cloneDeep } from 'lodash'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
import { type TreeNode } from 'primeng/api'
// Custom Imports
// =========================================================
import { SizeDistroDefaultParametersAPIs } from 'src/app/core/apis/size-distro-default-parameters_api-calls'
import {
  type StoreIndexCreateEditJobInterface,
  DefaultStoreIndexCreateEditJobForm
} from 'src/app/core/interfaces/data-expected-from-backend/store-index-create-edit-job-interface'
import { StoreIndexCreateEditJobFormSettings } from 'src/app/03_shared-components/forms/form-data/store-index/store-index-create-edit-job'
import { JobCreationAPIs } from 'src/app/core/apis/job-creation_api-calls'
import { StoreIndexAPIs } from 'src/app/core/apis/store-index_api-calls'
import { IsKeyInObj } from 'src/app/utils/global_functions'
import { ResetFormData } from 'src/app/03_shared-components/forms/form-data/form-data-reset'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { type TreeDataParamsInterface } from 'src/app/core/interfaces/data-expected-from-backend/default-parameters-inputs-interface'

@Component({
  selector: 'app-create-edit-store-index-job-modal',
  templateUrl: './create-edit-store-index-job-modal.component.html',
  styleUrls: ['./create-edit-store-index-job-modal.component.scss']
})
export class CreateEditStoreIndexJobModalComponent
implements OnInit, OnDestroy {
  modalTitle: string = 'Store Index Job'
  loading: boolean = true
  loadingMessage: string = 'loading'

  // Params for Product Hierarchy Section
  treeDataParams: TreeDataParamsInterface = null
  loadingProdHier: boolean = true
  selectedHierarchyNode: TreeNode = {}
  defaultSelected: TreeNode[] = []

  parentGridData: any
  selectedProduct: any = null
  isModalConfigEdit: boolean // 2 Options: either edit or create
  defaultStoreIndexCreateEditJobFormSettings = cloneDeep({
    ...StoreIndexCreateEditJobFormSettings
  })

  formData = new BehaviorSubject<any>(StoreIndexCreateEditJobFormSettings)

  get _formData () {
    return this.formData.getValue()
  }

  formErrors: any = {}
  isFormComplete: boolean = false

  priorSellingSeasonsArray: string[] = null
  completedJobScope: StoreIndexCreateEditJobInterface = {
    ...DefaultStoreIndexCreateEditJobForm
  }

  userHasEditPerms: boolean = false

  constructor (
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    public dialogService: DialogService,
    private readonly sizeDistroDefaultParametersAPIs: SizeDistroDefaultParametersAPIs,
    private readonly jobCreationAPIs: JobCreationAPIs,
    private readonly storeIndexAPIs: StoreIndexAPIs,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // Set the configuration of the modal -> either edit or create
    console.log(
      '---> Store Index Output Configuration Mode: ',
      config?.data.action
    )
    const { data } = config
    const status = data?.rowData?.data?.status || ''
    const allowedEditStatuses = ['GENERATED', 'FAILED', 'PENDING']
    this.isModalConfigEdit = data?.action === 'edit'
    this.userHasEditPerms =
            (data?.rowData?.data?.is_editable &&
                allowedEditStatuses.includes(status)) ||
            false

    console.log('---> User Has Edit Perms: ', this.userHasEditPerms)
    // Set the required keys based on the config mode
    this.getRequiredFields(this.isModalConfigEdit && this.userHasEditPerms)

    this.parentGridData = {
      ...this.config?.data?.componentParent,
      rowData: this.isModalConfigEdit ? { ...data.rowData.data } : {}
    }

    // Update the Modal Title
    this.modalTitle = `${
            this.isModalConfigEdit
                ? this.userHasEditPerms
                    ? 'Edit'
                    : ''
                : 'Create New'
        } ${this.modalTitle}`
  }

  ngOnInit () {
    console.log('---> Parent Grid Data: ', this.parentGridData)
    console.log('---> completedJobScope: ', this.completedJobScope)

    this.setSelectionOptions()
  }

  // Set the required fields to create & edit a job
  getRequiredFields (loadWithoutErrors: boolean) {
    const tempFields = {}
    Object.entries(StoreIndexCreateEditJobFormSettings).forEach(
      ([key, value]) => {
        if (value.validatorOptions.required) {
          tempFields[key] = loadWithoutErrors
        }
      }
    )
    this.formErrors = {
      ...tempFields,
      buying_season: true,
      prior_or_custom_season: true,
      season_weighting: loadWithoutErrors,
      product_node: true,
      job_name: true
    }
  }

  async setSelectionOptions () {
    const tempForm = this._formData
    let seasonSelectionOptions = null

    try {
      const allSelectionOptions =
                await this.jobCreationAPIs.GetAllJobSelectionOptions()
      await this.getAllProducts()
      console.log('---> all selection options: ', allSelectionOptions)
      if (allSelectionOptions) {
        const getFilterDates = (dateCat: string) =>
          allSelectionOptions[dateCat]
            .filter(season => {
              const currentDate = new Date()
              const endDate = new Date(season.end_date)
              if (dateCat === 'prior_selling_seasons') {
                return endDate < currentDate
              }
              if (dateCat === 'buying_seasons') {
                return endDate > currentDate
              }
            })
            .map(season => season.season)
        // Update Job selection options
        seasonSelectionOptions = {
          prior_selling_seasons: await getFilterDates(
            'prior_selling_seasons'
          ),
          buying_seasons: await getFilterDates('buying_seasons')
        }
      }

      console.log(
        '----> Season Selection Options:',
        seasonSelectionOptions
      )
    } catch (error) {
      console.error('Error Loading Selection Options')
    } finally {
      console.log(
        '---> Updating Job Scope Form: ',
        this.completedJobScope
      )
      // Update For Values and Selection Options
      Object.keys(tempForm).forEach((field, i) => {
        const currentVal = this.isModalConfigEdit
          ? this.parentGridData.rowData[field]
          : null

        // console.log('--->   field: ', field)
        // console.log('--->   current val: ', currentVal)
        // console.log("this.parentGridData.rowData: ", this.parentGridData.rowData)

        // The season field should be disabled
        if (tempForm[field]) {
          tempForm[field].disabled =
                        field === 'season' ||
                        (this.isModalConfigEdit && !this.userHasEditPerms)
          tempForm[field].loading = false

          if (
            this.isModalConfigEdit &&
                        (currentVal || currentVal === 0)
          ) {
            this.completedJobScope[field] = currentVal
          }
        }

        switch (field) {
          case 'buying_season':
            tempForm[field].selectionOptions =
                            seasonSelectionOptions?.buying_seasons
            tempForm[field].currentVal = currentVal

            break
          case 'prior_selling_seasons':
            tempForm[field].selectionOptions =
                            seasonSelectionOptions?.prior_selling_seasons
            console.log(
              '---> prior selling seasons current val: ',
              currentVal
            )
            if (currentVal && currentVal?.length > 0) {
              tempForm[field].currentVal = currentVal.map(
                season => season.season
              )
              this.priorSellingSeasonsArray =
                                tempForm[field].currentVal
            }
            break
          case 'custom_season':
            if (currentVal) {
              tempForm[field].currentVal = currentVal

              this.completedJobScope[field] =
                                tempForm[field].currentVal
            }

            break
          default:
            if (
              this.isModalConfigEdit &&
                            (currentVal || currentVal === 0)
            ) {
              if (field === 'job_name') {
                tempForm[field].disabled = true
              }
              tempForm[field].currentVal = currentVal
              tempForm[field].defaultVal = currentVal
            }
            break
        }

        if (Object.keys(tempForm).length === i + 1) {
          if (this.isModalConfigEdit) {
            this.runFinalValidation(null, 'validate')
          }
          // this.loadingSelectionOptions = false
          this.loading = false
          this.formData.next(tempForm)
          console.log(
            '---> Updating Job Creation Form Selection Options and Validation: ',
            tempForm
          )
        }
      })
    }
  }

  // Load the hierarchy tree data
  async getAllProducts () {
    try {
      await this.sizeDistroDefaultParametersAPIs
      this.treeDataParams = {
        queryString: '',
        selectedProd:
                    this.isModalConfigEdit &&
                    this.parentGridData?.rowData?.product_node
                      ? this.parentGridData?.rowData?.product_node
                      : null
      }
    } finally {
      console.log('--> async function completed')
    }
  }

  //  Hierarchy Node Selected
  async onNodeSelected (event) {
    console.log('Product Creation Node Selected: ', event)
    this.selectedHierarchyNode = event.node
    this.selectedProduct = {
      label: event.node.label,
      key: event.node.key
    }
    this.completedJobScope['product_node'] = this.selectedProduct.key

    if (this.formErrors['product_node']) {
      this.formErrors['product_node'] = false
      this.checkFormComplete()
    }
  }

  async onUpdateFormValue (form, index?) {
    const fieldData = {
      field: form.controlName,
      value: cloneDeep(form.value),
      status: form.status
    }
    const { field, value, status } = fieldData

    console.log('---> Form Value Updated: ', form)
    console.log('---> Completed Job Scope: ', this.completedJobScope)

    if (status === 'VALID') {
      switch (field) {
        case 'prior_selling_seasons':
        case 'custom_season':
          if (field === 'prior_selling_seasons') {
            try {
              const getSeasons = () => {
                if (value && value.length > 0) {
                  return value.map(val => {
                    const calc = 100 / value.length
                    return {
                      season: val,
                      weight: Number(calc.toFixed(2))
                    }
                  })
                } else {
                  return []
                }
              }
              const setSeasons = getSeasons()
              this.priorSellingSeasonsArray = setSeasons?.map(
                seasons => seasons.season
              )
              await this.checkFormErrors(
                {
                  ...fieldData,
                  errorKey: 'prior_or_custom_season',
                  value:
                                        setSeasons.length > 0
                                          ? setSeasons
                                          : null,
                  status:
                                        setSeasons.length > 0
                                          ? 'VALID'
                                          : 'INVALID'
                },
                true
              )
              await this.validateSeasonWeighting(setSeasons)
            } finally {
              this.completedJobScope['custom_season'] = {
                start: null,
                end: null
              }
            }
          } else if (field === 'custom_season') {
            const { start, end } = cloneDeep(value)
            const startHasVal = start && start !== ''
            const endHasVal = end && end !== ''
            if (
              ((startHasVal || endHasVal) &&
                                !this.completedJobScope[
                                  'prior_selling_seasons'
                                ]) ||
                            (startHasVal && endHasVal)
            ) {
              this.completedJobScope['custom_season'] = {
                start,
                end
              }
              this.priorSellingSeasonsArray = null
              this.completedJobScope['prior_selling_seasons'] =
                                null
              this.checkFormErrors(
                {
                  ...fieldData,
                  errorKey: 'prior_or_custom_season',
                  status:
                                        startHasVal && endHasVal
                                          ? 'VALID'
                                          : 'INVALID',
                  value: { start, end }
                },
                false
              )
              this.validateSeasonWeighting(null, true)
            }
          }
          if (
            !this.completedJobScope.prior_selling_seasons &&
                        !this.completedJobScope?.custom_season.start &&
                        !this.completedJobScope?.custom_season.end
          ) {
            this.checkFormErrors(
              {
                ...fieldData,
                errorKey: 'prior_or_custom_season',
                status: 'INVALID'
              },
              true
            )
          }
          break
        case 'weight':
          this.completedJobScope.prior_selling_seasons[index].weight =
                        Number(value)
          this.validateSeasonWeighting(
            this.completedJobScope.prior_selling_seasons
          )
          break
        default:
          if (
            (field === 'vg_count' || field === 'use_aug_sales') &&
                        !this.completedJobScope.params_changes.includes(field)
          ) {
            this.completedJobScope.params_changes.push(field)
          }
          this.checkFormErrors(fieldData, true)
          break
      }
    } else if (form.status === 'DISABLED') {
      // console.log('field is disabled: ', form);
    } else {
      console.error('---> Field Error: ', form)
      this.checkFormErrors(fieldData, true)
    }
  }

  checkFormErrors (fieldData: any, updateVal?: boolean) {
    console.log('---> Validating Form: ', fieldData)
    const { status, field } = fieldData
    const errorKey = fieldData?.errorKey || field

    if (updateVal) {
      this.completedJobScope[field] = cloneDeep(fieldData?.value)
    }

    if (IsKeyInObj(this.formErrors, errorKey)) {
      this.formErrors[errorKey] = status !== 'VALID'
      this.checkFormComplete()
    }
  }

  checkFormComplete () {
    const hasErrors = Object.values(this.formErrors).filter(
      fields => fields === true
    )

    if (hasErrors.length === 0) {
      console.log(
        '---- FORM DATA COMPLETE ----: ',
        this.completedJobScope
      )
      this.isFormComplete = true
    } else {
      console.error('---- FORM DATA ERROR ----: ', this.formErrors)
      this.isFormComplete = false
    }
  }

  // Validation for season weight in step 1
  validateSeasonWeighting (priorSeasons, sectionNotActive?) {
    console.log('---> Validating Season Weighting: ', priorSeasons)
    const singleSeasons = !sectionNotActive && priorSeasons.length === 1
    // Check Prior Selling Season
    if (priorSeasons && priorSeasons.length > 1 && !sectionNotActive) {
      let totalWeight = 0
      priorSeasons.forEach((season, i) => {
        totalWeight += Number(season.weight)
        if (priorSeasons.length === i + 1) {
          this.checkFormErrors({
            field: 'season_weighting',
            status:
                            Math.round(totalWeight) === 100
                              ? 'VALID'
                              : 'INVALID'
          })
        }
      })
      // }
    } else if (sectionNotActive || singleSeasons) {
      if (singleSeasons) {
        this.completedJobScope.prior_selling_seasons[0].weight = 100
      }
      this.checkFormErrors({
        field: 'season_weighting',
        status: 'VALID'
      })
    }
  }

  onDeleteSeasonWeighting (event, index) {
    console.log('---> Deleting Season Weight: ', event)
    this.completedJobScope?.prior_selling_seasons.splice(index, 1)
    const priorSeason = this.completedJobScope?.prior_selling_seasons

    this.priorSellingSeasonsArray =
            priorSeason?.length > 0
              ? priorSeason.map(season => season.season)
              : []

    this.validateSeasonWeighting(priorSeason)
  }

  // Final Validation Check Before executing functions
  async runFinalValidation (event: any, action: string) {
    console.log('--> Running Final Validation: ')
    console.log('--> Action to Execute: ', action)
    // Notes: 3 actions -> Validate, Run Job, Create Job

    try {
      const getStatus = key =>
        this.completedJobScope[key] &&
                this.completedJobScope[key] !== null &&
                this.completedJobScope[key] !== ''
          ? 'VALID'
          : 'INVALID'
      const checkFormErrors = () =>
        Object.keys(this.formErrors)
          .map((key, i) => {
            switch (key) {
              case 'season_weighting':
                // Validation for season weighting happens within the prior or custom season check
                break
              case 'prior_or_custom_season':
                const { custom_season, prior_selling_seasons } =
                                    this.completedJobScope
                if (
                  !prior_selling_seasons &&
                                    custom_season?.start &&
                                    custom_season?.end
                ) {
                  this.validateSeasonWeighting(null, true)
                  this.formErrors.prior_or_custom_season =
                                        false
                } else if (
                  prior_selling_seasons &&
                                    prior_selling_seasons.length > 0
                ) {
                  this.validateSeasonWeighting(
                    prior_selling_seasons
                  )
                  this.formErrors.prior_or_custom_season =
                                        custom_season &&
                                        (custom_season?.start ||
                                            custom_season?.end)
                }
                break
              default:
                const status = getStatus(key)
                this.checkFormErrors({
                  field: key,
                  status
                })
                break
            }
            return key
          })
          .filter(field => this.formErrors[field])
      const validationComplete = await checkFormErrors()
      this.isFormComplete = validationComplete.length === 0
      console.log('validation complete: ', validationComplete)
    } finally {
      // If the validation was successful execute the action
      switch (action) {
        case 'Validate':
          break
        case 'Run':
          this.triggerSubmitLoader(true)
          this.isModalConfigEdit
            ? this.onSaveIndexJobs('save', true)
            : this.onCreateIndexJobs('create', true)
          break
        case 'Create':
          this.triggerSubmitLoader(true)
          this.onCreateIndexJobs('create', false)
          break
        case 'Save':
          this.triggerSubmitLoader(true)
          this.onSaveIndexJobs('save', false)
          break
      }
    }
  }

  triggerSubmitLoader = loading => {
    this.loadingMessage = loading ? 'submitting' : 'loading'
    this.loading = loading
  }

  // On Run Jobs
  async onRunIndexJobs (newJobToRun: string) {
    console.log('---> Run Copy Job Index: ', this.completedJobScope)
    await this.storeIndexAPIs
      .OnRunJobs({ job_names: [newJobToRun] })
      .then(res => {
        if (res?.is_success) {
          this.loading = false
          this.close({
            message: 'success',
            data: this.completedJobScope,
            action: 'run'
          })
        } else {
          this.loading = false
        }
      })
  }

  // On save index jobs
  async onSaveIndexJobs (action, run) {
    console.log('---> Save Job Index: ', this.completedJobScope)

    await this.storeIndexAPIs
      .SaveStoreIndexJob(this.completedJobScope)
      .then(res => {
        if (res?.is_success) {
          if (run) {
            this.onRunIndexJobs(this.completedJobScope.job_name)
          } else {
            this.loading = false
            this.close({
              message: 'success',
              data: this.completedJobScope,
              action: 'save'
            })
          }
        } else {
          this.close()
        }
        this.loadingMessage = 'loading'
      })
  }

  // On create index jobs
  async onCreateIndexJobs (action, run) {
    console.log('---> Create Copy Job Index: ', this.completedJobScope)
    await this.storeIndexAPIs
      .CreateStoreIndexJob(this.completedJobScope)
      .then(res => {
        console.log('---> submitted create job: ', res)
        if (res.is_success) {
          if (run) {
            this.onRunIndexJobs(res?.data[0])
          } else {
            this.loading = false
            this.close({
              message: 'success',
              data: this.completedJobScope,
              action: 'create'
            })
          }
        } else {
          this.close()
        }
        this.loadingMessage = 'loading'
      })
  }

  async resetFormData () {
    const reset = await ResetFormData(
      {
        form: this.formData,
        currentFormData: this._formData,
        originalFormData:
                    this.defaultStoreIndexCreateEditJobFormSettings
      },
      true
    )
    console.log('---> Resetting Form Data: ', reset)
    this.completedJobScope = { ...DefaultStoreIndexCreateEditJobForm }

    // this.treeData = null
    this.selectedHierarchyNode = {}

    return reset
  }

  close (data?: any) {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.ref) this.ref.close(data || null)
  }

  ngOnDestroy (): void {
    this.resetFormData()
  }
}
