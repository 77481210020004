<form #formDirective="ngForm" [formGroup]="selectionForm">
    <span [ngSwitch]="_formParams?.formType">
        <!-- Standard Input Form Field -->
        <mat-form-field
            [floatLabel]="!hideLabel"
            *ngSwitchCase="'input'"
            color="accent"
        >
            <mat-label *ngIf="!hideLabel">{{ _formParams?.label }}</mat-label>
            <input
                matInput
                type="{{
                    _formParams?.datatype === 'integer' ||
                    _formParams?.datatype === 'number' ||
                    _formParams?.datatype === 'percentage'
                        ? 'number'
                        : 'text'
                }}"
                step="{{ _formParams?.datatype === 'integer' ? '1' : '0.01' }}"
                placeholder="{{ _formParams?.placeholder }}"
                formControlName="{{ controlName }}"
                [autocomplete]="_formParams?.autoComplete ? 'on' : 'off'"
                (ngModelChange)="
                    onInputChange(selectionForm.controls[controlName].value)
                "
                (blur)="onBlur.emit($event)"
            />
            <span matTextPrefix *ngIf="_formParams?.prefix">{{
                _formParams?.prefix
            }}</span>
            <span matTextSuffix *ngIf="_formParams?.suffix">{{
                _formParams?.suffix
            }}</span>
            <mat-hint
                *ngIf="_formParams?.hint"
                [attr.hintColor]="hintColor ? hintColor : 'primary'"
                >{{ _formParams?.hint }}</mat-hint
            >
            <mat-error
                *ngIf="
                    selectionForm.get(controlName).touched &&
                    selectionForm.get(controlName).errors
                "
                >{{ setFormError(controlName, selectionForm) }}
            </mat-error>
        </mat-form-field>
        <!-- Date Picker Input Form Field -->
        <mat-form-field
            [floatLabel]="!hideLabel"
            *ngSwitchCase="'date-picker'"
            color="accent"
        >
            <mat-label *ngIf="!hideLabel">{{ _formParams?.label }}</mat-label>
            <input
                matInput
                [matDatepicker]="picker1"
                formControlName="{{ controlName }}"
                [autocomplete]="_formParams?.autoComplete ? 'on' : 'off'"
                (ngModelChange)="
                    onInputChange(selectionForm.controls[controlName].value)
                "
            />
            <mat-datepicker-toggle
                matIconSuffix
                [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-hint
                *ngIf="_formParams?.hint"
                [attr.hintColor]="hintColor ? hintColor : 'primary'"
                >{{ _formParams?.hint }}</mat-hint
            >

            <mat-error
                *ngIf="
                    selectionForm.get(controlName).touched &&
                    selectionForm.get(controlName).errors
                "
                >{{ setFormError(controlName, selectionForm) }}
            </mat-error>
        </mat-form-field>
        <!-- Date Range -->
        <mat-form-field
            [floatLabel]="!hideLabel"
            *ngSwitchCase="'date-range-picker'"
            color="accent"
        >
            <mat-label *ngIf="!hideLabel">{{ _formParams?.label }}</mat-label>
            <mat-date-range-input [rangePicker]="picker2">
                <input
                    matStartDate
                    formControlName="start"
                    placeholder="Start date"
                    (ngModelChange)="
                        onDateRangeChange(selectionForm.value, 'start')
                    "
                />
                <input
                    matEndDate
                    formControlName="end"
                    placeholder="End date"
                    (ngModelChange)="
                        onDateRangeChange(selectionForm.value, 'end')
                    "
                />
            </mat-date-range-input>
            <mat-datepicker-toggle
                matIconSuffix
                [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>
            <mat-hint
                *ngIf="_formParams?.hint"
                [attr.hintColor]="hintColor ? hintColor : 'primary'"
                >{{ _formParams?.hint }}</mat-hint
            >

            <mat-error
                *ngIf="
                    selectionForm.get(controlName).touched &&
                    selectionForm.get(controlName).errors
                "
                >{{ setFormError(controlName, selectionForm) }}
            </mat-error>
        </mat-form-field>
        <!-- Selection Form Field -->
        <mat-form-field
            [floatLabel]="!hideLabel"
            *ngSwitchCase="'selection'"
            color="accent"
        >
            <mat-label *ngIf="!hideLabel">{{ _formParams?.label }}</mat-label>

            <mat-select
                placeholder="{{ _formParams?.placeholder }}"
                formControlName="{{ controlName }}"
                (selectionChange)="onSelectionChange($event)"
                multiple="{{ _formParams?.selectMultiple }}"
                *ngIf="_formParams?.selectionOptions"
            >
                <!-- <mat-option *ngIf="!_formParams?.validatorOptions.required">None</mat-option> -->
                <mat-option
                    *ngFor="let item of _formParams?.selectionOptions"
                    [value]="
                        _formParams?.valueKey
                            ? item[_formParams?.valueKey]
                            : item
                    "
                    class="capitalize-text"
                >
                    {{
                        _formParams?.displayKey &&
                        _formParams?.displayKey !== null
                            ? item[_formParams?.displayKey]
                            : item
                    }}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="
                    selectionForm.get(controlName).touched &&
                    selectionForm.get(controlName).errors
                "
                >{{ setFormError(controlName, selectionForm) }}
            </mat-error>
        </mat-form-field>
        <!-- Auto-Selection Form Field -->
        <span *ngSwitchCase="'auto-selection'">
            <!-- Single Selection -->
            <mat-form-field
                [floatLabel]="!hideLabel"
                *ngIf="!_formParams?.selectMultiple"
                color="accent"
            >
                <mat-label *ngIf="!hideLabel">{{
                    _formParams?.label
                }}</mat-label>
                <input
                    *ngIf="!_formParams?.selectMultiple"
                    placeholder="{{ _formParams?.placeholder }}"
                    formControlName="{{ controlName }}"
                    aria-label="Auto Selection"
                    matInput
                    (focus)="setFilters()"
                    [matAutocomplete]="columns"
                    (ngModelChange)="setColumn($event, 'value-update')"
                />
                <!-- Auto Complete Logic -->
                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                <mat-autocomplete
                    #columns="matAutocomplete"
                    [displayWith]="displayFn.bind(this)"
                >
                    <!-- [value]="
                _formParams?.valueKey
                    ? selectedColumn[_formParams?.valueKey]
                    : selectedColumn
            " -->
                    <mat-option
                        *ngFor="let selectedColumn of filteredColumns | async"
                        [value]="selectedColumn"
                        class="capitalize-text"
                    >
                        {{
                            _formParams?.displayKey &&
                            _formParams?.displayKey !== null
                                ? selectedColumn[_formParams?.displayKey]
                                : selectedColumn
                        }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error
                    *ngIf="
                        selectionForm.get(controlName).touched &&
                        selectionForm.get(controlName).errors
                    "
                    >{{ setFormError(controlName, selectionForm) }}</mat-error
                >
            </mat-form-field>
            <!-- Multi Select Filter -->
            <mat-form-field
                [floatLabel]="!hideLabel"
                *ngIf="_formParams?.selectMultiple"
                color="accent"
            >
                <mat-label *ngIf="!hideLabel">{{
                    _formParams?.label
                }}</mat-label>

                <mat-select
                    formControlName="{{ controlName }}"
                    multiple
                    (openedChange)="multiSelectInputFilter.focus()"
                    (selectionChange)="onSelectionChange($event)"
                >
                    <mat-form-field
                        color="accent"
                        class="multi-select-filter-form"
                    >
                        <input
                            id="multiSelectInputFilter"
                            #multiSelectInputFilter
                            matInput
                            focused="'true'"
                            type="text"
                            autocomplete="off"
                            [placeholder]="'Search..'"
                        />
                    </mat-form-field>
                    <div
                        class="mat-selection-options-wrapper custom-vertical-scroll"
                    >
                        <mat-option
                            *ngFor="let selectedColumn of multiSelectOptions"
                            [class.hide]="
                                multiSelectInputFilter.value !== '' &&
                                selectedColumn
                                    .toLowerCase()
                                    .indexOf(
                                        multiSelectInputFilter.value.toLowerCase()
                                    ) === -1
                            "
                            [value]="selectedColumn"
                            class="capitalize-text"
                        >
                            {{ selectedColumn }}
                        </mat-option>
                    </div>
                </mat-select>
                <mat-error
                    *ngIf="
                        selectionForm.get(controlName).touched &&
                        selectionForm.get(controlName).errors
                    "
                    >{{ setFormError(controlName, selectionForm) }}</mat-error
                >
            </mat-form-field>
        </span>
        <!-- Chip List - Multi Select -->
        <mat-form-field
            [floatLabel]="!hideLabel"
            color="accent"
            *ngSwitchCase="'chip-list'"
        >
            <mat-label *ngIf="!hideLabel">{{ _formParams?.label }}</mat-label>
            <!-- Multi Select Chip List -->
            <!-- TODO: Notes: Not being used in V1 -> rework disabled handler -->
            <mat-chip-grid #chipGrid aria-label="Selection">
                <mat-chip-row
                    *ngFor="let selectedColumn of selectedChipItems"
                    (removed)="onRemoveChipListItem($event, selectedColumn)"
                >
                    {{
                        _formParams?.displayKey &&
                        _formParams?.displayKey !== null
                            ? selectedColumn[_formParams?.displayKey]
                            : selectedColumn
                    }}
                    <button
                        matChipRemove
                        [attr.aria-label]="'remove ' + selectedColumn"
                    >
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
            </mat-chip-grid>

            <input
                (focus)="setFilters()"
                formControlName="{{ controlName }}"
                #chipSelectionInput
                placeholder="{{ _formParams?.placeholder }}"
                [matChipInputFor]="chipGrid"
                [matAutocomplete]="chipAutoComplete"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (blur)="onLeaveChipInput($event)"
            />

            <!-- Auto Complete Logic -->
            <!-- <mat-icon matSuffix>arrow_drop_down</mat-icon> -->
            <mat-autocomplete
                #chipAutoComplete="matAutocomplete"
                (optionSelected)="selectedChip($event)"
            >
                <mat-option
                    *ngFor="let selectedColumn of filteredColumns | async"
                    [value]="selectedColumn"
                    class="capitalize-text"
                >
                    <!-- [value]="valueKey ? selectedColumn[valueKey] : selectedColumn" -->
                    {{ selectedColumn }}
                </mat-option>
            </mat-autocomplete>
            <mat-error
                *ngIf="
                    selectionForm.get(controlName).touched &&
                    selectionForm.get(controlName).errors
                "
                >{{ setFormError(controlName, selectionForm) }}</mat-error
            >
        </mat-form-field>

        <!-- Radio Button Group -->
        <mat-radio-group
            *ngSwitchCase="'radio'"
            aria-labelledby="'Radio selection"
            formControlName="{{ controlName }}"
            class="custom-radio-group"
            (change)="onRadioChange($event)"
            color="accent"
        >
            <mat-radio-button
                *ngFor="let item of _formParams?.selectionOptions"
                [checked]="item.checked"
                value="{{ item.name }}"
            >
                {{ item.name }}
            </mat-radio-button>
        </mat-radio-group>
        <p *ngSwitchCase="'checkbox'">
            <mat-checkbox
                formControlName="{{ controlName }}"
                [value]="_formParams?.currentVal"
                [checked]="_formParams?.currentVal"
                (click)="$event.stopPropagation(); onCheckboxChange($event)"
                color="accent"
            >
                {{ _formParams?.label }}
            </mat-checkbox>
        </p>
    </span>
</form>
