<app-modal-header
    [modalTitle]="modalTitle"
    [showCloseBtn]="false"
></app-modal-header>
<!-- Modal Content -->
<div class="modal-content" [attr.loading]="false" [attr.size]="'large'">
    <section class="primary-section-container">
        <ag-grid-angular
            id="review-orders-pack-summary-config"
            [columnDefs]="columnDefs"
            class="ag-theme-alpine ag-grid-container"
            [gridOptions]="gridOptions"
        ></ag-grid-angular>
    </section>
</div>
<!-- Modal Footer -->
<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="false"
></app-modal-footer>
