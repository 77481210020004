// Angular and RJX Imports
// =========================================================
import {
  Component,
  type OnInit,
  ViewChild,
  type OnDestroy
} from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { BehaviorSubject } from 'rxjs'
import { cloneDeep } from 'lodash'
// Prime NG Imports
// =========================================================
import { type TreeNode } from 'primeng/api'
// Angular Material Imports
// =========================================================
import {
  type MatCheckboxDefaultOptions,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckbox
} from '@angular/material/checkbox'
// Custom Imports
// =========================================================
import { SizeDistroDefaultParametersAPIs } from 'src/app/core/apis/size-distro-default-parameters_api-calls'
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component'
import { JobManagementCreatorService } from 'src/app/core/services/job-management-creator-service.service'
import {
  GetDefaultParametersDefaultInputs,
  type DefaultParametersInputsInterface,
  DefaultParametersDefaultInputs,
  type TreeDataParamsInterface
} from 'src/app/core/interfaces/data-expected-from-backend/default-parameters-inputs-interface'
import { ResetFormData } from 'src/app/03_shared-components/forms/form-data/form-data-reset'
import { JobCreationAPIs } from 'src/app/core/apis/job-creation_api-calls'
import { type JobManagementsGridRowInterface } from 'src/app/core/interfaces/data-expected-from-backend/job-management-interfaces'
import { JobCreationFormSettings } from 'src/app/03_shared-components/forms/form-data/job-creation/job-creation'
import {
  DefaultJobCreationForm,
  JobResultsToDisplay,
  type JobResultsToDisplayInterface,
  type JobCreationInterface
} from 'src/app/core/interfaces/data-expected-from-backend/job-creation-interface'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { ManagementJobsAPIs } from 'src/app/core/apis/job-management_api-calls'
import { IsKeyInObj } from 'src/app/utils/global_functions'
import { type ApiDataInterface } from 'src/app/core/interfaces/data-expected-from-backend/api-data-interface'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { type PageEvent } from '@angular/material/paginator'

@Component({
  selector: 'app-job-creation',
  templateUrl: './job-creation.component.html',
  styleUrls: ['./job-creation.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
    }
    // ManagementJobsAPIs
  ]
})
export class JobCreationComponent implements OnInit, OnDestroy {
  // Step 1: Sope Form Group
  step_1_FormGroup = this._formBuilder.group({
    scopeCtrl: ['', Validators.required]
  })

  // Step 2: Products Form Group
  step_2_FormGroup = this._formBuilder.group({
    productsCtrl: ['', Validators.required]
  })

  // Step 3: Parameters (Optional)
  step_3_FormGroup = this._formBuilder.group({
    parametersCtrl: ['']
  })

  @ViewChild('confirmPopup') confirmPopup: ConfirmPopupComponent

  // Variables for step 1
  step_1_active_section: number = 0
  loadingSelectionOptions: boolean = true
  sizeSelectionOptions: string[] = []

  // Variables for step 3
  productDetails: DefaultParametersInputsInterface = cloneDeep({
    ...DefaultParametersDefaultInputs
  })

  ogProductDetails: DefaultParametersInputsInterface = null // monitor changes
  changesMade: string[] = []
  clearGeneralProductDetails: boolean

  // Variables For Step 2
  treeDataParams: TreeDataParamsInterface = null

  selectedHierarchyNode: TreeNode = {}
  defaultSelected: TreeNode[] = []
  loadingProdHier: boolean = true
  prodHiersSelected: any = {}
  allSelected = false
  loadingStycs = false

  // Import the original form settings and track updates
  defaultJobCreationFormSettings = cloneDeep({ ...JobCreationFormSettings })
  formData = new BehaviorSubject<any>({ ...JobCreationFormSettings })
  get _formData () {
    return this.formData.getValue()
  }

  priorSellingSeasonsArray: string[] = null
  $defaultJobCreationForm = cloneDeep({ ...DefaultJobCreationForm })
  ogStycList: string[] = []
  ogProductSelection: string = null
  jobFormEdits: JobCreationInterface = cloneDeep({
    ...DefaultJobCreationForm
  })

  step_1_FormErrors: any
  step_2_FormErrors: any = {
    product_id: true,
    styc_count: true,
    stycs: true
  }
  // Notes: The frontend is not currently tracking errors for step 3 due to the complexity of the component
  // step_3_FormErrors: boolean = false

  // Variables for Step 4: Review and Submit
  resultsToDisplay: JobResultsToDisplayInterface[] = JobResultsToDisplay
  submittingData: boolean = false
  // MISC: Global Variables:
  activeScreen: string = null //
  selectedJob: JobManagementsGridRowInterface = null // Create or edit mode
  ObjectKeys = Object.keys
  step_1_JobScopeTitle: string = 'Job Scope'
  paginatedData: string[] = []
  paginatedDataObject: {}
  pageSize = 8
  currentPage = 0

  maxStycCount = 600 // TODO this should be in the env file

  constructor (
    private readonly _formBuilder: FormBuilder,
    private readonly jobManagementCreatorService: JobManagementCreatorService,
    // private readonly sizeDistroDefaultParametersAPIs: SizeDistroDefaultParametersAPIs,
    private readonly jobCreationAPIs: JobCreationAPIs,
    private readonly generalAPIs: GeneralAPIs,
    private readonly router: Router,
    private readonly managementJobsAPIs: ManagementJobsAPIs,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    this.jobManagementCreatorService
      .getSelectedJobDetails()
      .subscribe((res: JobManagementsGridRowInterface) => {
        this.activeScreen = this.getCurrentScreen(res)
        console.log(
          'Job Details WorkFlow - Active Screen: ',
          this.activeScreen
        )
        console.log('Job Creator - Selected Job: ', res)
        if (this.activeScreen !== undefined) {
          if (
            this.activeScreen &&
                        Object.keys(res).length > 0 &&
                        this.activeScreen !== 'creation-screen'
          ) {
            this.selectedJob = res
          } else if (this.activeScreen === 'creation-screen') {
            const storedData = JSON.parse(
              sessionStorage.getItem('selected_job')
            )
            if (
              storedData &&
                            typeof storedData === 'object' &&
                            Object.keys(storedData).length > 0
            ) {
              this.selectedJob = null
              this.resetFormData()

              sessionStorage.removeItem('selected_job')
              this.jobFormEdits = cloneDeep(
                this.$defaultJobCreationForm
              )
            }
          } else {
            console.log('No Job Selected')
            this.selectedJob = null
            this.ngOnInit()
          }
        }
      })
  }

  async ngOnInit () {
    const enableEditing =
            (this.selectedJob && this.selectedJob?.is_editable) ||
            !this.selectedJob
    try {
      this.productDetails = cloneDeep(
        GetDefaultParametersDefaultInputs(enableEditing)
      )
      await this.getRequiredFields(!(enableEditing && !this.selectedJob))
    } catch (error) {
    } finally {
      console.log('---> Setting PRODUCT Details: ', this.productDetails)
      // Reset the Job Details Form
      if (this.selectedJob) {
        const data = await Promise.all([
          this.jobCreationAPIs.GetSelectedJob(
            this.selectedJob.job_name
          ),
          this.jobCreationAPIs.GetAllJobSelectionOptions(),
          this.generalAPIs.GetAllSizesAndOrder()
        ])
        const jobDetails = data[0]
        this.sizeSelectionOptions = data[2]
        console.log('sizeSelectionOptions', this.sizeSelectionOptions)
        if (jobDetails) {
          console.log('---> Setting Job Details: ', jobDetails)
          // console.log('---> Setting Job Details Parsed: ', JSON.parse(jobDetails))

          this.jobFormEdits = {
            ...this.jobFormEdits,
            ...jobDetails,
            parameters: {
              ...this.jobFormEdits.parameters,
              ...jobDetails.parameters
            }
          }
          this.jobFormEdits.job_name =
                        this.activeScreen === 'copy-screen'
                          ? `Copy of ${jobDetails.job_name}`
                          : jobDetails.job_name
          this.ogProductSelection = cloneDeep(jobDetails.product_id)
          this.ogStycList = cloneDeep(jobDetails.stycs)
          console.log('this.ogStycList', this.ogStycList)
          console.log(
            'this.ogProductSelection',
            this.ogProductSelection
          )
          console.log('this.jobFormEdits', this.jobFormEdits)
          // if (this.jobFormEdits?.stycs && this.jobFormEdits?.stycs.length > 0) {
          //    this.jobFormEdits?.stycs.map(styc => this.prodHiersSelected[styc] = true)
          // }
          this.setSelectionOptions(this.jobFormEdits, data[1])

          this.ogProductDetails = {
            ...this.productDetails,
            ...jobDetails.parameters
          }
          this.productDetails = {
            ...this.productDetails,
            ...jobDetails.parameters,
            product_id: jobDetails.product_id
          }
        } else {
          this.resetFormData()
          this.router.navigateByUrl(
            '/size-distro-execution/job-management'
          )
        }
      } else {
        this.ogProductDetails = { ...this.productDetails }
        const data = await Promise.all([
          this.jobCreationAPIs.GetAllJobSelectionOptions(),
          this.generalAPIs.GetAllSizesAndOrder()
        ])
        this.sizeSelectionOptions = data[1]
        this.setSelectionOptions(null, data[0])
      }
    }
  }

  // Determine/set the screen the user has entered
  getCurrentScreen (res) {
    const title = 'Job: Job Scope'
    switch (this.router.url) {
      case '/size-distro-execution/job-creation':
        this.step_1_JobScopeTitle = 'Create ' + title
        return 'creation-screen'
      case '/size-distro-execution/job-management/copy-job':
        this.step_1_JobScopeTitle = 'Copy ' + title
        return 'copy-screen'
      case '/size-distro-execution/job-management/view-edit-job':
        this.step_1_JobScopeTitle = `${
                    res && res.is_editable ? 'Edit' : 'View'
                } ${title}`
        return 'edit-screen'
    }
  }

  // Set the required fields to create a job
  getRequiredFields (loadWithoutErrors: boolean) {
    const tempFields = {}
    Object.entries(this.defaultJobCreationFormSettings).forEach(
      ([key, value]) => {
        if (value.validatorOptions.required) {
          tempFields[key] = loadWithoutErrors
        }
      }
    )
    this.step_1_FormErrors = {
      ...tempFields,
      prior_or_custom_season: loadWithoutErrors,
      season_weighting: loadWithoutErrors
    }
  }

  async setSelectionOptions (
    data: JobCreationInterface,
    allSelectionOptions: any
  ) {
    console.log('---> Setting Input Selection Options: ', data)
    const tempForm = this.defaultJobCreationFormSettings
    let jobSelectionOptions = null
    this.loadingSelectionOptions = true

    try {
      if (allSelectionOptions && this.sizeSelectionOptions) {
        const getFilterDates = (dateCat: string) =>
          allSelectionOptions[dateCat]
            .filter(season => {
              const currentDate = new Date()
              const endDate = new Date(season.end_date)
              if (dateCat === 'prior_selling_seasons') {
                return endDate < currentDate
              }
              if (dateCat === 'buying_seasons') {
                return endDate > currentDate
              }
            })
            .map(season => season.season)
        // Update Job selection options
        jobSelectionOptions = {
          ...allSelectionOptions,
          sizes: this.sizeSelectionOptions,
          prior_selling_seasons: await getFilterDates(
            'prior_selling_seasons'
          ),
          buying_seasons: await getFilterDates('buying_seasons')
        }
        // if Sizes are available sort them before updating the field
        this.jobFormEdits.sizes =
                    data?.sizes && data.sizes.length > 0
                      ? this.sizeSelectionOptions.filter(size =>
                        data.sizes.includes(size)
                      )
                      : []
      }

      console.log('----> All Selection Options:', allSelectionOptions)
    } catch (error) {
      console.error('Error Loading Selection Options')
    } finally {
      console.log('---> Updating Job Details Form: ', this.jobFormEdits)
      // Update For Values and Selection Options
      Object.keys(tempForm).forEach((field, i) => {
        // The season field should be disabled
        tempForm[field].disabled =
                    field === 'season' ||
                    (this.selectedJob && !this.selectedJob.is_editable)
        tempForm[field].loading = false
        if (data && data[field]) {
          const initValue = data[field]
          console.log(field)
          if (field == 'styc_count') {
            console.log(data[field])
          }
          const step = ['product_id', 'styc_count', 'stycs'].includes(
            field
          )
            ? 'step_2'
            : 'step_1'

          tempForm[field].currentVal = initValue

          this.checkStepFormErrors(
            step,
            {
              field,
              status: 'VALID',
              value: initValue,
              errorKey:
                                field === 'prior_selling_seasons' ||
                                field === 'custom_season'
                                  ? 'prior_or_custom_season'
                                  : null
            },
            true
          )
        }

        switch (field) {
          case 'channel':
            tempForm[field].selectionOptions =
                            jobSelectionOptions?.channels
            break
          case 'sizes':
            tempForm[field].selectionOptions =
                            jobSelectionOptions?.sizes
            break
          case 'buying_season':
            tempForm[field].selectionOptions =
                            jobSelectionOptions?.buying_seasons
            break
          case 'prior_selling_seasons':
            tempForm[field].selectionOptions =
                            jobSelectionOptions?.prior_selling_seasons
            if (
              data?.prior_selling_seasons &&
                            data?.prior_selling_seasons?.length > 0
            ) {
              tempForm[field].currentVal =
                                data.prior_selling_seasons.map(
                                  season => season.season
                                )
              this.priorSellingSeasonsArray =
                                tempForm[field].currentVal
              this.validateSeasonWeighting(
                data.prior_selling_seasons
              )
            }
            break
          case 'custom_season':
            tempForm[field].currentVal = data?.custom_season || {
              start: null,
              end: null
            }
            this.jobFormEdits[field] = tempForm[field].currentVal
            if (!data?.prior_selling_seasons) {
              this.validateSeasonWeighting(null, true)
            }
            break
        }

        if (Object.keys(tempForm).length === i + 1) {
          this.loadingSelectionOptions = false
          this.formData.next(tempForm)
          console.log(
            '---> Updating Job Creation Form Selection Options and Validation: ',
            tempForm
          )
        }
      })
      // Get the product hierarchy nodes
      this.getAllProducts()
    }
  }

  // Load the hierarchy tree data
  async getAllProducts () {
    console.log('---> Getting all Products')
    this.treeDataParams = {
      queryString: '',
      selectedProd:
                this.selectedJob && this.selectedJob?.top_product_node
                  ? this.selectedJob.top_product_node
                  : null
    }
  }

  onProductDetailsChanged (form) {
    console.log('Default Parameters Form Value Changed: ', form)
    console.log('---> Original Values: ', this.ogProductDetails)
    console.log('---> Current Values: ', this.jobFormEdits)
    const field = form.controlName
    const knownChanges = this.jobFormEdits.params_changes.includes(field)
    const removeChange = () =>
      this.jobFormEdits.params_changes.splice(
        this.jobFormEdits.params_changes.indexOf(field),
        1
      )
    // Update the data to send to the BE
    this.productDetails[field] = form.value
    // Monitor Changes
    if (this.ogProductDetails[field] != form.value) {
      // console.log('changes made: ', field)
      if (!knownChanges) this.jobFormEdits.params_changes.push(field)
    } else if (knownChanges) {
      removeChange()
    }
    // Update the values to submit to the BE
    this.jobFormEdits.parameters[field] = form.value
  }

  async onUpdate_step_1_FormValue (form, index?) {
    const fieldData = {
      field: form.controlName,
      value: cloneDeep(form.value),
      status: form.status
    }
    const { field, value, status } = fieldData

    console.log('---> Step 1 Form Value Updated: ', form)
    switch (status) {
      case 'VALID':
        switch (field) {
          case 'prior_selling_seasons':
          case 'custom_season':
            if (field === 'prior_selling_seasons') {
              try {
                const getSeasons = () => {
                  if (value && value.length > 0) {
                    return value.map(val => {
                      const calc = 100 / value.length
                      return {
                        season: val,
                        weight: calc.toFixed(2)
                      }
                    })
                  } else {
                    return []
                  }
                }
                const setSeasons = getSeasons()
                this.priorSellingSeasonsArray = setSeasons?.map(
                  seasons => seasons.season
                )
                await this.checkStepFormErrors(
                  'step_1',
                  {
                    ...fieldData,
                    errorKey: 'prior_or_custom_season',
                    value:
                                            setSeasons.length > 0
                                              ? setSeasons
                                              : null,
                    status:
                                            setSeasons.length > 0
                                              ? 'VALID'
                                              : 'INVALID'
                  },
                  true
                )
                await this.validateSeasonWeighting(setSeasons)
              } finally {
                this.jobFormEdits['custom_season'] = {
                  start: null,
                  end: null
                }
              }
            } else if (field === 'custom_season') {
              const { start, end } = cloneDeep(value)
              const startHasVal = start && start !== ''
              const endHasVal = end && end !== ''

              if (
                ((startHasVal || endHasVal) &&
                                    !this.jobFormEdits[
                                      'prior_selling_seasons'
                                    ]) ||
                                (startHasVal && endHasVal)
              ) {
                this.jobFormEdits['custom_season'] = {
                  start,
                  end
                }
                this.priorSellingSeasonsArray = null
                this.jobFormEdits['prior_selling_seasons'] =
                                    null
                this.checkStepFormErrors(
                  'step_1',
                  {
                    ...fieldData,
                    errorKey: 'prior_or_custom_season',
                    status:
                                            startHasVal && endHasVal
                                              ? 'VALID'
                                              : 'INVALID',
                    value: { start, end }
                  },
                  false
                )
                this.validateSeasonWeighting(null, true)
              }
            }
            if (
              !this.jobFormEdits.prior_selling_seasons &&
                            !this.jobFormEdits.custom_season
            ) {
              this.checkStepFormErrors(
                'step_1',
                {
                  ...fieldData,
                  errorKey: 'prior_or_custom_season',
                  status: 'INVALID'
                },
                true
              )
            }
            break
          case 'weight':
            this.jobFormEdits.prior_selling_seasons[index].weight =
                            value
            this.validateSeasonWeighting(
              this.jobFormEdits.prior_selling_seasons
            )
            break
          case 'sizes':
            // Sort Sizes by size order when new values are added or removed
            await this.checkStepFormErrors(
              'step_1',
              {
                ...fieldData,
                value:
                                    value.length > 0
                                      ? this.sizeSelectionOptions.filter(
                                        size => value.includes(size)
                                      )
                                      : null,
                status: value.length > 0 ? 'VALID' : 'INVALID'
              },
              true
            )
            break
          default:
            this.checkStepFormErrors('step_1', fieldData, true)

            break
        }
        break
      case 'INVALID':
        console.error('Input error: ', form)
        this.checkStepFormErrors('step_1', fieldData, true)
        break
      default:
        // console.log('Form Status Update : ', form);
        break
    }
  }

  // Validation for season weight in step 1
  validateSeasonWeighting (priorSeasons, sectionNotActive?) {
    console.log('---> Validating Season Weighting')
    const singleSeasons = !sectionNotActive && priorSeasons.length === 1
    // Check Prior Selling Season
    if (priorSeasons && priorSeasons.length > 1 && !sectionNotActive) {
      let totalWeight = 0
      priorSeasons.forEach((season, i) => {
        totalWeight += Number(season.weight)
        if (priorSeasons.length === i + 1) {
          this.checkStepFormErrors('step_1', {
            field: 'season_weighting',
            status:
                            Math.round(totalWeight) === 100
                              ? 'VALID'
                              : 'INVALID'
          })
        }
      })
      // }
    } else if (sectionNotActive || singleSeasons) {
      if (singleSeasons) {
        this.jobFormEdits.prior_selling_seasons[0].weight = 100
      }
      this.checkStepFormErrors('step_1', {
        field: 'season_weighting',
        status: 'VALID'
      })
    }
  }

  // Step 3 Validation
  // Notes: The frontend is not currently tracking errors for step 3 due to the complexity of the component
  // validateGeneralParameterChanges () {
  // console.log("---> Step 3 Form")
  // }

  checkStepFormErrors (stepForm: string, fieldData: any, updateVal?: boolean) {
    const { status, field } = fieldData
    console.log('---> Validating: ', field)
    const errorKey = fieldData?.errorKey || field
    const formErrors = `${stepForm}_FormErrors`
    const formControl = `${stepForm}_FormGroup`
    const controlName = stepForm.includes('1')
      ? 'scopeCtrl'
      : 'productsCtrl'

    if (updateVal) {
      this.jobFormEdits[field] = cloneDeep(fieldData?.value)
    }

    if (IsKeyInObj(this[formErrors], errorKey)) {
      this[formErrors][errorKey] = status !== 'VALID'

      const hasErrors = Object.values(this[formErrors]).filter(
        fields => fields === true
      )

      if (hasErrors.length === 0) {
        console.log(`---> ${field}: VALID `)
        this[formControl].controls[controlName].setValue('complete')
      } else if (
        this[formControl].controls[controlName].value ||
                !this[formControl].controls[controlName].errors
      ) {
        console.log(`---> ${field}: INVALID `)
        this[formControl].controls[controlName].setValue(null)
        this[formControl].controls[controlName].setErrors({
          required: true
        })
      }
    }
  }

  // Step 1 Functions
  onDeleteSeasonWeighting (event, index) {
    console.log('---> Deleting Season Weight: ', event)
    this.jobFormEdits?.prior_selling_seasons.splice(index, 1)
    const priorSeason = this.jobFormEdits?.prior_selling_seasons

    this.priorSellingSeasonsArray =
            priorSeason?.length > 0
              ? priorSeason.map(season => season.season)
              : []

    this.validateSeasonWeighting(priorSeason)
  }

  // Track stepper changes
  onStepperChange (event) {
    console.log('---> Step Changed: ', event)
    const getStatus = key =>
      this.jobFormEdits[key] &&
            this.jobFormEdits[key] !== null &&
            this.jobFormEdits[key] !== '' &&
            this.jobFormEdits[key].length !== 0
        ? 'VALID'
        : 'INVALID'
    const checkFormErrors = step =>
      Object.keys(this[`${step}_FormErrors`]).map(key => {
        if (
          step === 'step_1' &&
                    (key === 'season_weighting' ||
                        key === 'prior_or_custom_season') &&
                    !this.step_1_FormErrors[key]
        ) {
          // IMPORTANT: Validation for prior_or_custom_season && season_weighting is handled in onUpdate_step_1_FormValue function
        } else {
          let status = getStatus(key)
          if (key === 'styc_count' && this.jobFormEdits[key] > this.maxStycCount) {
            status = 'INVALID'
          }
          this.checkStepFormErrors(step, {
            field: key,
            status
          })
        }
        return this[`${step}_FormErrors`]
      })

    // Run one final check to make sure all required fields have been completed
    if (event.selectedIndex === 3) {
      const formsToValidate = ['step_1', 'step_2']
      const validationComplete = formsToValidate.map((ftv: string) =>
        checkFormErrors(ftv)
      )[0]
      console.log('---> Final Validation Complete: ', validationComplete)
    } else {
      switch (event.previouslySelectedIndex) {
        case 0:
          checkFormErrors('step_1')
          break
        case 1:
          checkFormErrors('step_2')
          break
        case 2:
          // Notes: The frontend is not currently tracking errors for step 3 due to the complexity of the component
          // this.validateGeneralParameterChanges()
          break
        default:
          break
      }
    }
  }

  // Step 2: Product Hierarchy Node Selected
  async onNodeSelected (event, initLoad?: boolean) {
    this.allSelected = false
    this.currentPage = 0
    console.log(
      '---> Job Creation Product Hierarchy Node Selected: ',
      this.jobFormEdits
    )

    const step2Val = this.step_2_FormGroup.controls.productsCtrl.value
    const flattenChildren = node => {
      if (node.children.length > 0) {
        return node.children.flatMap(childNode => [
          ...flattenChildren(childNode)
        ])
      } else {
        return [node.key]
      }
    }

    if (!initLoad) {
      this.prodHiersSelected = {}
    }
    console.log('if')
    if (
      event.node.selectable ||
            (this.selectedJob && !this.selectedJob?.is_editable)
    ) {
      this.selectedHierarchyNode = event.node
      this.jobFormEdits.stycs = []
      this.jobFormEdits.product_id = event.node?.key

      let selectedChildren = [...new Set(flattenChildren(event.node))]
      console.log('here')
      console.log(selectedChildren.length)
      console.log(selectedChildren[0])
      if (
        selectedChildren.length
      // &&
      // selectedChildren[0] === 'fakekey'
      ) {
        this.loadingStycs = true
        selectedChildren = await this.generalAPIs
          .GetStycsForNode(event.node.key)
          .then(res => {
            return res.data
          })
        this.loadingStycs = false
      }

      if (selectedChildren.length > 0) {
        selectedChildren.forEach((child: string) => {
          if (
            this.jobFormEdits.product_id ===
                            this.ogProductSelection &&
                        this.ogStycList.length > 0
          ) {
            if (this.ogStycList.includes(`${child}`)) {
              // console.log("has child: ", child)
              this.prodHiersSelected[child] = true
              this.jobFormEdits.stycs.push(child)
            } else {
              // console.log("no child: ", child)
              this.prodHiersSelected[child] = false
              // this.jobFormEdits.stycs.push(child)
            }
          } else {
            this.prodHiersSelected[child] = false
            // this.jobFormEdits.stycs.push(child)
          }
        })

        this.updatePaginatedData()
      }
      if (!step2Val || step2Val === '') {
        this.step_2_FormGroup.controls.productsCtrl.setValue(
          'complete'
        )
      }
      // console.log('selectedChildren: ', this.prodHiersSelected)
      // console.log('flattened children: ', selectedChildren)
      this.jobFormEdits.styc_count = this.jobFormEdits?.stycs
        ? this.jobFormEdits?.stycs?.length
        : null
    } else {
      if (step2Val) {
        this.step_2_FormGroup.controls.productsCtrl.setValue(null)
      }
    }
    // }
  }

  // Style color node level checkbox toggle
  updateStyleColorSelection (event, selectedNode) {
    console.log('---> Hierarchy Node Selected: ', selectedNode)
    console.log(
      '---> Hierarchy Node Selected-------->: ',
      this.jobFormEdits
    )
    console.log(event)
    console.log(
      '---> Hierarchy Node Selected: selected',
      !this.jobFormEdits?.stycs.includes(selectedNode)
    )

    this.prodHiersSelected[selectedNode] =
            !this.jobFormEdits?.stycs.includes(selectedNode)
    this.paginatedDataObject[selectedNode] =
            !this.jobFormEdits?.stycs.includes(selectedNode)
    if (this.jobFormEdits?.stycs.includes(selectedNode)) {
      this.jobFormEdits?.stycs.splice(
        this.jobFormEdits?.stycs.indexOf(selectedNode),
        1
      )
    } else {
      this.jobFormEdits.stycs.push(selectedNode)
    }
    // handle select all
    let newStatus = true
    for (const key of this.paginatedData) {
      if (!this.paginatedDataObject[key]) {
        newStatus = false
        break
      }
    }
    this.allSelected = newStatus
    // update form values
    this.jobFormEdits.styc_count = this.jobFormEdits?.stycs
      ? this.jobFormEdits?.stycs?.length
      : null
    console.log('--> Job Details Updated: ', this.jobFormEdits)
  }

  toggleAllSelection () {
    console.log('clicked')
    this.allSelected = !this.allSelected

    if (this.allSelected) {
      for (const key of Object.keys(this.paginatedDataObject)) {
        this.prodHiersSelected[key] = true
        this.paginatedDataObject[key] = true
        if (!this.jobFormEdits?.stycs.includes(key)) {
          this.jobFormEdits.stycs.push(key)
        }
      }
    } else {
      for (const key of Object.keys(this.paginatedDataObject)) {
        this.prodHiersSelected[key] = false
        this.paginatedDataObject[key] = false
        if (this.jobFormEdits?.stycs.includes(key)) {
          this.jobFormEdits?.stycs.splice(
            this.jobFormEdits?.stycs.indexOf(key),
            1
          )
        }
      }
    }
    this.jobFormEdits.styc_count = this.jobFormEdits?.stycs
      ? this.jobFormEdits?.stycs?.length
      : null
    console.log('--> Job Details Updated: ', this.jobFormEdits)
    // if (this.allSelected) {

    // {
    //   console.log(key)
    //   console.log(value)
    //   // console.log(this.prodHiersSelected)
    //   return !value;
    // });

    // (item: MatCheckbox) => item.checked = !item.checked);
    // } else {
    //   this.select.options.forEach((item: MatOption) => item.deselect());
    // }
  }

  async resetFormData (clearCurrentData?: boolean) {
    const reset = await ResetFormData(
      {
        form: this.formData,
        currentFormData: this._formData,
        originalFormData: this.defaultJobCreationFormSettings
      },
      true
    )
    console.log('---> Resetting Form Data: ', reset)
    // this.clearGeneralProductDetails = true
    // if (this.treeData) {
    this.getAllProducts()
    // this.treeData = null
    // }
    this.prodHiersSelected = {}
    this.selectedHierarchyNode = {}

    if (this.selectedJob || clearCurrentData) {
      this.jobFormEdits = cloneDeep(this.$defaultJobCreationForm)

      this.clearGeneralProductDetails = true
      this.productDetails = cloneDeep(
        GetDefaultParametersDefaultInputs(false)
      )
    }
  }

  // ** Only resets the Default parameters in step 3
  resetDefaultParameters (event) {
    this.clearGeneralProductDetails = true
  }

  // Track when the Default Parameters screen has finished updating
  onParamFormReset (event) {
    this.jobFormEdits.params_changes = []
    this.clearGeneralProductDetails = false
    this.productDetails = cloneDeep({
      ...GetDefaultParametersDefaultInputs(this.productDetails.editable),
      product_id: this.productDetails.product_id
    })
    this.jobFormEdits.parameters = this.productDetails

    console.log('---> reset Job Details: ', this.jobFormEdits)
    console.log('---> this.productDetails: ', this.productDetails)
  }

  // Trigger for confirm popup add confirm popup
  onConfirmPopupEvent (event) {
    if (event.userInput === 'except') {
      if (event.command === 'Edit Job') {
        this.saveJobAPI(false)
      }
    }
  }

  getDataToSubmit () {
    this.submittingData = true
    const dataToSubmit = cloneDeep({ ...this.jobFormEdits })
    if (IsKeyInObj(dataToSubmit.parameters, 'product_id')) {
      delete dataToSubmit.parameters.product_id
    }
    return dataToSubmit
  }

  // Run Jobs
  onRunJob (event) {
    console.log('----> Run Job initialized')
    this.submittingData = true

    if (
      this.activeScreen === 'creation-screen' ||
            this.activeScreen === 'copy-screen'
    ) {
      this.createJobAPI(true)
    } else {
      this.saveJobAPI(true)
    }
  }

  // Create Jobs
  createJobAPI (run: boolean) {
    console.log('---> Creating Job - run?: ', run)
    const dataToSubmit = this.getDataToSubmit()

    this.jobCreationAPIs
      .CreateJob(dataToSubmit)
      .then((res: ApiDataInterface) => {
        if (res?.is_success) {
          // If run job -> trigger the run job api
          run
            ? this.runJobApi(res?.data[0])
            : this.onAPISubmitComplete()
        } else {
          this.submittingData = false
        }
      })
  }

  // Save Jobs
  saveJobAPI (run: boolean) {
    console.log('---> Saving Job - run?: ', run)

    const dataToSubmit = this.getDataToSubmit()
    dataToSubmit.original_job_name = this.selectedJob.job_name

    this.jobCreationAPIs
      .SaveJob(dataToSubmit)
      .then((res: ApiDataInterface) => {
        if (res?.is_success) {
          // If run job -> trigger the run job api
          run
            ? this.runJobApi(res?.data[0])
            : this.onAPISubmitComplete()
        } else {
          this.submittingData = false
        }
      })
  }

  // Run Jobs
  runJobApi (newJobToRun) {
    console.log('---> Running Job: ', newJobToRun)

    this.managementJobsAPIs
      .RunJobs([newJobToRun], false)
      .then((res: ApiDataInterface) => {
        if (res?.is_success) {
          this.onAPISubmitComplete()
        } else {
          this.submittingData = false
        }
      })
  }

  // Run If API calls were successful
  onAPISubmitComplete = () => {
    // this.jobFormEdits = { ...DefaultJobCreationForm }
    this.submittingData = false
    this.resetFormData(true)
    this.router.navigateByUrl('/size-distro-execution/job-management')
  }

  // TODO: V2 Update: configure reset job details stepper button
  onResetJobDetails (event) {
    console.log('---- Reset Job Creation Stepper ----', event)
  }

  updatePaginatedData () {
    const divElement = document.querySelector(
      '.checkbox-selection-container'
    )
    this.pageSize = Math.floor(divElement.clientHeight / 45)
    const keys = this.ObjectKeys(this.prodHiersSelected)
    const start = this.currentPage * this.pageSize
    const end = start + this.pageSize
    this.paginatedData = keys.slice(start, end)
    this.paginatedDataObject = this.paginatedData.reduce((obj, key) => {
      if (this.prodHiersSelected.hasOwnProperty(key)) {
        obj[key] = this.prodHiersSelected[key]
      }
      return obj
    }, {})
    let newStatus = true
    for (const key of this.paginatedData) {
      if (!this.paginatedDataObject[key]) {
        newStatus = false
        break
      }
    }
    this.allSelected = newStatus
  }

  onPageChange (event: PageEvent) {
    console.log('Page changed to: ', event)
    this.currentPage = event.pageIndex
    this.pageSize = event.pageSize
    this.updatePaginatedData()
  }

  ngOnDestroy (): void {
    this.resetFormData(true)
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
  }
}
