// Custom Imports
// =========================================================
import { FormatKey, IsKeyInObj } from 'src/app/utils/global_functions'
import { GenerateDefaultColDefs } from '../02_global-settings/global-cell-formatter'

export interface UserManagement_GridDataParams {
  mainColDefs: any[]
}

export async function UserManagement_GenerateGridData (
  params: UserManagement_GridDataParams
) {
  const {
    mainColDefs
    // detailColDefs
  } = params
  const GenerateCols = async (data: any[], gridToRender: string) => {
    const mappedCols = {}

    Object.entries(data).map(([colKey, colVal]) => {
      const colId = colVal.refData?.customField || FormatKey(colKey)

      const obj = {
        ...GenerateDefaultColDefs(colKey, { colId, ...colVal })
      }
      Object.assign(mappedCols, {
        [obj.colId]: UserManagement_customCellFormatting(
          obj,
          gridToRender
        )
      })
    })

    return Object.values(mappedCols)
  }

  const getResults = async () => {
    const mainCols = await GenerateCols(mainColDefs, 'main')

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    return data
  }
  return await getResults()
}

export const UserManagement_customCellFormatting = (col, gridToRender) => {
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      switch (col.colId) {
        case 'products':
          return {
            cellEditorParams: {
              propertyRendered: 'products',
              returnObject: false,
              rowData: [],
              columnDefs: [
                {
                  headerName:
                                        'Product Selection'.toUpperCase(),
                  // field: 'product_id',
                  checkboxSelection: true,
                  refData: {
                    datatype: 'string',
                    custom_field: 'product_id'
                  },
                  // minWidth: 780,
                  suppressMenu: true,
                  valueFormatter: params => {
                    if (params.data) {
                      const { products, product_nm } =
                                                params.data
                      return `${products}: ${product_nm}`
                    }
                    return 'Select A Product'
                  }
                }
              ]
            },
            valueFormatter: params => {
              const value = params.data[col.colId]
              return (params.value =
                                value && value[0] !== null && value.length > 0
                                  ? value.sort((a, b) => a - b).join(', ')
                                  : 'No products selected.')
            }
          }
        case 'edited': {
          return {
            valueGetter: params => {
              if (
                params.value === null ||
                                !IsKeyInObj(params.data, 'edited')
              ) {
                params.data.edited = false
                params.value = false
              } else {
                params.value = params?.data?.edited
                return params.value
              }
            }
          }
        }
        case 'save':
          return {
            cellRendererParams: params => {
              const dataUpdates = IsKeyInObj(
                params.data,
                'edited'
              )
              return {
                disabled:
                                    (dataUpdates && !params?.data?.edited) ||
                                    !dataUpdates,
                disabledKeys: dataUpdates ? ['edited'] : null,
                toolTipMessage: `Save user permissions for: ${params.data.email}`,
                onClick: params =>
                  params.context.updateUsersPermissions(
                    params
                  )
              }
            }
          }
      }
    }
  }
  const updatedCol = { ...col, ...getParams() }
  return updatedCol
}
