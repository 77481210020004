import { Component, type OnInit } from '@angular/core'
import * as ApplicationSections from '../../core/json-data/application-sections.json'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  $ApplicationSections = ApplicationSections

  ngOnInit () {
    console.log('Dashboard Data: ', this.$ApplicationSections[0])
  }
}
