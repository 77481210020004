<section class="basic-headers-section-wrapper">
    <span
        class="basic-headers-container"
        [attr.color]="color"
        style="
            display: flex;
            justify-content: space-between;
            align-items: center;
        "
    >
        <h3 *ngIf="!textEllipsis" class="section-title">
            {{ sectionName }}
        </h3>
        <h3
            *ngIf="textEllipsis"
            class="section-title text-ellipsis"
            #tooltip="matTooltip"
            matTooltip="{{ sectionName }}"
            matTooltipClass="below"
        >
            {{ sectionName }}
        </h3>
        <ng-content></ng-content>
    </span>
    <!-- <div class="section-body-container" style="padding: 10px">
        <ng-content></ng-content>
    </div> -->
</section>
