// Angular and RJX Imports
// =========================================================
import { Component, EventEmitter, Output } from '@angular/core'
// Prime NG Imports
// =========================================================
import { ConfirmationService } from 'primeng/api'
// Custom Imports
// =========================================================
// import { ToastAlertComponent } from '../toast-alert/toast-alert.component';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
  providers: [ConfirmationService]
})
export class ConfirmPopupComponent {
  @Output() confirmModal: EventEmitter<any> = new EventEmitter<any>()

  constructor (
    private readonly confirmationService: ConfirmationService // private readonly toastAlert: ToastAlertComponent,
  ) {
    // console.log("--- Inside Confirm Popup ---")
  }

  // async
  async confirm (event, command?, customMessage?) {
    console.log('----- Confirmation -----: ', event)
    console.log('--> command: ', command)
    // console.log('--> customMessage: ', customMessage)
    // await
    await this.confirmationService.confirm({
      target: event?.target || event?.event?.target || null,
      message:
                customMessage ||
                'Are you sure that you want to proceed? This action cannot be reversed.',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        console.log('--> Action Excepted')
        this.confirmModal.emit({
          userInput: 'except',
          command,
          params: event
        })
      },
      reject: () => {
        this.confirmModal.emit({ userInput: 'reject' })
        console.log('---> Action canceled')
        // this.toastAlert.showWarn('Action Canceled')
      }
    })
  }

  ngOnInit () {}
}
