[
    {
        "Product Size": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "rowGroup": false,
            "hide": false
        },
        "Inner Carton QTY": {
            "editable": false,
            "refData": {
                "datatype": "number"
            },
            "rowGroup": false,
            "hide": false
        }
    }
]
