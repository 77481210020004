// Angular and RJX Imports
// =========================================================
import { Component, type OnDestroy, type OnInit } from '@angular/core'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Ag Grid Imports
// =========================================================
import {
  type GridReadyEvent,
  type GridApi,
  type ColumnApi
} from 'ag-grid-community'
// JSON Data
// =========================================================
import $packSummaryConfigGridJSON from '../../../05_ag-grid-configs/01_json-grid-configs/review-orders/colDefs-main-pack-summary-config.json'
// Custom Imports
// =========================================================
import { CustomGridOptions } from 'src/app/05_ag-grid-configs/02_global-settings/grid-options'
import { PackSummaryConfig_GenerateGridData } from 'src/app/05_ag-grid-configs/04_generate-colDefs/review-orders-pack-summary-config-colDefs'

@Component({
  selector: 'app-pack-summary-config',
  templateUrl: './pack-summary-config.component.html',
  styleUrls: ['./pack-summary-config.component.scss']
})
export class PackSummaryConfigComponent implements OnInit, OnDestroy {
  modalTitle: string = 'Carton Configuration: '

  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: this.modalTitle,
      fitAllColumns: true
    }
  }

  // Ag Grid Configuration
  gridApi: GridApi
  columnApi: ColumnApi
  gridOptions: any = {}
  isAgGridLoading: boolean = true
  // Grid Data
  columnDefs: any[] = []
  parentGridData: any

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService
  ) {
    console.log('---> Pack Summary Config Modal: ', config.data)
    this.modalTitle = this.modalTitle + config.data.carton_id
    this.parentGridData = config.data

    //  Set AG Grid Options
    this.gridOptions = {
      ...this.customGridOptions,
      onGridReady: (event: GridReadyEvent) => this.onGridReady(event)
    }
  }

  ngOnInit () {}

  onGridReady (event: GridReadyEvent) {
    this.gridApi = event.api
    this.columnApi = event.columnApi
    this.isAgGridLoading = false
    this.renderGrid(
      $packSummaryConfigGridJSON[0],
      this.parentGridData.sizes
    )
  }

  // Render AG Grid Column Definitions
  async renderGrid (mainColDefs, rowData) {
    const params = {
      mainColDefs
    }
    const gridData = await PackSummaryConfig_GenerateGridData(params)
    if (gridData) {
      this.columnDefs = gridData.mainColDefs
      // function to check if AgGrid is loading
      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          console.log('Column Defs: ', this.columnDefs)
          this.gridApi?.setColumnDefs(gridData.mainColDefs)
          this.gridApi?.setRowData(rowData)
          // Set pinned row to display totals
          this.gridApi?.setPinnedBottomRowData([
            {
              inner_carton_qty: null,
              product_size: null
            }
          ])
          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  close (data?: any) {
    if (this.ref) this.ref.close(data || null)
  }

  ngOnDestroy () {
    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions()
      this.gridApi.expireValueCache()
      this.gridApi = null
    }
  }
}
