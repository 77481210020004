// Angular and RJX Imports
// =========================================================
import {
  Component,
  EventEmitter,
  Input,
  Output,
  type OnInit
} from '@angular/core'
import { FormatKey } from 'src/app/utils/global_functions'

@Component({
  selector: 'app-accordion-headers',
  templateUrl: './accordion-headers.component.html',
  styleUrls: ['./accordion-headers.component.scss']
})
export class AccordionHeadersComponent implements OnInit {
  @Input() panelOpenState: boolean = false
  @Input() disabled: boolean = false
  @Input() sectionDescription?: string
  @Input() sectionName: string
  @Input() displayActionRow: boolean = false
  @Input() stepNumber: number = 0
  @Input() lastStep: boolean = false
  @Input() color: string = 'lte'
  @Output() stepChanged = new EventEmitter<any>()
  @Output() panelStateChanged = new EventEmitter<any>()

  step = 0
  // constructor () {}

  ngOnInit (): void {
    this.setStep(this.stepNumber)
  }

  onPanelStateChanged (state: string) {
    console.log('---> Panel State Updated: ', state)
    this.panelStateChanged.emit({
      state,
      section: FormatKey(this.sectionName)
    })
  }

  setStep (index: number) {
    this.step = index
  }

  nextStep () {
    if (this.step === this.stepNumber) {
      this.step++
      this.stepChanged.emit(this.step)
    }
  }

  prevStep () {
    if (this.step === this.stepNumber) {
      this.step--
      this.stepChanged.emit(this.step)
    }
  }
}
