<app-modal-header
    modalTitle="Size Distro Copy: New Buying Season"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div
    class="modal-content"
    [attr.loading]="loading"
    [attr.flexDirection]="'row'"
    [attr.size]="'large'"
>
    <section
        *ngIf="!loading"
        id="new-buying-season-selection"
        class="primary-section-container"
    >
        <app-basic-headers
            [sectionName]="'Selected Distro(s) To Copy'"
            [color]="'lte'"
        >
        </app-basic-headers>
        <!-- Checkbox list of selected distros -->
        <div class="section-body-container">
            <span class="checkbox-list-section custom-vertical-scroll">
                <!-- Selected Style Colors list -->
                <ul
                    class="form-content-container"
                    *ngIf="ObjectKeys(distroIdsToCopy).length > 0"
                >
                    <li
                        *ngFor="
                            let selectedDistro of ObjectKeys(distroIdsToCopy)
                        "
                    >
                        <mat-checkbox
                            color="accent"
                            [value]="distroIdsToCopy[selectedDistro].checked"
                            [checked]="distroIdsToCopy[selectedDistro].checked"
                            (click)="
                                $event.stopPropagation();
                                onUpdateDistroIdSelection(
                                    $event,
                                    selectedDistro
                                )
                            "
                        >
                            {{
                                selectedDistro +
                                    '; ' +
                                    distroIdsToCopy[selectedDistro].season
                            }}
                        </mat-checkbox>
                    </li>
                </ul>
                <!-- If there are no selected Distros -->
                <span *ngIf="ObjectKeys(distroIdsToCopy).length === 0">
                    <p class="m-t-10">** No Distros Selected **</p>
                </span>
            </span>
            <!-- Selected Distros Footer -->
            <div class="selection-summary-text-container">
                <!-- Original Selection From the grid -->
                <span>
                    <p class="summary-title"><b>Selected Distro(s):</b></p>
                    <p>
                        {{ selectedDistroIds.length }}
                    </p></span
                >
                <!-- Total Distros to Copy -->
                <span>
                    <p class="summary-title"><b>Total Distro(s) To Copy:</b></p>
                    <p>
                        {{
                            completedDistroCopy?.distros_to_copy?.length ||
                                'No Distros Selected'
                        }}
                    </p>
                </span>
            </div>
        </div>
    </section>
    <!-- New Buying Season Selection -->
    <section
        id="new-buying-season-form"
        *ngIf="!loading"
        class="primary-section-container"
    >
        <app-basic-headers
            [sectionName]="'Buying Season Selection'"
            [color]="'lte'"
        ></app-basic-headers>
        <!-- New Buying Season -->
        <div class="section-body-container">
            <app-form-fields
                [formParams]="formData"
                [controlName]="'new_buying_season'"
                (onFormFieldUpdated)="updateFormValue($event)"
            >
            </app-form-fields>
        </div>
    </section>
    <!-- Loading Component -->
    <div *ngIf="loading" class="flex-center-all h-100 w-100">
        <app-loader [loadingMessage]="loadingMessage"></app-loader>
    </div>
</div>

<!-- Modal Footer -->
<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="true"
    (update)="onSubmitCopyNewBuyingSeason($event)"
    [disabled]="
        loading ||
        completedDistroCopy.distros_to_copy.length === 0 ||
        !completedDistroCopy.new_buying_season
    "
></app-modal-footer>
