// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ExecuteGetAPI, ExecutePostAPI } from './execute_api-calls'
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { TriggerApiCallsService } from '../services/cancel-api-call'
import { type JobCreationInterface } from '../interfaces/data-expected-from-backend/job-creation-interface'

@Injectable()
export class JobCreationAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Get All Jobs
  async GetSelectedJob (jobName: string) {
    // the api call path with the parameter string is needed
    const path = `/jobs/details/${jobName.replaceAll('/', '%2F')}`
    const messages = {
      success: `---> API Call Successful: Retrieved Details for Job ${jobName}`,
      error: `Error retrieving details for job: ${jobName}.`
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success) {
      return response.data
    } else {
      return null
    }
  }

  // Get All Jobs Selection Options
  async GetAllJobSelectionOptions () {
    // the api call path with the parameter string is needed
    const path = '/jobs/selection_values'
    const messages = {
      success:
                '---> API Call Successful: Retrieved All Job Selection Options',
      error: 'Error retrieving job selection options.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success) {
      return response.data
    } else {
      return null
    }
  }

  // Create Job
  async CreateJob (data: JobCreationInterface) {
    // Delete the product id from the parameters
    delete data.parameters.product_id
    // the api call path with the parameter string is needed
    const path = '/jobs/create'
    const messages = {
      success: `Successfully created job: ${data.job_name}.`,
      error: `Error creating job: ${data.job_name}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  // Update an existing Job
  async SaveJob (data: JobCreationInterface) {
    console.log('Save Job', data)
    // the api call path with the parameter string is needed
    const path = '/jobs/edit'
    const messages = {
      success: `Successfully saved job: ${data.job_name}.`,
      error: `Error saving job: ${data.job_name}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }
}
