// Custom Imports
// =========================================================

import { FormatKey } from 'src/app/utils/global_functions'
import { GenerateDefaultColDefs } from '../02_global-settings/global-cell-formatter'

export interface JobExceptions_GridDataParams {
  mainColDefs: any[]
}

export async function JobExceptions_GenerateGridData (
  params: JobExceptions_GridDataParams
) {
  const {
    mainColDefs
    // detailColDefs
  } = params
  const GenerateCols = async (data: any[], gridToRender: string) => {
    const mappedCols = {}

    Object.entries(data).map(([colKey, colVal]) => {
      const colId = colVal.refData?.custom_field || FormatKey(colKey)

      const obj = {
        ...GenerateDefaultColDefs(colKey, { colId, ...colVal })
      }
      Object.assign(mappedCols, {
        [obj.colId]: JobExceptions_customCellFormatting(
          obj,
          gridToRender
        )
      })
    })

    return Object.values(mappedCols)
  }

  const getResults = async () => {
    const mainCols = await GenerateCols(mainColDefs, 'main')

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    return data
  }
  return await getResults()
}

export const JobExceptions_customCellFormatting = (col, gridToRender) => {
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      switch (col.colId) {
        case 'delete':
          return {
            cellRendererParams: params => {
              return {
                disabled: false,
                toolTipMessage: `WARNING: Deleting Job: ${params.data.job_name} cannot be reversed!`,
                onClick: params =>
                  params.context.deleteExceptions(params)
              }
            }
          }
      }
    }
  }
  const updatedCol = { ...col, ...getParams() }
  return updatedCol
}
