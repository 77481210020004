// Angular and RJX Imports
// =========================================================
import { Component } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ILoadingOverlayParams } from 'ag-grid-community'
import { type ILoadingOverlayAngularComp } from 'ag-grid-angular'

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './grid-loading-overlay.html',
  styleUrls: ['./grid-loading-overlay.scss']
})
export class GridLoadingOverlay implements ILoadingOverlayAngularComp {
  private params: ILoadingOverlayParams

  loadingMessage: string = 'loading'

  agInit (params): void {
    this.params = params
    // this.loadingMessage = this.params.loadingMessage
  }
}
