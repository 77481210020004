// Angular and RJX Imports
// =========================================================
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { cloneDeep } from 'lodash'

// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { SDS_DrillInFiltersSelectedStoreListFormSettings } from 'src/app/03_shared-components/forms/form-data/size-distro-summary-drill-in-filters'
import { DrillInFilteringAPIs } from 'src/app/core/apis/drill-in-filtering_api-calls'
import { FormatKey } from 'src/app/utils/global_functions'
import {
  type StoreListIDInterface,
  type StoreInterface,
  type StoreListInterface
} from 'src/app/core/interfaces/data-expected-from-backend/drill-in-filtering_interface'
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component'

@Component({
  selector: 'app-drill-in-filtering',
  templateUrl: './drill-in-filtering.component.html',
  styleUrls: ['./drill-in-filtering.component.scss']
})
export class DrillInFilteringComponent {
  loadingMessage: string = 'loading'
  loadingStores: boolean = true
  // List of currently saved stores: Left Side Drag and drop Vars
  savedStoreList!: StoreListIDInterface[]
  currentStoreListNames: string[] = []
  // Available Stores: Center drag and drop Vars
  og_availableStores: StoreInterface[]
  availableStores: StoreInterface[]
  // Selected stores in current store list -> Right side drag and drop
  selectedStoreList: StoreListIDInterface

  // Import the original form settings and track updates
  defaultSelectedStoreListFormSettings = cloneDeep({
    ...SDS_DrillInFiltersSelectedStoreListFormSettings
  })

  formData = new BehaviorSubject<any>(
    this.defaultSelectedStoreListFormSettings
  )

  get _formData () {
    return this.formData.getValue()
  }

  // Final store list
  completedStoreList: StoreListInterface = {
    store_list: null,
    stores: [],
    id: null
  }

  formDataErrors: string[] = []

  // Track if stores lists were added, changed, or deleted to update the left side list of available stores, the store list name validation, and available lists from the summary grid
  storeListsModified: boolean = false

  @ViewChild('pickList') pickList
  @ViewChild('confirmPopup') confirmPopup: ConfirmPopupComponent

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private readonly cdr: ChangeDetectorRef,
    public drillInFilteringAPIs: DrillInFilteringAPIs
  ) {
    console.log('---> Drill In Filtering Modal: ', this.config.data)
    const {
      data: {
        storeLists: { allLists, listNames }
      }
    } = this.config
    this.savedStoreList = allLists
    this.currentStoreListNames = listNames

    document.addEventListener('paste', event => {
      event.preventDefault()
      if (
        event.target['id'] &&
                event.target['id'].includes('paste-zone')
      ) {
        this.pasteToSelectedStoreList(event)
      }
      this.pickList.cd.detectChanges()
    })
  }

  getId () {
    console.log('track by: ', event)
    return 'id'
  }

  ngOnInit () {
    // Get all available stores
    this.getAllStores(true)
    this.cdr.detectChanges()
  }

  async getAllStores (selectedStoreList?: boolean) {
    // disabled available store list if no stores are available
    try {
      // retrieve the store list options
      const allStores = await this.drillInFilteringAPIs.GetAllStores()
      // Store the original list of available stores to reset the stores when changes are made to the store list selection
      this.og_availableStores = cloneDeep(allStores)
      this.availableStores = allStores
    } finally {
      this.loadingStores = false
      if (selectedStoreList) {
        const {
          selectedStoreList: { store_list }
        } = this.config.data
        this.onStoreSelectionChange({ value: store_list })
        this.updateStoreListFormSettings(false)
      }
      this.cdr.detectChanges()
    }
  }

  updateStoreListFormSettings (reset: boolean) {
    const tempForm = reset
      ? this._formData
      : cloneDeep({
        ...this.defaultSelectedStoreListFormSettings
      })
    // Reset the store list name form
    tempForm['store_list'] = {
      ...tempForm['store_list'],
      disabled: false,
      loading: false,
      currentVal: this.completedStoreList.store_list,
      validatorOptions: {
        ...tempForm['store_list'].validatorOptions,
        checkForDuplicates:
                    !reset && this.selectedStoreList.store_list
                      ? this.currentStoreListNames.filter(
                        name =>
                          name !==
                                  FormatKey(this.selectedStoreList.store_list)
                      )
                      : this.currentStoreListNames
      }
    }
    console.log('---> temp form: ', tempForm)
    this.formData.next(tempForm)
  }

  updateFormValue (form) {
    console.log('---> Update Selected Store List: ', form)

    const field = form.controlName
    const value = cloneDeep(form.value)

    const checkFormErrors = (status, errorField?: string) => {
      const f = errorField || field

      if (status === 'VALID') {
        // If a field was previously flagged as an error or had no data remove it from the form errors
        if (this.formDataErrors.includes(f)) {
          this.formDataErrors.splice(
            this.formDataErrors.indexOf(f),
            1
          )
        }
      } else {
        if (!this.formDataErrors.includes(f)) {
          this.formDataErrors.push(f)
        }
      }
    }
    if (form.form.status === 'VALID') {
      this.completedStoreList[field] = value
      checkFormErrors('VALID')
    } else if (form.form.status === 'DISABLED') {
      console.log('field is disabled: ', form)
    } else {
      console.error('field error: ', form)
      checkFormErrors('INVALID')
    }
  }

  // Transfer and process data from the clipboard
  pasteToSelectedStoreList (event) {
    let paste = (event['clipboardData'] || window['clipboardData']).getData(
      'text'
    )
    console.log('paste: ', paste)

    if (paste && typeof paste === 'string' && paste.trim() !== '') {
      paste = paste.includes(',')
        ? paste.trim().replaceAll(', ', ',').split(',')
        : paste.split(/\r?\n/)

      const pasteFormatted = [...new Set([...paste])]
        .filter(store => {
          const indexOfStore = this.availableStores.findIndex(
            avStore => avStore.id === store
          )
          this.availableStores.splice(indexOfStore, 1)
          return indexOfStore !== -1
        })
        .map(store => {
          return { id: store, store_id: store }
        })

      this.completedStoreList.stores = [
        ...new Set([
          ...this.completedStoreList.stores,
          ...pasteFormatted
        ])
      ].sort((a, b) => (a.id > b.id ? 0 : -1))
    }
  }

  // Sort the Source and Target store lists when items are dragged and dropped
  sortStoreLists (event, storeList: string) {
    if (storeList === 'source') {
      this.availableStores.sort((a, b) => (a.id > b.id ? 0 : -1))
    } else if (storeList === 'target') {
      this.completedStoreList.stores.sort((a, b) =>
        a.id > b.id ? 0 : -1
      )
    }
  }

  // Get and set new data on store list change
  async onStoreSelectionChange (event) {
    console.log('---> Store Selection Changed: ', event)
    this.loadingStores = true
    this.availableStores = cloneDeep(this.og_availableStores)
    this.selectedStoreList = event.value

    try {
      if (this.selectedStoreList.id) {
        const { id, store_list } = this.selectedStoreList
        const stores =
                    (await this.drillInFilteringAPIs.GetStoresByStoreListID(
                      this.selectedStoreList.id
                    )) || []
        this.completedStoreList = {
          //  Update the completed store list and the available store list
          stores: stores
            .filter(store => {
              const indexOfStore = this.availableStores.findIndex(
                avStore => avStore.id === store.id
              )
              this.availableStores.splice(indexOfStore, 1)
              return indexOfStore !== -1
            })
            .sort((a, b) => (a.id > b.id ? 0 : -1)),
          store_list,
          id
        }
        await this.updateStoreListFormSettings(false)
      }
    } finally {
      this.cdr.detectChanges()
      this.loadingStores = false
    }
  }

  // Reset the store list and store list selections
  onResetStoreList (event) {
    console.log('---> Reset Store List: ')

    this.availableStores = cloneDeep(this.og_availableStores)
    this.selectedStoreList = {
      store_list: null,
      id: null
    }
    this.completedStoreList = {
      stores: [],
      store_list: null,
      id: null
    }
    this.updateStoreListFormSettings(true)
  }

  // Delete Store List by ID
  async onDeleteStoreList (event, storeList) {
    console.log('---> Delete Store: ', storeList)

    try {
      if (event.userInput === 'except') {
        this.loadingStores = true
        this.loadingMessage = 'submitting'

        await this.drillInFilteringAPIs
          .OnDeleteStoreList(storeList)
          .then(async res => {
            if (res?.is_success) {
              // Track if save was successful to trigger a store list re-load from the summary grid when the modal is closed
              if (!this.storeListsModified) {
                this.storeListsModified = true
              }
              // Re-pull the store lists to get updated data
              const { allLists, listNames } =
                                await this.drillInFilteringAPIs.GetAllStoreLists()

              this.savedStoreList = allLists
              this.currentStoreListNames = listNames
              // Reset the current store list on save
              this.onResetStoreList(null)
            }
          })
      }
    } finally {
      this.loadingStores = false
      this.loadingMessage = 'loading'
    }
  }

  async onSaveStoreList (event) {
    console.log('---> Submit store list: ', this.completedStoreList)
    try {
      this.loadingStores = true
      this.loadingMessage = 'submitting'
      const dataToSend = cloneDeep({
        ...this.completedStoreList,
        stores: this.completedStoreList.stores.map(store => store.id)
      })

      await this.drillInFilteringAPIs
        .OnSaveStoreList(dataToSend)
        .then(async res => {
          if (res?.is_success && res?.data.id) {
            // Track if save was successful to trigger a store list re-load from the summary grid when the modal is closed
            if (!this.storeListsModified) {
              this.storeListsModified = true
            }

            // Re-pull the store lists to get updated data
            const { allLists, listNames } =
                            await this.drillInFilteringAPIs.GetAllStoreLists()

            this.savedStoreList = allLists
            this.currentStoreListNames = listNames

            // Reset the current store list on save
            this.onResetStoreList(null)
          }
        })
    } finally {
      this.loadingStores = false
      this.loadingMessage = 'loading'
    }
  }

  close () {
    if (this.ref) {
      if (this.storeListsModified) {
        this.ref.close({
          updatedStoreLists: {
            allLists: this.savedStoreList,
            listNames: this.currentStoreListNames
          }
        })
      } else {
        this.ref.close()
      }
    }
  }
}
