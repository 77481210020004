// Custom Imports
// =========================================================
import { ReverseFormatKey } from 'src/app/utils/global_functions'
import { FormatJSONColData } from '../02_global-settings/01_transformJSONColData'
import { CellClassRules } from '../02_global-settings/cell-class-rules'
import { FormatPercentage } from '../02_global-settings/global-cell-formatter'

export interface SRA_GridDataParams {
  mainColDefs: any[]
  customCellFormatting: Function
}

export async function SRA_GenerateGridData (params: SRA_GridDataParams) {
  const {
    mainColDefs,
    customCellFormatting
    // detailColDefs
  } = params

  const getResults = async () => {
    const mainCols = await FormatJSONColData(
      mainColDefs,
      'main',
      customCellFormatting
    )

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    console.log('Size Range Alterations: ', results[m])
    return data
  }
  return await getResults()
}

export const SRA_alterations_customCellFormatting = (col, gridToRender) => {
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      switch (col.colId) {
        case 'size':
          return {
            comparator: (valueA, valueB, nodeA) => {
              const sizeOrder =
                                nodeA?.beans?.clientSideRowModel
                                  ?.gridOptionsService?.gridOptions?.context
                                  ?.componentParent?.sizeOrder
              const indexA = sizeOrder.indexOf(valueA)
              const indexB = sizeOrder.indexOf(valueB)
              // Compare the indexes in the custom order array
              return indexA - indexB
            }
          }
        case 'alteration':
          return {
            valueFormatter: params => {
              const getFormattedAlt = alteration => {
                switch (alteration) {
                  case 'split_sizes':
                    // return "split size"
                    return `split size ${params.data.split_sizes} to ${params.data.new_size_1} & ${params.data.new_size_2}`
                  case 'merge_sizes':
                    // return "merge size"
                    return `merge size to ${
                                            params.data.merge_sizes
                                        } ${
                                            params.data.existing_size
                                                ? ''
                                                : `from ${params.data.existing_size_1} & ${params.data.existing_size_2}`
                                        }`
                  default:
                    return ReverseFormatKey(alteration)
                }
              }
              return params?.data?.alteration
                ? getFormattedAlt(params?.data?.alteration)
                : '--'
            }
          }
        case 'pct':
          return {
            filterValueGetter: params =>
              params.getValue(params.colDef),
            valueGetter: params => {
              if (
                params?.data?.alteration &&
                                (params?.data?.alteration ===
                                    'add_size_by_size' ||
                                    params?.data?.alteration === 'add_size')
              ) {
                if (
                  params?.data?.alteration ===
                                    'add_size_by_size'
                ) {
                  return `${params?.data?.pct_of_model}% of ${params?.data?.size_to_model_after}`
                } else {
                  return `${params?.data?.pct}%`
                }
              }
              return '--'
            }
          }
        case 'is_locked':
          return {
            cellRendererParams: params => {
              return {
                disabled: params.data.alteration === 'remove',
                toolTipMessage: `Lock Size: ${params.data.size}`,
                onClick: params =>
                  params.context.onLockAlteration(params)
              }
            }
          }
        case 'delete':
          return {
            cellRendererParams: params => {
              return {
                toolTipMessage: `Remove Alteration for: ${params.data.size}`,
                alternateIcon: 'cancel',
                alternateIconCondition: 'existing_size',
                onClick: event =>
                  params.context.onDeleteAlteration(event)
              }
            }
          }
        default:
          return {}
      }
    }
  }
  const updatedCol = {
    ...col,
    ...getParams(),
    cellClassRules: {
      ...CellClassRules,
      'disabled-cell': params => {
        return (
          (col.colId === 'delete' && !params?.data.alteration) ||
                    params?.data?.alteration === ''
        )
      }
    }
  }
  return updatedCol
}
export const SRA_distros_customCellFormatting = (col, gridToRender) => {
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      switch (col.colId) {
        case '':
          return {}
        default:
          return {}
      }
    }
  }
  const updatedCol = {
    ...col,
    ...getParams()
  }
  return updatedCol
}
