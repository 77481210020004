export const BuyingDefaultCartonizationFormSettings = {
  channel: {
    controlName: 'channel',
    label: 'Channel',
    formType: 'auto-selection',
    datatype: 'array',
    selectMultiple: false,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: false,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: null,
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '100%'
    }
  }
}
