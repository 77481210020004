<!-- #mainGrid -->
<mat-card class="upload-grid-card-container">
    <!-- Toolbar -->
    <div class="grid-toolbar">
        <!-- Optional Header -->
        <div *ngIf="displayHeader" class="grid-title">
            <h3>{{ gridTitle }}</h3>
        </div>
        <!-- Button Container -->
        <div class="grid-tool-panel-btn-container">
            <!-- Additional Grid Buttons -->
            <ng-content></ng-content>
            <!-- Add A Row -->
            <button
                *ngIf="addRowEnabled"
                mat-icon-button
                id="add-new-size-offering"
                class="submit-btn"
                (click)="onAddRow()"
                [disabled]="processingData"
            >
                <mat-icon> docs_add_on </mat-icon>
            </button>
            <!-- Download Template -->
            <button
                mat-icon-button
                color="accent"
                (click)="onDownloadTemplate()"
                [disabled]="processingData"
                #tooltip="matTooltip"
                matTooltip="Download Template"
                matTooltipClass="below"
            >
                <mat-icon>download</mat-icon>
            </button>

            <!-- Upload File -->
            <button
                id="upload-grid-file-btn-wrapper"
                mat-icon-button
                color="accent"
                [disabled]="processingData || !uploadIsEnabled"
                #tooltip="matTooltip"
                matTooltip="Upload File"
                matTooltipClass="below"
                (click)="onUploadFile($event)"
            >
                <p-fileUpload
                    #fileUpload
                    name="myfile[]"
                    maxFileSize="1000000"
                    multiple="true"
                    mode="basic"
                    chooseLabel=""
                    customUpload="true"
                    chooseIcon="upload_file"
                    (onSelect)="onFileChange($event, fileUpload)"
                    [disabled]="processingData || !uploadIsEnabled"
                    chooseIcon=""
                >
                </p-fileUpload>
                <mat-icon>upload_file</mat-icon>
            </button>

            <!-- Reset Button -->
            <button
                [id]="gridTitle + '-reset'"
                mat-raised-button
                color="warn"
                [disabled]="tableResetDisabled || processingData"
                (click)="onResetTable()"
            >
                Reset
            </button>
        </div>
    </div>

    <ag-grid-angular
        [id]="'upload-grid-' + gridTitle"
        class="ag-theme-alpine ag-grid-container upload-grid"
        [gridOptions]="gridOptions"
        [enableRangeSelection]="true"
        [popupParent]="popupParent"
    ></ag-grid-angular>
</mat-card>
