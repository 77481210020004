/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const addedData = /* GraphQL */ `subscription AddedData {
  addedData {
    title
    content
    job_name
    status
    error_message
    finished_at
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.AddedDataSubscriptionVariables,
  APITypes.AddedDataSubscription
>;
export const updatedData = /* GraphQL */ `subscription UpdatedData {
  updatedData {
    title
    content
    job_name
    status
    error_message
    finished_at
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.UpdatedDataSubscriptionVariables,
  APITypes.UpdatedDataSubscription
>;
export const response = /* GraphQL */ `subscription Response {
  response {
    title
    content
    job_name
    status
    error_message
    finished_at
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.ResponseSubscriptionVariables,
  APITypes.ResponseSubscription
>;
export const response_alterations = /* GraphQL */ `subscription Response_alterations {
  response_alterations {
    signed_url
    status
    error_message
    username
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.Response_alterationsSubscriptionVariables,
  APITypes.Response_alterationsSubscription
>;
