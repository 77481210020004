// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
import { BehaviorSubject, type Observable, fromEvent } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LastActiveService {
  private readonly localStorageKey: string = '__lastActive'
  private readonly events: string[] = [
    'click',
    'wheel',
    'scroll',
    'mousemove',
    'keyup',
    'keydown'
  ]

  private readonly lastActive: BehaviorSubject<Date>
  public lastActive$: Observable<Date>

  constructor () {
    const lastActiveDate = this.getLastActive() || new Date()
    this.lastActive = new BehaviorSubject<Date>(lastActiveDate)
    this.lastActive$ = this.lastActive.asObservable()
  }

  public initializeTimer () {
    this.events.forEach(event =>
      fromEvent(document, event).subscribe(_ => this.recordLastActive())
    )
  }

  private recordLastActive () {
    const currentDate = new Date()
    localStorage.setItem(this.localStorageKey, currentDate.toString())
    this.lastActive.next(currentDate)
  }

  private getLastActive (): Date | null {
    const valueFromStorage = localStorage.getItem(this.localStorageKey)
    if (!valueFromStorage) {
      return null
    }

    return new Date(valueFromStorage)
  }
}
