// Angular Imports
// =========================================================
import { Component, type OnDestroy, type OnInit } from '@angular/core'
// Column Definition JSON
// =========================================================
import reviewOrdersInputLinesColDefs from '../../../05_ag-grid-configs/01_json-grid-configs/review-orders/colDefs-main-review_orders.json'
import reviewOrdersBuySummColDefs from '../../../05_ag-grid-configs/01_json-grid-configs/review-orders/colDefs-buy-summ-review_orders.json'
import reviewOrderSDSColDefs from '../../../05_ag-grid-configs/01_json-grid-configs/review-orders/colDefs-source-dest-summ-review_orders.json'
import reviewOrderCartonSummColDefs from '../../../05_ag-grid-configs/01_json-grid-configs/review-orders/colDefs-carton-summ-review_orders.json'
// Custom Imports
// =========================================================
import { type UserInputLineSelectedInterface } from 'src/app/core/interfaces/review-orders-interface'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { ReviewOrdersAPIs } from 'src/app/core/apis/review-orders_api-calls'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import type {
  ValueFormatterParams,
  ValueGetterParams
} from 'ag-grid-community'

@Component({
  selector: 'app-review-orders',
  templateUrl: './review-orders.component.html',
  styleUrls: ['./review-orders.component.scss']
})
export class ReviewOrdersComponent implements OnInit, OnDestroy {
  activeTab: number
  tabs: any[] = []

  selectedIndex: number
  selectedStycId: string
  selectableStycIds: string[] = []
  inputLinesSelectedRows: any = {
    store_distro: null,
    styc_id: null,
    channels: [],
    rows: [],
    selectedDeliveryID: null,
    ids: []
  }

  rowsSelected: boolean = false
  outputGridData: any = null
  canViewData: boolean = true
  packConfigs: any = null

  constructor (
    public triggerApiCallsService: TriggerApiCallsService,
    private readonly reviewOrdersAPIs: ReviewOrdersAPIs,
    private readonly generalAPIs: GeneralAPIs
  ) {}

  ngOnInit (): void {
    this.generateTabs()
  }

  generateTabs () {
    console.log(
      'reviewOrdersInputLinesColDefs',
      reviewOrdersInputLinesColDefs
    )
    this.tabs = [
      {
        field: 'User Input',
        id: 'userInput',
        tabKey: 'user_input',
        // isTreeDataGrid: true,
        // treeDataParams: {
        //   groupLevelExpanded: 0,
        //   auto_group_name: 'STATUS',
        //   hideLowestLevel: true
        // },
        data: [],
        mainColDefs: reviewOrdersInputLinesColDefs[0],
        selectStyle: false,
        detailGridInfo: {
          columnDefs: reviewOrdersInputLinesColDefs[1]
        }
      },
      // {
      //   field: 'Store Size Breaks',
      //   id: 'storeSizeBreaks',
      //   tabKey: 'store_size_breaks',
      //   isTreeDataGrid: true,
      //   treeDataParams: {
      //     groupLevelExpanded: 1,
      //     auto_group_name: 'CHANNEL',
      //     hideLowestLevel: true
      //   },
      //   data: [],
      //   mainColDefs: reviewOrdersSSBColDefs[0],
      //   selectStyleColor: true,
      //   selectNeedDate: true
      // },
      // {
      //   field: 'Store SKU Plan',
      //   id: 'storeSkuPlan',
      //   tabKey: 'store_sku_plan',
      //   isTreeDataGrid: true,
      //   treeDataParams: {
      //     groupLevelExpanded: 1,
      //     auto_group_name: 'CHANNEL',
      //     hideLowestLevel: true
      //   },
      //   data: [],
      //   mainColDefs: reviewOrdersSSPColDefs[0],
      //   selectStyleColor: true,
      //   selectNeedDate: true
      // },
      {
        field: 'Buy Summary',
        id: 'buySummary',
        tabKey: 'buy_summary',
        isTreeDataGrid: true,
        treeDataParams: {
          groupLevelExpanded: 1,
          auto_group_name: 'CHANNEL',
          hideLowestLevel: true
        },
        mainColDefs: reviewOrdersBuySummColDefs[0],
        detailColDefs: reviewOrdersBuySummColDefs[1],
        detailDynamicColDefDefault: {
          aggFunc: params => {
            let buy = 0
            let target = 0
            params.rowNode.allLeafChildren.forEach(child => {
              const sizeObj =
                                child.data[params.colDef.headerName]
              if (!sizeObj) return
              buy += sizeObj.buy
              target += sizeObj.target
            })
            return {
              buy,
              target
            }
          },
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value?.buy
          }
        },
        selectStyleColor: false
      },
      {
        field: 'Source To Dest Summary',
        id: 'sourceDestSummary',
        tabKey: 'source_to_dest_summary',
        isTreeDataGrid: true,
        treeDataParams: {
          groupLevelExpanded: 2,
          auto_group_name: 'STORE ID',
          hideLowestLevel: true
        },
        data: [],
        mainColDefs: reviewOrdersBuySummColDefs[0],
        detailColDefs: reviewOrderSDSColDefs[1],
        selectStyleColor: false,
        selectNeedDate: false
      },
      {
        field: 'Carton Summary',
        id: 'cartonSummary',
        tabKey: 'carton_summary',
        isTreeDataGrid: true,
        treeDataParams: {
          groupLevelExpanded: 3,
          auto_group_name: 'CARTON ID',
          hideLowestLevel: true
        },
        data: [],
        mainColDefs: reviewOrdersBuySummColDefs[0],
        detailColDefs: reviewOrderCartonSummColDefs[1],
        detailDynamicColDefDefault: {
          aggFunc: params => {
            let total = 0
            params.rowNode.allLeafChildren.forEach(child => {
              const sizeVal =
                                child.data[params.colDef.headerName]
              if (!sizeVal) return
              total += sizeVal
            })
            return total
          },
          refData: {
            replaceNulls: true
          }
        },
        selectStyleColor: false,
        selectNeedDate: false
      }
    ]
  }

  onRowsSelectedEvent (data: any) {
    console.log('row Selected: ', data)
    this.rowsSelected = data.data?.rows?.length
    console.log('inputlinesselectedrows', this.inputLinesSelectedRows)

    data.data.ids = data.data.ids.reduce((accumulator, item) => {
      console.log('accumulator', accumulator)
      console.log('item', item)
      if (!accumulator.includes(item)) {
        accumulator.push(item)
      }
      return accumulator
    }, [])

    const buyGroup = this.rowsSelected
      ? data.data.rows[0].buy_group_id
      : null
    console.log('buy group is', buyGroup)
    let canViewData = true

    data.data.rows = data.data.rows.reduce((accumulator, item) => {
      console.log('accumulator', accumulator)
      console.log('item', item)
      if (item.buy_group_id && item.buy_group_id !== buyGroup) {
        console.log('setting canViewData false')
        canViewData = false
      }
      if (!accumulator.map(row => row.id).includes(item.id)) {
        accumulator.push(item)
      }
      return accumulator
    }, [])

    this.canViewData = canViewData

    this.inputLinesSelectedRows = data.data

    if (!data.ids?.length) {
      console.log('CLEARING OUTPUT DATA')
      this.outputGridData = null
    }
  }

  onViewDataEvent (event) {
    this.onSelectedIndex(1)
  }

  onSelectedIndex (event) {
    this.selectedIndex = event
    console.log('---> Review Orders Tab Changed: ', this.tabs[event].field)
    console.log('---> Current Output Grid Data: ', this.outputGridData)
    console.log(
      '---> Current User Input Data: ',
      this.inputLinesSelectedRows
    )

    if (
      event !== 0 &&
            (!this.outputGridData ||
                this.outputGridData?.ids !== this.inputLinesSelectedRows?.ids)
    ) {
      const { ids } = this.inputLinesSelectedRows
      const dataToSend = {
        ids
      }
      try {
        Promise.all([
          this.reviewOrdersAPIs.GetAllReviewOrdersOutputData(
            dataToSend
          ),
          this.generalAPIs.GetAllSizesAndOrder(false)
        ]).then(results => {
          const res = results[0]
          const sizeOrder = results[1]
          Object.keys(res.data).forEach(key => {
            const tab_row_data = res.data[key]
            if (key === 'carton_summary') {
              tab_row_data.forEach(row => {
                row.detailGridData.forEach(detailRow => {
                  detailRow.delivery_type = row.delivery_type
                  detailRow.delivery_date = row.delivery_date
                  detailRow.delivery_id = row.delivery_id
                })
              })
            } else if (key === 'source_to_dest_summary') {
              tab_row_data.forEach(row => {
                row.detailGridData.forEach(detailRow => {
                  detailRow.job_id = row.job_id
                })
              })
            }
            if (typeof tab_row_data === 'object') {
              if (tab_row_data?.dynamic_sizes) {
                const sizes = tab_row_data.dynamic_sizes
                if (sizes.length > 0) {
                  res.data[key].dynamic_sizes =
                                        sizeOrder.filter(size =>
                                          sizes.includes(size.product_size)
                                        )
                }
              }
              if (tab_row_data?.length) {
                if (tab_row_data[0].size_range) {
                  tab_row_data.forEach(row => {
                    const row_sizes =
                                            row.size_range.split('|')
                    if (row_sizes.length > 0) {
                      row.main_row_sizes =
                                                sizeOrder.filter(size =>
                                                  row_sizes.includes(
                                                    size.product_size
                                                  )
                                                )
                    }
                  })
                }
                if (
                  tab_row_data?.length &&
                                    tab_row_data[0].detailGridData &&
                                    (tab_row_data[0].detailGridData[0]
                                      .detail_row_sizes?.length || key === 'carton_summary')
                ) {
                  tab_row_data.forEach(row => {
                    row.detailGridData.forEach(
                      detailRow => {
                        if (
                          detailRow.detail_row_sizes?.length > 0
                        ) {
                          detailRow.detail_row_sizes =
                                                        sizeOrder.filter(size =>
                                                          detailRow.detail_row_sizes.includes(
                                                            size.product_size
                                                          )
                                                        )
                        } else if (key === 'carton_summary') {
                          detailRow.detail_row_sizes = row.main_row_sizes
                        }
                      }
                    )
                  })
                }
              }
            }
          })
          this.outputGridData = {
            ...res.data,
            ids
          }
          console.log('this.outputGridData', this.outputGridData)
          this.packConfigs = res.data.pack_configs
        })
      } finally {
        console.log('finally')
      }
    }
  }

  ngOnDestroy (): void {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
  }
}
