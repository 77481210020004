// Angular and RJX Imports
// =========================================================
import { BehaviorSubject } from 'rxjs'

export class SideNavPanelService {
  private readonly SideNavCurrentSection = new BehaviorSubject<string>(null)
  public SideNavCurrentSection$ = this.SideNavCurrentSection.asObservable()

  sideNavCurrentSection: string = ''

  // Update the current route
  onSetSideNav (route) {
    return this.SideNavCurrentSection.next(route)
  }

  // Send the current route
  getSideNavRoutes () {
    return this.SideNavCurrentSection.asObservable()
  }
}
