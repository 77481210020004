//  ----------------------------
//  Order Management Formatters
//  ----------------------------
import {
  FormatKey,
  GetValue,
  IsKeyInObj
} from 'src/app/utils/global_functions'
import { FormatPercentage } from '../02_global-settings/global-cell-formatter'
import {
  type ICellRendererParams,
  type IDetailCellRendererParams
} from 'ag-grid-community'
import { CartonIDSizesTooltip } from '../03_custom-components/grid-custom-tooltip/review-orders-carton_id-tooltip/review-orders-carton_id-tooltip.component'
import { P } from '@angular/cdk/keycodes'
import { GridCustomTooltipComponent } from '../03_custom-components/grid-custom-tooltip/grid-custom-tooltip.component'

// Conditional cell Formatting
export const OM_customCellFormatting = (
  col: any,
  tabKey: string,
  isDynamicCol?: boolean
) => {
  const getParams = () => {
    switch (tabKey) {
      case 'user_input':
        return GetUserInputParams(col, isDynamicCol)
      case 'store_size_breaks':
        return GetStoreSizeBreaksParams(col, isDynamicCol)
      case 'store_sku_plan':
        return GetStoreSkuPlanParams(col, isDynamicCol)
      case 'buy_summary':
        return GetBuySummaryParams(col, isDynamicCol)
      case 'source_to_dest_summary':
        return GetSourceToDestSummaryParams(col, isDynamicCol)
      case 'carton_summary':
        return GetCartonSummaryParams(col, isDynamicCol)
    }
  }
  const updatedCol = { ...col, ...getParams() }

  return updatedCol
}

export const GetBuySummaryParams = (col: any, isDynamicCol?: boolean) => {
  if (col.colId === 'target_vs_buy_chart') {
    return {
      cellRendererParams: (params: ICellRendererParams) => {
        const data = params.node.aggData
          ? params.node.aggData
          : params.data
        const message =
                    data.metric === 'Buy' ? 'Buy Percentage' : 'Mismatch'
        return {
          toolTipMessage: `Visualize ${message} Results`,
          onClick: params => {
            return params.context['openTargetBuyChart'](params)
          }
        }
      }
    }
  } else if (col.colId === 'total_unit_qty') {
    return {
      comparator: (valueA, valueB, nodeA, nodeB) => {
        if (nodeA.data && nodeB.data) {
          if (
            nodeA.data.metric === 'Mismatch' &&
                        nodeB.data.metric === 'Mismatch'
          ) {
            const valA = Math.abs(Number(valueA))
            const valB = Math.abs(Number(valueB))

            if (valA === valB) return 0
            return valA - valB
          } else {
            return valueA - valueB
          }
        }
      }
    }
  } else if (isDynamicCol) {
    return {
      valueGetter: params => {
        return params.data[params.colDef.headerName]?.buy
      }
      // aggFunc: params => {
      //   // console.log('Custom Buy Sum Agg Func Params: ', params)
      //   // console.log("Custom Buy Sum Agg Func Values: ", params.values);
      //   const data = params.rowNode.data
      //     ? params.rowNode.data
      //     : params.rowNode.aggData
      //   let qty_over_total = 0
      //   let qty_under_total = 0
      //   let qty_bought_total = 0
      //   params.values.forEach(obj => {
      //     if (obj) {
      //       qty_over_total += obj.qty_over
      //       qty_under_total += obj.qty_under
      //       qty_bought_total += obj.qty_bought
      //     }
      //   })
      //   const value =
      //               data?.metric === 'Buy'
      //                 ? qty_bought_total
      //                 : qty_over_total + qty_under_total
      //   return {
      //     qty_over: qty_over_total,
      //     qty_under: qty_under_total,
      //     qty_bought: qty_bought_total,
      //     value: data ? value : 0,
      //     toString: () => {
      //       return data ? value : 0
      //     }
      //   }
      // },
      // valueGetter: params => {
      //   console.log('getting value with params', params)
      //   // const data = params.node.data
      //   //   ? params.node.data
      //   //   : params.node.aggData
      //   // const { colId } = col
      //   // console.log('buySummSizeSum -----------: ', params)
      //   return params.value
      //   // if (params.node.group) {
      //   //   return params.value
      //   // }
      //   // if (data && IsKeyInObj(data, colId)) {
      //   //   if (!params.node.group) {
      //   //     return {
      //   //     }
      //   //     // const colData = data[colId]
      //   //     // const buyData = {
      //   //     //   qty_over: 0,
      //   //     //   qty_under: 0,
      //   //     //   qty_bought: colData?.qty_bought,
      //   //     //   value: colData?.qty_bought
      //   //     // }
      //   //     // const mismatchData = {
      //   //     //   qty_over: colData.qty_over,
      //   //     //   qty_under: colData.qty_under,
      //   //     //   qty_bought: 0,
      //   //     //   value: colData.qty_over + colData.qty_under
      //   //     // }
      //   //     // const updatedData =
      //   //     //                 data.metric === 'Buy' ? buyData : mismatchData
      //   //     // params.data[col.colId] = {
      //   //     //   ...params.data[col.colId],
      //   //     //   value: updatedData.value
      //   //     // }
      //   //     // return updatedData
      //   //   }
      //   // }
      // }
    }
  } else {
    return {}
  }
}

export const GetUserInputParams = (col: any, isDynamicCol?: boolean) => {
  switch (col.field) {
    case 'size_range':
      return {
        tooltipValueGetter: params => params.value
      }
    case 'settings':
      return {
        cellRendererParams: params => {
          // params.colDef.refData.toolTipMessage = `Add Size ${params.data.sizeNm}`;
          return {
            disabled:
                            params?.node.aggData?.status === 'RUNNING' ||
                            params?.node.aggData?.status === 'FAILED' ||
                            false,
            disabledKeys: ['status'],
            onClick: params =>
              params.context['openUserInputConfigSettings'](
                params
              )
          }
        }
      }
    case 'avg_mismatch':
      return {
        valueFormatter: params => {
          const value = GetValue(params.value)
          return isNaN(value)
            ? value
            : FormatPercentage({ ...params, value })
        }
      }
    case 'fit_quality':
      return {
        valueFormatter: params => {
          const value = GetValue(params.value)
          return isNaN(value)
            ? value
            : (value * 100).toFixed(2) + '%'
        }
      }
    case 'status':
      return {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        valueFormatter: params => params.value
      }
    case 'parameters':
      return {
        cellRendererParams: (params: IDetailCellRendererParams) => {
          return {
            toolTipMessage: 'Edit parameters for job',
            disabled:
                            params?.node.data.status === 'IN PROGRESS' || false,
            disabledKeys: ['status'],
            onClick: (params: ICellRendererParams) =>
              params.context.openUserInputConfigSettings(params)
          }
        }
      }
    case 'delete':
      return {
        cellRendererParams: params => {
          return {
            disabled: false,
            toolTipMessage: 'Delete Buy Group',
            onClick: params => {
              return params.context.openDeleteConfirmDialog(
                params
              )
            }
          }
        }
      }
    case 'channel':
    case 'style_color_ids':
    case 'style_color_id':
    case 'style_color_names':
    case 'style_color_name':
    case 'channels':
    case 'size_ranges':
    case 'statuses':
    case 'subclass_id':
    case 'date_submitted':
      return {
        tooltipComponent: GridCustomTooltipComponent
      } // don't overwrite the properties defined by the column type
    default:
      return {
        valueFormatter: params => params.value
      }
  }
}
export const GetSourceToDestSummaryParams = (
  col: any,
  isDynamicCol?: boolean
) => {
  switch (col.field) {
    case 'carton_id':
      return {
        tooltipComponent: CartonIDSizesTooltip
      }
    default:
      return {}
  }
}

export const GetCartonSummaryParams = (col: any, isDynamicCol?: boolean) => {
  switch (col.field) {
    //
    case 'config':
      return {
        cellRendererParams: params => {
          return {
            toolTipMessage: 'View Carton Configuration',
            onClick: params =>
              params.context['openPackConfigModal'](params)
          }
        }
      }
    case 'pack_size':
      return {
        valueFormatter: params => {
          if (params.data.isAggRow) {
            return '--'
          } else {
            return params.value
          }
        }
      }
    default:
      if (isDynamicCol) {
        return {
          valueGetter: params => {
            if (params.data) {
              return params.data[params.colDef.headerName]
            } else {
              return params.value
            }
          },
          valueFormatter: params => {
            if (params.data.isPctRow && params.value) {
              return (100 * params.value).toFixed(2) + '%'
            } else if (params.data.isPctRow && !params.value) {
              return '--'
            }
            // if (params.data.isAggRow) {
            //   const pctVal = params.data[params.colDef.headerName + '|PCT']
            //   if (isNaN(pctVal)) {
            //     return params.value
            //   } else return params.value + ' (' + pctVal + '%)'
            // }
          }
        }
      } else {
        return {}
      }
  }
}
export const GetStoreSizeBreaksParams = (col: any, isDynamicCol?: boolean) => {
  switch (col.field) {
    case 'select':
      return GenerateChartBn
    case 'calc_breaks_run':
      return {
        valueGetter: params => {
          params.value = params.value ? 'RUNNING' : 'DONE'
          params.data['calc_breaks_run'] = params.value
          return params.value
        }
      }
    case 'chart':
      return {
        cellRendererParams: params => {
          return {
            toolTipMessage: 'Visualize Breaks',
            onClick: params => params.context['viewCharts'](params)
          }
        }
      }
    default:
      // format % cells
      if (isDynamicCol) {
        return {
          valueGetter: params => {
            if (params.node.group) {
              if (params.node.key === 'Total') {
                return '--'
              } else {
                const keysArray = params.node.key.split('|')
                const channel = FormatKey(
                  keysArray[keysArray.length - 1]
                )
                if (channel) {
                  const { chartData, selectedDeliveryID } =
                                        params.context.componentParent
                  const value =
                                        chartData[
                                            `delivery_id-${selectedDeliveryID}`
                                        ][channel][col.colId]
                  return value ? value.rad_c : ''
                } else {
                  return ''
                }
              }
            } else {
              params.value = params.data[col.colId]
              return params.value
            }
          },
          valueFormatter: params => {
            if (params.node.key !== 'Total' && params.value != 0) {
              const value = params.value
              params.value = value
              return FormatPercentage(params)
            }
            return '--'
          }
        }
      } else {
        return {}
      }
  }
}
export const GetStoreSkuPlanParams = (col: any, isDynamicCol?: boolean) => {
  switch (col.field) {
    //
    case 'select':
      return GenerateChartBn
    default:
      if (isDynamicCol) {
        return {
          aggFunc: 'sum'
        }
      } else {
        return {}
      }
  }
}

export const GenerateChartBn = {
  cellRenderer: (params: any) => {
    // if (params.node.id === "t-0") {
    if (params?.data?.store === 'All Store Totals') {
      const element = document.createElement('div')
      const imageElement: string = `<span class="flex-center-all" style="height: 36px"> <mat-icon  role="img" class="mat-icon notranslate mat-tooltip-trigger material-icons icon-button mat-icon-no-color" aria-hidden="true" matTooltip="View Chart" matTooltipClass="chart-btn-tooltip" aria-label="View Chart">
        <span class="material-icons chart-icon"> insights </span>
     </mat-icon></span>`

      element.innerHTML = imageElement
      element.appendChild(document.createTextNode(params.value))
      return element
    }
    return undefined
  },
  onCellClicked: params => {
    if (params.node.id === 't-0') params.context['viewCharts'](params)
  }
}
