[
    {
        "Select": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "checkbox",
                "selectAll": true,
                "colSize": 50
            },
            "rowGroup": false,
            "hide": false,
            "maxWidth": 50,
            "suppressAutoSize": true
        },
        "Job Name": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false,
                "flexSize": 2,
                "minWidth": 200
            },
            "rowGroup": false,
            "hide": false
        },
        "Distro id": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Channel": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Styc id": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Styc name": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Buying Season": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false,
            "suppressAutoSize": true
        },
        "Prior Selling Season(s)": {
            "editable": false,
            "refData": {
                "datatype": "array",
                "arrayKey": "season",
                "customNullValue": "Custom Season",
                "showToolTip": false,
                "custom_field": "prior_selling_seasons"
            },
            "rowGroup": false,
            "hide": false,
            "suppressAutoSize": true
        },
        "Size Range": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "tooltipField": "size_range"
            },
            "rowGroup": false,
            "hide": false,
            "suppressAutoSize": true
        },
        "Sales units": {
            "editable": false,
            "refData": {
                "datatype": "number",
                "showToolTip": false,
                "replaceZeros": true
            },
            "rowGroup": false,
            "hide": false
        },
        "Status": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false,
                "showAlert": true,
                "customAlertCompRef": "job-management-status-alert",
                "alertConditions": ["failed", "completed with errors"],
                "alertLinks": {
                    "completed with errors": {
                        "key": "job_name",
                        "action": "filter"
                    }
                },
                "flexSize": 2
            },
            "rowGroup": false,
            "hide": false
        },
        "Distro tag": {
            "editable": true,
            "refData": {
                "datatype": "string-editor",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Edited": {
            "editable": false,
            "refData": {
                "datatype": "boolean",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": true
        },
        "Last Modified": {
            "editable": false,
            "refData": {
                "datatype": "date",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false,
            "sort": "desc"
        },
        "Last Modified By": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": true
        }
    }
]
