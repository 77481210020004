// Angular Imports
// =========================================================
import { Component, Input, ViewChild } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ICellRendererAngularComp } from 'ag-grid-angular'
import type { ICellRendererParams } from 'ag-grid-community'

@Component({
  selector: 'child-cell',
  templateUrl: './checkmark-boolean-cell-renderer.html',
  styleUrls: ['./checkmark-boolean-cell-renderer.scss']
})
export class CheckmarkBooleanCellRenderer implements ICellRendererAngularComp {
  booleanValue: boolean
  agInit (params: ICellRendererParams<any, any>): void {
    this.booleanValue = params.value
  }

  refresh (params: ICellRendererParams<any, any>): boolean {
    return true
  }
}
