// External Imports
// =========================================================
import { type ValueFormatterParams } from 'ag-grid-community'
import moment from 'moment'
// Custom Imports
// =========================================================
import {
  FormatKey,
  GetValue,
  IsKeyInObj
} from 'src/app/utils/global_functions'

export const GenerateDefaultColDefs = (colKey, colVal, isSize = false) => {
  // console.log('colKey: ', colKey)
  // console.log('colVal: ', colVal)

  const datatype = colVal.refData.datatype
  const getType = action => colVal.refData.datatype.includes(action)
  const actionCol =
        getType('open-modal') ||
        getType('open-screen') ||
        getType('action-button') ||
        getType('checkbox')
  let colId = null
  if (isSize && colVal.refData.isDynamicCol) {
    colId = colKey
  } else {
    colId = FormatKey(colKey)
  }
  const field = colVal.refData.custom_field || colId
  const headerName = FormatHeaderCell(datatype, colKey, colVal['refData'])
  const setWidth = () =>
    colVal?.refData?.colSize || actionCol
      ? {
          width: colVal?.refData?.colSize || 60,
          minWidth: actionCol ? 60 : 80
        }
      : {
          flex: colVal?.refData?.flexSize || 1,
          minWidth: colVal?.refData?.minWidth || 120
        }

  return {
    headerName,
    headerTooltip:
            colVal?.refData?.headerRendererIcon ||
            colVal?.refData?.displayBlankHeader
              ? null
              : headerName,
    headerCheckboxSelection:
            datatype === 'checkbox' && !colVal['refData']['displayHeaderName'],
    field,
    colId,
    type: SetCellType(
      datatype,
      colVal.editable,
      colVal.aggFunc,
      colVal?.refData
    ),
    suppressMenu: actionCol && !colVal?.refData?.displayMenu,
    suppressMovable: actionCol || datatype === 'checkbox',
    sortable: !actionCol || colVal?.refData?.displayMenu,
    ...setWidth(),
    ...ToolPanelConfig(actionCol, datatype),
    ...GetToolTipText(colVal),
    ...colVal,
    cellClass: datatype,
    refData: {
      ...colVal['refData'],
      actionColumn:
                datatype === 'multi-checkbox-column' ? false : actionCol
    }
  }
}
export const FormatHeaderCell = (
  datatype: string,
  header: string,
  refData: any
) => {
  const h = header.toUpperCase()
  if (!h.includes('%') && !h.includes('$')) {
    switch (datatype) {
      case 'multi-checkbox-column':
      case 'checkbox':
        return refData['displayHeaderName'] ? h : ''
      case 'percentage':
        return h + ' (%)'
      case 'currency':
        return h + ' ($)'
      default:
        return h
    }
  } else {
    return h
  }
}

// Set Cell Type array
export const SetCellType = (datatype, editable, aggFunc, refData) => {
  const showAlert = refData?.showAlert || false
  const displayBlankHeader = refData?.displayBlankHeader
  const headerRendererIcon = refData?.headerRendererIcon
  const getFormatter = () => {
    const type = datatype.includes('|') ? datatype.split('|')[0] : datatype
    const getNumericCellTypes = numFormatter =>
      [numFormatter, editable ? 'editableNumValues' : null].filter(
        t => t !== null
      )
    switch (type) {
      case 'number':
        return getNumericCellTypes('numberFormatter')
      case 'numberGroup':
        return ['numberGroupFormatter']
      case 'percentage':
        return getNumericCellTypes('percentageFormatter')
      case 'currency':
        return getNumericCellTypes('currencyFormatter')
      case 'boolean':
        return ['booleanFormatter']
      case 'checkmarkBoolean': // used for displaying booleans as checkmarks
        return ['checkmarkBooleanFormatter']
      case 'string':
        return ['stringFormatter']
      case 'string-delimiter':
        return ['stringDelimiterFormatter']
      case 'string-editor':
        return ['stringEditorFormatter']
      case 'array':
        return ['arrayFormatter']
      case 'statusesArray':
        return ['statusesArrayFormatter']
      case 'date':
        return ['dateFormatter']
      case 'checkbox':
        return ['checkboxFormatter']
      case 'multi-checkbox-column':
        return ['multiCheckboxColumnFormatter']
      case 'multi-selection-auto-complete':
        return ['multiSelectionCellFormatter', 'arrayFormatter']
      case 'open-modal':
      case 'open-screen':
      case 'action-button':
        return ['buttonFormatter']
      case 'dropdownSelect':
        return ['dropdownFormatter']
      case 'selectionAutoComplete':
        return ['selectionAutoCompleteFormatter']
      case 'isBulkString':
        return ['isBulkStringFormatter']
      case 'stringLiteral':
        return ['stringLiteralFormatter']
      default:
        return ['stringFormatter']
    }
  }
  const typeArr = [...getFormatter()]

  if (aggFunc === 'customAverage') typeArr.push('customAverage')
  if (showAlert) typeArr.push('alertCellFormatter')
  if (displayBlankHeader) typeArr.push('customHeaderFormatter')
  if (headerRendererIcon) typeArr.push('customHeaderFormatter')

  return typeArr
}

// Set Tooltip text
export const GetToolTipText = col => {
  if (col.refData.showToolTip && col.refData.tooltipField) {
    return { tooltipField: col.refData.tooltipField }
  }
  return {}
}
// Configure filter and column tool panels
export const ToolPanelConfig = (isActionCol, datatype) => {
  // Columns to hide
  if (isActionCol) {
    return {
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true
    }
  }
  const dataTypesToIgnore = [
    'date',
    'boolean',
    'array',
    'multi-selection-auto-complete',
    'percentage',
    'number',
    'currency',
    'statusesArray'
  ]
  // Set filter values
  if (!dataTypesToIgnore.includes(datatype)) {
    return {
      filterValueGetter: params => {
        if (params.data) {
          const val = params.data[params.colDef?.field]
          return !val || typeof val === 'string'
            ? !val
                ? '--'
                : val
            : `${GetValue(params.data[params.colDef?.field])}`
        }
        return `${params.node.key}`
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        debounceMs: 200,
        newRowsAction: 'keep',
        valueFormatter: params => {
          console.log('main filter value formatter')
          switch (datatype) {
            // case 'number':
            //   return FormatNumber(params)
            // case 'percentage':
            //   return FormatPercentage(params)
            // case 'currency':
            //   return FormatCurrency(params)
            case 'string':
              return FormatString(params)
            default:
              return params.value ? params.value : '--'
          }
        },
        comparator: (a, b) =>
          !b ? -1 : FormatKey(a) > FormatKey(b) ? 0 : -1
      },
      comparator: (a, b) => {
        const AF = FormatKey(a) || '--'
        const BF = FormatKey(b) || '--'
        if (!isNaN(AF) && !isNaN(AF)) {
          return AF - BF
        } else {
          return AF > BF ? 0 : -1
        }
      }
    }
  } else {
    return {}
  }
}
// Number Formatter
export function FormatNumber (params: ValueFormatterParams) {
  // console.log('FORMAT NUMBER: ', params)
  const replaceZeroVals = params?.colDef?.refData?.replaceZeros || false
  const replaceNullVals = params?.colDef?.refData?.replaceNulls || false

  if (IsKeyInObj(params, 'value')) {
    if (
      !replaceZeroVals &&
            (params.value === null || params.value === 'null')
    ) {
      return (params.value = '--')
    } else if (
      replaceZeroVals &&
            (params.value === 0 ||
                params.value === null ||
                params.value === 'null')
    ) {
      return '--'
    } else if (replaceNullVals) {
      if (params.value === null || params.value === 'null') {
        return '--'
      } else if (params.data.detail_row_sizes) {
        const detail_row_sizes = params.data.detail_row_sizes.map(size => size.product_size)
        if (!detail_row_sizes.includes(params.colDef.headerName)) {
          return '--'
        }
      }
    }
    const number = Number(
      typeof params.value === 'object'
        ? params.value.value
        : params.value
    )

    if (params) {
      if (params?.colDef?.refData?.is_whole_num) {
        return Math.round(number).toString()
      } else if (isFinite(number)) {
        // Display up to two decimal places
        const decimalAdjust = (value, exp) => {
          value = +value
          exp = +exp
          // Shift
          value = value.toString().split('e')
          value = Math.floor(
            +(
              value[0] +
                              'e' +
                              (value[1] ? +value[1] - exp : -exp)
            )
          )
          // Shift back
          value = value.toString().split('e')
          return +(value[0] + 'e' + exp)
        }
        if (
          params?.colDef?.refData?.datatype === 'percentage' ||
                      params?.colDef?.colId === 'store_index'
        ) {
          return decimalAdjust(number, -4).toString()
        } else {
          return decimalAdjust(number, -2)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
      } else if (
        isNaN(number) &&
                  params.value !== '--' &&
                  params.value !== null
      ) {
        return '0'
      } else {
        return '--'
      }
    } else {
      return '0'
    }
  }
}
// Percentage Formatter
export function FormatPercentage (params) {
  const value = FormatNumber({ ...params })
  // console.log('valueeeee: ', value)
  // return value

  // return value !== '--' ? Math.abs(Number(value)).toFixed(2) + '%' : value
  return value !== '--' ? Math.abs(Number(value)).toFixed(2) : value
}
// Number Formatter
export function FormatCurrency (params) {
  const number =
        typeof params.value === 'object' ? params.value.value : params.value

  return number !== '--' && number !== null
    ? '$' + FormatNumber(params)
    : number === '--'
      ? number
      : null
}
// String Formatter
export function FormatString (params) {
  if (params.value) return params.value
  else return '--'
}
// Date Formatter
export function FormatDate (params) {
  console.log('format date: ', params)
  const timestamp = !['date_submitted', 'delivery_date'].includes(
    params.colDef?.field
  )
  const dateConfig = params?.colDef?.refData?.dateFormat || null
  let dateFormat
  if (typeof params.value === 'number') {
    // 12/05/2022
    dateFormat = dateConfig || (timestamp ? 'LLL' : 'YYYY-MM-DD')
    const date = moment(params.value).format(dateFormat)
    return date === 'Invalid date' ? '--' : date
  } else if (typeof params.value === 'string') {
    if (dateConfig === 'string') {
      return params.value
    } else {
      dateFormat = dateConfig || (timestamp ? 'LLL' : 'YYYY-MM-DD')
      const date = moment(params.value).format(dateFormat)
      return date === 'Invalid date' ? '--' : date
    }
  } else {
    return '--'
  }
}
