// Angular Imports
// =========================================================
import {
  Component,
  type OnInit,
  type OnDestroy,
  Injectable
} from '@angular/core'
// Prime NG (UI) Imports
// =========================================================
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig
} from 'primeng/dynamicdialog'
// NG Idle Imports
// =========================================================
import { Subject } from 'rxjs'
// Custom Imports
// =========================================================
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { CognitoService } from 'src/app/core/services/auth/auth-cognito.service'
// import { AuthService } from 'src/app/core/authentication/auth.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-err-modal',
  templateUrl: './err-modal.component.html',
  styleUrls: ['./err-modal.component.scss'],
  providers: [DialogService]
})
export class ErrModalComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>()

  timeStamp: string = ''
  title: string = ''
  errorStatus: string = null
  errorMessage: string = ''
  endSession: boolean = false
  customMessage: string = ''
  inactiveUser
  countdown: number = 60

  constructor (
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    public config: DynamicDialogConfig,
    public cognitoService: CognitoService,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    console.error(
      '---- Inside Error Modal ---- : ',
      this.config.data.error
    )
  }

  ngOnInit (): void {
    // Error data
    const error = this.config.data.error

    if (error) {
      this.timeStamp = new Date().toLocaleString()
      this.title = error.title
      this.errorStatus = error.errorStatus
      this.errorMessage = error.errorMessage
      this.endSession = error.logout
      // To find the component with an error message console log the customMessage
      this.customMessage = error.customMessage
    }

    if (error.errorStatus === `${403}`) {
      // Log the user out if their session has expired
      this.inactiveUser = error.errorStatus === `${403}`
    }
  }

  // Close the modal
  close (event) {
    this.ref.close()
  }

  // Logout
  logout (event) {
    this.ref.close()
    this.cognitoService.signOut()
  }

  ngOnDestroy () {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    this.ref.close()
    // Allow new api calls once the modal is closed
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: false
    })
  }
}
