// Angular Imports
// =========================================================
import {
  Component,
  type OnInit,
  type OnDestroy,
  HostListener,
  ViewChild
} from '@angular/core'
import { cloneDeep } from 'lodash'
import { BehaviorSubject } from 'rxjs'
// Prime NG Imports
// =========================================================
import { type TreeNode } from 'primeng/api'
// Custom Imports
// =========================================================
import { CreateProxyProductFormSettings } from 'src/app/03_shared-components/forms/form-data/create-proxy-product'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { ProxyProductCreationAPIs } from 'src/app/core/apis/proxy-product-creation_api-calls'
import { SizeDistroDefaultParametersAPIs } from 'src/app/core/apis/size-distro-default-parameters_api-calls'
import {
  DefaultNewAttribute,
  DefaultProxyProductData,
  type ProxyProductCreationInterface
} from 'src/app/core/interfaces/data-expected-from-backend/proxy-product-creation-interface'
import { ResetFormData } from 'src/app/03_shared-components/forms/form-data/form-data-reset'
import { type ParameterSelectionOptionsInterface } from 'src/app/core/interfaces/data-expected-from-backend/parameter-selection-options'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { type TreeDataParamsInterface } from 'src/app/core/interfaces/data-expected-from-backend/default-parameters-inputs-interface'
import { ProductHierarchyComponent } from 'src/app/02_shared-features/product-hierarchy/product-hierarchy.component'
@Component({
  selector: 'app-product-creation',
  templateUrl: './product-creation.component.html',
  styleUrls: ['./product-creation.component.scss']
})
export class ProductCreationComponent implements OnInit, OnDestroy {
  confirmAction: boolean = false
  // Params for Product Hierarchy Section
  treeDataParams: TreeDataParamsInterface = null
  loadingProdHier: boolean = true
  selectedHierarchyNode: TreeNode = {}
  defaultSelected: TreeNode[] = []
  selectedNodeEvent: any = null

  generalProductDetails = null
  clearGeneralProductDetails: boolean = false
  ogDefaultProxyProductData = cloneDeep({ ...DefaultProxyProductData })
  newProxyProduct: ProxyProductCreationInterface = cloneDeep({
    ...DefaultProxyProductData
  })

  // Import the original form settings and track updates
  defaultProxyProductFormSettings = cloneDeep({
    ...CreateProxyProductFormSettings
  })

  formData = new BehaviorSubject<any>(this.defaultProxyProductFormSettings)
  get _formData () {
    return this.formData.getValue()
  }

  sizeSelectionOptions: string[] = []
  parameterSelectionOptions: ParameterSelectionOptionsInterface = null
  unavailableProdIds: string[] = []
  formDataErrors: string[] = ['proxy_product_id', 'proxy_product_sizes']
  isNodeEditable: boolean = false

  @ViewChild(ProductHierarchyComponent)
    productHierarchyComponent: ProductHierarchyComponent

  constructor (
    private readonly sizeDistroDefaultParametersAPIs: SizeDistroDefaultParametersAPIs,
    private readonly proxyProductCreationAPIs: ProxyProductCreationAPIs,
    private readonly generalAPIs: GeneralAPIs,
    public triggerApiCallsService: TriggerApiCallsService
  ) {}

  async ngOnInit () {
    await this.getAllProducts()
  }

  // Set from default params inputs
  onParameterSelectionOptions (event) {
    this.parameterSelectionOptions = event
    console.log(
      '---> Setting Product Creation - on Parameter Selection Options'
    )
  }

  // Load the hierarchy tree data
  async getAllProducts (event?) {
    console.log('---> Get All Products: ', event)
    try {
      this.treeDataParams = {
        queryString: '?for_proxy_product=true',
        selectedProd: null
      }
    } finally {
      this.sizeSelectionOptions =
                await this.generalAPIs.GetAllSizesAndOrder()
      // this.unavailableProdIds =
      //           await this.generalAPIs.GetListOfAllProdIds()
      this.parameterSelectionOptions =
                this.parameterSelectionOptions ||
                (await this.generalAPIs.GetProductParameterSelectionOptions())
    }
  }

  // Set the form field selection and validation options
  setSelectionOptions () {
    const tempForm = cloneDeep({ ...this.defaultProxyProductFormSettings })

    Object.keys(tempForm).forEach((field, i) => {
      tempForm[field].disabled = !this.isNodeEditable
      tempForm[field].loading = false
      tempForm[field].currentVal = null
      switch (field) {
        case 'proxy_product_id':
          tempForm[field].validatorOptions = {
            ...tempForm[field].validatorOptions,
            checkForDuplicates: this.unavailableProdIds
          }
          break
        case 'proxy_product_sizes':
          tempForm[field].selectionOptions =
                        this.sizeSelectionOptions
          break
        case 'attribute_family':
          tempForm[field].selectionOptions =
                        this.parameterSelectionOptions.attribute_family
          break
        case 'attribute_value':
          break
      }
      if (Object.keys(tempForm).length === i + 1) {
        this.formData.next(tempForm)
        console.log(
          '---> Updating Form Selection Options and Validation: ',
          tempForm
        )
      }
    })
  }

  getGeneralProductDetailsByID (productID) {
    this.isNodeEditable = false
    this.sizeDistroDefaultParametersAPIs
      .GetProductDetailsByID(productID)
      .then(res => {
        const data = cloneDeep({
          ...res.data
        })
        this.isNodeEditable =
                    this.selectedHierarchyNode.data.editable &&
                    this.selectedHierarchyNode.data.can_create_proxy_product
        // General Size Distro Params
        this.generalProductDetails = {
          editable: this.isNodeEditable,
          ...data
        }
        // New Params for Create Proxy Product
        this.newProxyProduct = {
          ...this.newProxyProduct,
          ...data
        }
        this.setSelectionOptions()
      })
  }

  //  Hierarchy Node Selected
  async onNodeSelected (event) {
    this.selectedNodeEvent = event
    console.log('Product Creation Node Selected: ', event)

    if (!event) {
      console.log('No Nodes Selected')
      this.resetFormData()
    } else {
      const resetComplete =
                Object.keys(this.selectedHierarchyNode).length > 1
                  ? await this.resetFormData(true)
                  : true
      if (resetComplete) {
        this.selectedHierarchyNode = event.node
        if (event.node?.data?.can_create_proxy_product) {
          this.getGeneralProductDetailsByID(
            this.selectedHierarchyNode.key
          )
        }
      }
    }
  }

  onDeletedSelection (isDeleted: boolean) {
    if (isDeleted) {
      this.selectedHierarchyNode = {}
    }
  }

  // Track Changes only for Default Parameters
  onChangesToParams (form) {
    const field = form.controlName
    const removeChange = () =>
      this.newProxyProduct.params_changes.splice(
        this.newProxyProduct.params_changes.indexOf(field),
        1
      )

    const knownChanges =
            this.newProxyProduct.params_changes.includes(field)
    // Update the data to send to the BE
    this.newProxyProduct[field] = form.value
    // Track data changes
    if (
      this.generalProductDetails &&
            this.generalProductDetails[field] != form.value
    ) {
      if (!knownChanges) this.newProxyProduct.params_changes.push(field)
    } else if (knownChanges) {
      removeChange()
    }
    // console.log('changes made: ', this.changesMade)
  }

  // Track when the Default Parameters screen has finished updating
  onParamFormReset (event) {
    this.clearGeneralProductDetails = false
  }

  onHandleNewAttribute (event, action, index) {
    console.log('---> Handle New Attribute: ', this.newProxyProduct)
    console.log(
            `---> ${
                action === 'add' ? 'Adding' : 'Removing'
            } attribute at index: ${index}`
    )
    if (action === 'add') {
      this.newProxyProduct.new_attributes.push({
        ...DefaultNewAttribute
      })
    } else if (action === 'delete') {
      this.newProxyProduct.new_attributes.splice(index, 1)
    }
  }

  updateFormValue (form, index?) {
    const field = form.controlName
    const value = cloneDeep(form.value)
    const checkFormErrors = (status, errorField?: string) => {
      const f = errorField || field
      // console.log(
      //   '----> Check For Errors - Form Data',
      //   this.formDataErrors
      // )
      // console.log('status: ', status)
      // console.log('errorField: ', errorField)

      if (status === 'VALID') {
        // If a field was previously flagged as an error or had no data remove it from the form errors
        if (this.formDataErrors.includes(f)) {
          this.formDataErrors.splice(
            this.formDataErrors.indexOf(f),
            1
          )
        }
      } else {
        if (!this.formDataErrors.includes(f)) {
          this.formDataErrors.push(f)
        }
      }
    }
    console.log('---> Product Creation Form Value Updated: ', form)
    if (field === 'attribute_family' || field === 'attribute_value') {
      this.newProxyProduct.new_attributes[index][field] = value
      const attrVal =
                this.newProxyProduct.new_attributes[index].attribute_value
      const noAttrVal = !attrVal || attrVal === ''
      const attrFam =
                this.newProxyProduct.new_attributes[index].attribute_family
      const targetControl =
                (field === 'attribute_family' && noAttrVal) ||
                (field === 'attribute_value' && (attrFam || attrVal !== ''))
                  ? 'attribute_value'
                  : 'attribute_family'
      const errorRef = targetControl + '-' + (index || 0)
      // console.log('errorRef: ', errorRef)

      if (
        (attrFam && attrFam !== '' && noAttrVal) ||
                (field === 'attribute_family' &&
                    (!value || value === '') &&
                    !noAttrVal)
      ) {
        checkFormErrors('INVALID', errorRef)
      } else {
        checkFormErrors('VALID', errorRef)
      }
    } else if (
      form.status === 'VALID' &&
            (field !== 'attribute_family' || field !== 'attribute_value')
    ) {
      this.newProxyProduct[field] = value

      checkFormErrors('VALID')
    } else if (form.status === 'DISABLED') {
      console.log('field is disabled: ', form)
    } else {
      // console.log('field status: ', form.status);
      console.error('field error: ', form)
      checkFormErrors('INVALID')
    }
  }

  async onBlur (event) {
    console.log(event)
  }

  async onCreateProxyProduct (event) {
    const dataToSend = cloneDeep({ ...this.newProxyProduct })
    try {
      // Reformat the data to send to the backend
      const newAttrCount = dataToSend.new_attributes.length
      if (newAttrCount > 1) {
        dataToSend.new_attributes.splice(newAttrCount - 1, 1)
      }
      delete dataToSend.editable
      // console.log('---> Create New Proxy Product: ', this.newProxyProduct)
    } finally {
      console.log('---> Create New Proxy Product: ', dataToSend)

      this.proxyProductCreationAPIs
        .CreateProxyProduct(dataToSend)
        .then(res => {
          console.log(
            'back inside proxy product creation function: ',
            res
          )
          if (res?.status == 200) {
            this.resetFormData(true)
            this.selectedNodeEvent.node.expanded = true
            this.productHierarchyComponent.toggleNodeExpanded(
              this.selectedNodeEvent,
              true
            )
          }
        })
    }
  }

  async resetFormData (maintainTreeData?: boolean) {
    // this.resettingFormData = true
    try {
      const defaultProxyFrom = {
        ...this.ogDefaultProxyProductData,
        new_attributes: [
          {
            attribute_family: null,
            attribute_value: null
          }
        ],
        params_changes: []
      }
      const reset = await ResetFormData(
        {
          form: this.formData,
          currentFormData: this._formData,
          originalFormData: this.defaultProxyProductFormSettings
        },
        true
      )
      console.log('---> Resetting Form Data: ', reset)
      this.selectedHierarchyNode = {}
      this.defaultSelected = null
      this.isNodeEditable = false
      this.newProxyProduct = defaultProxyFrom

      // reset required fields error
      this.formDataErrors = ['proxy_product_id', 'proxy_product_sizes']
      this.clearGeneralProductDetails = true
      this.setSelectionOptions()
      if (!maintainTreeData) {
        this.loadingProdHier = true
        await this.getAllProducts()
      }
    } catch (error) {
      console.log('Error resetting Proxy Product Hierarchy Form')
    } finally {
      return true
    }
  }

  @HostListener('unloaded')
  ngOnDestroy () {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
  }
}
