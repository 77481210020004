const add_size = {
  controlName: 'add_size',
  label: 'Size To Add',
  placeholder: null,
  hint: null,
  formType: 'auto-selection',
  datatype: 'array',
  selectMultiple: false,
  selectionOptions: [],
  loading: true,
  disabled: false,
  autoComplete: false,
  reset: false,
  defaultVal: null,
  currentVal: '',
  validatorOptions: {
    required: false
  },
  formDisplay: {
    formWidth: '50%'
  }
}

const existing_size = {
  controlName: 'existing_size',
  label: 'Existing Size',
  placeholder: null,
  hint: null,
  formType: 'auto-selection',
  datatype: 'array',
  selectMultiple: false,
  selectionOptions: [],
  loading: true,
  disabled: false,
  autoComplete: false,
  reset: false,
  defaultVal: null,
  currentVal: null,
  validatorOptions: {
    required: false
  },
  formDisplay: {
    formWidth: '50%'
  }
}

const new_size = {
  controlName: 'new_size',
  label: 'New Size',
  placeholder: null,
  hint: null,
  formType: 'auto-selection',
  datatype: 'array',
  selectMultiple: false,
  selectionOptions: [],
  loading: true,
  disabled: false,
  autoComplete: false,
  reset: false,
  defaultVal: null,
  currentVal: null,
  validatorOptions: {
    required: false
  },
  formDisplay: {
    formWidth: '50%'
  }
}

export const AddSize = {
  add_size,
  pct: {
    controlName: 'pct',
    label: 'PCT',
    placeholder: null,
    hint: null,
    suffix: '%',
    formType: 'input',
    datatype: 'percentage',
    selectMultiple: null,
    selectionOptions: null,
    loading: false,
    disabled: false,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: '',
    validatorOptions: {
      required: false,
      min: 0,
      max: 100
    },
    formDisplay: {
      formWidth: '50%'
    }
  }
}
export const AddSizeBySize = {
  add_size_by_size: {
    ...add_size,
    controlName: 'add_size_by_size',
    label: 'Size To Add'
  },
  size_to_model_after: {
    controlName: 'size_to_model_after',
    label: 'Size To Model After',
    placeholder: null,
    hint: null,
    formType: 'auto-selection',
    datatype: 'array',
    selectMultiple: false,
    selectionOptions: [],
    loading: true,
    disabled: false,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  pct_of_model: {
    controlName: 'pct_of_model',
    label: 'PCT of Model',
    placeholder: null,
    hint: null,
    suffix: '%',
    formType: 'input',
    datatype: 'percentage',
    selectMultiple: null,
    selectionOptions: null,
    loading: false,
    disabled: false,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false,
      min: 0,
      max: 100
    },
    formDisplay: {
      formWidth: '50%'
    }
  }
}

export const RemoveSizes = {
  remove_sizes: {
    controlName: 'remove_sizes',
    label: 'Size(s) To Remove',
    placeholder: null,
    hint: null,
    formType: 'auto-selection',
    datatype: 'array',
    selectMultiple: true,
    selectionOptions: [],
    loading: true,
    disabled: false,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '100%'
    }
  }
}

export const MergeSizes = {
  existing_size_1: {
    ...existing_size,
    controlName: 'existing_size_1',
    label: 'Existing Size 1'
  },
  existing_size_2: {
    ...existing_size,
    controlName: 'existing_size_2',
    label: 'Existing Size 2'
  },
  merge_sizes: new_size
}

export const SplitSizes = {
  split_sizes: existing_size,
  new_size_1: {
    ...existing_size,
    controlName: 'new_size_1',
    label: 'New Size 1'
  },
  new_size_2: {
    ...existing_size,
    controlName: 'new_size_2',
    label: 'New Size 2'
  }
}

export const SRA_formSettings = {
  add_size_config: AddSize,
  add_size_by_size_config: AddSizeBySize,
  remove_sizes_config: RemoveSizes,
  merge_sizes_config: MergeSizes,
  split_sizes_config: SplitSizes
}
