export const StoreIndexCopyJobsFormSettings = {
  prior_selling_season: {
    controlName: 'prior_selling_season',
    label: 'Prior Selling Season(s)',
    formType: 'auto-selection',
    datatype: 'string',
    selectMultiple: false,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: '',
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  buying_seasons: {
    controlName: 'buying_seasons',
    label: 'Buying Season',
    formType: 'auto-selection',
    datatype: 'string',
    selectMultiple: false,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: '',
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  }
}
