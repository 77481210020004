<div class="ro-ag-grid-container">
    <!-- AG Grid -->
    <div class="ag-grid-wrapper h-100">
        <!-- Button Tool Panel -->
        <mat-accordion
            [className]="
                tabKey === 'user_input'
                    ? 'user-input-accordion'
                    : 'output-accordion'
            "
        >
            <mat-expansion-panel
                [hideToggle]="tabKey === 'user_input'"
                expanded
                (opened)="onPanelOpened($event)"
                (closed)="onPanelClosed($event)"
            >
                <mat-expansion-panel-header
                    *ngIf="tabKey !== 'user_input'"
                    [ngStyle]="{ 'font-weight': 'bold', 'font-size': '18px' }"
                    >Job List</mat-expansion-panel-header
                >
                <div class="ro-button-tool-panel" [ngSwitch]="tabKey">
                    <!-- User Input Tab -->
                    <span *ngSwitchCase="'user_input'">
                        <!-- Approve -->
                        <button
                            mat-raised-button
                            color="accent"
                            class="primary-split-button"
                            [disabled]="
                                inputLinesSelectedRows.ids.length === 0 ||
                                isUpdatingData ||
                                !gridActionsEnabled.approve
                            "
                            (click)="onApprove($event)"
                        >
                            Approve
                        </button>
                        <!-- More Options - Trigger-->
                        <button
                            id="split-button"
                            mat-raised-button
                            color="accent"
                            [matMenuTriggerFor]="moreOptions"
                            #tooltip="matTooltip"
                            matTooltip="More Options"
                            matTooltipClass="below"
                            [disabled]="
                                inputLinesSelectedRows.ids.length === 0 ||
                                isUpdatingData
                            "
                        >
                            <mat-icon
                                aria-hidden="false"
                                aria-label="More Options"
                                class="m-0"
                                >menu_open</mat-icon
                            >
                        </button>
                        <!-- More Options - Item -->
                        <mat-menu #moreOptions="matMenu">
                            <!-- View Data -->
                            <button
                                mat-menu-item
                                color="accent"
                                class="menu-panel-accent-btn"
                                [disabled]="
                                    inputLinesSelectedRows.ids.length === 0 ||
                                    isUpdatingData ||
                                    !canViewData
                                "
                                (click)="onViewDataEvent.emit($event)"
                            >
                                View Data
                            </button>
                            <mat-divider></mat-divider>
                            <!-- Mark as Pending Review -->
                            <button
                                mat-menu-item
                                color="accent"
                                class="menu-panel-accent-btn"
                                (click)="onMarkAsPendingReview($event)"
                                [disabled]="!gridActionsEnabled.completed"
                            >
                                Unapprove
                            </button>
                            <mat-divider></mat-divider>
                            <!-- Calculate Breaks -->
                            <button
                                mat-menu-item
                                color="accent"
                                class="menu-panel-accent-btn"
                                (click)="onCalculateBreaks($event)"
                                [disabled]="!gridActionsEnabled.calculateBreaks"
                            >
                                Calculate Breaks
                            </button>
                            <mat-divider></mat-divider>
                            <!-- Export -->
                            <app-confirm-popup
                                #confirmPopup
                                (confirmModal)="
                                    onExportBreaks($event); trigger.closeMenu()
                                "
                            ></app-confirm-popup>
                            <app-confirm-popup
                                #confirmPopupDelete
                                (confirmModal)="
                                    onDelete($event); trigger.closeMenu()
                                "
                            ></app-confirm-popup>
                            <button
                                mat-menu-item
                                color="accent"
                                class="menu-panel-accent-btn"
                                [disabled]="!gridActionsEnabled.exportBreaks"
                                (click)="
                                    confirmPopup.confirm(
                                        $event,
                                        'Export Breaks'
                                    );
                                    $event.stopPropagation()
                                "
                            >
                                Export Breaks
                            </button>
                        </mat-menu>
                    </span>
                </div>
                <div
                    [className]="
                        tabKey === 'user_input'
                            ? 'ag-grid-outer-container'
                            : 'top-panel-grid ' + tabKey
                    "
                >
                    <ag-grid-angular
                        [attr.grid]="tabKey"
                        #agGrid
                        id="review-orders-grid"
                        class="ag-theme-alpine ag-grid-container"
                        [gridOptions]="gridOptions"
                        [columnDefs]="columnDefs"
                        (gridReady)="onGridReady($event)"
                        [masterDetail]="tabKey === 'user_input'"
                        [detailCellRendererParams]="detailCellRendererParams"
                    ></ag-grid-angular>
                </div>
                <div
                    *ngIf="
                        tabKey === 'buy_summary' || tabKey === 'carton_summary'
                    "
                    class="top-right-chart"
                >
                    <p-chart
                        class="chart"
                        id="output-chart"
                        [type]="tabKey === 'carton_summary' ? 'pie' : 'line'"
                        [data]="outputChartData"
                        [options]="outputChartOptions"
                        height="100%"
                        width="100%"
                        *ngIf="outputChartData"
                    ></p-chart>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <!-- Output Tabs -->
        <div
            *ngIf="tabKey !== 'user_input'"
            [className]="
                'bottom-grid ' +
                (panelCollapsed ? 'bottom-grid-full-screen ' : '') +
                tabKey
            "
        >
            <ag-grid-angular
                [attr.grid]="tabKey"
                #agGridOutputDetail
                id="review-orders-grid-detail"
                class="ag-theme-alpine ag-grid-container"
                [gridOptions]="outputGridOptions"
                [columnDefs]="outputColumnDefs"
                (gridReady)="onOutputGridReady($event)"
                [pinnedTopRowData]="pinnedTopRowData"
            ></ag-grid-angular>
        </div>
    </div>
</div>
