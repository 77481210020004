// TODO: add interface
export const ResetFormData = async (formParams: any, resetVal: boolean) => {
  const { form, currentFormData, originalFormData } = formParams
  // console.log('---> RESETTING FORM DATA: ', formParams)
  // console.log('---> RESETTING FORM DATA: ', resetVal)

  let tempForm = { ...currentFormData }
  try {
    const dataReset = await Object.keys(currentFormData).map((field, i) => {
      // tempForm[field].reset = reset
      tempForm[field].disabled = originalFormData[field].disabled
      if (resetVal) {
        tempForm[field].currentVal = originalFormData[field].currentVal
      }
      return tempForm[field]
    })
    if (dataReset) {
      form.next(tempForm)
      if (resetVal) {
        tempForm = await ResetFormData(formParams, false)
      }
    }
  } finally {
    return tempForm
  }
}
