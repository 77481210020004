<div class="h-100">
    <!-- ToolBar -->
    <div class="tree-action-container">
        <div class="flex-center-all">
            <form>
                <mat-form-field appearance="outline" color="accent">
                    <input
                        class="filterByProductNodeInput"
                        type="text"
                        matInput
                        placeholder="Filter by Product Node"
                        (keyup)="onTextSearchFilter($event)"
                    />
                    <mat-icon matSuffix>search</mat-icon>
                    <mat-hint>Press 'Enter' to search.</mat-hint>
                </mat-form-field>
            </form>
            <!-- Left Side Buttons -->
            <div id="prod-hier-left-button-container">
                <!-- Expand -->
                <button
                    mat-icon-button
                    color="accent"
                    aria-label="Expand Product Nodes"
                    (click)="expandAll($event)"
                    #tooltip="matTooltip"
                    matTooltip="Expand All"
                    matTooltipClass="below"
                >
                    <mat-icon>expand_more</mat-icon>
                </button>
                <!-- Expand -->
                <button
                    mat-icon-button
                    color="accent"
                    aria-label="Collapse Product Nodes"
                    (click)="collapseAll($event)"
                    #tooltip="matTooltip"
                    matTooltip="Collapse To 3rd Level"
                    matTooltipClass="below"
                >
                    <mat-icon>expand_less</mat-icon>
                </button>
            </div>
        </div>
        <!--  Right Side Buttons -->
        <div class="right-inner-container">
            <!-- View Proxy Product Details -->
            <button
                mat-button
                color="accent"
                *ngIf="
                    showEligibleProxySizes &&
                    selectedNode?.data?.proxy_product &&
                    selectedNode?.children.length === 0
                "
                (click)="onShowProxyProductDetails($event, selectedNode)"
            >
                Proxy Product Details
            </button>

            <!-- Delete Proxy Product -->
            <app-confirm-popup
                #confirmPopup
                (confirmModal)="emitConfirmation($event)"
            ></app-confirm-popup>

            <button
                mat-icon-button
                color="warn"
                aria-label="Delete Node"
                (click)="confirmPopup.confirm($event, 'Delete Proxy Product')"
                *ngIf="
                    allowNodeDeletion &&
                    selectedNode?.data &&
                    selectedNode?.data[nodeToDelete]
                "
                #tooltip="matTooltip"
                matTooltip="Delete Node"
                matTooltipClass="below"
            >
                <mat-icon>delete</mat-icon>
            </button>
            <!-- Filter Icon Panel -->
            <button
                *ngIf="filtersToDisplay.length > 0"
                mat-icon-button
                color="accent"
                aria-label="Filter Options"
                (click)="filterPanel.toggle($event)"
                #tooltip="matTooltip"
                matTooltip="Filter Options"
                matTooltipClass="below"
            >
                <mat-icon>more_vert</mat-icon>
            </button>
            <!-- Filter Menu -->
            <p-overlayPanel #filterPanel [dismissable]="true">
                <ng-template pTemplate="content">
                    <div id="filter-panel-content">
                        <span class="filter-menu-title"> Filter By: </span>
                        <!-- Changes Filter -->
                        <button
                            mat-button
                            *ngIf="showChangesFilter"
                            (click)="onFilterProductHier($event, 'has_changes')"
                            [attr.active]="filtersApplied.has_changes"
                            color="accent"
                            class="filter-option"
                        >
                            <mat-icon class="basic-indicator">
                                change_history
                            </mat-icon>
                            Nodes With Changes
                        </button>
                        <!-- New Nodes Filter -->
                        <button
                            mat-button
                            *ngIf="showNewProductNodesFilter"
                            (click)="onFilterProductHier($event, 'new_product')"
                            [attr.active]="filtersApplied.new_product"
                            color="accent"
                            class="filter-option"
                        >
                            <mat-icon class="basic-indicator"> grade </mat-icon>
                            New Product
                        </button>
                        <!-- Proxy Products Filter -->
                        <button
                            mat-button
                            *ngIf="showProxyProductsFilter"
                            (click)="
                                onFilterProductHier($event, 'proxy_product')
                            "
                            [attr.active]="filtersApplied.proxy_product"
                            color="accent"
                            class="filter-option"
                        >
                            <b
                                id="filter-proxy-indicator"
                                class="proxy-indicator"
                                [attr.filterActive]="
                                    filtersApplied.proxy_product
                                "
                            >
                                ~
                            </b>
                            Proxy Products
                        </button>
                        <div class="overlay-panel-footer-container">
                            <button
                                mat-button
                                color="warn"
                                (click)="filterPanel.hide()"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </ng-template>
            </p-overlayPanel>
        </div>
    </div>
    <!-- Tree Data -->
    <p-tree
        #prodHierTreeData
        [value]="treeData"
        class="w-full"
        [(selection)]="selection"
        [selectionMode]="selectionMode"
        (onNodeSelect)="disabled ? '' : nodeSelect($event)"
        (onNodeUnselect)="disabled ? '' : nodeSelect($event)"
        (onNodeExpand)="toggleNodeExpanded($event)"
        (onNodeCollapse)="expandRecursive($event, false, false, true)"
        [loading]="loading"
        [virtualScroll]="true"
        [virtualScrollItemSize]="35"
        [scrollHeight]="'calc(100% - 86px)'"
    >
        <!-- Empty message -->
        <ng-template pTemplate="empty"> No Products found. </ng-template>
        <!-- Node Display -->
        <ng-template let-node pTemplate="default">
            <span id="custom-prod-node-wrapper">
                <!-- Change Icon -->
                <span
                    class="icon-wrapper"
                    *ngIf="
                        (!additionalChangeFilter &&
                            node.data?.has_changes &&
                            showChangesFilter) ||
                        (additionalChangeFilter &&
                            node.data?.has_changes &&
                            showChangesFilter &&
                            node.data?.filters_to_track[additionalChangeFilter])
                    "
                >
                    <mat-icon class="basic-indicator"> change_history</mat-icon>
                </span>
                <!-- New Products Icon -->
                <span
                    class="icon-wrapper"
                    *ngIf="node.data.new_product && showNewProductNodesFilter"
                >
                    <mat-icon class="basic-indicator">grade</mat-icon>
                </span>
                <!-- Proxy Product Icon  -->
                <span
                    class="icon-wrapper"
                    *ngIf="node.data.proxy_product && showProxyProductsFilter"
                >
                    <p class="proxy-indicator" [attr.color]="'dark'">~</p>
                </span>
                <p class="node-item">
                    {{ node.label }}
                </p>
            </span>
        </ng-template>
    </p-tree>
</div>
