// Angular Imports
// =========================================================
import { Component, ViewChild } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ICellRendererAngularComp } from 'ag-grid-angular'
// Custom Imports
// =========================================================
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component'
import { IsKeyInObj } from 'src/app/utils/global_functions'

@Component({
  selector: 'child-cell',
  templateUrl: './button-renderer.html',
  styleUrls: ['./button-renderer.scss']
})
export class ButtonCellRenderer implements ICellRendererAngularComp {
  public params: any

  btnText: string = ''
  showIcon: boolean = false
  labelIsIcon: boolean = false

  btnDisabled: boolean = false
  hideBtn: boolean = false

  openModalBtn: boolean = false
  openNewScreen: boolean = false
  actionBtn: boolean = false
  iconName: string = ''
  isStaticIcon: boolean = false
  toolTipMessage: string = ''
  toolTipPosition: string = ''

  iconBtnsArray = [
    'chart',
    'settings',
    'delete',
    'add',
    'execute',
    'copy',
    'save',
    'cancel',
    'output',
    'edit'
  ]

  buttonID: string
  parentName: string = ''
  groupRow: boolean = false
  confirmAction: boolean = false
  @ViewChild('confirmPopup')
    confirmPopup: ConfirmPopupComponent

  agInit (params: any): void {
    this.groupRow =
            ((params?.node.group && params.node.level === 0) ||
                params.node.level === 2) &&
            !params.value
    if (!this.groupRow) {
      const refData = params?.colDef?.refData || {}
      const datatype = params.colDef.refData.datatype
      const message = params?.toolTipMessage
        ? params?.toolTipMessage
        : refData?.toolTipMessage

      this.params = params
      this.btnDisabled = params?.disabled || false
      this.parentName = params.context.componentParent
      this.confirmAction = refData?._confirm_action
      // Set the tooltip classes based on the order of the columns -> If the current column is last in the grid the tooltip will need to be smaller
      const colState = params.columnApi.getColumnState()
      this.toolTipPosition =
                colState[colState.length - 1].colId === params.colDef.colId
                  ? 'lastColumn'
                  : 'below'

      this.openModalBtn = datatype.includes('open-modal')
      this.openNewScreen = datatype.includes('open-screen')
      this.actionBtn = datatype.includes('action-button')
      this.iconName =
                params?.alternateIcon &&
                params?.data[params?.alternateIconCondition]
                  ? params?.alternateIcon
                  : datatype.split('|')[1]
      this.buttonID = this.iconName + '_' + params.node.id
      // console.log('icon name: ', this.iconName)
      this.toolTipMessage = message || this.iconName
      // hideBtnKey from the ref data works in reverse: Example we only want to show a button if data is_editable the is_editable data value should be true;
      this.hideBtn =
                (IsKeyInObj(params?.colDef?.refData, 'hideBtnKey') &&
                    !params?.data[params?.colDef?.refData?.hideBtnKey]) ||
                false

      this.btnText = params.btnText || params.colDef.headerName

      this.labelIsIcon = params.labelIsIcon

      this.showIcon = this.iconBtnsArray.includes(this.iconName)

      this.isStaticIcon = refData?.isStaticIcon || false

      // if (IsKeyInObj(refData, 'btn_id')) {

      // }
    }
  }

  public EmitBtn (event) {
    console.log('---> EmitBtn: ', event)
    event.stopPropagation()
    this.params.event = event
    // else console.log('no button click event')
    if (this.params.onClick && !this.confirmAction) {
      this.params.onClick(this.params)
    } else if (this.params.onClick && this.confirmAction) {
      this.confirmPopup.confirm(event)
    }
  }

  emitConfirmation (event) {
    // console.log('---> emitConfirmation: ', event)
    if (event.userInput === 'except') {
      this.params.onClick(this.params)
    }
  }

  isButtonDisabled () {
    const hasDisabledKey =
            IsKeyInObj(this.params, 'disabledKeys') &&
            this.params?.disabledKeys
    const disabledKey = hasDisabledKey
      ? this.params?.disabledKeys.filter(
        key => key === this.params.colDef.colId
      )[0]
      : null
    this.btnDisabled =
            (this.params?.disabled && !hasDisabledKey) ||
            (hasDisabledKey &&
                IsKeyInObj(this.params?.data, disabledKey) &&
                !this.params?.data[disabledKey])
  }

  refresh (): boolean {
    this.isButtonDisabled()
    return true
  }
}
