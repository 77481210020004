export const DistroBulkOverrideFormSettings = {
  source: {
    controlName: 'source',
    label: 'Source STYC', // For this component the label will change depending on the override action selection
    formType: 'auto-selection',
    datatype: 'string',
    selectMultiple: false,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: '',
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  destination: {
    controlName: 'destination',
    label: 'Destination',
    formType: 'auto-selection',
    datatype: 'string',
    selectMultiple: true,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: '',
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  }
}
