<div
    class="flex-center-all"
    style="width: 100%"
    matTooltip="{{ toolTipMessage.toUpperCase() }}"
    [matTooltipClass]="toolTipPosition"
>
    <!-- Custom Header Icon -->
    <div
        [ngSwitch]="iconName"
        class="custom-header-icon-container"
        *ngIf="iconName"
        (click)="onSortRequested($event)"
    >
        <!-- Edit Icon -->
        <span
            *ngSwitchCase="'edit'"
            class="material-icons edit-icon header-icon-style"
        >
            edit
        </span>
        <!-- Default Header so that a tooltip displays for columns with blank headers-->
        <span *ngSwitchDefault class="" style="opacity: 0; width: 40px">
            X
        </span>
    </div>
    <!-- Menu Icon -->
    <div
        *ngIf="params.enableMenu"
        #menuButton
        class="customHeaderMenuButton"
        (click)="onMenuClicked($event)"
    >
        <span class="material-icons menu-icon header-icon-style"> menu </span>
    </div>
</div>
