// Angular Imports
// =========================================================
import { Component, ViewChild } from '@angular/core'
import { cloneDeep } from 'lodash'
// AG Grid Imports
// =========================================================
import { type IToolPanelParams } from 'ag-grid-community'
import { type IToolPanelAngularComp } from 'ag-grid-angular'
// Prime NG Imports
// =========================================================
import { Tree } from 'primeng/tree'
import { type TreeNode } from 'primeng/api'
// Custom Imports
// =========================================================
import {
  FormatKey,
  IsKeyInObj,
  ReverseFormatKey
} from 'src/app/utils/global_functions'
import { SizeDistroManagementSummaryAPIs } from 'src/app/core/apis/size-distro-management-summary_api-calls'
import { type AccordionHeaderPanelStateInterface } from 'src/app/core/interfaces/shared-component-interfaces'
import { SizeDistroDefaultParametersAPIs } from 'src/app/core/apis/size-distro-default-parameters_api-calls'
import { SaveGridState } from 'src/app/05_ag-grid-configs/02_global-settings/grid-functions-general'

@Component({
  selector: 'app-size-distro-management-summary-tool-panel',
  templateUrl: './size-distro-management-summary-tool-panel.component.html',
  styleUrls: ['./size-distro-management-summary-tool-panel.component.scss']
})
export class SizeDistroManagementSummaryToolPanelComponent
implements IToolPanelAngularComp {
  parentGrid
  // buyingSeasonFilters = {}
  ObjectKeys = Object.keys
  FormatText = ReverseFormatKey
  private params: IToolPanelParams

  // Params for Product Hierarchy Section
  // Set the tree data
  treeData: TreeNode[] = []
  treeDataInitializing: boolean = false

  loadingProdHier: boolean = true
  selectedHierarchyNode: TreeNode = null
  defaultSelected: TreeNode[] = []
  allSelectedProductNodes: any = []
  loadingBuyingSeasonFilters: boolean = true
  resetBuyingSeasonFilters: boolean = false
  openPanels: string[] = ['product_filters', 'buying_season_filters']
  expandedNodes: string[] = []

  @ViewChild('prodHierTreeData') tree!: Tree

  constructor (
    public sizeDistroManagementSummaryAPIs: SizeDistroManagementSummaryAPIs,
    private readonly sizeDistroDefaultParametersAPIs: SizeDistroDefaultParametersAPIs
  ) {}

  agInit (params: IToolPanelParams): void {
    this.params = params
    this.parentGrid = this.params?.context?.componentParent
    console.log(
      '---> Size Distro Management Advanced Filtering Tool Panel: ',
      this.params
    )

    // Listen for onModelUpdated to set the filters
    this.params.api.addEventListener(
      'modelUpdated',
      this.updateExternalFilters.bind(this)
    )
  }

  // Track the accordion panel state to adjust the heights of the accordion bodies
  onPanelStateChanged (event: AccordionHeaderPanelStateInterface) {
    console.log('---> Advanced filtering panel state updated: ', event)
    const { state, section } = event
    const panelIndex = this.openPanels.indexOf(section)
    if (state === 'opened' && panelIndex === -1) {
      this.openPanels.push(section)
    } else if (state === 'closed' && panelIndex > -1) {
      this.openPanels.splice(panelIndex, 1)
    }
  }

  // Give each childnode access to the parent node
  wireParents (nodes, parent: TreeNode) {
    if (nodes) {
      return nodes.map(node => {
        node.data['parent'] = parent
        return this.wireParents(node.children, node)
      })
    }
  }

  // Set the default values for each filter section
  async updateExternalFilters (event) {
    // Set the Buying Season Filters
    if (
      this.parentGrid.buyingSeasonFilters &&
            Object.keys(this.parentGrid.buyingSeasonFilters).length > 0
    ) {
      if (this.loadingBuyingSeasonFilters) {
        this.loadingBuyingSeasonFilters = false
        console.log(
          '---> Received Buying Season Filters from Parent: ',
          this.parentGrid.buyingSeasonFilters
        )
      }
    }
    // Set the Tree Node data then update the node selection
    if (
      (this.parentGrid.productTreeData &&
                this.parentGrid.productTreeData.length > 0) ||
            this.parentGrid.productFilters.length !==
                this.allSelectedProductNodes.length
    ) {
      this.allSelectedProductNodes = []
      this.treeData = this.parentGrid.productTreeData
      try {
        // Give all children nodes access to their parent nodes
        await this.wireParents(this.treeData, null)
      } finally {
        const setNodeSelection = node => {
          this.treeDataInitializing = true
          const children =
                        node.children.map(childNode =>
                          setNodeSelection(childNode)
                        ) || []
          node.children = children
          node.data.is_selected =
                        this.parentGrid.productFilters.includes(node.key)

          if (this.parentGrid.productFilters.includes(node.key)) {
            const parent = node.parent || node?.data?.parent
            this.allSelectedProductNodes.push(node)
            this.setParentsPartialSelected(
              parent,
              this.parentGrid.productFilters
            )
          }
          return node
        }

        if (!this.treeDataInitializing) {
          console.log(
            '---> Received Product Tree Data from Parent: ',
            this.parentGrid.productTreeData
          )
          console.log(
            '---> Received Product Tree Filters from Parent: ',
            this.parentGrid.productFilters
          )
          this.loadingProdHier = false

          const mappedData = await this.treeData.map(node =>
            setNodeSelection(node)
          )
          if (mappedData) {
            this.treeDataInitializing = false
            console.log(
              '---> Updated All Selected Product Nodes: ',
              this.allSelectedProductNodes
            )
          }
        }
      }
    }
  }

  // Set Partial Selected at all Levels
  setParentsPartialSelected (node: TreeNode, selectedKeys: string[]) {
    if (node && !selectedKeys.includes(node.key)) {
      const parent = node.parent || node?.data?.parent
      const selectedChildren =
                node.children.filter(child => child.data.is_selected) || []
      const allChildrenSelected =
                selectedChildren.length === node.children.length

      node.partialSelected =
                selectedChildren.length === 0 ? null : !allChildrenSelected
      node.data.is_selected = selectedChildren.length !== 0

      console.log(
                `---> Setting partial parent: ${node.key}: ${node.partialSelected}`
      )

      if (selectedChildren.length > 0 && allChildrenSelected) {
        this.setParentsPartialSelected(parent, [
          ...selectedKeys,
          node.key
        ])
      } else {
        this.setParentsPartialSelected(parent, selectedKeys)
      }
    }
  }

  // Update Node Selection and Partial Selection
  async onNodeSelectionChange (event, isSelected) {
    console.log(`---> product node selected - ${isSelected}: `, event)

    const updateNodeSelection = node => {
      node.data.is_selected = isSelected

      if (node.children.length > 0) {
        node.children = node.children.map(childNode =>
          updateNodeSelection(childNode)
        )
        // set the partial selection
        const selectedChildren =
                    cloneDeep(node.children).filter(
                      child => child.data.is_selected
                    ) || []
        const allChildrenSelected =
                    selectedChildren.length === node.children.length
        node.partialSelected =
                    selectedChildren.length === 0 ? null : !allChildrenSelected
      }
      return node
    }

    const getSelectedNodes = node => {
      if (node.children.length > 0) {
        return [
          node.key,
          ...node.children.flatMap(childNode =>
            getSelectedNodes(childNode)
          )
        ]
      } else if (node.key) {
        return node.key
      }
    }
    let selectedNodes

    try {
      selectedNodes = await [
        ...new Set([
          ...this.allSelectedProductNodes.flatMap(node =>
            getSelectedNodes(node)
          )
        ])
      ]
      await updateNodeSelection(event.node)
    } finally {
      this.parentGrid.productFilters = selectedNodes
    }
  }

  // Apply external filters
  async onApplyAllFilters (event) {
    console.log('---> Applying external filters', this.params)

    if (this.resetBuyingSeasonFilters) {
      this.loadingBuyingSeasonFilters = true
    }
    this.params.api.showLoadingOverlay()
    await SaveGridState(this.parentGrid.gridOptions, this.parentGrid.gridName)
    await this.parentGrid.getAllSizeDistros(true)
  }

  // Reset external product filters
  async onResetBuyingSeasonFilters (event) {
    console.log('---> Reset buying season filters: ', event)
    if (!this.resetBuyingSeasonFilters) {
      this.resetBuyingSeasonFilters = true
    }

    return Object.keys(this.parentGrid.buyingSeasonFilters).forEach(key => {
      this.parentGrid.buyingSeasonFilters[key] = false
    })
  }

  // Reset external product filters
  async onResetProductFilters (event) {
    console.log('---> Reset product filters: ', event)
    const updateNodeSelection = node => {
      node.data.is_selected = false
      if (node.children.length > 0) {
        const parent = node.parent || node?.data?.parent

        node.children = node.children.map(childNode =>
          updateNodeSelection(childNode)
        )
        this.setParentsPartialSelected(parent, [])
      }

      return node
    }
    try {
      // Reset the stored product filters
      this.parentGrid.productFilters = []
      // Make sure the partial selected is set to null for group level nodes
      await this.treeData.map(node => updateNodeSelection(node))
    } finally {
      // Reset the selected product filters
      return (this.allSelectedProductNodes = [])
    }
  }

  // Reset external filters
  async onResetAllFilters (event) {
    console.log('---> Reset all filters: ', event)
    if (!this.resetBuyingSeasonFilters) {
      this.resetBuyingSeasonFilters = true
    }

    this.onResetProductFilters(null)
    this.onResetBuyingSeasonFilters(null)

    // TODO: Delete code below once the functionality is tested and confirmed
    // try {
    // this.loadingBuyingSeasonFilters = true;
    // this.params.api.showLoadingOverlay()
    // await this.onResetProductFilters(null)
    // await this.onResetBuyingSeasonFilters(null)
    // } finally {
    // this.params.api.setFilterModel(null)
    // await this.parentGrid.getAllSizeDistros(true)
    // }
  }

  onValueSelected (data, season) {
    console.log('---> Filter Toggled: ', season + ': ' + data)
    if (this.resetBuyingSeasonFilters) {
      this.resetBuyingSeasonFilters = false
    }
    this.parentGrid.buyingSeasonFilters[season] = data.checked
  }

  toggleNodeExpanded (data) {
    const node: TreeNode = IsKeyInObj(data, 'key') ? data : data.node
    const formKey = FormatKey(node.key)

    if (node.expanded && !this.expandedNodes.includes(formKey)) {
      this.expandedNodes.push(formKey)
      console.log(
                `---> Saving Expanded Node:  ${node.key}: ${node.label}`
      )
      if (node.children.length && node.children[0].children.length == 0) {
        console.log('expanded and node.children')
        // console.log('node.children', JSON.stringify(node.children))
        // this.loadingProdHier = true
        // this.sizeDistroDefaultParametersAPIs
        //   .GetProductTreeNode(
        //     {
        //       queryString: '?a=a'
        //     },
        //     node.key
        //   )
        //   .then(res => {
        //     const data = JSON.parse(res.data).map(childNode => {
        //       childNode = JSON.parse(childNode)
        //       childNode.parent = node
        //       return childNode
        //     })
        //     console.log('node.children', node.children)
        //     node.children = data
        //     console.log('node.children', node.children)
        //     console.log(
        //       '---> Fetched additional tree data',
        //       this.treeData
        //     )
        //     this.loadingProdHier = false
        //     // this.setTopLevelNodes(false)
        //     // this.tree.updateSerializedValue()
        //     // this.nodeSelect({ node: this.treeData[0] })
        //     // this.setSelectedValues([this.treeData[0]])
        //   })
        console.log('No more children to load')
      }
    } else if (!node.expanded && this.expandedNodes.includes(formKey)) {
      this.expandedNodes.splice(this.expandedNodes.indexOf(formKey), 1)
      console.log(
                `---> Removing Saved Expanded Node:  ${node.key}: ${node.label}`
      )
    }
  }
}
