// Angular and RJX Imports
// =========================================================
import { Component } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ICellRendererAngularComp } from 'ag-grid-angular'
import { type IAfterGuiAttachedParams } from 'ag-grid-community'

@Component({
  selector: 'filter-cell',
  templateUrl: './row-loading-renderer.html',
  styleUrls: ['./row-loading-renderer.scss']
})
export class RowLoadingCellRenderer implements ICellRendererAngularComp {
  loadingMessage: string

  agInit (params: any): void {
    const data = params.node.data
    const loadingM = params.context.componentParent.loadingMessage
    if (loadingM !== '') {
      this.loadingMessage = loadingM
    } else {
      this.loadingMessage = 'Loading'
    }
  }

  refresh (params: any): boolean {
    throw new Error('Method not implemented.')
  }

  afterGuiAttached? (params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.')
  }

  mouseWheelListener (event) {
    event.stopPropagation()
  }
}
