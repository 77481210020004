import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class GridFileUploadService {
  private readonly rowDataErrors = new Subject<any>()
  public rowDataErrors$ = this.rowDataErrors.asObservable()

  // constructor () {}

  onUpdateRowDataErrors (gridDetails) {
    return this.rowDataErrors.next(gridDetails)
  }

  getRowDataUpdates () {
    return this.rowDataErrors$
  }
}
