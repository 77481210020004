[
    [
        {
            "name": "Size Distribution",
            "description": null,
            "icon": "insert_chart",
            "level": "category",
            "is_favorite": true,
            "is_enabled": true,
            "route": null,
            "children": [
                {
                    "name": "Size Distro Strategy",
                    "description": "Define default parameters that guide how size distributions will be generated. Create proxy products that account for how new merchandise will be planned in an assortment.",
                    "level": "section",
                    "icon": null,
                    "is_enabled": true,
                    "route": "size-distro-strategy/default-parameters",
                    "children": [
                        {
                            "name": "Default Parameters",
                            "description": "Add a description here..",
                            "route": "size-distro-strategy/default-parameters",
                            "is_enabled": true,
                            "level": "screen"
                        },
                        {
                            "name": "Product Creation",
                            "description": "Add a description here..",
                            "route": "size-distro-strategy/product-creation",
                            "is_enabled": true,
                            "level": "screen"
                        },
                        {
                            "name": "Product Upload",
                            "description": "Add a description here..",
                            "route": "size-distro-strategy/product-upload",
                            "is_enabled": true,
                            "level": "screen"
                        }
                    ]
                },
                {
                    "name": "Size Distro Execution",
                    "description": "Create jobs that generate style-color size distributions and manage style-color exceptions.",
                    "level": "section",
                    "is_enabled": true,
                    "icon": null,
                    "route": "size-distro-execution/job-management",
                    "children": [
                        {
                            "name": "Job Management",
                            "description": "Add a description here..",
                            "is_enabled": true,
                            "level": "screen",
                            "route": "size-distro-execution/job-management"
                        },
                        {
                            "name": "Job Creator",
                            "description": "Add a description here..",
                            "is_enabled": true,
                            "level": "screen",
                            "route": "size-distro-execution/job-creation"
                        },
                        {
                            "name": "Job Upload",
                            "description": "Add a description here..",
                            "is_enabled": true,
                            "level": "screen",
                            "route": "size-distro-execution/job-upload"
                        },
                        {
                            "name": "Job Exceptions",
                            "description": "Add a description here..",
                            "is_enabled": true,
                            "level": "screen",
                            "route": "size-distro-execution/job-exceptions"
                        }
                    ]
                },
                {
                    "name": "Size Distro Management",
                    "description": "Review, edit, and approve generated  style-color size distributions to be used for buying. ",
                    "level": "screen",
                    "is_enabled": true,
                    "icon": null,
                    "route": "size-distro-management",
                    "children": []
                }
            ]
        },
        {
            "name": "Store Indexes",
            "description": null,
            "icon": "donut_small",
            "level": "category",
            "is_favorite": true,
            "is_enabled": true,
            "route": "store-index-execution/store-index-management",
            "children": [
                {
                    "name": "Store Index Execution and Management",
                    "description": "Create jobs that generate store index percentages. Review, edit, and approve generated store indexes to be used for buying.",
                    "level": "screen",
                    "is_enabled": true,
                    "icon": null,
                    "route": "store-index-execution/store-index-management"
                }
            ]
        },
        {
            "name": "Buying",
            "description": "Add a description here..",
            "icon": "shopping_cart",
            "level": "category",
            "is_favorite": true,
            "is_enabled": true,
            "route": "buying/buying-strategy",
            "children": [
                {
                    "name": "Buying Strategy",
                    "description": "Define default parameters that guide carton recommendations and how order quantities are cartonized.",
                    "level": "screen",
                    "is_enabled": true,
                    "icon": null,
                    "route": "buying/buying-strategy"
                },
                {
                    "name": "Review Orders",
                    "description": "Review, manage, and approve orders.",
                    "level": "screen",
                    "is_enabled": true,
                    "icon": null,
                    "route": "buying/review-orders"
                }
            ]
        },
        {
            "name": "Admin",
            "description": null,
            "icon": "admin_panel_settings",
            "level": "category",
            "is_favorite": false,
            "is_enabled": false,
            "route": null,
            "children": [
                {
                    "name": "User Management",
                    "description": "Add a description here..",
                    "is_enabled": true,
                    "level": "screen",
                    "route": "admin/user-management"
                }
            ]
        }
    ]
]
