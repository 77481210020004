// Angular Imports
// =========================================================
import { Component } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ICellRendererAngularComp } from 'ag-grid-angular'

@Component({
  selector: 'multi-checkbox-column',
  templateUrl: './multi-checkbox-column-renderer.html',
  styleUrls: ['./multi-checkbox-column-renderer.scss']
})
export class MultiCheckboxColumnRenderer implements ICellRendererAngularComp {
  public params: any

  isChecked: boolean = false
  colId: string = ''
  parentName: string = ''
  groupRow: boolean = false
  disabled: boolean = false

  agInit (params: any): void {
    console.log('--> multi checkbox renderer params: ', params)

    this.colId = params.colDef.colId
    this.groupRow = params?.node?.group
    this.isChecked = params.value
    this.params = params
    this.disabled = params?.disabled || false
  }

  getValue () {
    return this.isChecked
  }

  public EmitBtn (event) {
    event.stopPropagation()

    this.params.node.setDataValue(
      [this.params.colDef.colId],
      this.isChecked
    )
    this.params.value = this.isChecked

    if (this.params.onClick) {
      this.params.onClick(this.params)
    }
  }

  refresh (): boolean {
    return true
  }
}
