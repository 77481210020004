// Angular Imports
// =========================================================
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Subject } from 'rxjs'
// Amplify Imports
// =========================================================
import Amplify, { Cache, Auth, Hub, Storage, Logger } from 'aws-amplify'
// Custom Imports
// =========================================================

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  private readonly authenticationSubject: BehaviorSubject<any>
  private readonly triggerUserUpdate = new Subject<any>()
  public triggerUserUpdate$ = this.triggerUserUpdate.asObservable()

  loading: boolean = true
  isUserIdle: boolean = false
  tokenExpiration: number
  tempFlag: boolean = true
  user: string
  callbackUrl: string
  // Track Auth State
  logger = new Logger('Logger', 'INFO')

  constructor (private readonly router: Router) {
    this.authenticationSubject = new BehaviorSubject<boolean>(false)
  }

  public hubListener () {
    // Listen for authentication updates
    Hub.listen('auth', async ({ payload, payload: { event, data } }) => {
      // console.log('hub listener: ', event)
      // console.log('payload: ', payload)

      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          if (!this.user) {
            this.tempFlag = false
            this.checkUser()
          }
          this.logger.info('user signed in')
          this.router.navigateByUrl(
            '/size-intelligence-modules-dashboard'
          )
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          this.logger.info('user sign in failed')
          break
        case 'signUp':
          this.logger.info('user signed up')
          break
        case 'configured':
          this.logger.info('the Auth module is configured')
          console.log('Payload Data: ', data)
          this.checkUser()
          break
        case 'oAuthSignOut':
          this.logger.info('oAuthSignOut: ', data)
          break
        case 'signOut':
          this.logger.info('user signed out')
          this.clearUserDetails()
          break
        case 'implicitFlow':
          console.log('implicit flow: ', payload)
          break
        case 'tokenRefresh':
          console.info('token refresh succeeded')
          break
        case 'tokenRefresh_failure':
          console.error('token refresh failed')
          this.signOut()
          break
        case 'parsingCallbackUrl':
          this.logger.info('Parsing Callback Url: ', event)
          break
        case 'codeFlow':
          this.logger.info('codeFlow: ', data)
          break
        default:
          this.logger.error(
            'Something went wrong, look at data object',
            event
          )
      }
    })
  }

  checkUser (test?: boolean) {
    // console.log('in check user')
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.user = user
        console.log('Checking for user in cognito service: ', user)
        return this.triggerUserUpdate.next(user.attributes)
      })
      .catch(async () => {
        this.user = null
        console.log('Hub listener - No user found')
        return this.triggerUserUpdate.next(null)
      })
  }

  // Sign out of the application
  public async signOut (): Promise<any> {
    // console.log('Signing out')
    return await Auth.signOut().then(() => {
      // this.checkUser()
      this.router.navigateByUrl('/')
      this.authenticationSubject.next(false)
    })
  }

  // Verify that the user is authenticated
  public async isAuthenticated (): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return await Promise.resolve(true)
    } else {
      return await this.getUser()
        .then((user: any) => {
          if (user) {
            return true
          } else {
            return false
          }
        })
        .catch(() => {
          return false
        })
    }
  }

  // Get the current user details
  public async getUser (): Promise<any> {
    return await Auth.currentSession()
  }

  // First step to user login -> authenticate the account ID
  public async authUIStateChange (authState, authData) {}

  // Refresh access tokens
  refreshToken = async () => {
    console.log('---> Refreshing Users Auth Tocken')

    // refresh the token here and get the new token info
    const cognitoUser = await Auth.currentAuthenticatedUser()

    await Auth.currentSession().then(session => {
      // console.log('current session: ', session)

      const cognitoUserSession = session
      const refresh = cognitoUserSession.getRefreshToken()
      // console.log('refresh: ', refresh)

      cognitoUser.refreshSession(refresh, (err, success) => {})
    })
  }

  // Functionality to update user credentials
  public async updateUser (user): Promise<any> {
    return await Auth.currentUserPoolUser().then(
      async (cognitoUser: any) => {
        return await Auth.updateUserAttributes(cognitoUser, user)
      }
    )
  }

  // When the user signs out or the session times put -> reset the data from cognito and session storage
  public async clearUserDetails () {
    try {
      console.log('Clearing User Details')
      await Cache.removeItem('accountId')
      await Cache.removeItem('accountConfig')
      await Cache.clear()
      await localStorage.clear()
      await sessionStorage.clear()
    } catch (error) {
      console.log('error clearing user details')
    } finally {
      location.reload()
    }
  }

  public async authFederatedSignIn (): Promise<any> {
    console.log('---- Federate Login ---- ')

    return await Auth.federatedSignIn()
      .then(async cred => {
        // If success, you will get the AWS credentials
        // console.log('credentials: ', cred)
        return await Auth.currentAuthenticatedUser()
      })
      .then(user => {
        // If success, the user object you passed in Auth.federatedSignIn
        // console.log('success', user)
        return user
      })
      .catch(e => {
        console.log(e)
      })
  }
}
