<!-- Modal Footer -->
<div class="modal-footer">
    <!-- Additional Buttons or text -->
    <ng-content></ng-content>
    <!-- Standard Submit button -->
    <button
        *ngIf="showUpdateBtn"
        mat-raised-button
        [color]="submitBtnColor"
        class="submit-btn-dark-reverse"
        type="button"
        class="submit-btn"
        (click)="updateModal()"
        [disabled]="disabled"
    >
        {{ confirmBtnText }}
    </button>
    <!-- Close Modal Button -->
    <button
        mat-button
        color="warn"
        class="text-warning"
        type="button"
        (click)="closeModal()"
        [disabled]="closeDisabled"
    >
        <span [ngSwitch]="showUpdateBtn">
            <span *ngSwitchCase="true"> {{ closeBtnText }}</span>
            <span *ngSwitchCase="false">{{
                closeBtnText === 'Cancel' ? 'Close' : closeBtnText
            }}</span>
        </span>
    </button>
</div>
