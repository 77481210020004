import { __awaiter as t } from "tslib";
import { Hub as e } from "aws-amplify";
import { appendToCognitoUserAgent as n } from "@aws-amplify/auth";
import { waitFor as i } from "xstate/lib/waitFor.js";
import { ALLOWED_SPECIAL_CHARACTERS as a } from "./constants.mjs";
import { getActorState as o } from "./actor.mjs";
import { isFunction as s } from "../../utils/index.mjs";
const r = ({
    packageName: t,
    version: e
  }) => {
    n(`${t}/${e}`);
  },
  c = t => {
    const e = t.trim().split("");
    for (let t = 0; t < e.length; t++) t > 0 && t < e.length - 1 && (e[t] = "*");
    return e.join("");
  },
  l = t => {
    if (t.length < 4) return t;
    const e = t.split("");
    for (let t = 0; t < e.length - 4; t++) e[t] = "*";
    return e.join("");
  },
  u = e => t(void 0, void 0, void 0, function* () {
    try {
      yield i(e, t => o(t).matches("autoSignIn"));
    } catch (t) {}
  }),
  m = ({
    payload: {
      data: e,
      event: n
    }
  }, i, a) => t(void 0, void 0, void 0, function* () {
    const {
        send: t
      } = i,
      r = i.getSnapshot(),
      {
        onSignIn: c,
        onSignOut: l
      } = null != a ? a : {};
    switch (n) {
      case "tokenRefresh":
        r.matches("authenticated.idle") && t("TOKEN_REFRESH");
        break;
      case "autoSignIn":
        if (!r.matches("authenticated")) {
          yield u(i);
          const n = o(i.getSnapshot());
          (null == n ? void 0 : n.matches("autoSignIn")) && t({
            type: "AUTO_SIGN_IN",
            data: e
          });
        }
        break;
      case "autoSignIn_failure":
        {
          yield u(i);
          const n = o(i.getSnapshot());
          (null == n ? void 0 : n.matches("autoSignIn")) && t({
            type: "AUTO_SIGN_IN_FAILURE",
            data: e
          });
          break;
        }
      case "signIn":
        s(c) && c();
        break;
      case "signOut":
      case "tokenRefresh_failure":
        s(l) && l(), r.matches("authenticated.idle") && t("SIGN_OUT");
    }
  }),
  d = (t, n = m) => e.listen("auth", ((t, e) => n => {
    e(n, t);
  })(t, n), "authenticator-hub-handler"),
  h = t => a.some(e => t.includes(e)),
  p = (t, e, n) => encodeURI(`otpauth://totp/${t}:${e}?secret=${n}&issuer=${t}`);
function f(t, ...e) {
  return Object.entries(t).reduce((t, [n, i]) => Object.assign(Object.assign({}, t), {
    [n]: e.includes(n) ? i : null == i ? void 0 : i.trim()
  }), {});
}
export { c as censorAllButFirstAndLast, l as censorPhoneNumber, r as configureComponent, m as defaultAuthHubHandler, p as getTotpCodeURL, h as hasSpecialChars, d as listenToAuthHub, f as trimValues };