// Angular, RJX and lodash Imports
// =========================================================
import {
  type AsyncValidatorFn,
  type AbstractControl,
  type ValidationErrors,
  type ValidatorFn
} from '@angular/forms'
// Custom Imports
// =========================================================
import { FormatKey } from 'src/app/utils/global_functions'

export const CheckForDuplicates = (valuesNotAllowed): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value =
            control.value !== null ? FormatKey(control?.value?.trim()) : null

    if (!value) {
      return null
    }
    const uniqueValue = !valuesNotAllowed.includes(value)
    return !uniqueValue ? { duplicateValue: true } : null
  }
}

export const CheckForExistingValue = (
  existingValues: string[]
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value =
            control.value !== null ? FormatKey(control?.value?.trim()) : null

    if (!value) {
      return null
    }
    const existingValue = !existingValues.includes(value)
    return !existingValue ? { unknownValue: true } : null
  }
}
export const CheckIsInteger = (val?): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = val || (control.value !== null ? control.value : null)
    if (typeof value === 'number') {
      value = value.toString()
    }
    if (typeof val === 'number') {
      val = val.toString()
    }

    if (!val) {
      val = value
    }

    if (!value) {
      return { invalidInteger: true }
    }
    const regUser = /^[a-zA-Z0-9-\s]+$/

    if (
      !regUser.test(value) ||
            (val && !regUser.test(val)) ||
            (val && value.toString().includes('.'))
    ) {
      return { invalidInteger: true }
    } else {
      return null
    }
  }
}
export const CheckForSpecialCharacters = (val?): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = val || (control.value !== null ? control.value : null)

    if (!value) {
      return null
    }
    const regUser = /^[a-zA-Z0-9-\s]+$/

    if (
      !regUser.test(value) ||
            (val && !regUser.test(val)) ||
            (val && value.toString().includes('.'))
    ) {
      return { specialCharacters: true }
    } else {
      return null
    }
  }
}

export const CheckS3FilePathForSpecialCharacters = (val?): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value !== null ? control.value : null

    if (!value) {
      return null
    }
    // const regUser = /^[a-zA-Z0-9\.\s\-\_]+$/g
    const regUser = /^[a-zA-Z0-9\s]+$/g

    if (!regUser.test(value)) {
      return { specialCharacters: true }
    } else {
      return null
    }
  }
}
export const ValidateCartonSizes = (val?): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value !== null ? control.value : null

    if (!value) {
      return null
    }
    const regUser = /^[0-9,]+$/

    if (!regUser.test(value)) {
      return { specialCharacters: true }
    } else {
      return null
    }
  }
}

export const CheckForEmailValidationCharacters = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value !== null ? control.value : null

    if (!value) {
      return null
    }
    const regUser =
            /^([a-zA-Z0-9\d\-|+]+)@([a-zA-Z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
    if (!regUser.test(value)) {
      return { emailValidationCharacters: true }
    } else {
      return null
    }
  }
}

export const NoWhitespaceValidator = (): ValidatorFn => {
  return (control: AbstractControl): Record<string, any> => {
    // messy but you get the idea
    const isWhitespace = (control?.value || '').trim().length === 0
    const isValid = !isWhitespace
    return isValid ? null : { required: true }
  }
}
