<div class="main-ag-grid-container flex-column">
    <!-- Container for above grid actions / buttons -->

    <!-- All Size Distro Exceptions -->
    <mat-card class="h-100" id="buying-strat-card-container">
        <!-- Left Side -->
        <div
            style="
                flex-basis: 55%;
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;
            "
        >
            <app-basic-headers [sectionName]="'Product Hierarchy Selection'">
            </app-basic-headers>
            <app-product-hierarchy
                [noNodeSelectedAllowed]="false"
                [treeDataParams]="treeDataParams"
                [filtersToDisplay]="hierFiltersToDisplay"
                [selectionMode]="'single'"
                [selectedValues]="defaultSelected"
                [showEligibleProxySizes]="true"
                [allowNodeDeletion]="false"
                (onNodeSelected)="onNodeSelected($event)"
                [filtersApplied]="hierFiltersApplied"
                [loading]="loadingProdHier"
                style="flex: 2; padding: 0px 10px"
            ></app-product-hierarchy>
        </div>
        <!-- Right Side -->
        <div
            class="selected-nodes-wrapper"
            style="flex-basis: 45%; height: inherit; overflow: hidden"
        >
            <app-basic-headers
                [textEllipsis]="true"
                [sectionName]="
                    'Selected Node: ' +
                    (selectedHierarchyNode?.label
                        ? selectedHierarchyNode?.label
                        : 'No Node Selected')
                "
            ></app-basic-headers>

            <!-- Button Tool Panel -->
            <div class="button-tool-panel">
                <!-- Revert To Inheritance -->
                <app-confirm-popup
                    #confirmPopup
                    (confirmModal)="emitConfirmation($event)"
                ></app-confirm-popup>
                <!-- New Save -->
                <button
                    mat-raised-button
                    color="accent"
                    [disabled]="loadingProdHier || changesMade.length === 0"
                    (click)="
                        $event.stopPropagation();
                        confirmPopup.confirm($event, 'Save')
                    "
                    class="primary-split-button"
                >
                    Save
                </button>
                <!-- More Options - Trigger-->
                <button
                    id="split-button"
                    mat-raised-button
                    color="accent"
                    [matMenuTriggerFor]="moreOptions"
                    #tooltip="matTooltip"
                    matTooltip="More Options"
                    matTooltipClass="below"
                    [disabled]="
                        selectedHierarchyNode &&
                        !selectedHierarchyNode?.data?.editable
                    "
                >
                    <mat-icon
                        aria-hidden="false"
                        aria-label="More Options"
                        class="m-0"
                        >menu_open</mat-icon
                    >
                </button>
                <!-- More Options - Item -->
                <mat-menu #moreOptions="matMenu">
                    <!-- Revert To Inheritance btn -->
                    <button
                        mat-menu-item
                        color="accent"
                        [disabled]="
                            !selectedHierarchyNode?.data?.parent ||
                            selectedHierarchyNode?.data?.parent?.length === 0
                        "
                        (click)="
                            $event.stopPropagation();
                            confirmPopup.confirm(
                                $event,
                                'Revert To Inheritance'
                            )
                        "
                    >
                        Revert To Inheritance
                    </button>
                    <!-- Override All btn -->
                    <button
                        mat-menu-item
                        color="accent"
                        [disabled]="
                            !selectedHierarchyNode?.children ||
                            selectedHierarchyNode?.children?.length === 0
                        "
                        (click)="
                            $event.stopPropagation();
                            confirmPopup.confirm($event, 'Override All')
                        "
                    >
                        Override All
                    </button>
                    <!-- TODO: V2 Update: Hardcoded Disabled for V1 -->
                    <!-- <button
                        mat-menu-item
                        color="accent"
                        [disabled]="true"
                        (click)="
                            $event.stopPropagation();
                            confirmPopup.confirm($event, 'Override Changes')
                        "
                    >
                        Override Changes
                    </button> -->
                </mat-menu>
            </div>
            <div class="inputs-container">
                <div id="job-details-container">
                    <!-- Channel -->
                    <span>
                        <p class="form-title">Channel:</p>
                        <app-form-fields
                            [formParams]="formData"
                            [controlName]="'channel'"
                            (onFormFieldUpdated)="onUpdateChannel($event)"
                            [hideLabel]="true"
                            class="w-100"
                        >
                        </app-form-fields>
                    </span>
                </div>
                <!-- Parameter Inputs -->
                <div class="custom-vertical-scroll parameter-inputs-container">
                    <app-cartonization-settings-input-fields
                        [cartonSettings]="cartonSettings"
                        (onFormValueChanged)="onFormValueChanged($event)"
                        [clearFormGroup]="clearCartonSettingsForm"
                        (onFormReset)="onCartonSettingsFormReset($event)"
                        panelOpenOnLoad="true"
                    ></app-cartonization-settings-input-fields>
                </div>
            </div>
        </div>
    </mat-card>
</div>
