// Angular, RJX and lodash Imports
// =========================================================
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
// Amplify Imports
// =========================================================
// Prime NG Imports
// =========================================================
// Custom Imports
// =========================================================

@Injectable({
  providedIn: 'root'
})
export class TriggerApiCallsService {
  public triggerApiCalls = new BehaviorSubject({ clear_api_callss: false })
  public triggerApiCalls$ = this.triggerApiCalls.asObservable()

  // Tracks and updates globally accessible plan data
  onTriggerApiCalls (data) {
    console.log('--> Clear api calls: ', data['clear_api_calls'])
    this.triggerApiCalls.next(data)
  }
}
