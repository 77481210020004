[
    {
        "Job Count": {
            "editable": false,
            "refData": {
                "datatype": "numberGroup"
            },
            "lockVisible": true,
            "hide": false,
            "checkboxSelection": true,
            "headerCheckboxSelection": true,
            "headerCheckboxSelectionFilteredOnly": true,
            "lockPosition": true,
            "pinned": "left"
        },
        "Rainbow Pack Eligible": {
            "editable": false,
            "refData": {
                "datatype": "checkmarkBoolean"
            },
            "hide": false
        },
        "Status(es)": {
            "editable": false,
            "refData": {
                "datatype": "statusesArray",
                "custom_field": "statuses"
            },
            "hide": false
        },
        "Date Submitted": {
            "editable": false,
            "refData": {
                "datatype": "date"
            },
            "hide": false
        },
        "Buy Group": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "custom_field": "buy_group_id"
            },
            "hide": false
        },
        "Subclass Id": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "custom_field": "subclass_id",
                "tooltipField": "subclass_id"
            },
            "hide": false
        },
        "Style-Color ID(s)": {
            "editable": false,
            "refData": {
                "datatype": "array",
                "showToolTip": true,
                "custom_field": "style_color_ids",
                "tooltipField": "style_color_ids"
            },
            "filter": "agSetColumnFilter",
            "hide": false
        },
        "Style-Color Name(s)": {
            "editable": false,
            "refData": {
                "datatype": "array",
                "showToolTip": true,
                "custom_field": "style_color_names",
                "tooltipField": "style_color_names"
            },
            "filter": "agSetColumnFilter",
            "hide": false
        },
        "Channel(s)": {
            "editable": false,
            "refData": {
                "datatype": "array",
                "showToolTip": true,
                "custom_field": "channels",
                "tooltipField": "channels"
            },
            "filter": "agSetColumnFilter",
            "hide": false
        },
        "Size Range(s)": {
            "editable": false,
            "refData": {
                "datatype": "array",
                "showToolTip": true,
                "custom_field": "size_ranges",
                "tooltipField": "size_ranges"
            },
            "filter": "agSetColumnFilter",
            "hide": false
        },
        "Delete": {
            "editable": false,
            "refData": {
                "datatype": "open-screen|delete"
            },
            "rowGroup": false,
            "hide": false,
            "lockPosition": true,
            "pinned": "right"
        }
    },
    {
        "Status": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "hide": false,
            "checkboxSelection": true,
            "resizable": true,
            "lockPosition": true,
            "pinned": "left"
        },
        "Subclass Id": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "custom_field": "subclass_id",
                "tooltipField": "subclass_id"
            },
            "hide": false,
            "resizable": true
        },
        "Style Color ID(s)": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "custom_field": "style_color_id",
                "tooltipField": "style_color_id"
            },
            "hide": false,
            "resizable": true
        },
        "Style Color Name(s)": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "custom_field": "style_color_name",
                "tooltipField": "style_color_name"
            },
            "hide": false,
            "resizable": true
        },
        "Channel": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "custom_field": "channel",
                "tooltipField": "channel"
            },
            "hide": false,
            "resizable": true
        },
        "Size Range(s)": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "custom_field": "size_ranges",
                "tooltipField": "size_ranges"
            },
            "hide": false,
            "resizable": true
        },
        "Total Qty": {
            "editable": false,
            "refData": {
                "datatype": "number"
            },
            "hide": false,
            "resizable": true
        },
        "# Deliveries": {
            "editable": false,
            "refData": {
                "datatype": "number",
                "custom_field": "num_deliveries"
            },
            "hide": false,
            "resizable": true
        },
        "Store Distro(s)": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "custom_field": "store_distros"
            },
            "hide": false,
            "resizable": true
        },
        "Store Count": {
            "editable": false,
            "refData": {
                "datatype": "number"
            },
            "hide": false,
            "resizable": true
        },
        "Avg Mismatch": {
            "editable": false,
            "refData": {
                "datatype": "number"
            },
            "hide": false,
            "resizable": true
        },
        "Fit Quality": {
            "editable": false,
            "refData": {
                "datatype": "number"
            },
            "hide": false,
            "resizable": true
        },
        "Parameters": {
            "editable": false,
            "refData": {
                "datatype": "open-screen|settings"
            },
            "rowGroup": false,
            "hide": false,
            "resizable": true
        }
    }
]
