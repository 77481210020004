// Angular and RJX Imports
// =========================================================
import {
  Component,
  EventEmitter,
  Input,
  Output,
  type OnInit,
  type OnDestroy
} from '@angular/core'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { cloneDeep } from 'lodash'
import { Router } from '@angular/router'
// JSON Data
// =========================================================
import { DefaultParamsFormSettings } from '../../03_shared-components/forms/form-data/default-parameters'
// Custom Imports
// =========================================================
import { type FormGroupInterface } from 'src/app/core/interfaces/form-field-interface'
import { DefaultParametersInputsInterface } from 'src/app/core/interfaces/data-expected-from-backend/default-parameters-inputs-interface'
import { IsKeyInObj } from 'src/app/utils/global_functions'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { ResetFormData } from 'src/app/03_shared-components/forms/form-data/form-data-reset'
import { type ParameterSelectionOptionsInterface } from 'src/app/core/interfaces/data-expected-from-backend/parameter-selection-options'

@Component({
  selector: 'app-default-parameters-input-fields',
  templateUrl: './default-parameters-input-fields.component.html',
  styleUrls: ['./default-parameters-input-fields.component.scss']
})
export class DefaultParametersInputFieldsComponent
implements OnInit, OnDestroy {
  isInitialDataLoaded: boolean = false
  ogCurveSmoothingParams: any
  currentProductId: string = null

  refDefaultParamsFormSettings: FormGroupInterface = cloneDeep(
    DefaultParamsFormSettings
  )

  // Import the original form settings and track updates
  formData = new BehaviorSubject<FormGroupInterface>(
    cloneDeep(DefaultParamsFormSettings)
  )

  urlsExcludingIndexParameters: string[] = []
  hideIndexParameters: boolean = true
  isCurveSmoothingDisabled: boolean = true
  smoothing_strength: string = null
  blending_strength: string = null

  @Input() panelOpenOnLoad: boolean

  productDetails: DefaultParametersInputsInterface
  @Input('productDetails')
  set _productDetails (productDetails: DefaultParametersInputsInterface) {
    if (productDetails) {
      const tempForm = this.refDefaultParamsFormSettings
      console.log(
        '---> Setting Default Parameter Inputs: ',
        productDetails
      )
      console.log(
                `---> Current -> New Product Id: ${this.currentProductId} -> ${productDetails?.product_id} `
      )
      if (
        !this.currentProductId ||
                this.currentProductId !== productDetails?.product_id
      ) {
        const {
          curve_smoothing,
          smoothing_strength,
          blending_strength
        } = productDetails
        this.ogCurveSmoothingParams = curve_smoothing
          ? cloneDeep({
            curve_smoothing,
            smoothing_strength,
            blending_strength
          })
          : {
              curve_smoothing: false,
              smoothing_strength: null,
              blending_strength: null
            }
      }

      const setFormValues = async () => {
        if (
          this.currentProductId &&
                    this.currentProductId !== productDetails.product_id
        ) {
          const reset = await ResetFormData(
            {
              form: this.formData,
              currentFormData: this._formData,
              originalFormData: DefaultParamsFormSettings
            },
            true
          )
          if (reset) {
            this.currentProductId = productDetails.product_id
            setFormValues()
          }
        } else {
          Object.entries(productDetails).forEach(
            ([key, value], i) => {
              if (IsKeyInObj(tempForm, key)) {
                // Update values for all keys
                tempForm[key].disabled =
                                    !productDetails.editable
                tempForm[key].reset = false

                if (
                  key === 'smoothing_strength' ||
                                    key === 'blending_strength'
                ) {
                  if (productDetails.curve_smoothing) {
                    this.isCurveSmoothingDisabled = false
                    tempForm[key].currentVal =
                                            value || 'Low' // set the default value to low
                  } else {
                    this.isCurveSmoothingDisabled = true
                  }
                } else {
                  tempForm[key].currentVal = value
                }
              }
              if (Object.keys(productDetails).length === i + 1) {
                this.refDefaultParamsFormSettings = tempForm
                this.formData.next(tempForm)
                this.currentProductId =
                                    productDetails.product_id
              }
            }
          )
        }
      }

      setFormValues()
    } else {
      console.log('---> No Product Details Found: ')
      // this.formData.next(tempForm)
    }
  }

  clearFormGroup: boolean
  @Input('clearFormGroup')
  set _clearFormGroup (clearForm: boolean) {
    if (clearForm) {
      const resetForm = async () => {
        this.currentProductId = null
        this.ogCurveSmoothingParams = {
          curve_smoothing: false,
          smoothing_strength: null,
          blending_strength: null
        }
        const reset = await ResetFormData(
          {
            form: this.formData,
            currentFormData: this._formData,
            originalFormData: DefaultParamsFormSettings
          },
          true
        )
        console.log('---> Resetting Default Parameter Inputs:', reset)
        this.onFormReset.emit(true)
      }
      resetForm()
    }
  }

  @Output() onFormReset: EventEmitter<any> = new EventEmitter<any>()
  @Output() onFormValueChanged: EventEmitter<any> = new EventEmitter<any>()
  @Output()
    onParameterSelectionOptions: EventEmitter<ParameterSelectionOptionsInterface> =
      new EventEmitter<any>()

  constructor (
    private readonly generalAPIs: GeneralAPIs,
    public readonly router: Router
  ) {
    // Exclude the Index Parameters from the size distro execution job creator workflow
    this.hideIndexParameters = [
      '/size-distro-execution/job-creation',
      '/size-distro-execution/job-management/copy-job',
      '/size-distro-execution/job-management/view-edit-job'
    ].includes(this.router.url)
  }

  get _formData () {
    return this.formData.getValue()
  }

  ngOnInit () {
    this.setParameterSelectionOptions()
  }

  async setParameterSelectionOptions () {
    if (!this.isInitialDataLoaded) {
      let parameterSelectionOptions
      try {
        parameterSelectionOptions =
                    await this.generalAPIs.GetProductParameterSelectionOptions()
        this.onParameterSelectionOptions.emit(
          parameterSelectionOptions
        )
      } finally {
        const updatedForm = {
          ...this.refDefaultParamsFormSettings,
          attribute_family: {
            ...this.refDefaultParamsFormSettings.attribute_family,
            selectionOptions:
                            parameterSelectionOptions?.attribute_family || []
          },
          starting_node_level: {
            ...this.refDefaultParamsFormSettings
              .starting_node_level,
            selectionOptions:
                            parameterSelectionOptions?.starting_node_level ||
                            []
          },
          top_node_level: {
            ...this.refDefaultParamsFormSettings.top_node_level,
            selectionOptions:
                            parameterSelectionOptions?.top_node_level || []
          }
        }
        this.refDefaultParamsFormSettings = updatedForm
        this.isInitialDataLoaded = true
        this.formData.next(updatedForm)
        console.log(
          '---> Setting Default Parameters Form Details: ',
          this._formData
        )
      }
    }
  }

  updateFormValue (form) {
    const value = cloneDeep(form.value)
    console.log('--> Shared component updated form: ', form)

    if (form.status === 'VALID') {
      // console.log('form valid: ', form)
      // console.log('--> Shared component value: ', value)
      if (form.controlName === 'curve_smoothing') {
        this.isCurveSmoothingDisabled = !value
        const curveSmoothingParams = [
          'smoothing_strength',
          'blending_strength'
        ]

        curveSmoothingParams.forEach(param => {
          const ogParamVal = cloneDeep(
            this.ogCurveSmoothingParams[param]
          )
          const paramVal =
                        value && ogParamVal
                          ? ogParamVal
                          : value && !ogParamVal
                            ? 'Low'
                            : null

          this[param] = paramVal
          this.onFormValueChanged.emit({
            controlName: param,
            value: paramVal,
            status: 'VALID'
          })
        })

        this.onFormValueChanged.emit(form)
      } else {
        this.onFormValueChanged.emit(form)
      }
    } else if (form.status === 'DISABLED') {
      // console.log('field is disabled: ', form)
    } else {
      console.log('field status: ', form.status)
      console.error('field error: ', form)
      this.onFormValueChanged.emit(form)
    }
  }

  ngOnDestroy (): void {
    const resetForm = async () => {
      const reset = await ResetFormData(
        {
          form: this.formData,
          currentFormData: this._formData,
          originalFormData: DefaultParamsFormSettings
        },
        true
      )
      console.log('---> Destroying Default Parameter Inputs:', reset)
    }
    resetForm()
  }
}
