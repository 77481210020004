// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { ExecuteGetAPI, ExecutePostAPI } from './execute_api-calls'
import { type StoreIndexOutputInterface } from '../interfaces/data-expected-from-backend/store-index-interfaces'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class StoreIndexOutputAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Get a list of all stored indexes
  async GetStoreIndexOutputData (jobName: string) {
    // the api call path with the parameter string is needed
    const path = `/store_index/output/${jobName.replaceAll('/', '%2F')}`
    const messages = {
      success:
                '---> API Call Successful: Retrieved Store Index Output Data',
      error: 'Error retrieving store index output data.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response?.is_success) {
      return response.data
    } else {
      return []
    }
  }

  async SaveStoreIndexOutputData (data: StoreIndexOutputInterface) {
    const path = '/store_index/grid/save_changes'
    const messages = {
      success: 'Successfully saved store index output data.',
      error: 'Error saving store index output data.',
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }
}
