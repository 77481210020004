// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import {
  ExecuteDeleteAPI,
  ExecuteGetAPI,
  ExecutePostAPI
} from './execute_api-calls'
import {
  type BuyingStrategyParametersInterface,
  type UpdateBuyingParametersInterface
} from 'src/app/core/interfaces/data-expected-from-backend/buying-strategy-interface'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class BuyingStrategyAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  async GetBuyingStrategyParameters (data: BuyingStrategyParametersInterface) {
    // the api call path with the parameter string is needed
    const path = '/buying_parameters'
    const messages = {
      success:
                '---> API Call Successful: Retrieved Buying Strategy Parameters.',
      error: 'Error retrieving buying strategy parameters.'
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async OnGetAllChannels () {
    // the api call path with the parameter string is needed
    const path = '/order_management/channels'
    const messages = {
      success:
                '---> API Call Successful: Retrieved All Channels for Buying Strategy.',
      error: 'Error retrieving channels for buying strategy.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success && response.data.length > 0) {
      return response.data
    } else {
      return []
    }
  }

  async SaveBuyingParametersChanges (data: UpdateBuyingParametersInterface) {
    // the api call path with the parameter string is needed
    const path = '/buying_parameters/update'
    const messages = {
      success: `Successfully saved buying parameters for ${data.product_id}.`,
      error: `Error saving buying parameters for ${data.product_id}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async RevertBuyingParametersToInheritance (
    data: BuyingStrategyParametersInterface
  ) {
    // the api call path with the parameter string is needed
    const path = '/buying_parameters'
    const messages = {
      success: `Successfully reverted ${data.product_id}'s buying parameters to parent.`,
      error: `Error reverting ${data.product_id}'s buying parameters to parent.`,
      showSuccessToast: true
    }
    return await ExecuteDeleteAPI(path, this, messages, data)
  }

  async OverrideAllBuyingParameters (data: BuyingStrategyParametersInterface) {
    // console.log('Overriding Buying Parameters', data)
    // the api call path with the parameter string is needed
    const path = '/buying_parameter_children'
    const messages = {
      success: `Successfully overrode ${data.product_id}'s children buying parameters.`,
      error: `Error overriding ${data.product_id}'s children buying parameters.`,
      showSuccessToast: true
    }
    return await ExecuteDeleteAPI(path, this, messages, data)
  }
}
