// Angular Imports
// =========================================================
import { Component, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
// AG Grid Imports
// =========================================================
import { type ICellRendererAngularComp } from 'ag-grid-angular'
import { type ICellRendererParams } from 'ag-grid-community'
import { SaveGridState } from '../../02_global-settings/grid-functions-general'
import { IsKeyInObj } from 'src/app/utils/global_functions'

@Component({
  selector: 'suffix-cell-icon-renderer',
  templateUrl: './suffix-cell-icon-renderer.html',
  styleUrls: ['./suffix-cell-icon-renderer.scss']
})
export class SuffixCellIconRenderer implements ICellRendererAngularComp {
  params!: ICellRendererParams

  value: string
  iconName: string

  cellPopoverEvent: boolean = false // If a popover should be triggered or not
  displayAlert: boolean = false
  alertDisplayed: string = null
  alertKey: string = null
  alertLink: string = null
  staticIcon: boolean = false
  // To Use the customAlertCompRef there must be an existing
  customAlertCompRef: string = null
  displayStaticIcon: boolean = false

  @ViewChild('alertPopup') alertPopup
  @ViewChild('iconRow') iconRow

  constructor (public router: Router) {}

  agInit (params: ICellRendererParams): void {
    // console.log('Edit Cell Icon Renderer Params: ', params)
    this.params = params
    this.value = params.valueFormatted
      ? params.valueFormatted
      : params.value
    this.iconName = params['renderer_icon']
    const refData = params?.colDef?.refData
    const showAlert =
            refData?.showAlert &&
            refData?.customAlertCompRef &&
            refData?.alertConditions
    // Handle Alert Icons
    if (showAlert) {
      this.showAlert(refData)
    }
    // Handle static icons
    if (params['static_icon']) {
      this.staticIcon = true
      this.handleStaticIcon()
    }
  }

  showAlert (refData) {
    const formattedVal = this.value.toString().toLowerCase()
    this.customAlertCompRef =
            this.params?.colDef?.refData?.customAlertCompRef
    this.cellPopoverEvent =
            refData?.alertConditions?.length === 0 ||
            refData?.alertConditions.includes(formattedVal)
    if (this.cellPopoverEvent) {
      this.alertKey = formattedVal
      this.alertLink = refData?.alertLinks[formattedVal]?.link || null
      // console.log('---> Setting an alert for: ', this.value)
      // console.log('--- display alert for ---- ', this.alertLink)
    }
  }

  handleStaticIcon () {
    // console.log('---> handle static icon: ', this.params);
    if (
      this.params['action'] === 'object' &&
            this.params?.data[this.params['icon_key']]
    ) {
      this.displayStaticIcon =
                (this.params?.data[this.params['icon_key']] &&
                    this.params?.data[this.params['icon_key']][
                      this.params?.colDef.colId
                    ]) ||
                false
    }
  }

  onDisplayAlert (event, alert) {
    this.displayAlert = true
    // this.alertDisplayed =
  }

  onHideAlert (event, alert) {
    this.displayAlert = false
    // this.alertPopup.hide()
    // this.alertDisplayed =
  }

  async onAlertAction (event) {
    const alert =
            this.params?.colDef?.refData?.alertLinks[
              this.value.toString().toLowerCase()
            ]

    sessionStorage.setItem(
      this.alertLink,
      JSON.stringify({
        action: alert.action,
        data: this.params?.data[alert.key],
        key: alert.key
      })
    )

    if (
      IsKeyInObj(this.params.context, 'allowSaveView') &&
            this.params.context?.allowSaveView
    ) {
      try {
        await SaveGridState(
          this.params,
          this.params.context.gridName,
          false
        )
        this.params.api.showLoadingOverlay()
      } finally {
        this.router.navigateByUrl(this.alertLink)
      }
    } else {
      this.router.navigateByUrl(this.alertLink)
    }
  }

  refresh (params: ICellRendererParams) {
    this.params = params
    this.value = params.valueFormatted
      ? params.valueFormatted
      : params.value
    // console.log('---> UPDATE SUFFIX ICON');
    if (this.staticIcon) this.handleStaticIcon()
    return true
  }
}
