// lodash Imports
// =========================================================
import * as _ from 'lodash'

//  function to check if an id is a UUID
export function IsUuid (id: string) {
  const regId =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regId.test(id)
}

export function IsKeyInObj (value_1, value_2) {
  return typeof value_1 === 'object'
    ? Object.keys(value_1).includes(value_2)
    : false
}

// Function to format keys to prevent text case issues
export function FormatKey (key) {
  return typeof key === 'string' && isNaN(Number(key))
    ? key.replaceAll(' ', '_').toLowerCase()
    : key
}
// Function to format keys to prevent text case issues
export function ReverseFormatKey (key) {
  return typeof key === 'string'
    ? key.replaceAll('_', ' ').toLowerCase()
    : key
}

// Function to format and generate values from row Data
export function GetValue (data) {
  const checkObject = value =>
    value && typeof value === 'object'
      ? checkObject(value.value)
      : CheckIsNumber(value)
  return checkObject(data)
}
export const CheckIsNumber = total => {
  return isNaN(total) ||
        isNaN(total) ||
        !isFinite(total) ||
        total === Number('-0')
    ? 0
    : total
}

export const SortSizes = (sizeArray, arrayToSort, key?: string) => {
  const objKey = key || 'sizeNm'
  const sizeOrder = sizeArray.sort((a, b) => a.size_order - b.size_order)
  return arrayToSort.sort((a, b) => {
    return (
      sizeOrder.findIndex(size => size.product_size === a[objKey]) -
            sizeOrder.findIndex(size => size.product_size === b[objKey])
    )
  })
}

export const GetJSONCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return
      }
      seen.add(value)
    }
    return value
  }
}

export function StripTimestamp (timestamp: string): string {
  return new Date(timestamp).toLocaleDateString()
}
