<!-- #mainGrid -->
<div class="main-ag-grid-container flex-column">
    <mat-card class="h-100 row-selection">
        <!-- Toolbar -->
        <div class="grid-toolbar">
            <!-- Grid Header -->
            <div class="grid-title">
                <h3>Size Distro Summary Grid</h3>
            </div>
            <!-- Button Container -->
            <div
                class="grid-tool-panel-btn-container"
                id="size-distro-summary-menu-options"
            >
                <!-- Rows Selected User Feedback -->
                <caption *ngIf="selectedDistros.length > 0">
                    {{
                        selectedDistros.length
                    }}
                    Distro{{
                        selectedDistros.length === 1 ? '' : 's'
                    }}
                    Selected
                </caption>
                <!-- Drill In -->
                <span>
                    <button
                        id="drill-in"
                        mat-raised-button
                        color="accent"
                        class="primary-split-button"
                        (click)="onDrillIn($event)"
                        [disabled]="selectedDistros.length === 0"
                    >
                        Drill In
                    </button>
                    <!-- More Options - Trigger-->
                    <button
                        id="split-button"
                        mat-raised-button
                        color="accent"
                        #tooltip="matTooltip"
                        matTooltip="More Options"
                        matTooltipClass="below"
                        (click)="
                            onToggleMoreOptions(!showMoreOptions);
                            moreOptions.toggle($event)
                        "
                        [disabled]="isLoadingSavedView"
                    >
                        <mat-icon
                            aria-hidden="false"
                            aria-label="More Options"
                            class="m-0"
                            >menu_open</mat-icon
                        >
                    </button>
                </span>
                <!-- More Options - Item -->
                <p-overlayPanel
                    #moreOptions
                    id="sdm-more-options-panel"
                    [dismissable]="false"
                    (onShow)="onToggleMoreOptions(true)"
                    [ngStyle]="{ display: showMoreOptions ? 'block' : 'none' }"
                >
                    <ng-template pTemplate="content">
                        <!-- <mat-menu #moreOptions="matMenu"> -->
                        <p class="overlaypanel-title">Drill In Filters:</p>
                        <!-- Send Neg 1 -->
                        <app-form-fields
                            [formParams]="formData"
                            [controlName]="'send_neg_1'"
                            (onFormFieldUpdated)="updateFormValue($event)"
                            [dynamicValue]="drillInFilters.send_neg_1"
                        >
                        </app-form-fields>
                        <span class="flex-center-all">
                            <!-- Store List Selection -->
                            <app-form-fields
                                [formParams]="formData"
                                [controlName]="'store_list'"
                                (onFormFieldUpdated)="updateFormValue($event)"
                                [dynamicValue]="drillInFilters.store_list"
                            ></app-form-fields>
                            <!-- Open Drill in Filters Modal -->
                            <button
                                id="custom-store-list-settings"
                                #tooltip="matTooltip"
                                mat-icon-button
                                id="open-custom-store-list-btn"
                                matTooltip="Custom Store List Settings"
                                [matTooltipClass]="'below'"
                                color="accent"
                                (click)="
                                    onOpenDrillInFiltersModal($event);
                                    moreOptions.hide()
                                "
                            >
                                <span
                                    class="material-icons launch-icon align-icon-center"
                                >
                                    launch
                                </span>
                            </button>
                        </span>
                        <!-- Line to separate the menu -->
                        <mat-divider> </mat-divider>
                        <!--  Approve button -->
                        <button
                            class="menu-panel-accent-btn"
                            color="accent"
                            mat-menu-item
                            (click)="
                                $event.stopPropagation();
                                moreOptions.hide();
                                onApprove($event)
                            "
                            [disabled]="
                                trackedGridSelections.status.includes(
                                    'APPROVED'
                                ) || selectedDistros.length === 0
                            "
                        >
                            Approve Distro(s)
                        </button>
                        <!--  Unapprove button -->
                        <button
                            class="menu-panel-accent-btn"
                            mat-menu-item
                            color="accent"
                            (click)="
                                $event.stopPropagation();
                                moreOptions.hide();
                                onUnapprove($event)
                            "
                            [disabled]="selectedDistros.length === 0"
                        >
                            Unapprove Distro(s)
                        </button>
                        <!--  on revert To system distros button -->
                        <button
                            class="menu-panel-accent-btn"
                            mat-menu-item
                            color="accent"
                            (click)="
                                confirmPopupRevert.confirm(
                                    $event,
                                    'Revert Distros'
                                )
                            "
                            [disabled]="
                                trackedGridSelections.status.includes(
                                    'APPROVED'
                                ) || selectedDistros.length === 0
                            "
                        >
                            Revert To System Distro
                        </button>
                        <!-- Delete button -->
                        <button
                            class="menu-panel-warn-btn"
                            mat-menu-item
                            color="warn"
                            (click)="
                                confirmPopupDelete.confirm(
                                    $event,
                                    'Delete Distro'
                                )
                            "
                            [disabled]="selectedDistros.length === 0"
                        >
                            Delete Distro(s)
                        </button>
                        <!-- Line to separate the menu -->
                        <mat-divider> </mat-divider>
                        <!-- Copy Product -->
                        <button
                            class="menu-panel-accent-btn"
                            mat-menu-item
                            color="accent"
                            (click)="
                                $event.stopPropagation();
                                moreOptions.hide();
                                onOpenCopyProductModal($event)
                            "
                            [disabled]="selectedDistros.length !== 1"
                        >
                            Copy To Product
                        </button>
                        <!-- New Buying Season -->
                        <button
                            class="menu-panel-accent-btn"
                            mat-menu-item
                            color="accent"
                            (click)="
                                $event.stopPropagation();
                                moreOptions.hide();
                                onOpenNewBuyingSeasonModal($event)
                            "
                            [disabled]="selectedDistros.length === 0"
                        >
                            Copy To New Season
                        </button>
                        <mat-divider> </mat-divider>
                        <!-- Bulk Overrides Distros -->
                        <button
                            class="menu-panel-accent-btn"
                            mat-menu-item
                            color="accent"
                            (click)="
                                $event.stopPropagation();
                                moreOptions.hide();
                                onOpenBulkOverrideDistrosModal($event)
                            "
                            [disabled]="
                                trackedGridSelections.channel.length !== 1 ||
                                trackedGridSelections.size_range.length !== 1
                            "
                        >
                            Bulk Override Distros
                        </button>
                        <!--  Size Range Alterations -->
                        <button
                            class="menu-panel-accent-btn"
                            mat-menu-item
                            color="accent"
                            (click)="
                                $event.stopPropagation();
                                moreOptions.hide();
                                onOpenSizeRangeAlterationsModal($event)
                            "
                            [disabled]="
                                trackedGridSelections.size_range.length !== 1
                            "
                        >
                            Size Range Alterations
                        </button>
                        <mat-divider> </mat-divider>
                        <span id="overlaypanel-footer">
                            <button
                                class="menu-panel-accent-btn"
                                mat-button
                                color="warn"
                                (click)="moreOptions.hide()"
                            >
                                Close
                            </button>
                        </span>
                    </ng-template>
                </p-overlayPanel>
            </div>
        </div>

        <!-- Confirm Popups -->
        <!-- Confirm popup for Revert To System Distros  -->
        <app-confirm-popup
            #confirmPopupRevert
            (confirmModal)="
                onRevertToTheSystemDistros($event); moreOptions.hide()
            "
        ></app-confirm-popup>
        <!-- Confirm popup for deletion a single row  -->
        <app-confirm-popup
            #confirmPopupDelete
            (confirmModal)="onDeleteDistro($event); moreOptions.hide()"
        ></app-confirm-popup>
        <!-- AG Grid -->
        <ag-grid-angular
            id="size-distro-management-summary"
            class="ag-theme-alpine ag-grid-container"
            [gridOptions]="gridOptions"
            [enableRangeSelection]="true"
            [attr.tool-panel]="true"
        ></ag-grid-angular>
    </mat-card>
</div>
