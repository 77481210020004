export const CartonizationFormSettings = {
  allow_cartons_in_flows: {
    controlName: 'allow_cartons_in_flows',
    label: 'Allow Cartons in Flows',
    formType: 'checkbox',
    datatype: 'boolean',
    selectMultiple: null,
    selectionOptions: null,
    // displayKey: ;
    // valueKey: ;
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: false,
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  allow_holes_in_cartons: {
    controlName: 'allow_holes_in_cartons',
    label: 'Allow Holes in Cartons',
    formType: 'checkbox',
    datatype: 'boolean',
    selectMultiple: null,
    selectionOptions: null,
    // displayKey: ;
    // valueKey: ;
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: false,
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  apply_mins_to_flows: {
    controlName: 'apply_mins_to_flows',
    label: 'Apply Min To Flows',
    formType: 'checkbox',
    datatype: 'boolean',
    selectMultiple: null,
    selectionOptions: null,
    // displayKey: ;
    // valueKey: ;
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: false,
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  apply_maxes_to_flows: {
    controlName: 'apply_maxes_to_flows',
    label: 'Apply Max To Flows',
    formType: 'checkbox',
    datatype: 'boolean',
    selectMultiple: null,
    selectionOptions: null,
    // displayKey: ;
    // valueKey: ;
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: false,
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  allow_bulk_in_set: {
    controlName: 'allow_bulk_in_set',
    label: 'Allow Bulk in Set',
    formType: 'checkbox',
    datatype: 'boolean',
    selectMultiple: null,
    selectionOptions: null,
    // displayKey: ;
    // valueKey: ;
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: false,
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  bulk_multiple: {
    controlName: 'bulk_multiple',
    label: 'Bulk Multiple',
    formType: 'input',
    datatype: 'integer',
    selectMultiple: null,
    selectionOptions: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: 1,
    currentVal: 1,
    validatorOptions: {
      required: true,
      min: 1,
      checkIsInteger: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  carton_sizes: {
    controlName: 'carton_sizes',
    label: 'Carton Sizes',
    hint: 'Must be comma separated integers',
    formType: 'input',
    datatype: 'string',
    selectMultiple: null,
    selectionOptions: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  deviation_from_buy_qty: {
    controlName: 'deviation_from_buy_qty',
    label: 'Deviation from Buy Qty',
    formType: 'input',
    datatype: 'percentage',
    selectMultiple: null,
    selectionOptions: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: 0,
    currentVal: 0,
    validatorOptions: {
      required: true,
      min: 0,
      max: 100
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  every_size_in_every_pack: {
    controlName: 'every_size_in_every_pack',
    label: 'Every Size in Every Pack',
    formType: 'checkbox',
    datatype: 'boolean',
    selectMultiple: null,
    selectionOptions: null,
    // displayKey: ;
    // valueKey: ;
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: false,
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  min_units_per_styc_per_store: {
    controlName: 'min_units_per_styc_per_store',
    label: 'Min Units Per Styc Per Store',
    formType: 'input',
    datatype: 'integer',
    selectMultiple: null,
    selectionOptions: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: 0,
    currentVal: 0,
    validatorOptions: {
      required: true,
      min: 0,
      checkIsInteger: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  max_units_per_styc_per_store: {
    controlName: 'max_units_per_styc_per_store',
    label: 'Max Units Per Styc Per Store',
    formType: 'input',
    datatype: 'integer',
    selectMultiple: null,
    selectionOptions: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false,
      checkIsInteger: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  min_by_size: {
    controlName: 'min_by_size',
    label: 'Min By Size',
    formType: 'input',
    datatype: 'integer',
    selectMultiple: null,
    selectionOptions: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: 0,
    currentVal: 0,
    validatorOptions: {
      required: true,
      min: 0,
      checkIsInteger: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  min_order_qty_for_each_pack: {
    controlName: 'min_order_qty',
    label: 'Min Order Qty for Each Pack',
    formType: 'input',
    datatype: 'integer',
    selectMultiple: null,
    selectionOptions: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: 0,
    currentVal: 0,
    validatorOptions: {
      required: true,
      min: 0,
      checkIsInteger: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  number_of_cartons: {
    controlName: 'number_of_cartons',
    label: 'Number of Cartons',
    formType: 'input',
    datatype: 'integer',
    selectMultiple: null,
    selectionOptions: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: 1,
    currentVal: 1,
    validatorOptions: {
      required: true,
      min: 0,
      checkIsInteger: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  }
}
