export const CreateProxyProductFormSettings = {
  proxy_product_id: {
    controlName: 'proxy_product_id',
    label: 'Product ID',
    placeholder: null,
    hint: 'Must be unique',
    formType: 'input',
    datatype: 'string',
    selectMultiple: null,
    selectionOptions: null,
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: true,
      minLength: 3,
      checkForDuplicates: []
    },
    formDisplay: {
      formWidth: '100%'
    }
  },
  proxy_product_sizes: {
    controlName: 'proxy_product_sizes',
    label: 'Eligible Sizes',
    placeholder: null,
    hint: null,
    formType: 'auto-selection',
    datatype: 'array',
    selectMultiple: true,
    selectionOptions: [],
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '100%'
    }
  },
  attribute_family: {
    controlName: 'attribute_family',
    label: 'Attribute Family',
    placeholder: null,
    hint: null,
    formType: 'auto-selection',
    datatype: 'array',
    selectMultiple: false,
    selectionOptions: [],
    loading: true,
    disabled: true,
    autoComplete: true,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {}
  },
  attribute_value: {
    controlName: 'attribute_value',
    label: 'Attribute Value',
    placeholder: null,
    hint: null,
    formType: 'input',
    datatype: 'string',
    selectMultiple: null,
    selectionOptions: null,
    loading: false,
    disabled: true,
    autoComplete: true,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {}
  }
}
