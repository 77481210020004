// Angular Imports
// =========================================================
import {
  Component,
  type OnInit,
  ViewChild,
  type SimpleChanges,
  ChangeDetectorRef,
  type AfterViewInit,
  type OnDestroy,
  HostListener
} from '@angular/core'
import { type MatTabChangeEvent } from '@angular/material/tabs'
import { cloneDeep } from 'lodash'
import { Router } from '@angular/router'
// Prime NG Imports
// =========================================================
import { type TreeNode, type MenuItem } from 'primeng/api'
// Custom Imports
// =========================================================
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component'
import { SizeDistroDefaultParametersAPIs } from 'src/app/core/apis/size-distro-default-parameters_api-calls'
import {
  GetDefaultParametersDefaultInputs,
  type DefaultParametersInputsInterface,
  type TreeDataParamsInterface
} from 'src/app/core/interfaces/data-expected-from-backend/default-parameters-inputs-interface'
import { IsKeyInObj } from 'src/app/utils/global_functions'
import { MatMenuTrigger } from '@angular/material/menu'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
@Component({
  selector: 'app-default-parameters',
  templateUrl: './default-parameters.component.html',
  styleUrls: ['./default-parameters.component.scss']
})
export class DefaultParametersComponent
implements OnInit, AfterViewInit, OnDestroy {
  confirmAction: boolean = false
  // Params for Product Hierarchy Section
  treeDataParams: TreeDataParamsInterface = {
    queryString: '',
    selectedProd: 'all'
  }

  loadingProdHier: boolean = true
  selectedHierarchyNode: TreeNode = null
  defaultSelected: TreeNode[] = []

  activeTab: any = 0

  productDetails: DefaultParametersInputsInterface = null
  ogProductDetails: DefaultParametersInputsInterface = null // monitor changes
  changesMade: string[] = []
  clearGeneralProductDetails: boolean
  // Default Parameters Save Options
  items: MenuItem[]
  // TODO: add interface for filters applied
  hierFiltersApplied: any = {}

  @ViewChild('confirmPopup') confirmPopup: ConfirmPopupComponent
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger

  constructor (
    private readonly sizeDistroDefaultParametersAPIs: SizeDistroDefaultParametersAPIs,
    public readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    public triggerApiCallsService: TriggerApiCallsService
  ) {}

  ngOnInit (): void {
    console.log('---> Active route: ', this.router.url)
    // if (this.router.url.includes('/product-creation')) {
    //   this.activeTab = 1
    // } else {
    //   this.getAllProducts()
    // }
  }

  ngAfterViewInit (): void {
    if (this.router.url.includes('/product-creation')) {
      this.activeTab = 1
      this.cdr.detectChanges()
    } else {
      // this.getAllProducts()
      // this.cdr.detectChanges();
    }
  }

  ngAfterContentChecked () {
    this.cdr.detectChanges()
  }

  ngOnChanges (changes: SimpleChanges) {
    // console.log('changessssssssss: ', changes)
  }

  // Function to track and update tab changes
  tabChanged (tabChangeEvent: MatTabChangeEvent) {
    console.log('active tab: ', tabChangeEvent)
    this.activeTab = tabChangeEvent
    this.onParamFormReset(null)

    if (this.activeTab === 0) {
      this.router.navigateByUrl(
        '/size-distro-strategy/default-parameters'
      )
    } else if (this.activeTab === 1) {
      this.router.navigateByUrl('/size-distro-strategy/product-creation')
    }
  }

  getProductDetailsByID (prodId: string) {
    this.sizeDistroDefaultParametersAPIs
      .GetProductDetailsByID(prodId)
      .then(res => {
        this.changesMade = []
        this.ogProductDetails = cloneDeep({ ...res.data })
        this.productDetails = {
          ...cloneDeep({ ...res.data }),
          editable: this.selectedHierarchyNode.data.editable
        }
        this.loadingProdHier = false
      })
  }

  emitConfirmation (event) {
    // Close the menu panel
    this.trigger.closeMenu()

    if (event.userInput === 'except') {
      console.log(`---> Execute ${event.command}`)

      const dataToSend = { ...this.productDetails }
      const onAPICallSuccessful = () => {
        this.changesMade = []
        this.clearGeneralProductDetails = true
        this.treeDataParams = {
          queryString: '',
          selectedProd: dataToSend.product_id
        }
      }

      if (IsKeyInObj(dataToSend, 'editable')) delete dataToSend.editable

      switch (event.command) {
        case 'Save':
          this.sizeDistroDefaultParametersAPIs
            .SaveParameterChanges(dataToSend)
            .then(res => {
              if (res?.is_success) onAPICallSuccessful()
            })
          break
        case 'Revert To Inheritance':
          this.sizeDistroDefaultParametersAPIs
            .RevertToInheritance(dataToSend)
            .then(res => {
              if (res?.is_success) {
                this.hierFiltersApplied = {
                  has_changes: false
                }
                onAPICallSuccessful()
              }
            })
          break
        case 'Override All':
          this.sizeDistroDefaultParametersAPIs
            .OverrideAllParameters(dataToSend)
            .then(res => {
              if (res?.is_success) {
                this.hierFiltersApplied = {
                  has_changes: false
                }
                onAPICallSuccessful()
              }
            })
          break
      }
    }
  }

  async onNodeSelected (event) {
    const prodId = event?.node?.key
    if (prodId !== undefined) {
      try {
        if (
          this.selectedHierarchyNode &&
                    this.selectedHierarchyNode?.key !== event.node.key
        ) {
          this.loadingProdHier = true
          this.changesMade = []
          this.productDetails =
                        await GetDefaultParametersDefaultInputs(false)
        }
      } finally {
        this.selectedHierarchyNode = event?.node
        this.getProductDetailsByID(prodId)
      }
    }
  }

  onFormValueChanged (form) {
    const field = form.controlName
    const knownChanges = this.changesMade.includes(field)
    // const curveSmoothingParams = ['smoothing_strength', 'blending_strength', 'curve_smoothing']
    const removeChange = () => {
      this.changesMade.splice(this.changesMade.indexOf(field), 1)
      console.log(
                `---> ${
                    this.changesMade.length > 0
                        ? 'Current Changes: ' + this.changesMade
                        : 'No Input Changes'
                } `
      )
    }

    if (this.productDetails) {
      console.log('Form value changed in default parameters: ', form)
      // console.log('---> Original Product Details: ', this.ogProductDetails)
      // console.log('---> Current Product Details: ', this.productDetails)
      // Update the data to send to the BE
      this.productDetails[field] = form.value
      // Monitor Changes
      if (
        (this.ogProductDetails &&
                    this.ogProductDetails[field] !== '' &&
                    this.ogProductDetails[field] != form.value) ||
                (this.ogProductDetails[field] === '' &&
                    form.value !== null &&
                    form.value !== '')
      ) {
        // console.log('changes made: ', field)
        if (!knownChanges) {
          this.changesMade.push(field)
          console.log(
                        `---> ${
                            this.changesMade.length > 0
                                ? 'Current Changes: ' + this.changesMade
                                : 'No Input Changes'
                        } `
          )
        }
      } else if (knownChanges) {
        removeChange()
      }
    }
  }

  // Track when the Default Parameters screen has finished updating
  onParamFormReset (event) {
    this.clearGeneralProductDetails = false
  }

  @HostListener('unloaded')
  ngOnDestroy () {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
  }
}
