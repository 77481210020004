[
    {
        "Proxy Product ID": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Parent Product ID": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Eligible Sizes ( | )": {
            "editable": false,
            "refData": {
                "datatype": "array",
                "joinForExcelExport": "|",
                "showToolTip": true,
                "tooltipField": "eligible_sizes_(_|_)"
            },
            "rowGroup": false,
            "hide": false
        },
        "Status": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false,
                "flexSize": 2
            },
            "wrapText": true,
            "autoHeight": true,
            "rowGroup": false,
            "hide": false
        },
        "Delete": {
            "editable": false,
            "pinned": "right",
            "refData": {
                "datatype": "action-button|delete",
                "displayBlankHeader": true,
                "_confirm_action": false,
                "colSize": 90
            },
            "rowGroup": false,
            "hide": false,
            "sortable": false,
            "maxWidth": 90
        }
    }
]
