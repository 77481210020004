// Custom Imports
// =========================================================
import { IsKeyInObj } from 'src/app/utils/global_functions'
import { SuffixCellIconRenderer } from '../03_custom-components/suffix-cell-icon-renderer/suffix-cell-icon-renderer'
import { FormatJSONColData } from '../02_global-settings/01_transformJSONColData'
import { CellClassRules } from '../02_global-settings/cell-class-rules'

export interface SizeDistroManagementDetailGrid_GridDataParams {
  mainColDefs: any
  dynamicCols: string[]
}

export async function SizeDistroManagementDetailGrid_GenerateGridData (
  params: SizeDistroManagementDetailGrid_GridDataParams
) {
  const { mainColDefs, dynamicCols } = params
  console.log('---> dynamicCols: ', dynamicCols)

  const getResults = async () => {
    const dynamicColDefs = {}
    await dynamicCols.forEach(
      col =>
        (dynamicColDefs[col] = {
          editable: true,
          sortIndex: 1,
          refData: {
            datatype: 'percentage',
            showToolTip: false,
            isDynamicCol: true,
            isColLocked: false
          },
          rowGroup: false,
          hide: false,
          spanHeaderHeight: true
        })
    )
    const updatedColDefs = {
      ...mainColDefs,
      Sizes: {
        headerName: 'Sizes',
        marryChildren: true,
        children: [dynamicColDefs],
        refData: {
          datatype: 'string'
        }
      },
      Editable: {
        editable: false,
        sortIndex: 2,
        refData: {
          showToolTip: false,
          headerRendererIcon: 'edit',
          datatype: 'action-button|edit',
          displayMenu: true,
          toolTipMessage: 'Editable',
          isStaticIcon: true,
          hideBtnKey: 'is_editable',
          colSize: 80
        },
        rowGroup: false,
        hide: false,
        spanHeaderHeight: true
      }
    }
    const mainCols = await FormatJSONColData(
      updatedColDefs,
      'main',
      SizeDistroManagementDetailGrid_customCellFormatting,
      true
    )

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    return data
  }
  return await getResults()
}

export const SizeDistroManagementDetailGrid_customCellFormatting = (
  col: any,
  gridToRender: string,
  dynamicCols: any[]
) => {
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      // console.log('--> Detail Grid Col Id', col.colId)
      switch (col?.colId) {
        case 'editable':
          return {
            valueGetter: params => {
              params.value = params?.data?.is_editable
                ? 'Editable'
                : 'Not Editable'
              return params.value
            }
          }
        default:
          if (col?.refData?.isDynamicCol) {
            return {
              useValueFormatterForExport: true,
              useValueParserForImport: true,
              editable: params => {
                if (
                  params?.data &&
                                    params.data?.is_editable &&
                                    params.data[col.colId] !== '--' &&
                                    (!IsKeyInObj(params.data, 'locked_sizes') ||
                                        !params?.data?.locked_sizes[col.colId])
                ) {
                  return true
                }
                return false
              },
              comparator: (valueA, valueB) => {
                const valA = valueA === '--' ? -1 : valueA
                const valB = valueB === '--' ? -1 : valueB

                return valA - valB
              },
              valueSetter: params => {
                if (
                  Number(params.data[col.colId]).toFixed(
                    2
                  ) !== Number(params?.newValue).toFixed(2)
                ) {
                  params.data[col.colId] = params?.newValue
                  return true
                } else {
                  return false
                }
              },
              // enableCellChangeFlash: true,
              cellRendererFramework: SuffixCellIconRenderer,
              cellRendererParams: {
                renderer_icon: 'lock',
                icon_key: 'locked_sizes',
                action: 'object',
                static_icon: true
              }
            }
          } else {
            return {}
          }
      }
    }
  }
  const updatedCol = {
    ...col,
    ...getParams(),
    cellClassRules: {
      ...CellClassRules,
      'cell-text-bold': params => {
        return params?.context['updatedRows'][
          params?.data?.row_id
        ]?.includes(params?.colDef?.colId)
      }
    }
  }
  return updatedCol
}
