// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Axios Imports
// =========================================================
import axios from 'axios'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { type ProxyProductCreationInterface } from '../interfaces/data-expected-from-backend/proxy-product-creation-interface'
import { ExecutePostAPI, ExecuteDeleteAPI } from './execute_api-calls'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class ProxyProductCreationAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  async DeleteProxyProduct (productID: string) {
    // the api call path with the parameter string is needed
    const path = `/proxy/delete/${productID.replaceAll('/', '%2F')}`
    const messages = {
      success: `Successfully deleted proxy product: ${productID}.`,
      error: `Error deleting proxy product: ${productID}.`,
      showSuccessToast: true
    }
    return await ExecuteDeleteAPI(path, this, messages)
  }

  async CreateProxyProduct (data: ProxyProductCreationInterface) {
    // the api call path with the parameter string is needed
    const path = '/products'
    const messages = {
      success: `Successfully created proxy product: ${data.proxy_product_id}.`,
      error: `Error creating proxy product: ${data.proxy_product_id}.`,
      showSuccessToast: true
    }
    const proxyProductsData = await ExecutePostAPI(
      path,
      this,
      { proxy_product_data: [data] },
      messages
    )
    console.log('proxyProductsData: ', proxyProductsData)
    if (proxyProductsData?.is_success) {
      if (proxyProductsData.data) {
        return await axios
          .get(proxyProductsData.data, {})
          .then(response => {
            console.log('response: ', response)
            return response
          })
          .catch(error => {
            console.error(
              'Error retrieving proxy product data: ',
              error
            )
            this.toastAlert.showError('Error Loading Data')
            throw error
          })
      } else {
        return {
          status: 200,
          data: []
        }
      }
    } else {
      return {
        status: null,
        data: []
      }
    }
  }
}
