// Angular Imports
// =========================================================
import {
  Component,
  HostListener,
  type OnDestroy,
  type OnInit
} from '@angular/core'
import { Subject } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'
// Prime NG Imports
// =========================================================
import { CustomGridOptions } from '../../../05_ag-grid-configs/02_global-settings/grid-options'
// JSON Data
// =========================================================
import $userManagementJSON from '../../../05_ag-grid-configs/01_json-grid-configs/colDefs-main-user-management.json'
// Ag Grid Imports
// =========================================================
import {
  type GridReadyEvent,
  type GridApi,
  type ColumnApi,
  type PostProcessPopupParams,
  type NewValueParams
} from 'ag-grid-community'
// Custom Imports
// =========================================================
import { UserManagement_GenerateGridData } from 'src/app/05_ag-grid-configs/04_generate-colDefs/user-management-colDefs'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { UserManagementAPIs } from 'src/app/core/apis/user-management_api-calls'
import { IsKeyInObj } from 'src/app/utils/global_functions'

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>()

  // Ag Grid Configuration
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: 'User Management',
      updateUsersPermissions: async params =>
        await this.onUpdateUsersPermissions(params),
      providers: [UserManagementAPIs]
    }
  }

  public userManagement = $userManagementJSON

  // Ag Grid Configuration
  gridApi: GridApi
  columnApi: ColumnApi
  gridOptions: any = {}
  isAgGridLoading: boolean = true
  // Grid Data
  columnDefs: any[] = []
  // selectedItems: any[] = [];
  multiSelectOptions: any = { products: [] }

  // Popup repositioning after it rendered
  public postProcessPopup: (params: PostProcessPopupParams) => void = (
    params: PostProcessPopupParams
  ) => {
    // Make the position change only for popupCellEditor
    if (params.type !== 'popupCellEditor') {
      return
    }
    const ePopup = params.ePopup
    let oldTopStr = ePopup.style.top
    const windowHeight = window.innerHeight
    // Check the popup position in comparison to the window height
    if (windowHeight - 400 < parseInt(oldTopStr)) {
      oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf('px'))
      const oldTop = parseInt(oldTopStr)
      const newTop = oldTop + -165
      ePopup.style.top = newTop + 'px'
    }
  }

  constructor (
    private readonly userManagementAPIs: UserManagementAPIs,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    this.gridOptions = {
      ...this.customGridOptions,
      onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
      getRowId: node => uuidv4(),
      onCellValueChanged: (event: NewValueParams) => {
        console.log('cellValue Changed: ', event)
        if (
          event.oldValue !== event.newValue &&
                    event.colDef.colId !== 'edited'
        ) {
          event.node.setData({ ...event.data, edited: true })
          event.api.refreshCells({ force: true })
        }
      }
    }
  }

  async ngOnInit () {
    // Get Grid Data
    this.getAllUsers()
    this.multiSelectOptions.products = await this.getAllProducts()
  }

  onGridReady (event: GridReadyEvent) {
    this.gridApi = event.api
    this.columnApi = event.columnApi
    this.isAgGridLoading = false
  }

  // Render AG Grid Column Definitions
  async renderGrid (mainColDefs, rowData) {
    const params = {
      mainColDefs
    }
    const gridData = await UserManagement_GenerateGridData(params)
    if (gridData) {
      this.columnDefs = gridData.mainColDefs

      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          if (this.gridApi && !this.gridApi['destroyCalled']) {
            this.gridApi?.setColumnDefs(gridData.mainColDefs)
            this.gridApi.setRowData(rowData)
          }

          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  // Get All Users
  async getAllUsers () {
    await this.userManagementAPIs.GetAllUsers().then(res => {
      if (res && res.length > 0) {
        this.renderGrid(this.userManagement[0], res)
      }
    })
  }

  // Update Users Permissions
  async onUpdateUsersPermissions (event) {
    this.gridApi.showLoadingOverlay()
    const { data } = cloneDeep(event)
    if (IsKeyInObj(data, 'edited')) {
      delete data.edited
    }

    this.userManagementAPIs.UpdateUsersPermissions(data).then(res => {
      if (res?.is_success) {
        // console.log('Successfully Saved Users')
        // this.getAllUsers()
        event.node.setData({ ...event.data, edited: false })
        this.gridApi.redrawRows()
        this.gridApi.hideOverlay()
      } else {
        this.gridApi.hideOverlay()
      }
    })
  }

  // Get All Products
  async getAllProducts () {
    return await this.userManagementAPIs.GetAllProducts().then(res => {
      if (res && res.length > 0) {
        return res.map(product => {
          return {
            products: product.product_id,
            product_nm: product.product_nm
          }
        })
      } else {
        return []
      }
    })
  }

  @HostListener('unloaded')
  ngOnDestroy () {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions()
      this.gridApi.expireValueCache()
      this.gridApi = null
    }
  }
}
