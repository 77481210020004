[
    {
        "Job Name": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false,
                "flexSize": 2,
                "minWidth": 200
            },
            "rowGroup": false,
            "hide": false
        },
        "Job Tag": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false,
                "optionalInput": true
            },
            "rowGroup": false,
            "hide": false
        },
        "Channel": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Product Node": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Size Range ( | )": {
            "editable": false,
            "refData": {
                "datatype": "array",
                "joinForExcelExport": "|",
                "showToolTip": true,
                "tooltipField": "size_range_(_|_)"
            },
            "rowGroup": false,
            "hide": false,
            "suppressAutoSize": true
        },
        "Prior Selling Season": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false,
                "optionalInput": true
            },
            "rowGroup": false,
            "hide": false
        },
        "Custom Season Start": {
            "editable": false,
            "refData": {
                "datatype": "dateString",
                "showToolTip": false,
                "optionalInput": true
            },
            "rowGroup": false,
            "hide": false
        },
        "Custom Season End": {
            "editable": false,
            "refData": {
                "datatype": "dateString",
                "showToolTip": false,
                "optionalInput": true
            },
            "rowGroup": false,
            "hide": false
        },
        "Buying Season": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Proxy Products": {
            "editable": false,
            "refData": {
                "datatype": "boolean",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Status": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "wrapText": true,
            "autoHeight": true,
            "rowGroup": false,
            "hide": false
        },
        "Delete": {
            "editable": false,
            "pinned": "right",
            "refData": {
                "datatype": "action-button|delete",
                "_confirm_action": false,
                "displayBlankHeader": true
            },
            "rowGroup": false,
            "hide": false
        }
    }
]
