[
    {
        "Store ID": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Index": {
            "editable": true,
            "refData": {
                "datatype": "percentage",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false,
            "sort": "desc"
        },
        "Source": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Lock": {
            "editable": false,
            "refData": {
                "datatype": "multi-checkbox-column",
                "custom_field": "is_locked",
                "showToolTip": false,
                "displayHeaderName": true,
                "colSize": 100
            },
            "rowGroup": false,
            "hide": false
        }
    }
]
