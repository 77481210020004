<app-modal-header
    [modalTitle]="modalTitle"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div
    class="modal-content"
    [attr.loading]="loading"
    [attr.flexDirection]="'row'"
    [attr.size]="'large'"
>
    <!-- Product Hierarchy Section -->
    <section id="prod-hier-section-container" *ngIf="!loading">
        <app-basic-headers
            [sectionName]="'Product Hierarchy Selection'"
            color="lte"
        ></app-basic-headers>
        <div class="content-wrapper">
            <app-product-hierarchy
                [treeDataParams]="treeDataParams"
                [selectionMode]="'single'"
                [selectedValues]="defaultSelected"
                [filtersToDisplay]="[]"
                [showEligibleProxySizes]="false"
                [allowNodeDeletion]="true"
                (onNodeSelected)="onNodeSelected($event)"
                [loading]="loadingProdHier"
                [disabled]="!userHasEditPerms && isModalConfigEdit"
            ></app-product-hierarchy>
        </div>
    </section>
    <!-- Job Scope Section -->
    <section
        *ngIf="!loading"
        id="job-scope-forms"
        class="primary-section-container"
    >
        <app-basic-headers
            [sectionName]="'Job Scope'"
            [color]="'lte'"
        ></app-basic-headers>

        <div class="section-body-container custom-vertical-scroll h-100">
            <!-- Job details container -->
            <div id="job-details-container">
                <!-- Job Name -->
                <span>
                    <p class="form-title">Job Name:</p>
                    <app-form-fields
                        [formParams]="formData"
                        [controlName]="'job_name'"
                        (onFormFieldUpdated)="onUpdateFormValue($event)"
                        [hideLabel]="true"
                        style="width: calc(100% - 138px)"
                    >
                    </app-form-fields>
                </span>
                <!-- Selected Product -->
                <span>
                    <p class="form-title">Selected Product:</p>
                    <p>
                        {{
                            selectedProduct?.label
                                ? selectedProduct.label
                                : 'Select a node from the product hierarchy'
                        }}
                    </p>
                </span>
            </div>
            <!-- Store Index Parameters -->
            <app-accordion-headers
                [panelOpenState]="true"
                sectionName="Store Index Parameters (Optional)"
            >
                <!-- Volume Group Count -->
                <app-form-fields
                    [formParams]="formData"
                    [controlName]="'vg_count'"
                    (onFormFieldUpdated)="onUpdateFormValue($event)"
                    [ngStyle]="{
                        width: _formData.vg_count.formDisplay.formWidth
                    }"
                >
                </app-form-fields>
                <!-- Use Augmented Sales -->
                <app-form-fields
                    [formParams]="formData"
                    [controlName]="'use_aug_sales'"
                    (onFormFieldUpdated)="onUpdateFormValue($event)"
                    [ngStyle]="{
                        width: _formData.use_aug_sales.formDisplay.formWidth
                    }"
                >
                </app-form-fields>
            </app-accordion-headers>
            <!-- Section: Seasons -->
            <app-accordion-headers
                [panelOpenState]="true"
                [sectionName]="
                    !formErrors?.job_name &&
                    !formErrors?.product_node &&
                    (formErrors?.buying_season ||
                        formErrors?.prior_or_custom_season)
                        ? 'Seasons - Please complete the required fields.'
                        : 'Seasons'
                "
                [color]="
                    !formErrors?.job_name &&
                    !formErrors?.product_node &&
                    (formErrors?.buying_season ||
                        formErrors?.prior_or_custom_season)
                        ? 'warn'
                        : 'lte'
                "
            >
                <!-- Season / Custom Season Selection Container -->
                <div class="season-selection-container">
                    <!-- Prior Selling Seasons -->
                    <app-form-fields
                        [formParams]="formData"
                        [controlName]="'prior_selling_seasons'"
                        [dynamicValue]="priorSellingSeasonsArray"
                        (onFormFieldUpdated)="onUpdateFormValue($event)"
                        id="seasons-control"
                    >
                    </app-form-fields>
                    <!-- Or -->
                    <h3>OR*</h3>
                    <!-- Custom Selling Season -->
                    <app-form-fields
                        [formParams]="formData"
                        [controlName]="'custom_season'"
                        [dynamicValue]="completedJobScope?.custom_season"
                        (onFormFieldUpdated)="onUpdateFormValue($event)"
                    >
                    </app-form-fields>
                </div>
                <!-- Buying Season -->
                <app-form-fields
                    [formParams]="formData"
                    [controlName]="'buying_season'"
                    (onFormFieldUpdated)="onUpdateFormValue($event)"
                    style="max-width: calc(50% - 25px); width: 100%"
                >
                </app-form-fields>
            </app-accordion-headers>
            <!-- Section: Weighting -->
            <app-accordion-headers
                [panelOpenState]="
                    completedJobScope?.prior_selling_seasons?.length > 1
                "
                [sectionName]="
                    completedJobScope?.prior_selling_seasons?.length > 0 &&
                    formErrors?.season_weighting
                        ? 'Season Weighting - The sum of the weights do not equal 100%.'
                        : 'Season Weighting'
                "
                [color]="
                    formErrors?.season_weighting
                        ? 'warn'
                        : completedJobScope?.prior_selling_seasons?.length > 1
                        ? 'lte'
                        : 'disabled'
                "
                [disabled]="
                    !completedJobScope ||
                    (completedJobScope &&
                        completedJobScope?.prior_selling_seasons?.length <= 1)
                "
            >
                <span
                    *ngFor="
                        let key of completedJobScope?.prior_selling_seasons;
                        let i = index
                    "
                    id="season-weight-inputs-wrapper"
                >
                    <app-form-fields
                        [formParams]="formData"
                        [controlName]="'season'"
                        [dynamicValue]="key.season"
                        (onFormFieldUpdated)="onUpdateFormValue($event, i)"
                        style="width: calc(48% - 20px)"
                    >
                    </app-form-fields>
                    <app-form-fields
                        [formParams]="formData"
                        [controlName]="'weight'"
                        [dynamicValue]="key.weight"
                        (onFormFieldUpdated)="onUpdateFormValue($event, i)"
                        style="width: calc(48% - 20px)"
                    >
                    </app-form-fields>
                    <!-- Delete Attribute Pair -->
                    <button
                        mat-icon-button
                        (click)="onDeleteSeasonWeighting($event, i)"
                        color="warn"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </span>
            </app-accordion-headers>
        </div>
    </section>
    <!-- Loading Component -->
    <app-loader *ngIf="loading" [loadingMessage]="loadingMessage"></app-loader>
</div>
<!-- Modal Footer -->
<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="true"
    submitBtnColor="primary"
    confirmBtnText="Run Job"
    (update)="runFinalValidation($event, 'Run')"
    [disabled]="
        loading || !isFormComplete || (!userHasEditPerms && isModalConfigEdit)
    "
    [closeDisabled]="loadingMessage === 'submitting'"
>
    <!-- Run Job -->
    <button
        mat-raised-button
        color="accent"
        type="button"
        class="submit-btn"
        (click)="
            runFinalValidation($event, isModalConfigEdit ? 'Save' : 'Create')
        "
        [disabled]="
            loading ||
            !isFormComplete ||
            (!userHasEditPerms && isModalConfigEdit)
        "
    >
        {{ isModalConfigEdit ? 'Save' : 'Create' }} Job
    </button>
</app-modal-footer>
