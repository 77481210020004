<!-- Left Side -->
<section class="prod-hierarchy-section-wrapper">
    <app-basic-headers
        [sectionName]="'Product Hierarchy Selection'"
    ></app-basic-headers>

    <app-product-hierarchy
        [selectionMode]="'single'"
        [treeDataParams]="treeDataParams"
        [selectedValues]="defaultSelected"
        [filtersToDisplay]="['proxy']"
        [showEligibleProxySizes]="false"
        [allowNodeDeletion]="true"
        [nodeToDelete]="'proxy_product'"
        (onNodeSelected)="onNodeSelected($event)"
        (onNodeDeleted)="resetFormData(false)"
        [loading]="loadingProdHier"
        style="padding: 0px 10px"
        (deletedSelection)="onDeletedSelection($event)"
    ></app-product-hierarchy>
</section>
<!-- Right Side -->
<section class="selected-nodes-wrapper">
    <app-basic-headers
        [textEllipsis]="true"
        [sectionName]="
            'Selected Node: ' +
            (selectedHierarchyNode?.label
                ? selectedHierarchyNode?.label
                : 'No Node Selected')
        "
    ></app-basic-headers>
    <!-- Button Container -->
    <div class="button-tool-panel">
        <!-- Create Proxy Product -->
        <button
            mat-raised-button
            color="accent"
            (click)="onCreateProxyProduct($event)"
            [disabled]="formDataErrors.length > 0"
        >
            Create Proxy Product
        </button>
    </div>
    <!-- Inputs Container -->
    <div class="inputs-container">
        <!-- Required Inputs Section -->
        <app-accordion-headers
            [panelOpenState]="true"
            sectionName="Required Inputs"
        >
            <!-- Product ID -->
            <app-form-fields
                [formParams]="formData"
                [controlName]="'proxy_product_id'"
                (onFormFieldUpdated)="updateFormValue($event)"
                [dynamicValue]="newProxyProduct.proxy_product_id"
                [ngStyle]="{
                    width: _formData.proxy_product_id.formDisplay.formWidth
                }"
                (onBlur)="onBlur($event)"
            >
            </app-form-fields>
            <app-form-fields
                [formParams]="formData"
                [controlName]="'proxy_product_sizes'"
                (onFormFieldUpdated)="updateFormValue($event)"
                [dynamicValue]="newProxyProduct.proxy_product_sizes"
                [ngStyle]="{
                    width: _formData.proxy_product_sizes.formDisplay.formWidth
                }"
            >
            </app-form-fields>
        </app-accordion-headers>
        <!-- New Attributes  -->
        <app-accordion-headers [panelOpenState]="true" sectionName="Attributes">
            <span
                id="new-attributes-container"
                *ngFor="
                    let key of newProxyProduct.new_attributes;
                    let i = index
                "
            >
                <app-form-fields
                    [id]="'attribute_family' + i"
                    [formParams]="formData"
                    [controlName]="'attribute_family'"
                    (onFormFieldUpdated)="updateFormValue($event, i)"
                    style="width: calc(48% - 20px)"
                    [dynamicValue]="
                        newProxyProduct.new_attributes[i].attribute_family
                    "
                >
                </app-form-fields>
                <app-form-fields
                    [id]="'attribute_value' + i"
                    [formParams]="formData"
                    [controlName]="'attribute_value'"
                    (onFormFieldUpdated)="updateFormValue($event, i)"
                    [dynamicValue]="
                        newProxyProduct.new_attributes[i].attribute_value
                    "
                    style="width: calc(48% - 20px)"
                >
                </app-form-fields>
                <!-- Add New Attribute Pair -->
                <button
                    mat-icon-button
                    *ngIf="newProxyProduct.new_attributes.length === i + 1"
                    (click)="onHandleNewAttribute($event, 'add', i)"
                    color="accent"
                    [disabled]="
                        newProxyProduct.new_attributes[i].attribute_family ===
                            null ||
                        newProxyProduct.new_attributes[i].attribute_value ===
                            null
                    "
                >
                    <mat-icon
                        *ngIf="newProxyProduct.new_attributes.length === i + 1"
                        >add</mat-icon
                    >
                </button>
                <!-- Delete Attribute Pair -->
                <button
                    mat-icon-button
                    *ngIf="newProxyProduct.new_attributes.length !== i + 1"
                    (click)="onHandleNewAttribute($event, 'delete', i)"
                    color="warn"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
        </app-accordion-headers>

        <!-- Default Param Inputs -->
        <app-default-parameters-input-fields
            [productDetails]="generalProductDetails"
            [clearFormGroup]="clearGeneralProductDetails"
            (onParameterSelectionOptions)="onParameterSelectionOptions($event)"
            (onFormValueChanged)="onChangesToParams($event)"
            (onFormReset)="onParamFormReset($event)"
            panelOpenOnLoad="false"
        ></app-default-parameters-input-fields>
    </div>
</section>
