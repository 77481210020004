[
    {
        "Size": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "string",
                "showToolTip": false,
                "colSize": 80
            },
            "rowGroup": false,
            "hide": false,
            "sort": "asc"
        },
        "Alteration": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "PCT": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "rowGroup": false,
            "hide": false,
            "skipHeaderOnAutoSize": true,
            "suppressAutoSize": true
        },
        "Lock": {
            "editable": false,
            "pinned": "right",
            "refData": {
                "datatype": "multi-checkbox-column",
                "custom_field": "is_locked",
                "displayHeaderName": true,
                "colSize": 80
            },
            "rowGroup": false,
            "maxWidth": 200,
            "hide": false,
            "skipHeaderOnAutoSize": true,
            "suppressAutoSize": true
        },
        "Delete": {
            "editable": false,
            "pinned": "right",
            "refData": {
                "datatype": "action-button|delete",
                "displayBlankHeader": true,
                "colSize": 80
            },
            "rowGroup": false,
            "hide": false,
            "sortable": false,
            "skipHeaderOnAutoSize": true,
            "suppressAutoSize": true,
            "maxWidth": 80
        }
    }
]
