// Angular Imports
// =========================================================
import {
  Component,
  type OnInit,
  Input,
  Output,
  EventEmitter,
  type AfterViewInit
} from '@angular/core'

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit, AfterViewInit {
  @Output('closeModal') close: EventEmitter<any> = new EventEmitter<any>()

  @Input('modalTitle') updatedTitle: string
  @Input('subTitle') updatedSubTitle: string
  @Input('showCloseBtn') showCloseBtn: boolean
  @Input() disabled: boolean = false

  hover: boolean = false
  // constructor () {}

  ngOnInit () {}

  ngAfterViewInit (): void {}

  mouseEnter () {
    this.hover = true
    console.log('hover')
  }

  closeModal () {
    this.close.emit()
  }
}
