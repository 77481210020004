<!-- Custom Text Btn -->
<span
    tabIndex="1"
    *ngIf="!groupRow && !isStaticIcon"
    class="flex-center-all h-100"
>
    <!-- Text Btn -->
    <button
        *ngIf="!showIcon && btnText !== 'View/Edit' && btnText !== 'View'"
        [id]="buttonID"
        mat-raised-button
        [ngClass]="{'submit-btn': true, 'material-icons': labelIsIcon, 'custom-grid-cell-btn': true}"
        (click)="EmitBtn($event)"
        matTooltip="{{ btnText }}"
        matTooltipClass="custom-btn-tooltip"
        color="accent"
    >
        {{btnText}}
    </button>
    <button
        *ngIf="showIcon && !hideBtn"
        [id]="buttonID"
        #tooltip="matTooltip"
        mat-icon-button
        [ngClass]="{'icon-button': !btnDisabled, 'icon-btn-handler': true}"
        style="font-size: 18px"
        [disabled]="btnDisabled"
        matTooltip="{{ toolTipMessage }}"
        [matTooltipClass]="toolTipPosition"
        (click)="EmitBtn($event)"
        color="accent"
        [attr.warn]="iconName"
        [attr.disabled]="btnDisabled"
    >
        <span [ngSwitch]="iconName" class="h-100">
            <!-- open-modal|settings  or open-screen|settings -->
            <span
                *ngSwitchCase="'settings'"
                class="material-icons launch-icon align-icon-center"
            >
                launch
            </span>
            <!-- open-modal|settings -->
            <span
                *ngSwitchCase="'copy'"
                class="material-icons file-copy-icon align-icon-center"
            >
                file_copy
            </span>
            <!-- action-button|add -->
            <span
                *ngSwitchCase="'add'"
                class="material-icons library_add-icon align-icon-center"
            >
                library_add
            </span>
            <!-- action-button|delete -->
            <span *ngSwitchCase="'cancel'">
                <app-confirm-popup
                    #confirmPopup
                    *ngIf="confirmAction"
                    (confirmModal)="emitConfirmation($event)"
                ></app-confirm-popup>
                <span class="material-icons close-icon align-icon-center">
                    close
                </span>
            </span>
            <!-- action-button|delete -->
            <span *ngSwitchCase="'delete'">
                <app-confirm-popup
                    #confirmPopup
                    *ngIf="confirmAction"
                    (confirmModal)="emitConfirmation($event)"
                ></app-confirm-popup>
                <span class="material-icons delete-icon align-icon-center">
                    delete
                </span>
            </span>
            <!-- action-button|execute -->
            <span
                *ngSwitchCase="'execute'"
                class="material-icons sync-icon align-icon-center"
            >
                sync
            </span>
            <!-- action-button|charts -->
            <span
                *ngSwitchCase="'chart'"
                class="material-icons chart-icon align-icon-center"
            >
                insights
            </span>
            <!-- action-button|save -->
            <span
                *ngSwitchCase="'save'"
                class="material-icons save-icon align-icon-center"
            >
                save
            </span>
            <!-- Edit Icon -->
            <span
                *ngSwitchCase="'edit'"
                class="icon-button material-icons edit-icon align-icon-center"
            >
                edit
            </span>
            <!-- open-screen|output -->
            <span
                *ngSwitchCase="'output'"
                class="material-icons output-icon align-icon-center"
            >
                table_view
            </span>
        </span>
    </button>
</span>
<!-- Row Group Display -->
<span
    *ngIf="groupRow && !isStaticIcon"
    [ngClass]="{'flex-center-all': true, 'h-100': true}"
>
    <p>--</p>
</span>
<!-- Display Static Icon -->
<span
    *ngIf="!groupRow && isStaticIcon && showIcon && !hideBtn"
    [ngClass]="{'flex-center-all': true, 'h-100': true}"
>
    <span
        [ngSwitch]="iconName"
        class="h-100"
        #tooltip="matTooltip"
        matTooltip="{{ toolTipMessage }}"
        matTooltipClass="below"
    >
        <!-- Edit Icon -->
        <span
            *ngSwitchCase="'edit'"
            class="icon-button material-icons edit-icon align-icon-center"
        >
            edit
        </span>
    </span>
</span>
