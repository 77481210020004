export const SDS_DrillInFiltersFormSettings = {
  send_neg_1: {
    controlName: 'send_neg_1',
    label: 'Average Distro(-1)',
    formType: 'checkbox',
    datatype: 'boolean',
    selectMultiple: null,
    selectionOptions: null,
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: false,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: false,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  store_list: {
    controlName: 'store_list',
    label: 'Store List',
    // label: 'Select An Attribute Family',
    formType: 'auto-selection',
    datatype: 'array',
    selectMultiple: false,
    selectionOptions: [],
    displayKey: 'store_list',
    valueKey: 'id',
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '100%'
    }
  }
}

export const SDS_DrillInFiltersSelectedStoreListFormSettings = {
  store_list: {
    controlName: 'store_list',
    label: 'Store List',
    formType: 'input',
    datatype: 'string',
    selectMultiple: false,
    selectionOptions: [],
    displayKey: null,
    valueKey: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: true,
      checkForDuplicates: [],
      minLength: 3
    },
    formDisplay: {
      formWidth: '100%'
    }
  }
}
