<app-modal-header
    [modalTitle]="modalTitle"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div class="modal-content-small" [attr.loading]="loadingProductAttrs">
    <!-- If loading Product Attributes -->
    <app-loader
        *ngIf="loadingProductAttrs"
        [loadingMessage]="'loading'"
    ></app-loader>
    <!-- If product attributes are loaded -->
    <div *ngIf="!loadingProductAttrs" id="proxy-panel-content">
        <div id="eligible-sizes-contanier">
            <div class="proxy-detail-title">
                <h4>Eligible Sizes</h4>
            </div>
            <span
                ><p class="proxy-detail-text m-b-10">
                    {{ eligibleSizes }}
                </p></span
            >
        </div>
        <div>
            <div class="proxy-detail-title">
                <h4>Attributes</h4>
            </div>
            <div
                *ngIf="
                    productAttrData?.attributes.length > 0 &&
                    productAttrData?.attributes[0]?.attribute_family
                "
            >
                <span *ngFor="let attrs of productAttrData?.attributes">
                    <p class="proxy-detail-text">
                        {{
                            attrs.attribute_family +
                                ': ' +
                                attrs.attribute_value
                        }}
                    </p>
                </span>
            </div>
            <div
                *ngIf="
                    productAttrData?.attributes.length === 0 ||
                    !productAttrData?.attributes[0]?.attribute_family
                "
            >
                <p class="proxy-detail-text">No Attributes available.</p>
            </div>
        </div>
    </div>
</div>

<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="false"
></app-modal-footer>
