export const DefaultParamsFormSettings = {
  attribute_family: {
    controlName: 'attribute_family',
    label: 'Attribute Family',
    // label: 'Select An Attribute Family',
    formType: 'auto-selection',
    datatype: 'array',
    selectMultiple: false,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '100%'
    }
  },
  min_store_sales: {
    controlName: 'min_store_sales',
    label: 'Min Sales per Store',
    // label: 'Set Min Sales per Store',
    formType: 'input',
    datatype: 'integer',
    selectMultiple: null,
    selectionOptions: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false,
      min: 1,
      checkIsInteger: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  min_size_sales: {
    controlName: 'min_size_sales',
    label: 'Min Sales per Size',
    // label: 'Set Min Sales per Size',
    formType: 'input',
    datatype: 'integer',
    selectMultiple: null,
    selectionOptions: null,
    loading: true,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false,
      min: 0,
      checkIsInteger: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  starting_node_level: {
    controlName: 'starting_node_level',
    label: 'Size Distro Lowest Level',
    formType: 'selection',
    datatype: 'array',
    selectMultiple: false,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  top_node_level: {
    controlName: 'top_node_level',
    label: 'Size Distro Highest Level',
    formType: 'selection',
    datatype: 'array',
    selectMultiple: false,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  curve_smoothing: {
    controlName: 'curve_smoothing',
    label: 'Apply AI Curve Smoothing',
    formType: 'checkbox',
    datatype: 'boolean',
    selectMultiple: null,
    selectionOptions: null,
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: false,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '100%'
    }
  },
  vg_count: {
    controlName: 'vg_count',
    label: 'Number of Volume Groups',
    formType: 'input',
    datatype: 'integer',
    selectMultiple: null,
    selectionOptions: null,
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null,
    currentVal: null,
    validatorOptions: {
      required: true,
      min: 0,
      checkIsInteger: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  use_aug_sales: {
    controlName: 'use_aug_sales',
    label: 'Use Augmented Sales',
    formType: 'checkbox',
    datatype: 'boolean',
    selectMultiple: null,
    selectionOptions: null,
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: false,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '50%'
    }
  },
  smoothing_strength: {
    controlName: 'smoothing_strength',
    label: 'Smoothing Strength',
    formType: 'auto-selection',
    datatype: 'array',
    selectMultiple: false,
    selectionOptions: ['Off', 'Low', 'Medium', 'High'],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null, // the default value is 'low' if curve smoothing is selected
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '100%'
    }
  },
  blending_strength: {
    controlName: 'blending_strength',
    label: 'Blending Strength',
    formType: 'auto-selection',
    datatype: 'array',
    selectMultiple: false,
    selectionOptions: ['Off', 'Low', 'Medium', 'High'],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: null, // the default value is 'low' if curve smoothing is selected
    currentVal: null,
    validatorOptions: {
      required: false
    },
    formDisplay: {
      formWidth: '100%'
    }
  }
}
