// Angular and RJX Imports
// =========================================================
import { Component } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { cloneDeep } from 'lodash'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { JobCreationAPIs } from 'src/app/core/apis/job-creation_api-calls'
import { SizeDistroManagementSummaryAPIs } from 'src/app/core/apis/size-distro-management-summary_api-calls'
import { DistroCopyToNewBuyingSeasonFormSettings } from 'src/app/03_shared-components/forms/form-data/distro-copy-to-new-buying-season'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'

@Component({
  selector: 'app-distro-copy-buying-season-modal',
  templateUrl: './distro-copy-buying-season-modal.component.html',
  styleUrls: ['./distro-copy-buying-season-modal.component.scss']
})
export class DistroCopyBuyingSeasonModalComponent {
  loading: boolean = true
  loadingMessage: string = 'loading'
  // Import the original form settings and track updates
  formData = new BehaviorSubject<any>(
    DistroCopyToNewBuyingSeasonFormSettings
  )

  get _formData () {
    return this.formData.getValue()
  }

  ObjectKeys = Object.keys

  parentGridData: any
  selectedDistroIds: string[] = []
  distroIdsToCopy: any = {}
  // Data to submit
  completedDistroCopy: any = {
    distros_to_copy: [],
    new_buying_season: null
  }

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private readonly jobCreationAPIs: JobCreationAPIs,
    private readonly sizeDistroManagementSummaryAPIs: SizeDistroManagementSummaryAPIs,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    console.log('---> New Buying Season Modal: ', config.data)
    this.parentGridData = {
      ...this.config?.data?.componentParent,
      rowData: config?.data.rowData
    }
    this.selectedDistroIds =
            this.parentGridData?.rowData.map(data => {
              const id = data.distro_id
              this.distroIdsToCopy[id] = {
                checked: true,
                season: data.buying_season
              }
              return id
            }) || []
    this.completedDistroCopy.distros_to_copy = this.selectedDistroIds
  }

  ngOnInit () {
    this.setSelectionOptions()
  }

  async setSelectionOptions () {
    const tempForm = this._formData
    console.log('tempForm: ', tempForm)

    this.loading = true

    try {
      const allSelectionOptions =
                await this.jobCreationAPIs.GetAllJobSelectionOptions()
      console.log('allSelectionOptions: ', allSelectionOptions)
      if (allSelectionOptions) {
        const getFilterDates = (dateCat: string) =>
          allSelectionOptions[dateCat]
            .filter(season => {
              const currentDate = new Date()
              const endDate = new Date(season.end_date)
              if (dateCat === 'buying_seasons') {
                return endDate > currentDate
              }
            })
            .map(season => season.season)
        console.log('tempForm: ', getFilterDates('buying_seasons'))

        // Form Settings
        tempForm.new_buying_season.selectionOptions =
                    (await getFilterDates('buying_seasons')) || []
        tempForm.new_buying_season.disabled = false
        tempForm.new_buying_season.loading = false

        this.formData.next(tempForm)
      }
    } catch (error) {
      console.error('Error Loading Selection Options')
    } finally {
      this.loading = false
    }
  }

  updateFormValue (form, index?) {
    const field = form.controlName
    const value = cloneDeep(form.value)

    console.log('---> Updating New Buying Season Form:', form)
    this.completedDistroCopy[field] = value
  }

  onUpdateDistroIdSelection (event, selectedDistro) {
    console.log('---> Distro Selection Updated: ', selectedDistro)
    this.distroIdsToCopy[selectedDistro].checked =
            !this.distroIdsToCopy[selectedDistro].checked
    this.completedDistroCopy.distros_to_copy = Object.keys(
      this.distroIdsToCopy
    )
      .filter(distro => this.distroIdsToCopy[distro]['checked'])
      .map(distro => Number(distro))

    console.log(
      ' this.completedDistroCopy.distros_to_copy: ',
      this.completedDistroCopy.distros_to_copy
    )
  }

  // submit distros to copy
  async onSubmitCopyNewBuyingSeason (event) {
    this.loadingMessage = 'submitting'
    this.loading = true

    console.log(
      '---> Submit Copy New Buying Season: ',
      this.completedDistroCopy
    )

    await this.sizeDistroManagementSummaryAPIs
      .OnDistroCopyBuyingSeason(this.completedDistroCopy)
      .then(res => {
        if (res?.is_success) {
          console.log('copy was successful')
          this.loadingMessage = 'loading'
          this.close({
            message: 'success',
            data: this.completedDistroCopy
          })
        } else {
          this.loading = false
          this.loadingMessage = 'loading'
          this.close()
        }
      })
  }

  close (data?: any) {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.ref) this.ref.close(data || null)
  }
}
