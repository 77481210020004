<div class="dashboard-container">
    <h1 class="dashboard-header">Dashboard</h1>
    <section class="dashboard-wrapper">
        <div class="tile-container">
            <div
                *ngFor="let category of $ApplicationSections[0]"
                [attr.hide]="!category?.is_favorite"
            >
                <app-dashboard-tile
                    [category]="category"
                    *ngIf="category?.is_favorite"
                >
                </app-dashboard-tile>
            </div>
        </div>
    </section>
</div>
