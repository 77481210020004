[
    {
        "Job ID": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "number"
            },
            "hide": false,
            "checkboxSelection": true
        },
        "Distro ID": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "hide": false
        },
        "Subclass Id": {
            "editable": false,
            "refData": {
                "custom_field": "subclass_id",
                "datatype": "string",
                "showToolTip": true,
                "tooltipField": "subclass_id"
            },
            "hide": false
        },
        "Style-Color ID": {
            "editable": false,
            "refData": {
                "custom_field": "styc_id",
                "datatype": "array",
                "showToolTip": true,
                "tooltipField": "styc_id"
            },
            "filter": "agSetColumnFilter",
            "hide": false
        },
        "Style-Color Name": {
            "editable": false,
            "refData": {
                "custom_field": "styc_nm",
                "datatype": "array",
                "showToolTip": true,
                "tooltipField": "styc_nm"
            },
            "filter": "agSetColumnFilter",
            "hide": false
        },
        "Size Range": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "tooltipField": "size_range"
            },
            "hide": false
        },
        "Channel": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true
            },
            "hide": false
        },
        "Store Distro": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": true,
                "tooltipField": "store_distro"
            },
            "hide": false
        },
        "Delivery Date": {
            "editable": false,
            "refData": {
                "datatype": "date"
            },
            "hide": false
        },
        "Unit Qty": {
            "editable": false,
            "refData": {
                "custom_field": "quantity",
                "datatype": "number"
            },
            "hide": false
        },
        "Delivery Type": {
            "editable": false,
            "refData": {
                "datatype": "string"
            },
            "hide": false
        }
    },
    {
        "Channel": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "string"
            },
            "hide": true,
            "rowGroup": true
        },
        "Subclass Id": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "custom_field": "subclass_id",
                "datatype": "string",
                "showToolTip": true,
                "tooltipField": "subclass_id"
            },
            "hide": false
        },
        "Store ID": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "custom_field": "location_id",
                "datatype": "string"
            },
            "hide": false
        },
        "Store Index": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "datatype": "number"
            },
            "hide": false,
            "aggFunc": "sum"
        },
        "Target Qty": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "custom_field": "store_qty",
                "datatype": "number"
            },
            "hide": false,
            "aggFunc": "sum"
        },
        "Buy Qty": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "custom_field": "qty_bought",
                "datatype": "number"
            },
            "hide": false,
            "aggFunc": "sum"
        },
        "Mismatch": {
            "editable": false,
            "pinned": "left",
            "refData": {
                "custom_field": "ttl_mismatch",
                "datatype": "number"
            },
            "hide": false,
            "aggFunc": "sum"
        },
        "Target vs Buy Chart": {
            "editable": false,
            "refData": {
                "datatype": "open-modal|chart"
            },
            "hide": false
        }
    }
]
