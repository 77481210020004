<mat-card>
    <mat-tab-group
        #changeEvent
        style="height: 100%; width: 100%"
        color="accent"
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        [selectedIndex]="activeTab"
        (selectedIndexChange)="tabChanged($event)"
    >
        <mat-tab label="Default Parameters">
            <ng-template matTabContent>
                <!-- Left Side -->
                <div
                    *ngIf="router.url.includes('/default-parameters')"
                    style="
                        flex-basis: 55%;
                        display: flex;
                        flex-direction: column;
                    "
                >
                    <app-basic-headers
                        [sectionName]="'Product Hierarchy Selection'"
                    >
                    </app-basic-headers>
                    <app-product-hierarchy
                        [noNodeSelectedAllowed]="false"
                        [treeDataParams]="treeDataParams"
                        [selectionMode]="'single'"
                        [selectedValues]="defaultSelected"
                        [filtersToDisplay]="['proxy', 'new', 'changes']"
                        [showEligibleProxySizes]="true"
                        [allowNodeDeletion]="false"
                        (onNodeSelected)="onNodeSelected($event)"
                        [filtersApplied]="hierFiltersApplied"
                        [loading]="loadingProdHier"
                        style="flex: 2; padding: 0px 10px"
                    ></app-product-hierarchy>
                </div>
                <!-- Right Side -->
                <div
                    *ngIf="router.url.includes('/default-parameters')"
                    class="selected-nodes-wrapper"
                    style="flex-basis: 45%; overflow: hidden"
                >
                    <app-basic-headers
                        [textEllipsis]="true"
                        [sectionName]="
                            'Selected Node: ' +
                            (selectedHierarchyNode?.label
                                ? selectedHierarchyNode?.label
                                : 'No Node Selected')
                        "
                    ></app-basic-headers>
                    <!-- Button Tool Panel -->
                    <div class="button-tool-panel">
                        <!-- Revert To Inheritance -->
                        <app-confirm-popup
                            #confirmPopup
                            (confirmModal)="emitConfirmation($event)"
                        ></app-confirm-popup>
                        <!-- New Save -->
                        <button
                            mat-raised-button
                            color="accent"
                            class="primary-split-button"
                            (click)="
                                $event.stopPropagation();
                                confirmPopup.confirm($event, 'Save')
                            "
                            [disabled]="
                                loadingProdHier || changesMade.length === 0
                            "
                        >
                            Save
                        </button>
                        <!-- More Options - Trigger-->
                        <button
                            id="split-button"
                            mat-raised-button
                            color="accent"
                            [matMenuTriggerFor]="moreOptions"
                            [disabled]="
                                selectedHierarchyNode &&
                                !selectedHierarchyNode?.data?.editable
                            "
                            #tooltip="matTooltip"
                            matTooltip="More Options"
                            matTooltipClass="below"
                        >
                            <mat-icon
                                aria-hidden="false"
                                aria-label="More Options"
                                class="m-0"
                                >menu_open</mat-icon
                            >
                        </button>
                        <!-- More Options - Item -->
                        <mat-menu #moreOptions="matMenu">
                            <!-- Revert To Inheritance -->
                            <button
                                mat-menu-item
                                color="accent"
                                class="menu-panel-accent-btn"
                                [disabled]="
                                    !selectedHierarchyNode?.data?.parent ||
                                    selectedHierarchyNode?.data?.parent
                                        ?.length === 0
                                "
                                (click)="
                                    $event.stopPropagation();
                                    confirmPopup.confirm(
                                        $event,
                                        'Revert To Inheritance'
                                    )
                                "
                            >
                                Revert To Inheritance
                            </button>
                            <!-- Override All -->
                            <button
                                mat-menu-item
                                color="accent"
                                class="menu-panel-accent-btn"
                                [disabled]="
                                    !selectedHierarchyNode?.children ||
                                    selectedHierarchyNode?.children?.length ===
                                        0
                                "
                                (click)="
                                    $event.stopPropagation();
                                    confirmPopup.confirm($event, 'Override All')
                                "
                            >
                                Override All
                            </button>
                            <!-- Override Changes -->
                            <!-- TODO: V2 Update: Hardcoded Disabled for V1 -->
                            <!-- <button
                                mat-menu-item
                                color="accent"
                                [disabled]="true"
                                class="menu-panel-accent-btn"
                                (click)="
                                    $event.stopPropagation();
                                    confirmPopup.confirm(
                                        $event,
                                        'Override Changes'
                                    )
                                "
                            >
                                Override Changes
                            </button> -->
                        </mat-menu>
                    </div>
                    <div class="inputs-container">
                        <app-default-parameters-input-fields
                            [productDetails]="productDetails"
                            (onFormValueChanged)="onFormValueChanged($event)"
                            [clearFormGroup]="clearGeneralProductDetails"
                            (onFormReset)="onParamFormReset($event)"
                            panelOpenOnLoad="true"
                        ></app-default-parameters-input-fields>
                    </div>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Product Creation">
            <ng-template matTabContent>
                <app-product-creation
                    *ngIf="
                        activeTab === 1 &&
                        router.url.includes('/product-creation')
                    "
                    style="overflow: hidden; height: inherit"
                ></app-product-creation>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</mat-card>
