// Angular Imports
// =========================================================
import { Component, type OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss']
})
export class FormWrapperComponent implements OnInit {
  @Input() labelText: string
  @Input() formSize?: string
  @Input() subtext?: string
  @Input() condensed: boolean = false
  @Input() labelWidth: string
  @Input() formWidth: string

  // constructor() {}

  ngOnInit (): void {}
}
