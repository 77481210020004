<!-- General Size Distro Parameters -->
<div *ngIf="isInitialDataLoaded">
    <app-accordion-headers
        [panelOpenState]="panelOpenOnLoad"
        sectionName="General Size Distro Parameters"
    >
        <!-- Select An Attribute Family -->
        <app-form-fields
            class="attributeFamily"
            [formParams]="formData"
            [controlName]="'attribute_family'"
            (onFormFieldUpdated)="updateFormValue($event)"
            [ngStyle]="{
                width: _formData.attribute_family.formDisplay.formWidth
            }"
        >
        </app-form-fields>
        <!-- Set Min Store Sales -->
        <app-form-fields
            class="minStoreSales"
            [formParams]="formData"
            [controlName]="'min_store_sales'"
            (onFormFieldUpdated)="updateFormValue($event)"
            [ngStyle]="{
                width: _formData.min_store_sales.formDisplay.formWidth
            }"
        >
        </app-form-fields>
        <!-- Set Min Size Sales -->
        <app-form-fields
            class="minSizeSales"
            [formParams]="formData"
            [controlName]="'min_size_sales'"
            (onFormFieldUpdated)="updateFormValue($event)"
            [ngStyle]="{
                width: _formData.min_size_sales.formDisplay.formWidth
            }"
        >
        </app-form-fields>
        <!-- Select A Starting Node -->
        <app-form-fields
            class="startingNodeLevel"
            [formParams]="formData"
            [controlName]="'starting_node_level'"
            (onFormFieldUpdated)="updateFormValue($event)"
            [ngStyle]="{
                width: _formData.starting_node_level.formDisplay.formWidth
            }"
        >
        </app-form-fields>
        <!-- Select A  Top Node -->
        <app-form-fields
            class="topNodeLevel"
            [formParams]="formData"
            [controlName]="'top_node_level'"
            (onFormFieldUpdated)="updateFormValue($event)"
            [ngStyle]="{
                width: _formData.top_node_level.formDisplay.formWidth
            }"
        >
        </app-form-fields>
        <!-- Curve Smoothing -->
        <app-form-fields
            class="curveSmoothing"
            [formParams]="formData"
            [controlName]="'curve_smoothing'"
            (onFormFieldUpdated)="updateFormValue($event)"
            [ngStyle]="{
                width: _formData.curve_smoothing.formDisplay.formWidth
            }"
        >
        </app-form-fields>
    </app-accordion-headers>
    <!-- Curve Smoothing Parameters -->
    <app-accordion-headers
        [panelOpenState]="!isCurveSmoothingDisabled && panelOpenOnLoad"
        sectionName="Curve Smoothing Parameters"
        [disabled]="isCurveSmoothingDisabled"
    >
        <!-- Smoothing Strength -->
        <app-form-fields
            [formParams]="formData"
            [controlName]="'smoothing_strength'"
            (onFormFieldUpdated)="updateFormValue($event)"
            [dynamicValue]="smoothing_strength"
            [ngStyle]="{
                width: _formData.vg_count.formDisplay.formWidth
            }"
        >
        </app-form-fields>
        <!-- Blending Strength -->
        <app-form-fields
            [formParams]="formData"
            [controlName]="'blending_strength'"
            (onFormFieldUpdated)="updateFormValue($event)"
            [dynamicValue]="blending_strength"
            [ngStyle]="{
                width: _formData.vg_count.formDisplay.formWidth
            }"
        >
        </app-form-fields>
    </app-accordion-headers>
    <!-- Store Index Parameters -->
    <app-accordion-headers
        id="hide-index-parameters-wrapper"
        [attr.hideIndexParameters]="hideIndexParameters"
        [panelOpenState]="panelOpenOnLoad"
        sectionName="Store Index Parameters"
        [disabled]="hideIndexParameters"
    >
        <!-- Volume Group Count -->
        <app-form-fields
            class="vgCount"
            [formParams]="formData"
            [controlName]="'vg_count'"
            (onFormFieldUpdated)="updateFormValue($event)"
            [ngStyle]="{
                width: _formData.vg_count.formDisplay.formWidth
            }"
        >
        </app-form-fields>
        <!-- Use Augmented Sales -->
        <app-form-fields
            [formParams]="formData"
            [controlName]="'use_aug_sales'"
            (onFormFieldUpdated)="updateFormValue($event)"
            [ngStyle]="{
                width: _formData.use_aug_sales.formDisplay.formWidth
            }"
        >
        </app-form-fields>
    </app-accordion-headers>
</div>
<!-- If the parameter options are loading -->
<div
    *ngIf="!isInitialDataLoaded"
    class="flex-center-all loading-wrapper"
    [attr.hideIndexParameters]="hideIndexParameters"
>
    <app-loader loadingMessage="loading"></app-loader>
</div>
