<span class="multi-checkbox-container">
    <mat-checkbox
        class="multi-checkbox"
        name="checkbox"
        [(ngModel)]="isChecked"
        (click)="EmitBtn($event)"
        style="font-size: 20px"
        [disabled]="disabled"
    >
    </mat-checkbox>
</span>
