import { GenerateDefaultColDefs } from '../02_global-settings/global-cell-formatter'
import { OM_customCellFormatting } from '../05_cell-formatters/review-orders-cell-formatters'

export interface OM_GridDataParams {
  tabKey: string
  mainColDefs: any[]
  detailColDefs?: any[]
  mainDynamicColDefs?: any[]
  detailDynamicColDefs?: any[]
  mainDynamicColDefDefault?: any
  detailDynamicColDefDefault?: any
}
export async function OM_GenerateGridData (params: OM_GridDataParams) {
  console.log('om generategriddata', params)
  const {
    tabKey,
    mainColDefs,
    detailColDefs,
    mainDynamicColDefs,
    detailDynamicColDefs,
    mainDynamicColDefDefault,
    detailDynamicColDefDefault
  } = params

  const getResults = async tabKey => {
    const mainCols = await GenerateCols(mainColDefs, tabKey)
    const detailCols = detailColDefs
      ? await GenerateCols(detailColDefs, tabKey)
      : []
    const mainDynamicCols = mainDynamicColDefs
      ? await GenerateDynamicCols(
        mainDynamicColDefs,
        tabKey,
        mainDynamicColDefDefault
      )
      : []
    const detailDynamicCols = detailDynamicColDefs
      ? await GenerateDynamicCols(
        detailDynamicColDefs,
        tabKey,
        detailDynamicColDefDefault
      )
      : []

    const results = await Promise.all([
      mainCols,
      detailCols,
      mainDynamicCols,
      detailDynamicCols
    ])
    // const m = results[0]
    // const d = results.indexOf(detailCols)
    // const md = results.indexOf(mainDynamicColDefs)
    // const dd = results.indexOf(detailDynamicColDefs)

    console.log('results from col def generation', results)

    return {
      mainColDefs: results[0].concat(results[2]),
      detailColDefs: results[1].concat(results[3])
    }

    // if (tabKey === 'user_input') {
    //   return {
    //     mainColDefs: results[m],
    //     detailColDefs: results[d]
    //   }
    // } else if (tabKey === 'buy_summary') {
    //   return {
    //     mainColDefs: results[m].concat(results[d]),
    //     topGridColDefs: results[t]
    //   }
    // } else {
    //   return {
    //     mainColDefs: results[m].concat(results[d])
    //   }
    // }
  }
  return await getResults(tabKey)
}

export const GenerateDynamicCols = async (
  data: any[],
  gridToRender: string,
  dynamicColDefDefault = { refData: {} }
) => {
  const mappedCols = []
  console.log('generating dynamic cols for tab', gridToRender)
  console.log(data)
  // console.log("detailColDefData", detailColDefData)
  // console.log('data', data)
  data.map(element => {
    const obj = {
      ...GenerateDefaultColDefs(element, {
        ...dynamicColDefDefault,
        editable: false,
        refData: {
          ...dynamicColDefDefault.refData,
          datatype: 'number'
        },
        hide: false
      })
    }
    mappedCols.push(OM_customCellFormatting(obj, gridToRender, true))
  })
  console.log('mapped dynamic cols', mappedCols)
  return Object.values(mappedCols)
}

export const GenerateCols = async (data: any[], gridToRender: string) => {
  const mappedCols = []
  console.log('generating cols for tab', gridToRender)
  console.log(data)
  Object.entries(data).map(([colKey, colVal]) => {
    // console.log('colKey', colKey)
    // console.log('colVal', colVal)
    const obj = {
      ...GenerateDefaultColDefs(colKey, colVal),
      width: colVal.refData.colSize ? colVal.refData.colSize : 100,
      flex: colVal.refData.colSize ? 0 : 1
    }
    mappedCols.push(OM_customCellFormatting(obj, gridToRender, false))
  })
  // console.log("6", mappedCols)
  return Object.values(mappedCols)
}
