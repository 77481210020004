// Angular Imports
// =========================================================
import {
  Component,
  Input,
  type OnDestroy,
  type OnInit,
  Output,
  EventEmitter,
  ViewChild,
  HostListener
} from '@angular/core'
import { Subject } from 'rxjs'
// AG Grid Imports
// =========================================================
import type {
  GetDetailRowDataParams,
  GetRowIdParams,
  IDetailCellRendererParams,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  SelectionChangedEvent,
  GetContextMenuItemsParams,
  ICellRendererParams,
  DetailGridInfo,
  RowSelectedEvent,
  RowGroupOpenedEvent,
  RowClassParams,
  DisplayedColumnsChangedEvent,
  GridColumnsChangedEvent,
  ColumnMovedEvent,
  FilterChangedEvent,
  SortChangedEvent
} from 'ag-grid-community'
import 'ag-grid-enterprise'
// Angular Material Imports
// =========================================================
import { MatMenuTrigger } from '@angular/material/menu'
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { RO_SharedGridOptions, TreeDataGridOptions } from './ro-grid-options'
import {
  AutoSizeColumns,
  CustomGridOptions,
  DefaultGridContextMenu
} from 'src/app/05_ag-grid-configs/02_global-settings/grid-options'
import { FormatKey, ReverseFormatKey } from 'src/app/utils/global_functions'
import {
  GenerateCols,
  GenerateDynamicCols,
  OM_GenerateGridData
} from 'src/app/05_ag-grid-configs/04_generate-colDefs/review-orders-grid-config'
import {
  DefaultUserInputLinesGrid,
  UserInputLinesGridInterface
} from 'src/app/core/interfaces/review-orders-interface'
import { UserInputConfigSettingsComponent } from 'src/app/04_modals/review-orders/user-input-config/user-input-config.component'
import { PackSummaryConfigComponent } from 'src/app/04_modals/review-orders/pack-summary-config/pack-summary-config.component'
import { MismatchChartComponent } from 'src/app/04_modals/review-orders/mismatch-chart/mismatch-chart.component'
import { StoreTotalsChartsComponent } from 'src/app/04_modals/review-orders/store-totals-charts/store-totals-charts.component'
import { ReviewOrdersAPIs } from 'src/app/core/apis/review-orders_api-calls'
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { DefaultChartOptions } from 'src/app/utils/global-chart-settings'
import { Chart, ChartDataset } from 'chart.js'
import {
  SaveGridState,
  SaveGridStateWithData
} from 'src/app/05_ag-grid-configs/02_global-settings/grid-functions-general'

@Component({
  selector: 'app-ro-ag-grid',
  templateUrl: './ro-ag-grid.component.html',
  styleUrls: ['./ro-ag-grid.component.scss']
})
export class RoAgGridComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>()
  detailCellRendererParams: any

  // Ag Grid Configuration
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: 'Review orders - user input',
      allowRowGroupToggle: true,
      viewCharts: params => this.viewTotalsCharts(params),
      openMismatchChartModal: params =>
        this.openMismatchChartModal(params),
      openPackConfigModal: params => this.openPackConfigModal(params),
      openDeleteConfirmDialog: event => {
        console.log('delete confirm event', event)
        this.confirmPopupDelete.confirm(event, 'Delete Buy Group')
      }
    }
  }

  outputGridOptions: any = {
    ...CustomGridOptions,
    rowSelection: 'single',
    pagination: false,
    // getRowId: (params: GetRowIdParams) => {
    //   return params.data.detail_id
    // },
    getContextMenuItems: params =>
      params?.context?.gridName === 'user_input_detail'
        ? []
        : [
            {
              name: 'Save Grid View',
              action: async () => {
                await SaveGridState(
                  params,
                  params?.context?.gridName,
                  true
                )
              },
              cssClasses: ['text-accent']
            },
            'separator',
            ...DefaultGridContextMenu(params)
          ],
    context: {
      componentParent: this,
      allowRowGroupToggle: true,
      openTargetBuyChart: params => this.openTargetBuyChart(params),
      getPackConfigs: params => this.packConfigs
    }
  }

  pinnedTopRowData = []

  @Input() packConfigs = null

  outputChartData = null
  outputDetailChartData = null
  outputChartOptions = null
  outputDetailChartOptions = null

  // Basic Input Variables
  @Input('importTabData') importTabData: any
  @Input() selectableStycIds: string[] = []
  @Input() selectedStycId: string
  @Input() canViewData: boolean = true
  @Input() selectedRowIndex: number = 0
  // Dynamic Inputs with select functions -----------
  inputLinesSelectedRows: UserInputLinesGridInterface = {
    ...DefaultUserInputLinesGrid
  }

  @Input('inputLinesSelectedRows')
  set _inputLinesSelectedRows (data: UserInputLinesGridInterface) {
    this.inputLinesSelectedRows = data
    if (this.tabKey === 'user_input') {
      console.log('--------> User Input Data Update: ', data)
      console.log(
        '--------> Current Input Lines Data: ',
        this.inputLinesSelectedRows
      )
    }
  }

  // Track the current tab
  selectedIndex
  @Input('selectedIndex')
  set _selectedIndex (index) {
    console.log('---> Tab index changed: Grid Comp: ', index)

    if (this.tabKey !== 'user_input') {
      console.log(
        '--------> Input Lines Selected Rows: ',
        this.inputLinesSelectedRows
      )
      if (
        !this.initialRender &&
                index?.currentRowSelection?.ids &&
                index?.currentRowSelection?.ids !==
                    this.inputLinesSelectedRows?.ids
      ) {
        console.log('---> Clearing stored grid data: ', index)
        if (this.gridApi && !this.gridApi['destroyCalled']) {
          this.gridApi?.setColumnDefs([])
          this.gridApi.setRowData([])
          this.outputGridApi?.setRowData([])
          this.gridApi.flushAsyncTransactions()
          this.gridApi.expireValueCache()
          this.gridApi.showLoadingOverlay()
          this.selectedRowIndex = 0
        }
      }
      if (!this.initialRender) {
        console.log('Not initial render')
        this.checkAndSelectCorrectNode()
      }
    }
  }

  setGridState (gridName, gridOptionsParam) {
    // Apply the saved column state
    console.log('applying state for grid', gridName)
    console.log('this.storedGridSettings', this.storedGridSettings)
    if (
      Object.keys(this.storedGridSettings).length > 0 &&
            this.storedGridSettings[gridName]
    ) {
      const { colDefs, filters } = this.storedGridSettings[gridName]
      // Apply the saved column state
      gridOptionsParam?.columnApi?.applyColumnState({
        state: colDefs
      })
      // Apply the column order
      gridOptionsParam?.columnApi?.moveColumns(
        colDefs.map(col => col.colId),
        0
      )
      // Apply the saved filter state
      gridOptionsParam?.api?.setFilterModel(filters)
    }
  }

  // Update data for the output grids
  outputGridData
  @Input('outputGridData')
  set _outputGridData (updatedOutputGridData) {
    console.log('set output grid data')
    const { tabKey } = this.importTabData
    this.tabKey = tabKey
    if (this.tabKey !== 'user_input') {
      console.log('this.tabKey', this.tabKey)
      this.outputGridOptions.context.gridName = this.tabKey + '_detail'
      this.outputChartOptions = {
        ...DefaultChartOptions,
        plugins: {
          ...DefaultChartOptions.plugins,
          title: {
            display: true,
            position: 'top',
            text:
                            this.tabKey === 'carton_summary'
                              ? 'Total Carton Qty'
                              : 'Target vs Buy',
            font: {
              size: '16px'
            }
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: context => {
                console.log('get label context', context)
                switch (context.dataset.type) {
                  case 'line':
                    return (
                      ' ' +
                                            context.dataset.label +
                                            ': ' +
                                            (context.raw * 100).toFixed(2) +
                                            '%'
                    )
                  case 'bar':
                    return (
                      ' ' +
                                            context.dataset.label +
                                            ': ' +
                                            context.raw
                    )
                  default:
                    console.log('pie context', context)
                    return (
                      ' ' +
                                            context.raw +
                                            ' (' +
                                            (
                                              (100 * context.raw) /
                                                context.dataset.data.reduce(
                                                  (
                                                    accumulator: number,
                                                    item: number
                                                  ) => {
                                                    return (
                                                      accumulator + item
                                                    )
                                                  },
                                                  0
                                                )
                                            ).toFixed(2) +
                                            '%)'
                    )
                }
              }
            }
          }
        }
      }

      this.outputDetailChartOptions = {
        ...this.outputChartOptions,
        plugins: {
          ...this.outputChartOptions.plugins,
          tooltip: {
            enabled: true,
            callbacks: {
              label: context => {
                switch (context.dataset.type) {
                  case 'line':
                    return (
                      context.dataset.label +
                                            ': ' +
                                            (context.raw * 100).toFixed(2) +
                                            '%'
                    )
                  case 'pie':
                    return context.raw
                  default:
                    return (
                      context.dataset.label +
                                            ': ' +
                                            context.raw +
                                            (context.datasetIndex === 1
                                              ? ' (mismatch: ' +
                                                  (
                                                    context.chart.data
                                                      .datasets[0].data[
                                                        context.dataIndex
                                                      ] - context.raw
                                                  ).toFixed(2) +
                                                  ')'
                                              : '')
                    )
                }
              }
            }
          }
        },
        scales: {
          y: {
            position: 'left',
            min: 0,
            max: scale => {
              return scale.chart.data.datasets
                .slice(0, 2)
                .reduce((accumulator: number, item) => {
                  const sum = item.data.reduce(
                    (a2: number, i2: number) => {
                      return a2 + i2
                    },
                    0
                  )
                  return sum > accumulator
                    ? sum
                    : accumulator
                }, 0)
            }
          },
          y2: {
            grid: {
              drawOnChartArea: false
            },
            position: 'right',
            min: 0,
            max: 1,
            ticks: {
              callback: (value, index, ticks) => {
                return (value * 100).toFixed(2) + '%'
              }
            }
          }
        }
      }
      if (!updatedOutputGridData) {
        // console.log('----> no output data')
        this.outputGridData = updatedOutputGridData
      } else if (
        (this.outputGridData &&
                    this.outputGridData?.buy_group_id !==
                        updatedOutputGridData.buy_group_id) ||
                !this.outputGridData
      ) {
        console.log(
          '--------> Output Grid Data Updated in Grid Comp: ',
          updatedOutputGridData
        )
        // Update the output data
        this.outputGridData = updatedOutputGridData
        this.configureOutputRowData(updatedOutputGridData[this.tabKey])
      }
    } else if (tabKey === 'user_input' && this.initialRender) {
      this.configureUserInputGridData()
    }

    this.customGridOptions.context.gridName = tabKey

    if (tabKey !== 'user_input') {
      Promise.all([
        this.generalAPIs.GetGridView(tabKey),
        this.generalAPIs.GetGridView(tabKey + '_detail')
      ]).then(data => {
        this.storedGridSettings[tabKey] = data[0]?.is_success
          ? data[0].data
          : {
              colDefs: [],
              filters: []
            }
        this.storedGridSettings[tabKey + '_detail'] = data[1]
          ?.is_success
          ? data[1].data
          : {
              colDefs: [],
              filters: []
            }
        this.setGridState(tabKey, this.gridOptions)
        this.setGridState(tabKey + '_detail', this.outputGridOptions)
      })
    }
  }

  // ------------------------------------------------
  eventPointer: any // trigger context menu popup
  // AG Grid Settings
  gridApi: GridApi
  outputGridApi: GridApi
  columnApi: ColumnApi
  outputColumnApi: ColumnApi
  isAgGridLoading: boolean = false
  isOutputAgGridLoading: boolean = false
  gridOptions: GridOptions = {}
  columnDefs: any = []
  outputColumnDefs: any = []
  initialRender: boolean = true
  configuringUserData: boolean = false
  renderTreeDataGrid: boolean
  tabName: string = ''
  tabKey: string = ''

  dynamicSizes: string[] = []
  deliveryIDs: any[] = []
  selectedDeliveryID: number = null
  chartData: any = {}
  isUpdatingData: boolean = false
  currentUserInputRowSelection: any[] = []
  pendingRowSelected: boolean = false
  approvedRowSelected: boolean = false
  selectedGroupIDs: any[] = []
  selectedMasterRows: number = 0

  panelCollapsed = false

  // Enable and disable grid actions based on specific rules
  gridActionsEnabled = {
    viewData: false,
    exportBreaks: false,
    approve: false,
    markAsPendingReview: false,
    calculateBreaks: false,
    completed: false
  }

  // Saved grid settings
  storedGridSettings: any = {}
  lastSavedDetailGridView: string

  @Output() rowsSelectedEvent = new EventEmitter<any>()
  @Output() onViewDataEvent = new EventEmitter<any>()
  @Output() updateCanViewDataStatus = new EventEmitter<any>()
  @Output() updateSelectedRowIndex = new EventEmitter<any>()

  @ViewChild('confirmPopup') confirmPopup: ConfirmPopupComponent
  @ViewChild('confirmPopupDelete') confirmPopupDelete: ConfirmPopupComponent
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger

  constructor (
    private ref: DynamicDialogRef,
    public dialogService: DialogService,
    private readonly reviewOrdersAPIs: ReviewOrdersAPIs,
    public triggerApiCallsService: TriggerApiCallsService,
    private readonly generalAPIs: GeneralAPIs
  ) {
    // Event is fired when the user right-clicks on an element to open the context menu
    document.addEventListener('contextmenu', event => {
      if (event.button === 2) {
        this.eventPointer = event
      }
    })
  }

  ngOnInit () {
    const { field, tabKey, isTreeDataGrid } = this.importTabData
    this.tabName = field
    this.tabKey = tabKey
    this.renderTreeDataGrid = isTreeDataGrid
    this.configureGridOptions()
  }

  // On Grid Ready
  onGridReady (params?: GridReadyEvent) {
    console.log('AG Grid is Ready')
    if (params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    }
    this.isAgGridLoading = false
  }

  // On Output Bottom Grid Ready
  onOutputGridReady (params?: GridReadyEvent) {
    console.log('AG Grid is Ready')
    if (params) {
      this.outputGridApi = params.api
      this.outputColumnApi = params.columnApi
    }
    this.isOutputAgGridLoading = false
  }

  configureGridOptions () {
    console.log('configuring grid options')
    // Shared Grid Configuations
    const mainOptions = {
      ...this.customGridOptions,
      ...RO_SharedGridOptions(this.tabKey),
      onRowSelected: null,
      onSelectionChanged: null,
      getContextMenuItems: (params: GetContextMenuItemsParams) =>
        this.reviewOrdersGridContextMenu(params),
      isExternalFilterPresent: () => this.isExternalFilterPresent(),
      doesExternalFilterPass: node => this.doesExternalFilterPass(node),
      onFirstDataRendered: params => {
        AutoSizeColumns(params.api)
        if (this.tabKey !== 'user_input') {
          this.gridApi.getRenderedNodes()[0].setSelected(true)
        } else {
          this.setGridState('user_input', this.gridOptions)
        }
      }
    }

    if (this.tabKey === 'user_input') {
      mainOptions['onRowSelected'] = async event =>
        this.masterRowSelected(event)
      mainOptions['onRowGroupOpened'] = async event =>
        this.onRowGroupOpened(event)
    } else {
      mainOptions['onSelectionChanged'] = async event =>
        await this.rowSelectionChangedOutputMain(event)
    }

    // Set custom grid options for the tabs that should be configured with treedata
    if (this.renderTreeDataGrid) {
      console.log(
        'rendering tree data grid',
        TreeDataGridOptions(
          this.tabKey,
          this.importTabData?.treeDataParams
        )
      )
      this.gridOptions = {
        ...mainOptions,
        ...TreeDataGridOptions(
          this.tabKey,
          this.importTabData?.treeDataParams
        )
      }
      this.outputGridOptions = {
        ...this.outputGridOptions,
        ...TreeDataGridOptions(
          this.tabKey,
          this.importTabData?.treeDataParams
        ),
        getRowStyle: (params: RowClassParams) => {
          return params?.node?.data?.isAggRow ||
                        params.node.allLeafChildren?.length
            ? { fontWeight: 'bold' }
            : {}
        }
      }
    } else {
      this.gridOptions = mainOptions
    }
  }

  configureUserInputGridData () {
    console.log('---> Configuring User Input Grid Data')
    this.customGridOptions.context.gridName = 'user_input'
    if (!this.configuringUserData) {
      this.configuringUserData = true
      Promise.all([
        this.generalAPIs.GetGridView('user_input'),
        // this.generalAPIs.GetGridView('user_input_detail'),
        this.reviewOrdersAPIs.GetAllReviewOrdersUserInputData()
      ]).then(data => {
        if (data[1]?.is_success) {
          console.log(
            'detailgridinfo',
            this.importTabData.detailGridInfo
          )
          this.renderGrid({
            mainColDefs: this.importTabData.mainColDefs,
            detailColDefs:
                            this.importTabData.detailGridInfo.columnDefs,
            rowData: data[1].data
          })
        } else {
          // Render the grid without row data
          this.renderGrid({
            mainColDefs: this.importTabData.mainColDefs,
            detailColDefs:
                            this.importTabData.detailGridInfo.columnDefs,
            rowData: []
          })
        }

        this.storedGridSettings['user_input'] = data[0]?.is_success
          ? data[0].data
          : {
              colDefs: [],
              filters: []
            }
        // this.storedGridSettings['user_input_detail'] = data[1]?.is_success
        //   ? data[1].data
        //   : {
        //       colDefs: [],
        //       filters: []
        //     }
      })
    }
  }

  async configureOutputRowData (tabData) {
    const {
      tabKey,
      mainColDefs,
      detailColDefs,
      detailDynamicColDefDefault
    } = this.importTabData
    const rowData = tabData
    console.log(`---> Configuring ${tabKey} Grid Data`)

    try {
      // if (tabKey === 'store_size_breaks') {
      //   this.chartData = tabData?.chart_data
      // }
      // this.deliveryIDs = this.outputGridData[
      //   'additional_data'
      // ].delivery_ids.sort((a, b) => a - b)
      // this.selectedDeliveryID =
      //           this.inputLinesSelectedRows.selectedDeliveryID
      // this.dynamicSizes = this.outputGridData[tabKey][0].size_range
      //   ? this.outputGridData[tabKey][0].size_range.split('|')
      //   : []
      // console.log('this dynamic sizes', this.dynamicSizes)
    } finally {
      // Render grids
      switch (tabKey) {
        // Render standard grids
        case 'source_to_dest_summary':
        case 'carton_summary':
        case 'buy_summary':
          this.renderGrid({
            mainColDefs,
            detailColDefs,
            detailDynamicColDefs:
                            this.tabKey === 'source_to_dest_summary'
                              ? null
                              : this.dynamicSizes,
            detailDynamicColDefDefault,
            rowData
          })
          break
        case 'store_size_breaks':
        case 'store_sku_plan':
          this.renderGrid({
            mainColDefs,
            mainDynamicColDefs: this.dynamicSizes,
            rowData
          })
          break
        default:
          console.error('no tab id!')
      }
    }
  }

  async renderGrid ({
    mainColDefs,
    detailColDefs = null,
    mainDynamicColDefs = null,
    detailDynamicColDefs = null,
    mainDynamicColDefDefault = {},
    detailDynamicColDefDefault = {},
    rowData = []
  }) {
    console.log(`---> Rendering ${this.tabKey} Grid Data `)
    const params = {
      tabKey: this.tabKey,
      packConfigs: this.packConfigs,
      mainColDefs,
      detailColDefs,
      mainDynamicColDefs,
      detailDynamicColDefs,
      mainDynamicColDefDefault,
      detailDynamicColDefDefault
    }

    const gridData = await OM_GenerateGridData(params)
    if (gridData) {
      console.log(
                `Review Orders - ${this.tabKey} col defs: `,
                gridData.mainColDefs
      )
      this.columnDefs = gridData.mainColDefs
      console.log('gridData', gridData)

      // Update the page title for exports
      this.gridOptions.context.pageTitle = `Review orders - ${ReverseFormatKey(
                this.tabKey
            )}`
      if (this.tabKey === 'user_input') {
        this.gridOptions.masterDetail = true
        this.detailCellRendererParams = {
          detailGridOptions: {
            ...CustomGridOptions,
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            enableRangeSelection: true,
            pagination: false,
            keepDetailRows: true,
            columnDefs: gridData.detailColDefs,
            defaultColDef: {
              flex: 1
            },
            columnTypes: CustomGridOptions.columnTypes,
            getRowId: (params: GetRowIdParams) => {
              return `rownode${params.data.id}`
            },
            onSelectionChanged: (event: SelectionChangedEvent) =>
              this.rowSelectionChanged(event, true),
            getContextMenuItems: (
              params: GetContextMenuItemsParams
            ) => this.reviewOrdersGridContextMenu(params),
            context: {
              componentParent: this,
              openUserInputConfigSettings: (
                params: ICellRendererParams
              ) => this.openUserInputConfigSettings(params),
              gridName: 'user_input_detail'
            },
            // column events for saving state on api call
            onDisplayedColumnsChanged: (
              event: DisplayedColumnsChangedEvent
            ) => this.updateChangedDetailGridView(event),
            onFilterChanged: (event: FilterChangedEvent) =>
              this.updateChangedDetailGridView(event),
            onSortChanged: (event: SortChangedEvent) =>
              this.updateChangedDetailGridView(event)
          },
          getDetailRowData: (params: GetDetailRowDataParams) => {
            params.successCallback(params.data.detail_data)
          }
        }
      } else {
        this.outputColumnDefs = gridData.detailColDefs
        console.log(
          'output top grid col defs from om generate etc',
          gridData.detailColDefs
        )
        this.outputGridApi?.refreshCells()
      }
      // function to check if AgGrid is loading
      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          if (this.gridApi && !this.gridApi['destroyCalled']) {
            this.gridApi?.setColumnDefs(gridData.mainColDefs)
            if (this.tabKey == 'user_input') {
              this.setGridState(this.tabKey, this.gridOptions)
            }
            this.outputGridApi?.setColumnDefs(
              gridData.detailColDefs
            )
            console.log('setting row data', rowData)
            this.gridApi.setRowData(rowData)
            if (this.tabKey === 'user_input') {
              this.gridApi.refreshClientSideRowModel('group')
            } else {
              const selectedNodes =
                                this.gridApi.getSelectedNodes()
              console.log('selectednodes', selectedNodes)
              if (selectedNodes.length) {
                console.log(
                  'setting top grid data',
                  JSON.stringify(
                    selectedNodes[0].data.detailGridData
                  )
                )
                this.outputGridApi.setRowData(
                  selectedNodes[0].data.detailGridData
                )
              } else {
                this.outputGridApi.setRowData([])
                this.checkAndSelectCorrectNode()
              }
            }
            this.gridApi.refreshClientSideRowModel('aggregate')
            if (this.tabKey !== 'user_input') {
              console.log('refreshing output data')
              this.outputGridApi.refreshClientSideRowModel()
              this.outputGridApi.refreshClientSideRowModel(
                'group'
              )
              this.outputGridApi.refreshClientSideRowModel(
                'aggregate'
              )
            }

            // if (
            //   this.tabKey !== 'user_input' &&
            //                 this.tabKey !== 'buy_summary'
            // ) {
            //   this.setDropdownValueForGrids({
            //     value: this.selectedDeliveryID
            //   })
            // }

            if (this.initialRender) {
              this.initialRender = false

              if (this.tabKey === 'user_input') {
                // Start the subscription and send the grid api to update the needed rows
                this.reviewOrdersAPIs.JobGridDataSubscription(
                  this.gridApi,
                  this.gridOptions.context.componentParent
                )
              }
            }
            this.configuringUserData = false
          }
          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      if (this.tabKey == 'user_input') {
        this.setGridState('user_input', this.gridOptions)
      } else {
        this.setGridState(this.tabKey, this.gridOptions)
        this.setGridState(
          this.tabKey + '_detail',
          this.outputGridOptions
        )
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  // Sets the context menu in the grid
  reviewOrdersGridContextMenu (params: GetContextMenuItemsParams) {
    console.log('---> context menu: ', params)
    console.log('---> this.gridActionsEnabled: ', this.gridActionsEnabled)
    const numRowsSelected = this.inputLinesSelectedRows.ids.length
    if (params.column) {
      // View Data Action
      const viewDataButton = [
        {
          name: 'View Data',
          action: event => this.onViewDataEvent.emit(event),
          disabled:
                        !this.inputLinesSelectedRows.ids.length ||
                        !this.canViewData,
          cssClasses: ['text-accent']
        }
      ]
      // Export Breaks Action
      const exportBreaksButton = [
        {
          name:
                        numRowsSelected > 0
                          ? 'Export Breaks'
                          : 'Export Breaks (No Jobs Selected)',
          action: async () =>
            await this.confirmPopup.confirm(
              this.eventPointer,
              'Export Breaks'
            ),
          disabled: !this.gridActionsEnabled.exportBreaks,
          cssClasses: ['text-warn']
        }
      ]
      const approveButton = [
        {
          name:
                        numRowsSelected > 0
                          ? `Approve Job${numRowsSelected === 1 ? '' : 's'}`
                          : 'Approve (No Jobs Selected)',
          action: () => this.onApprove('context-menu-action'),
          disabled: !this.gridActionsEnabled.approve,
          cssClasses: ['text-accent']
        }
      ]
      //  Mark as Pending Review Action
      const markAsPendingReviewButton = [
        {
          name:
                        numRowsSelected > 0
                          ? `Unapprove Job${numRowsSelected === 1 ? '' : 's'}`
                          : 'Unapprove (No Jobs Selected)',
          action: () =>
            this.onMarkAsPendingReview('context-menu-action'),
          disabled:
                        !this.gridActionsEnabled.completed ||
                        numRowsSelected === 0,
          cssClasses: ['text-accent']
        }
      ]
      // Calculate Breaks Action
      const calculateBreaksButton = [
        {
          name:
                        numRowsSelected > 0
                          ? 'Calculate Breaks'
                          : 'Calculate Breaks (No Jobs Selected)',
          action: () => this.onCalculateBreaks('context-menu-action'),
          disabled: !this.gridActionsEnabled.calculateBreaks,
          cssClasses: ['text-accent']
        }
      ]
      // Save View Action
      const saveViewButton =
                params.context.gridName === 'user_input_detail'
                  ? []
                  : [
                      {
                        name: 'Save Grid View',
                        action: async () => {
                          // if (params.context.gridName === 'user_input_detail') {
                          //   this.lastSavedDetailGridView = 'detail_' + params.node.data.buy_group_id + ' grid row id'
                          // }
                          await SaveGridState(
                            params,
                            params?.context?.gridName,
                            true
                          )
                        },
                        cssClasses: ['text-accent']
                      },
                      'separator'
                    ]
      // Display the context menu items for the user input table only
      const getUserInputMenuItems = () =>
        this.tabKey === 'user_input'
          ? [
              ...approveButton,
              ...markAsPendingReviewButton,
              'separator',
              ...viewDataButton,
              ...calculateBreaksButton,
              ...exportBreaksButton,
              'separator',
              ...saveViewButton
            ]
          : [...saveViewButton]

      const result = [
        ...getUserInputMenuItems(),
        ...DefaultGridContextMenu(params)
      ]

      return result
    } else {
      return DefaultGridContextMenu(params)
    }
  }

  // On row change update emit the new data to the main Review Orders Component
  rowSelectionChanged (
    event: SelectionChangedEvent,
    wasDetailSelected: boolean
  ) {
    // const masterRows = await event.api.getSelectedNodes()
    // masterRows.forEach(masterRow => {
    //   console.log('for each amster row', masterRow)
    //   masterRow.setExpanded(true)
    //   const detailGridInfo = this.gridApi.getDetailGridInfo('detail_' + masterRow.data.grid_row_id)
    //   if (detailGridInfo) {
    //     console.log('selecting all')
    //     detailGridInfo.api.selectAll()
    //   }
    // })

    // const detailRows = masterRows.reduce((accumulator, item) => {
    //   console.log('master row to reduce:', item)
    //   const copyRows = item.data.detail_data.map(row => {
    //     return { ...row }
    //   })
    //   copyRows.forEach(row => {
    //     row.buy_group_id = item.data.buy_group_id
    //   })
    //   return accumulator.concat(copyRows)
    // }, [])
    // console.log('all detail grid datas', detailRows)
    this.addToSelectedRows(event, wasDetailSelected)
  }

  // rowSelectionChangedDetail (event: SelectionChangedEvent) {
  //   // const stycIDs = []
  //   // let updatedGroupLevelData
  //   // let singleGroupsSelected
  //   // // Reset Grid Actions
  //   // this.gridActionsEnabled = {
  //   //   approve: false,
  //   //   markAsPendingReview: false,
  //   //   calculateBreaks: false
  //   // }
  //   // const selectedStatuses = {
  //   //   approved: false,
  //   //   pending_review: false,
  //   //   running: false
  //   // }
  //   // console.log('finding node by id')
  //   // console.log(event.api.getRowNode('rownode2'))

  //   // if (!this.configuringUserData) {
  //   //   try {
  //   //     const rows = event.api.getSelectedRows()
  //   //     console.log('---> User Input Row Selection Change: ', rows)

  //   //     this.currentUserInputRowSelection = rows
  //   //     // const groupsSelected = await
  //   //     singleGroupsSelected =
  //   //                 rows.length > 0
  //   //                   ? [...new Set([...rows.map(row => row.group)])]
  //   //                       .length === 1
  //   //                   : false
  //   //     updatedGroupLevelData = !singleGroupsSelected
  //   //       ? DefaultUserInputLinesGrid
  //   //       : {
  //   //           store_distro: rows[0]?.store_distro,
  //   //           selectedDeliveryID: rows[0]?.delivery_id,
  //   //           size_range: rows[0]?.size_range,
  //   //           isRowDisabled: false,
  //   //           channels: [],
  //   //           rows: [],
  //   //           row_ids: [],
  //   //           buy_group_id: rows[0]?.buy_group_id
  //   //         }

  //   //     const updateGridActionsAndData = rows.map((row, i) => {
  //   //       const status = FormatKey(row.status)
  //   //       // If only 1 group is selected -> update the top level group data
  //   //       // if (singleGroupsSelected) {
  //   //       //   const { row_id, channel, styc_id, grid_row_id } = row
  //   //       //   const { row_ids, channels } = updatedGroupLevelData

  //   //       //   if (!row_ids.includes(row_id)) {
  //   //       //     updatedGroupLevelData.row_ids.push(row_id)
  //   //       //   }
  //   //       //   if (!channels.includes(channel)) {
  //   //       //     updatedGroupLevelData.channels.push(channel)
  //   //       //   }
  //   //       //   if (!stycIDs.includes(styc_id)) {
  //   //       //     stycIDs.push(styc_id)
  //   //       //   }
  //   //       //   updatedGroupLevelData.rows.push([row_id, grid_row_id])
  //   //       // }

  //   //       // Update Grid functionality
  //   //       if (!selectedStatuses[status]) {
  //   //         selectedStatuses[status] = true
  //   //       }
  //   //       if (rows.length === i + 1) {
  //   //         const { approved, pending_review, running } =
  //   //                         selectedStatuses
  //   //         const editActionsEnabled = !running && rows.length > 0
  //   //         this.gridActionsEnabled = {
  //   //           approve: editActionsEnabled && !approved,
  //   //           markAsPendingReview:
  //   //                             editActionsEnabled && !pending_review,
  //   //           calculateBreaks: editActionsEnabled && !approved
  //   //         }
  //   //       }
  //   //       return row
  //   //     })
  //   //     if (updateGridActionsAndData && singleGroupsSelected) {
  //   //       if (updatedGroupLevelData.channels.length > 0) {
  //   //         updatedGroupLevelData.isRowDisabled =
  //   //                         stycIDs.length !== 1
  //   //       }
  //   //     }
  //   //   } finally {
  //   //     console.log(
  //   //       '----> Updating the row selection',
  //   //       updatedGroupLevelData
  //   //     )
  //   //     console.log('gridActions enabled: ', this.gridActionsEnabled)
  //   //     this.rowsSelectedEvent.emit({
  //   //       rowsSelected: singleGroupsSelected,
  //   //       data: updatedGroupLevelData
  //   //     })
  //   //   }
  //   // }
  //   this.addToSelectedRows()
  // }

  addToSelectedRows (
    event: SelectionChangedEvent,
    wasDetailSelected: boolean
  ) {
    if (wasDetailSelected) {
      this.gridApi.forEachDetailGridInfo((info: DetailGridInfo) => {
        const parentNode = this.gridApi.getRowNode(info.id.slice(7))
        parentNode.setSelected(
          info.api.getSelectedNodes().length ===
                        parentNode.data.detail_data.length
        )
      })
    } else {
      this.gridApi.forEachDetailGridInfo((info: DetailGridInfo) => {
        const parentNode = this.gridApi.getRowNode(info.id.slice(7))
        if (parentNode.isSelected()) {
          info.api.selectAll()
        }
      })
    }
    const masterRows = this.gridApi.getSelectedRows()
    let detailRows = masterRows.reduce((accumulator, item) => {
      const copyRows = item.detail_data.map(row => {
        return { ...row }
      })
      copyRows.forEach(row => {
        row.buy_group_id = item.buy_group_id
      })
      return accumulator.concat(copyRows)
    }, [])
    this.selectedGroupIDs = []
    this.selectedMasterRows = masterRows.length
    this.gridApi.forEachDetailGridInfo(gridInfo => {
      // this.setGridState('user_input_detail', gridInfo)
      detailRows = detailRows.concat(
        gridInfo.api.getSelectedNodes().map(node => {
          console.log(
            'selected nodes-----------------------------',
            node
          )
          if (
            !this.selectedGroupIDs.includes(
              node.data.style_color_id
            )
          ) {
            this.selectedGroupIDs.push(node.data.style_color_id)
          }

          return node.data
        })
      )

      // .map(node => node.data)
      // .reduce((accumulator, item) => {
      //   console.log('reducing with accumulator', accumulator)
      //   console.log('and item', item)
      //   if (!accumulator.map(row => row.id).includes(item.id)) {
      //     console.log('pushing to accumulator')
      //     accumulator.push(item)
      //   }
      //   return accumulator
      // }, detailRows.map(row => row))
    })

    if (
      (this.selectedGroupIDs.length === 1 &&
                this.selectedMasterRows === 0) ||
            (this.selectedGroupIDs.length === 0 &&
                this.selectedMasterRows === 1) ||
            (this.selectedGroupIDs.length === 1 &&
                this.selectedMasterRows === 1 &&
                masterRows[0]?.buy_group_id === this.selectedGroupIDs[0])
    ) {
      this.canViewData = true
      this.updateCanViewDataStatus.emit(true)
    } else {
      this.canViewData = false
      this.updateCanViewDataStatus.emit(false)
    }

    console.log('detail rows', detailRows)
    const selectedStatuses = {
      approved: false,
      completed: false,
      'in progress': false
    }

    detailRows.forEach(row => {
      console.log('checking for status of row', row)
      selectedStatuses[row.status.toLowerCase()] = true
    })

    console.log('final selected statuses', selectedStatuses)

    const { approved, completed } = selectedStatuses
    const in_progress = selectedStatuses['in progress']
    const editActionsEnabled = !in_progress && detailRows.length > 0
    console.log('editactionsenabled', editActionsEnabled)
    this.gridActionsEnabled = {
      ...this.gridActionsEnabled,
      approve: editActionsEnabled && !approved,
      markAsPendingReview: editActionsEnabled && !completed,
      completed: editActionsEnabled && !completed,
      calculateBreaks: editActionsEnabled && !approved,
      exportBreaks:
                editActionsEnabled &&
                approved &&
                !completed &&
                masterRows.length > 0
    }
    console.log('this.gridActionsEnabled', this.gridActionsEnabled)

    console.log('this.inputliensselectedrows', this.inputLinesSelectedRows)
    this.rowsSelectedEvent.emit({
      data: {
        rows: detailRows,
        ids: detailRows.map(row => row.id),
        wasDetailSelected
      }
    })
  }

  masterRowSelected (event: RowSelectedEvent) {
    console.log('master row selected', event)
    if (event.event || event.source === 'uiSelectAllFiltered') {
      if (!event.node.isSelected()) {
        console.log('row is deselected', event.node)
        this.gridApi
          .getDetailGridInfo('detail_' + event.node.id)
          ?.api.deselectAll()
      }
      this.addToSelectedRows(null, false)
    } else {
      console.log('not a real event, ignore')
    }
  }

  // detailRowSelected (event: RowSelectedEvent) {
  //   console.log('detail row selected', event)
  //   if (event.source === 'uiSelectAll') {
  //     this.addToSelectedRows(null, true)
  //   }
  // }

  onRowGroupOpened (event: RowGroupOpenedEvent) {
    this.addToSelectedRows(null, false)
  }

  async rowSelectionChangedOutputMain (event: SelectionChangedEvent) {
    const nodes = event.api.getSelectedNodes()
    nodes.sort((a, b) => a.rowIndex - b.rowIndex)
    console.log('selected nodes', nodes)
    if (nodes.length > 0) {
      this.selectedRowIndex = nodes[0]?.rowIndex
      this.updateSelectedRowIndex.emit(nodes[0]?.rowIndex)
    }

    // get columns
    let dynamicSizesFiltered = []
    if (this.tabKey === 'buy_summary' || this.tabKey === 'carton_summary') {
      let dynamicSizes = []
      nodes.forEach(node => {
        console.log('finding sizes in node', node)
        dynamicSizes = dynamicSizes.concat(node.data.main_row_sizes)
      })
      console.log('after concat', dynamicSizes)
      dynamicSizesFiltered = dynamicSizes.reduce((accumulator, item) => {
        if (!accumulator.includes(item)) {
          accumulator.push(item)
        }
        return accumulator
      }, [])

      console.log('after reducing', dynamicSizesFiltered)

      dynamicSizesFiltered.sort((a, b) => {
        return a.size_order - b.size_order
      })

      console.log('after sorting', dynamicSizesFiltered)
    }
    this.dynamicSizes = dynamicSizesFiltered.map(size => size.product_size)
    const newDetailColDefs = await GenerateCols(
      this.importTabData.detailColDefs,
      this.tabKey
    )
    const newSizeColDefs = await GenerateDynamicCols(
      this.dynamicSizes,
      this.tabKey,
      this.importTabData.detailDynamicColDefDefault
    )

    this.outputGridApi.setRowData([])

    this.outputGridApi.setColumnDefs([])
    this.outputGridApi.setColumnDefs(
      newDetailColDefs.concat(newSizeColDefs)
    )

    let newGridData = []
    if (nodes.length) {
      console.log('newGridData', newGridData)
      if (this.tabKey === 'carton_summary') {
        // make aggregate rows
        const totals = {
          carton_id: 'SKU Totals by Size',
          pack_ttl: 0,
          isAggRow: true
        }
        const totalsPct = {
          carton_id: 'SKU % by Size',
          pack_ttl: '--',
          isAggRow: true,
          isPctRow: true
        }
        const bulkTotals = {
          carton_id: 'In Bulk',
          pack_ttl: 0,
          isAggRow: true
        }
        const packTotals = {
          carton_id: 'In Pack',
          pack_ttl: 0,
          isAggRow: true
        }
        const profileRows = {}
        const profileRowsArray = []
        this.dynamicSizes.forEach(size => {
          totals[size] = 0
          bulkTotals[size] = 0
          packTotals[size] = 0
        })
        nodes.forEach(node => {
          console.log('inspecting node for detail list', node)
          const distro_id = node.data.distro_id
          newGridData = newGridData.concat(node.data.detailGridData)
          if (!(distro_id in profileRows)) {
            console.log(
              'adding distro id ' + distro_id + ' to profilerows'
            )
            const row = node.data.profile_row[0]
            row.pack_ttl = '--'
            row.carton_id = node.data.distro_id
            row.isAggRow = true
            row.isPctRow = true
            profileRows[distro_id] = true
            profileRowsArray.push(row)
          }

          node.data.detailGridData.forEach(row => {
            this.dynamicSizes.forEach(size => {
              if (row.is_bulk || row.is_bulk_multiple) {
                totals[size] += row[size]
                bulkTotals[size] += row[size]
                totals.pack_ttl += row[size]
                bulkTotals.pack_ttl += row[size]
              } else {
                totals[size] += row.pack_ttl * row[size]
                packTotals[size] += row.pack_ttl * row[size]
                totals.pack_ttl += row.pack_ttl * row[size]
                packTotals.pack_ttl += row.pack_ttl * row[size]
              }
            })
          })
        })
        this.dynamicSizes.forEach(size => {
          totalsPct[size] = totals[size] / totals.pack_ttl
          // bulkTotals[size + '|PCT'] = (100 * bulkTotals[size] / bulkTotals.pack_ttl).toFixed(2)
          // packTotals[size + '|PCT'] = (100 * packTotals[size] / packTotals.pack_ttl).toFixed(2)
          // if (isNaN(packTotals[size + '|PCT'])) {
          //   console.log('packtotals pct is nan', packTotals[size + '|PCT'])
          //   overallStore[size] = bulkTotals[size + '|PCT']
          // } else if (isNaN(bulkTotals[size + '|PCT'])) {
          //   console.log('bulktotals pct is nan', bulkTotals[size + '|PCT'])
          //   overallStore[size] = packTotals[size + '|PCT']
          // } else {
          //   console.log('neither is nan')
          //   console.log('bulktotals component', (bulkTotals.pack_ttl / totals.pack_ttl) * bulkTotals[size] / bulkTotals.pack_ttl)
          //   console.log('packtotals component', (packTotals.pack_ttl / totals.pack_ttl) * packTotals[size] / packTotals.pack_ttl)
          //   overallStore[size] = (((bulkTotals[size] / bulkTotals.pack_ttl) + (packTotals[size] / packTotals.pack_ttl)) * 50).toFixed(2) + '%' // 50 = 100 (for pct) / 2 (for average)
          // }
        })
        console.log('profileRowsArray', profileRowsArray)
        this.pinnedTopRowData = [
          totals,
          totalsPct,
          bulkTotals,
          packTotals
        ].concat(profileRowsArray)
        console.log('pinendtoprowdata', this.pinnedTopRowData)
      } else {
        nodes.forEach(node => {
          newGridData = newGridData.concat(node.data.detailGridData)
        })
        this.pinnedTopRowData = []
      }
      this.outputGridApi.setRowData(newGridData)
    } else {
      newGridData = []
      this.pinnedTopRowData = []
      this.outputChartData = null
      this.outputDetailChartData = null
      console.log('newGridData', newGridData)
      this.outputGridApi.setRowData(newGridData)
      return
    }

    if (this.tabKey === 'buy_summary') {
      const allNodes = []
      this.outputGridApi.forEachNode(node => {
        if (node.allLeafChildren?.length) {
          allNodes.push(node)
        }
      })
      // put together chart data
      // buy first
      const buyChartData = allNodes.map(row => {
        return {
          label: `${row.key} - Buy`, // key should be the channel name
          type: 'bar',
          data: this.dynamicSizes.map(
            size => row.aggData[FormatKey(size)]?.buy
          )
        }
      })
      const targetChartData = allNodes.map(row => {
        return {
          label: `${row.key} - Target`,
          type: 'bar',
          data: this.dynamicSizes.map(size =>
            row.aggData[FormatKey(size)]?.target.toFixed(2)
          )
        }
      })
      this.outputChartData = {
        datasets: buyChartData.concat(targetChartData),
        labels: this.dynamicSizes
      }
    } else if (this.tabKey === 'carton_summary') {
      let bulkTotal = 0
      let packTotal = 0
      this.dynamicSizes.forEach(size => {
        bulkTotal += this.pinnedTopRowData[2][size]
        packTotal += this.pinnedTopRowData[3][size]
      })
      console.log('bulkTotal', bulkTotal)
      console.log('packTotal', packTotal)
      this.outputChartData = {
        datasets: [
          {
            label: 'Total Carton Qty',
            data: [bulkTotal, packTotal]
          }
        ],
        labels: ['Bulk', 'Pack']
      }
    }
  }

  openTargetBuyChart (params: ICellRendererParams) {
    // put together chart data
    // buy first
    console.log('opening target buy chart with event', params)
    const allNodes = []
    const buyChartData = [
      {
        label: `${params.data.location_id} - Buy`, // key should be the channel name
        type: 'bar',
        data: params.data.product_size.map(
          (size: string) => params.data[size].buy
        ),
        yAxisID: 'y'
      }
    ]
    const targetChartData = [
      {
        label: `${params.data.location_id} - Target`, // key should be the channel name
        type: 'bar',
        data: params.data.product_size.map((size: string) =>
          params.data[size].target.toFixed(2)
        ),
        yAxisID: 'y'
      }
    ]
    const profileChartData = [
      {
        label: `${params.data.location_id} - Profile`, // key should be the channel name
        type: 'line',
        data: params.data.product_size.map(
          (size: string) => params.data[size].profile_contrib
        ),
        yAxisID: 'y2'
      }
    ]
    this.outputDetailChartData = {
      datasets: buyChartData
        .concat(targetChartData)
        .concat(profileChartData),
      labels: this.dynamicSizes
    }
    this.dialogService.open(MismatchChartComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'large-modal',
      data: {
        data: this.outputDetailChartData,
        options: this.outputDetailChartOptions
      }
    })
  }

  // Delivery Date Selection
  setDropdownValueForGrids (event) {
    console.log('---> Delivery ID Filter Updated: ', event.value)
    this.selectedDeliveryID = event.value
    this.gridOptions.api.onFilterChanged()
    const renderedNodes = this.gridApi.getRenderedNodes()
    if (renderedNodes && renderedNodes.length === 0) {
      this.gridApi.showNoRowsOverlay()
    } else {
      this.gridApi.hideOverlay()
    }
  }

  // Only apply external filters to tabs that display the Delivery ID Selection
  isExternalFilterPresent () {
    return false // this.tabKey !== 'user_input' && this.tabKey !== 'buy_summary'
  }

  // Function to update the rowData based on external filers (Delivery ID Selection)
  doesExternalFilterPass (node) {
    if (node.data) {
      return node.data.delivery_id === this.selectedDeliveryID
    }
  }

  // Tab = User Input
  openUserInputConfigSettings (cell) {
    console.log('---> cell: ', cell)
    const { id, channel, product_id } = cell.node.data
    // Api call -> GetParameterSettings
    this.ref = this.dialogService.open(UserInputConfigSettingsComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'medium-modal',
      data: {
        id,
        channel,
        product_id
      }
    })

    // Modal Close Data
    this.ref.onClose.subscribe(data => {
      console.log('User Input Configs Modal Closed: ', data)
      // // Allow new api calls once the modal is closed
      this.triggerApiCallsService.onTriggerApiCalls({
        clear_api_calls: false
      })
      if (data) {
        this.gridApi.deselectAll()
        this.gridApi.showLoadingOverlay()
        this.configureUserInputGridData()
      }
    })
  }

  // Tab = Buy Summary
  openMismatchChartModal (cell) {
    console.log('---> mismatch modal cell', cell)
    const selectedRows = []
    const cellData = cell?.data || cell.node.aggData
    switch (cell.node.level) {
      case 0:
        // pass total
        selectedRows.push(cellData)
        break
      case 1:
        // pass this channel
        selectedRows.push(cellData)
        break
      case 2:
        // pass this channel and this store
        selectedRows.push(cellData)
        selectedRows.push(cell.node.parent.aggData)
        break
    }
    this.ref = this.dialogService.open(MismatchChartComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'large-modal',
      data: {
        selectedRows,
        sizes: this.dynamicSizes,
        level: cell.node.level,
        metric: cellData?.metric
      }
    })
  }

  // Tab = Carton Summary
  openPackConfigModal (cell) {
    this.ref = this.dialogService.open(PackSummaryConfigComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'large-modal',
      data: cell.node.data
    })
  }

  // Tab = Store Size Breaks
  viewTotalsCharts (cell) {
    const cellData = cell?.data || cell.node.aggData

    this.ref = this.dialogService.open(StoreTotalsChartsComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'large-modal',
      data: {
        cellData,
        sizes: this.dynamicSizes,
        chartData: this.chartData,
        deliveryID: `delivery_id-${this.selectedDeliveryID}`,
        params: cell
      }
    })
  }

  async toggleUpdatingDataHandler (isUpdating: boolean, isSuccess?: boolean) {
    this.isUpdatingData = isUpdating

    if (isUpdating) {
      this.gridApi.showLoadingOverlay()
    } else if (!isUpdating && isSuccess) {
      try {
        await this.gridApi.flushAsyncTransactions()
        await this.gridApi.expireValueCache()
      } finally {
        this.gridApi.deselectAll()

        this.rowsSelectedEvent.emit({
          rowsSelected: null,
          data: DefaultUserInputLinesGrid
        })
        this.configureUserInputGridData()
      }
    } else {
      this.gridApi.hideOverlay()
    }
  }

  onDelete (params) {
    this.toggleUpdatingDataHandler(true)
    Promise.all([
      this.saveInputGridState(),
      this.reviewOrdersAPIs.OnDeleteBuyGroup({
        buy_group_id: params.params.data.buy_group_id
      })
    ]).then(data => {
      this.gridApi.deselectAll()
      if (data[1]?.is_success) {
        this.toggleUpdatingDataHandler(false, true)
      } else {
        this.toggleUpdatingDataHandler(false)
      }
    })
  }

  // Approve Jobs
  onApprove ($event) {
    console.log('---> Approve Rows: ', this.inputLinesSelectedRows)
    this.toggleUpdatingDataHandler(true)
    const dataToSend = this.inputLinesSelectedRows.rows.map(
      rowData => rowData.id
    )
    Promise.all([
      this.saveInputGridState(),
      this.reviewOrdersAPIs.ApproveOrders({ job_ids: dataToSend })
    ]).then(data => {
      if (data[1]?.is_success) {
        this.toggleUpdatingDataHandler(false, true)
      } else {
        this.toggleUpdatingDataHandler(false)
      }
    })
  }

  // Mark Jobs as Pending Review
  onMarkAsPendingReview (event) {
    console.log('mark pending', this.inputLinesSelectedRows)
    this.toggleUpdatingDataHandler(true)

    const dataToSend = this.inputLinesSelectedRows.rows.map(
      rowData => rowData.id
    )

    Promise.all([
      this.saveInputGridState(),
      this.reviewOrdersAPIs.OnMarkAsPendingReview({
        job_ids: dataToSend
      })
    ]).then(data => {
      if (data[1]?.is_success) {
        this.toggleUpdatingDataHandler(false, true)
      } else {
        this.toggleUpdatingDataHandler(false)
      }
    })
  }

  // Calculate Breaks
  onCalculateBreaks (event) {
    // const dataToSend = this.currentUserInputRowSelection.map(rowData => {
    //   const { row_id, channel } = rowData
    //   return { row_id, channel }
    // })
    const dataToSend = this.inputLinesSelectedRows.rows.map(
      rowData => rowData.id
    )
    this.gridApi.showLoadingOverlay()

    Promise.all([
      this.saveInputGridState(),
      this.reviewOrdersAPIs.OnCalculateBreaks({ job_ids: dataToSend })
    ]).then(data => {
      if (data[1]?.is_success) {
        this.gridApi.deselectAll()
        this.gridApi.forEachDetailGridInfo((info: DetailGridInfo) => {
          info.api.deselectAll()
        })
        this.toggleUpdatingDataHandler(false, true)
      } else {
        this.toggleUpdatingDataHandler(false)
      }
    })
  }

  // Export Job Data -> Exported data cannot be reversed
  onExportBreaks (event) {
    if (event.userInput === 'except') {
      this.toggleUpdatingDataHandler(true)
      const rows = this.gridApi.getSelectedRows()
      const dataToSend = rows.map(rowData => {
        return rowData.buy_group_id
      })

      console.log('---> Data to send on export: ', dataToSend)

      Promise.all([
        this.saveInputGridState(),
        this.reviewOrdersAPIs.OnExportBreaks({
          buy_group_ids: dataToSend
        })
      ]).then(data => {
        this.gridApi.deselectAll()
        if (data[1]?.is_success) {
          this.toggleUpdatingDataHandler(false, true)
        } else {
          this.toggleUpdatingDataHandler(false)
        }
      })
    }
  }

  onPanelOpened (event) {
    this.panelCollapsed = false
  }

  onPanelClosed (event) {
    this.panelCollapsed = true
  }

  checkAndSelectCorrectNode () {
    const selectedNodes = this.gridOptions.api.getSelectedNodes()

    if (selectedNodes.length === 0 && !this.selectedRowIndex) {
      this.gridOptions.api.forEachNode((node, index) => {
        if (index === 0) {
          node.setSelected(true)
        }
      })
    } else {
      this.gridOptions.api.forEachNode((node, index) => {
        if (index === this.selectedRowIndex) {
          node.setSelected(true)
        } else if (index !== this.selectedRowIndex) {
          node.setSelected(false)
        }
      })
    }
  }

  async saveInputGridState () {
    return await Promise.all([
      SaveGridState(this.gridOptions, 'user_input', false)
      // SaveGridStateWithData({ context: this.gridOptions.context }, this.storedGridSettings['user_input_detail'].colDefs, this.storedGridSettings['user_input_detail'].filters, 'user_input_detail', false)
    ])
  }

  updateChangedDetailGridView (event) {
    // this.storedGridSettings['user_input_detail'] = {
    //   colDefs: event.columnApi.getColumnState(),
    //   filters: event.api.getFilterModel()
    // }
    // this.lastSavedDetailGridView = 'detail_' + event.api.getRenderedNodes()[0].data.buy_group_id + ' grid row id'
  }

  @HostListener('unloaded')
  ngOnDestroy () {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions()
      this.gridApi.expireValueCache()
      this.gridApi = null
    }
  }
}
