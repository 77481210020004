import { __assign, __awaiter, __generator } from "tslib";
import { InternalGraphQLAPIClass } from '@aws-amplify/api-graphql/internals';
import { RestAPIClass } from '@aws-amplify/api-rest';
import { Auth } from '@aws-amplify/auth';
import { Cache } from '@aws-amplify/cache';
import { Amplify, ApiAction, Category, Credentials, ConsoleLogger as Logger } from '@aws-amplify/core';
var logger = new Logger('API');
/**
 * @deprecated
 * Use RestApi or GraphQLAPI to reduce your application bundle size
 * Export Cloud Logic APIs
 */
var InternalAPIClass = /** @class */function () {
  /**
   * Initialize API with AWS configuration
   * @param {Object} options - Configuration object for API
   */
  function InternalAPIClass(options) {
    this.Auth = Auth;
    this.Cache = Cache;
    this.Credentials = Credentials;
    this._options = options;
    this._restApi = new RestAPIClass(options);
    this._graphqlApi = new InternalGraphQLAPIClass(options);
    logger.debug('API Options', this._options);
  }
  InternalAPIClass.prototype.getModuleName = function () {
    return 'InternalAPI';
  };
  /**
   * Configure API part with aws configurations
   * @param {Object} config - Configuration of the API
   * @return {Object} - The current configuration
   */
  InternalAPIClass.prototype.configure = function (options) {
    this._options = Object.assign({}, this._options, options);
    // Share Amplify instance with client for SSR
    this._restApi.Credentials = this.Credentials;
    this._graphqlApi.Auth = this.Auth;
    this._graphqlApi.Cache = this.Cache;
    this._graphqlApi.Credentials = this.Credentials;
    var restAPIConfig = this._restApi.configure(this._options);
    var graphQLAPIConfig = this._graphqlApi.configure(this._options);
    return __assign(__assign({}, restAPIConfig), graphQLAPIConfig);
  };
  /**
   * Make a GET request
   * @param apiName - The api name of the request
   * @param path - The path of the request
   * @param [init] - Request extra params
   * @return A promise that resolves to an object with response status and JSON data, if successful.
   */
  InternalAPIClass.prototype.get = function (apiName, path, init) {
    return this._restApi.get(apiName, path, this.getInitWithCustomUserAgentDetails(init, ApiAction.Get));
  };
  /**
   * Make a POST request
   * @param apiName - The api name of the request
   * @param path - The path of the request
   * @param [init] - Request extra params
   * @return A promise that resolves to an object with response status and JSON data, if successful.
   */
  InternalAPIClass.prototype.post = function (apiName, path, init) {
    return this._restApi.post(apiName, path, this.getInitWithCustomUserAgentDetails(init, ApiAction.Post));
  };
  /**
   * Make a PUT request
   * @param apiName - The api name of the request
   * @param path - The path of the request
   * @param [init] - Request extra params
   * @return A promise that resolves to an object with response status and JSON data, if successful.
   */
  InternalAPIClass.prototype.put = function (apiName, path, init) {
    return this._restApi.put(apiName, path, this.getInitWithCustomUserAgentDetails(init, ApiAction.Put));
  };
  /**
   * Make a PATCH request
   * @param apiName - The api name of the request
   * @param path - The path of the request
   * @param [init] - Request extra params
   * @return A promise that resolves to an object with response status and JSON data, if successful.
   */
  InternalAPIClass.prototype.patch = function (apiName, path, init) {
    return this._restApi.patch(apiName, path, this.getInitWithCustomUserAgentDetails(init, ApiAction.Patch));
  };
  /**
   * Make a DEL request
   * @param apiName - The api name of the request
   * @param path - The path of the request
   * @param [init] - Request extra params
   * @return A promise that resolves to an object with response status and JSON data, if successful.
   */
  InternalAPIClass.prototype.del = function (apiName, path, init) {
    return this._restApi.del(apiName, path, this.getInitWithCustomUserAgentDetails(init, ApiAction.Del));
  };
  /**
   * Make a HEAD request
   * @param apiName - The api name of the request
   * @param path - The path of the request
   * @param [init] - Request extra params
   * @return A promise that resolves to an object with response status and JSON data, if successful.
   */
  InternalAPIClass.prototype.head = function (apiName, path, init) {
    return this._restApi.head(apiName, path, this.getInitWithCustomUserAgentDetails(init, ApiAction.Head));
  };
  /**
   * Checks to see if an error thrown is from an api request cancellation
   * @param error - Any error
   * @return If the error was from an api request cancellation
   */
  InternalAPIClass.prototype.isCancel = function (error) {
    return this._restApi.isCancel(error);
  };
  /**
   * Cancels an inflight request for either a GraphQL request or a Rest API request.
   * @param request - request to cancel
   * @param [message] - custom error message
   * @return If the request was cancelled
   */
  InternalAPIClass.prototype.cancel = function (request, message) {
    if (this._restApi.hasCancelToken(request)) {
      return this._restApi.cancel(request, message);
    } else if (this._graphqlApi.hasCancelToken(request)) {
      return this._graphqlApi.cancel(request, message);
    }
    return false;
  };
  InternalAPIClass.prototype.getInitWithCustomUserAgentDetails = function (init, action) {
    var customUserAgentDetails = {
      category: Category.API,
      action: action
    };
    var initParams = __assign(__assign({}, init), {
      customUserAgentDetails: customUserAgentDetails
    });
    return initParams;
  };
  /**
   * Getting endpoint for API
   * @param apiName - The name of the api
   * @return The endpoint of the api
   */
  InternalAPIClass.prototype.endpoint = function (apiName) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2 /*return*/, this._restApi.endpoint(apiName)];
      });
    });
  };
  /**
   * to get the operation type
   * @param operation
   */
  InternalAPIClass.prototype.getGraphqlOperationType = function (operation) {
    return this._graphqlApi.getGraphqlOperationType(operation);
  };
  InternalAPIClass.prototype.graphql = function (options, additionalHeaders, customUserAgentDetails) {
    var apiUserAgentDetails = __assign({
      category: Category.API,
      action: ApiAction.GraphQl
    }, customUserAgentDetails);
    return this._graphqlApi.graphql(options, additionalHeaders, apiUserAgentDetails);
  };
  return InternalAPIClass;
}();
export { InternalAPIClass };
export var InternalAPI = new InternalAPIClass(null);
Amplify.register(InternalAPI);