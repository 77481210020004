<div class="main-ag-grid-container flex-column">
    <!-- All Size Distro Exceptions -->
    <mat-card class="h-100">
        <!-- Toolbar -->
        <div class="grid-toolbar">
            <!-- Grid Header -->
            <div class="grid-title">
                <h3>Job Exceptions</h3>
            </div>
            <!-- Notes: currently no actions available -->
            <div class="grid-tool-panel-btn-container"></div>
        </div>
        <!-- Ag Grid -->
        <ag-grid-angular
            id="size-distro-execution-job-exceptions-grid"
            class="ag-theme-alpine ag-grid-container"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
    </mat-card>
</div>
