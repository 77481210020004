// Data object expected from the backend
import {
  DefaultParametersDefaultInputs,
  type DefaultParametersInputsInterface
} from './default-parameters-inputs-interface'

export interface ProxyProductCreationInterface
  extends DefaultParametersInputsInterface {
  params_changes: string[]
  proxy_product_id: string
  proxy_product_sizes: string
  new_attributes: ProductAttributesInterface[]
}

export interface ProductAttributesInterface {
  attribute_family: string
  attribute_value: string
}
export const DefaultNewAttribute = {
  attribute_family: null,
  attribute_value: null
}

export const DefaultProxyProductData = {
  editable: null,
  params_changes: [],
  ...DefaultParametersDefaultInputs,
  proxy_product_id: null,
  proxy_product_sizes: null,
  new_attributes: [{ ...DefaultNewAttribute }]
}
