<!-- #mainGrid -->
<div class="main-ag-grid-container flex-column">
    <!-- Toolbar -->
    <div class="main-ag-grid-button-container">
        <!-- Button Container -->
        <div class="grid-tool-panel-btn-container">
            <!-- Rows Selected User Feedback -->
            <caption *ngIf="selectedStoreIndexes.length > 0">
                {{
                    selectedStoreIndexes.length
                }}
                Job{{
                    selectedStoreIndexes.length === 1 ? '' : 's'
                }}
                Selected
            </caption>
            <!-- Split button container -->
            <span>
                <!-- Create Job -->
                <button
                    mat-raised-button
                    color="accent"
                    class="primary-split-button"
                    (click)="onOpenCreateEditJobsModal(null, 'create')"
                >
                    Create Job
                </button>
                <!-- More Options - Trigger-->
                <button
                    id="split-button"
                    mat-raised-button
                    color="accent"
                    [matMenuTriggerFor]="moreOptions"
                    #tooltip="matTooltip"
                    matTooltip="More Options"
                    matTooltipClass="below"
                >
                    <mat-icon
                        aria-hidden="false"
                        aria-label="More Options"
                        class="m-0"
                        >menu_open</mat-icon
                    >
                </button>
            </span>
            <!-- More Options - Item -->
            <mat-menu #moreOptions="matMenu">
                <!-- On Copy Jobs -->
                <button
                    id="copyJobs"
                    class="menu-panel-accent-btn"
                    mat-menu-item
                    color="accent"
                    (click)="
                        $event.stopPropagation();
                        trigger.closeMenu();
                        onOpenCopyJobsModal($event)
                    "
                    [disabled]="!gridActionsEnabled.copy"
                >
                    Copy Job(s)
                </button>
                <!-- menu divider -->
                <mat-divider> </mat-divider>
                <!--  Approve Jobs button -->
                <button
                    id="approveJobs"
                    class="menu-panel-accent-btn"
                    color="accent"
                    mat-menu-item
                    (click)="
                        $event.stopPropagation();
                        trigger.closeMenu();
                        onApproveJobs($event)
                    "
                    [disabled]="!gridActionsEnabled.approve"
                >
                    Approve Job(s)
                </button>
                <!--  Unapprove Jobs button -->
                <button
                    id="unApproveJobs"
                    class="menu-panel-accent-btn"
                    mat-menu-item
                    color="accent"
                    (click)="
                        $event.stopPropagation();
                        trigger.closeMenu();
                        onUnapproveJobs($event)
                    "
                    [disabled]="!gridActionsEnabled.unapprove"
                >
                    Unapprove Job(s)
                </button>
                <!-- Run Jobs button -->
                <button
                    id="runJobs"
                    class="menu-panel-accent-btn"
                    mat-menu-item
                    color="accent"
                    (click)="
                        $event.stopPropagation();
                        trigger.closeMenu();
                        onRunJobs($event)
                    "
                    [disabled]="!gridActionsEnabled.run"
                >
                    Run Job(s)
                </button>
                <!-- menu divider -->
                <mat-divider> </mat-divider>
                <!-- Confirm popup for deletion a single row  -->
                <app-confirm-popup
                    #confirmPopupDelete
                    (confirmModal)="onDeleteJobs($event)"
                ></app-confirm-popup>
                <!--  Delete button -->
                <button
                    id="deleteJobs"
                    class="menu-panel-warn-btn"
                    mat-menu-item
                    color="warn"
                    (click)="confirmPopupDelete.confirm($event, 'Delete Job')"
                    [disabled]="!gridActionsEnabled.delete"
                >
                    Delete Job(s)
                </button>
            </mat-menu>
        </div>
    </div>
    <mat-card class="h-100">
        <ag-grid-angular
            id="store-index"
            [columnDefs]="columnDefs"
            class="ag-theme-alpine ag-grid-container"
            [gridOptions]="gridOptions"
            [enableRangeSelection]="true"
            [attr.tool-panel]="true"
        ></ag-grid-angular>
    </mat-card>
</div>
