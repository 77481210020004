// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// AWS Imports
// =========================================================
import { type GraphQLSubscription } from '@aws-amplify/api'
import { API, graphqlOperation } from 'aws-amplify'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Axios Imports
// =========================================================
import axios from 'axios'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import {
  ExecuteDeleteAPI,
  ExecuteGetAPI,
  ExecutePutAPI,
  ExecutePostAPI
} from './execute_api-calls'
import {
  type DataStoreIndexJobsInterface,
  type DeleteStoreIndexJobsInterface,
  type ApprovalStoreIndexJobsInterface,
  type CopyStoreIndexJobInterface
} from 'src/app/core/interfaces/data-expected-from-backend/store-index-interfaces'
import { type ResponseSubscription } from '../../../API'
import * as subscriptions from '../../../graphql/subscriptions'
import { FormatKey } from 'src/app/utils/global_functions'
import { type StoreIndexCreateEditJobInterface } from '../interfaces/data-expected-from-backend/store-index-create-edit-job-interface'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class StoreIndexAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Get a list of all stored indexes
  async GetAllStoreIndexes () {
    // the api call path with the parameter string is needed
    const path = '/store_index/grid'
    const messages = {
      success: '---> API Call Successful: Retrieved All Store Indexes',
      error: 'Error retrieving rowData for the Store Index Execution and Management Grid.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success) {
      return await axios
        .get(response.data, {})
        .then(res => {
          console.log('res: ', res)
          return res.data
        })
        .catch(error => {
          console.error('Error retrieving store index data: ', error)
          this.toastAlert.showError('Error Loading Data')
          throw error
        })
    } else {
      return []
    }
  }

  // On approve store index
  async OnApproveJobs (indexIds: ApprovalStoreIndexJobsInterface) {
    // the api call path with the parameter string is needed
    const path = '/store_index/status/approve'
    const totalNumOfJobs = indexIds.index_ids.length
    const suffix = totalNumOfJobs === 1 ? '' : 's'

    const messages = {
      success: `Successfully approved ${totalNumOfJobs} store index job${suffix}.`,
      error: `Error approving ${totalNumOfJobs} store index job${suffix}.`,
      showSuccessToast: true
    }

    return await ExecutePutAPI(path, this, indexIds, messages)
  }

  // On unapprove store index
  async OnUnapproveJobs (indexIds: ApprovalStoreIndexJobsInterface) {
    console.log('indexIds', indexIds)
    // the api call path with the parameter string is needed
    const path = '/store_index/status/unapprove'
    const totalNumOfJobs = indexIds.index_ids.length
    const suffix = totalNumOfJobs === 1 ? '' : 's'
    const messages = {
      success: `Successfully unapproved ${totalNumOfJobs} store index job${suffix}.`,
      error: `Error unapproving ${totalNumOfJobs} store index job${suffix}.`,
      showSuccessToast: true
    }

    return await ExecutePutAPI(path, this, indexIds, messages)
  }

  // On run jobs
  async OnRunJobs (jobNames: DataStoreIndexJobsInterface) {
    // the api call path with the parameter string is needed
    const path = '/jobs/qsi'
    const totalNumOfJobs = jobNames.job_names.length
    const suffix = totalNumOfJobs === 1 ? '' : 's'

    const messages = {
      success: `Successfully ran ${totalNumOfJobs} store index job${suffix}.`,
      error: `Error running ${totalNumOfJobs} store index job${suffix}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, jobNames, messages)
  }

  // Delete store index
  async OnDeleteJobs (storeIndexJobNames: DeleteStoreIndexJobsInterface) {
    console.log('storeIndexJobNames', storeIndexJobNames)
    // the api call path with the parameter string is needed
    const path = '/store_index/jobs/delete_store_index_jobs'
    const totalNumOfJobs = storeIndexJobNames.store_index_job_names.length
    const suffix = totalNumOfJobs === 1 ? '' : 's'

    const messages = {
      success: `Successfully deleted ${totalNumOfJobs} store index job${suffix}.`,
      error: `Error deleting ${totalNumOfJobs} store index job${suffix}.`,
      showSuccessToast: true
    }
    return await ExecuteDeleteAPI(path, this, messages, storeIndexJobNames)
  }

  async CreateStoreIndexJob (data: StoreIndexCreateEditJobInterface) {
    const path = '/store_index/jobs/create'
    const messages = {
      success: `Successfully created job: ${data.job_name}.`,
      error: `Error creating job: ${data.job_name}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async SaveStoreIndexJob (data: StoreIndexCreateEditJobInterface) {
    const path = '/store_index/jobs/edit'
    const messages = {
      success: `Successfully saved job: ${data.job_name}.`,
      error: `Error saving job: ${data.job_name}.`,
      showSuccessToast: true
    }

    return await ExecutePutAPI(path, this, data, messages)
  }

  async CopyStoreIndexJob (data: CopyStoreIndexJobInterface) {
    const path = '/store_index/jobs/copy'
    const totalNumOfJobs = data.jobs_to_copy.length
    const suffix = totalNumOfJobs === 1 ? '' : 's'

    const messages = {
      success: `Successfully copied ${totalNumOfJobs} store index job${suffix}.`,
      error: `Error copying ${totalNumOfJobs} store index job${suffix}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async JobGridDataSubscription (gridApi) {
    console.log('inside JobGridDataSubscription')
    // console.log("inside jobsToSubscribeTo", jobsToSubscribeTo)
    const subscription = API.graphql<
    GraphQLSubscription<ResponseSubscription>
    >(graphqlOperation(subscriptions.response, {})).subscribe({
      next: ({ provider, value }) => {
        console.log('inside subscriber next', value)
        // if the result of the subscription has the same job_name that we triggered the subscription for

        // get the data
        console.log('inside job_name', value.data?.response?.job_name)
        console.log('inside status', value.data?.response?.status)
        console.log(
          'error_message',
          value.data?.response?.error_message
        )
        if (gridApi && !gridApi['destroyCalled']) {
          const rowData = gridApi.getRowNode(
            FormatKey(value?.data?.response?.job_name)
          )
          if (rowData) {
            gridApi.applyTransactionAsync({
              update: [
                {
                  ...rowData.data,
                  status: value?.data?.response?.status,
                  error_message:
                                        value?.data?.response?.error_message
                }
              ]
            })
          }
        }
      },
      error: error => console.warn(error)
    })
  }
}
