// Angular and RJX Imports
// =========================================================
import { Component, type OnDestroy, type OnInit } from '@angular/core'
import { cloneDeep } from 'lodash'
import { BehaviorSubject } from 'rxjs'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Ag Grid Imports
// =========================================================
import {
  type GridReadyEvent,
  type GridApi,
  type ColumnApi,
  type RowDataUpdatedEvent,
  CellValueChangedEvent
} from 'ag-grid-community'
// JSON Imports
// =========================================================
import $DistrosColDefsJSON from '../../../05_ag-grid-configs/01_json-grid-configs/size-range-alterations/colDefs-main-sra-distros.json'
import $AlterationsColDefsJSON from '../../../05_ag-grid-configs/01_json-grid-configs/size-range-alterations/colDefs-main-sra-alterations.json'
// Custom Imports
// =========================================================
import { CustomGridOptions } from 'src/app/05_ag-grid-configs/02_global-settings/grid-options'
import { SRA_formSettings } from 'src/app/03_shared-components/forms/form-data/size-range-alterations/size-range-alterations'
import {
  SRA_alterations_customCellFormatting,
  SRA_distros_customCellFormatting,
  SRA_GenerateGridData
} from 'src/app/05_ag-grid-configs/04_generate-colDefs/size-range-alterations-colDefs'
import { ResetFormData } from 'src/app/03_shared-components/forms/form-data/form-data-reset'
import { SizeRangeAlterationsAPIs } from 'src/app/core/apis/size-range-alterations_api-calls'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { IsKeyInObj } from 'src/app/utils/global_functions'

@Component({
  selector: 'app-size-range-alterations-modal',
  templateUrl: './size-range-alterations-modal.component.html',
  styleUrls: ['./size-range-alterations-modal.component.scss']
})
export class SizeRangeAlterationsModalComponent implements OnInit, OnDestroy {
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this
    }
  }

  subTitle: string = 'Selected Size Range: '
  loadingMessage: string = 'loading'
  loading: boolean = true
  submittingData: boolean = false
  // AG Grid Options:
  gridOptions: any = {}

  // Selected Distros Ag Grid Configuration
  public distrosColDefsJSON = $DistrosColDefsJSON
  distros_gridOptions: any = {}
  distros_gridApi: GridApi
  distros_columnApi: ColumnApi
  is_distros_AgGridLoading: boolean = true
  distros_columnDefs: any[] = []

  // Selected Distros Ag Grid Configuration
  public alterationsColDefsJSON = $AlterationsColDefsJSON
  alterations_gridOptions: any = {}
  alterations_gridApi: GridApi
  alterations_columnApi: ColumnApi
  is_alterations_AgGridLoading: boolean = true
  alterations_columnDefs: any[] = []

  // Form Info
  default_SRA_formSettings = cloneDeep({ ...SRA_formSettings })
  add_size_form = new BehaviorSubject<any>(SRA_formSettings.add_size_config)
  get _add_size_form () {
    return this.add_size_form.getValue()
  }

  add_size_by_size_form = new BehaviorSubject<any>(
    SRA_formSettings.add_size_by_size_config
  )

  get _add_size_by_size_form () {
    return this.add_size_by_size_form.getValue()
  }

  remove_sizes_form = new BehaviorSubject<any>(
    SRA_formSettings.remove_sizes_config
  )

  get _remove_sizes_form () {
    return this.remove_sizes_form.getValue()
  }

  merge_sizes_form = new BehaviorSubject<any>(
    SRA_formSettings.merge_sizes_config
  )

  get _merge_sizes_form () {
    return this.merge_sizes_form.getValue()
  }

  split_sizes_form = new BehaviorSubject<any>(
    SRA_formSettings.split_sizes_config
  )

  get _split_sizes_form () {
    return this.split_sizes_form.getValue()
  }

  ObjectKeys = Object.keys
  // Available Sizes for Alterations
  newSizes: string[] = []
  sizeOrder: string[] = []
  alterations = {
    distro_ids: [],
    alterations: {
      add_size: {},
      add_size_by_size: {},
      merge_sizes: {},
      split_sizes: {},
      sizes_to_remove: [],
      locked_sizes: []
    }
  }

  // Data from parent grid
  parentGridData: any
  existingSizeRange: string[] = []
  alterationsDataError: boolean = false
  alterationsFunctionErrors = {
    add_size: false,
    add_size_by_size_pct: false,
    add_size_by_size_model_size: [],
    merge_sizes_existing_size_1: [],
    merge_sizes_existing_size_2: [],
    split_sizes: [],
    remove_sizes: []
  }

  allSizesLocked: boolean = false
  duplicateIds: string[] = []

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public sizeRangeAlterationsAPIs: SizeRangeAlterationsAPIs,
    public generalAPIs: GeneralAPIs
  ) {
    console.log('---> Size Range Alterations Modal: ', config?.data)
    this.parentGridData = {
      ...this.config?.data?.componentParent,
      rowData: config?.data.rowData
    }
    const sizeRange: string = (
      this.parentGridData.trackedGridSelections?.size_range || ''
    ).toString()
    this.subTitle = this.subTitle + sizeRange
    this.existingSizeRange = sizeRange.includes('|')
      ? sizeRange.split('|')
      : [sizeRange]
    console.log('---> existing sizes: ', this.existingSizeRange)

    // Set the grid options for the selected distros grid
    this.distros_gridOptions = {
      ...this.customGridOptions,
      context: {
        ...this.customGridOptions.context,
        pageTitle: 'Size Range Alterations - Selected Distros'
      }
    }
    // Set the grid options for the size range alterations grid
    this.alterations_gridOptions = {
      ...this.customGridOptions,
      context: {
        ...this.customGridOptions.context,
        pageTitle: 'Size Range Alterations - Summary',
        onDeleteAlteration: async event =>
          await this.onDeleteAlteration(event),
        onLockAlteration: event => this.onLockAlteration(event)
      },
      getRowId: node => node.data.size,
      onRowDataUpdated: async (event: RowDataUpdatedEvent) => {
        console.log('rowData updated: ', event)
        const nodeIds = []
        this.duplicateIds = []
        try {
          await event.api.forEachNode(node => {
            const size = node.data.size
            if (nodeIds.includes(size)) {
              this.duplicateIds.push(size)
            }
            nodeIds.push(size)
          })
        } finally {
          console.log(
            '---> Validating Alterations - No Duplicate Sizes: ',
            nodeIds.length === new Set(nodeIds).size
          )
          this.alterationsDataError =
                        nodeIds.length !== new Set(nodeIds).size
          // Update the size sorting when new sizes are added
          event.columnApi.applyColumnState({
            state: [{ colId: 'size', sort: 'asc' }]
          })
          event.api.redrawRows()
        }
      }
    }
  }

  ngOnInit () {
    this.updateFormSelections()
    // Update the export details for the Size Range Alterations table
    this.alterations_gridOptions.context.pageTitle =
            'Size Range Alterations - Current Alterations'
    this.alterations_gridOptions.context.gridKey = 'alterations'
    // Update the export details for the Selected distros table
    this.distros_gridOptions.context.pageTitle =
            'Size Range Alterations - Selected Rows'
    this.distros_gridOptions.context.gridKey = 'distros'
  }

  async updateFormSelections () {
    console.log('---> Updating Form Selections')
    try {
      this.alterations.distro_ids =
                this.parentGridData?.rowData?.map(data => data.distro_id) || []
      if (this.alterations.distro_ids.length > 0) {
        console.log(
          '---> Size Range Alterations Distro IDs: ',
          this.alterations.distro_ids
        )
        await this.sizeRangeAlterationsAPIs
          .GetAvailableSizes(this.alterations.distro_ids)
          .then(res => {
            console.log('---> Available Size Range: ', res)
            if (res.is_success) this.newSizes = res.data
          })
        await this.generalAPIs.GetAllSizesAndOrder().then(res => {
          console.log('---> Size Order: ', res)
          this.sizeOrder = res
          if (this.newSizes.length > 0) {
            this.newSizes = this.sizeOrder.filter(size =>
              this.newSizes.includes(size)
            )
          }
        })
      }
    } catch (error) {
      console.error('---> error getting form data: ', error)
    } finally {
      //  Update form settings
      this.updateFormSettings('All')
    }
  }

  updateFormSettings (formsToUpdate: string) {
    //  Update form settings
    const {
      add_size_config,
      add_size_by_size_config,
      remove_sizes_config,
      merge_sizes_config,
      split_sizes_config
    } = cloneDeep({ ...this.default_SRA_formSettings })
    this.existingSizeRange = this.sizeOrder.filter(size =>
      this.existingSizeRange.includes(size)
    )
    const hasNewSizes = this.newSizes.length > 0
    if (this.existingSizeRange.length > 0) {
      // Update Add Size Form
      const add_size = () => {
        add_size_config.add_size.selectionOptions = this.newSizes
        add_size_config.add_size.disabled = !hasNewSizes
        add_size_config.pct.disabled = !hasNewSizes
        this.add_size_form.next(add_size_config)
      }
      // Update the Add Size by Size Form
      const add_size_by_size = () => {
        add_size_by_size_config.add_size_by_size.selectionOptions =
                    this.newSizes
        add_size_by_size_config.add_size_by_size.disabled =
                    !hasNewSizes
        add_size_by_size_config.size_to_model_after.selectionOptions =
                    this.existingSizeRange
        add_size_by_size_config.size_to_model_after.disabled =
                    !hasNewSizes
        add_size_by_size_config.pct_of_model.disabled = !hasNewSizes

        this.add_size_by_size_form.next(add_size_by_size_config)
      }
      // Update the Remove Sizes Form
      const remove_sizes = () => {
        remove_sizes_config.remove_sizes.selectionOptions =
                    this.existingSizeRange
        this.remove_sizes_form.next(remove_sizes_config)
      }
      // Update the Merge Sizes Sizes Form
      const merge_sizes = () => {
        merge_sizes_config.existing_size_1.selectionOptions =
                    this.existingSizeRange
        merge_sizes_config.existing_size_1.disabled = !hasNewSizes
        merge_sizes_config.existing_size_2.selectionOptions =
                    this.existingSizeRange
        merge_sizes_config.existing_size_2.disabled = !hasNewSizes
        merge_sizes_config.merge_sizes.selectionOptions = this.newSizes
        merge_sizes_config.merge_sizes.disabled = !hasNewSizes
        this.merge_sizes_form.next(merge_sizes_config)
      }
      // Update the Split Sizes Sizes Form
      const split_sizes = () => {
        split_sizes_config.split_sizes.selectionOptions =
                    this.existingSizeRange
        split_sizes_config.split_sizes.disabled = !hasNewSizes
        split_sizes_config.new_size_1.selectionOptions = this.newSizes
        split_sizes_config.new_size_1.disabled = !hasNewSizes
        split_sizes_config.new_size_2.selectionOptions = this.newSizes
        split_sizes_config.new_size_2.disabled = !hasNewSizes
        this.split_sizes_form.next(split_sizes_config)
      }
      switch (formsToUpdate) {
        case 'All':
          add_size()
          add_size_by_size()
          remove_sizes()
          merge_sizes()
          split_sizes()
          break
      }

      this.loading = false
    }
  }

  onGridReady (event: GridReadyEvent, grid: string) {
    this[`${grid}_gridApi`] = event.api
    this[`${grid}_columnApi`] = event.columnApi
    this[`is_${grid}_AgGridLoading`] = false
    if (grid === 'distros') {
      const rowData = this.parentGridData?.rowData?.map(data => {
        const { distro_id, styc_name } = data
        return { distro_id, styc_name }
      })
      this.renderGrid(this.distrosColDefsJSON[0], rowData, grid)
    } else {
      const rowData = this.existingSizeRange.map(size => {
        return {
          size,
          existing_size: true,
          alteration: null,
          is_locked: false,
          pct: null,
          size_to_model_after: null,
          pct_of_model: null
        }
      })
      this.renderGrid(this.alterationsColDefsJSON[0], rowData, grid)
    }
  }

  // Render AG Grid Column Definitions
  async renderGrid (mainColDefs, rowData, gridToRender) {
    const params = {
      mainColDefs,
      customCellFormatting:
                gridToRender === 'distros'
                  ? SRA_distros_customCellFormatting
                  : SRA_alterations_customCellFormatting
    }

    const gridData = await SRA_GenerateGridData(params)

    if (gridData) {
      this[`${gridToRender}_columnDefs`] = gridData.mainColDefs

      const checkAgGrid = () => {
        if (!this[`is_${gridToRender}_AgGridLoading`]) {
          this[`${gridToRender}_gridApi`]?.setColumnDefs(
            gridData.mainColDefs
          )
          this[`${gridToRender}_gridApi`]?.setRowData(rowData)
          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  // Form Changes
  onFormUpdated (form, formName?) {
    console.log('---> Size Alterations Form Updated: ', form)
    console.log('---> Size Alterations Form formName: ', formName)

    // const field = form.controlName
    // const value = cloneDeep(form.value)
    const { status, controlName, value } = cloneDeep(form)
    const formData = this[`${formName}_form`].getValue()
    const currentVal = cloneDeep(formData[controlName].currentVal)

    switch (status) {
      case 'VALID':
        console.log('valid response', form)
        console.log('formData', formData)

        if (value !== currentVal) {
          formData[controlName].currentVal = value
          // this[`${formName}_form`].next(formData)
        }
        break
      case 'DISABLED':
        console.log('field is disabled: ', form)
        break
      default:
        console.error('field error: ', form)
    }
    this.checkIsFormValid(form, formName)
  }

  checkIsFormValid (form, formName?) {
    console.log('--> Form Validation for: ', formName)
    console.log('--> status: ', form.status)
    console.log(
      '---> alterations.alterations: ',
      this.alterations.alterations
    )
    const { status, controlName, value } = cloneDeep(form)
    const checkAppliedAlterations = errorKey => {
      this.alterationsFunctionErrors[errorKey] = []
      this.alterations_gridApi.forEachNode(node => {
        const { size, alteration } = node.data
        const getSizeToCheck = alteration => {
          switch (alteration) {
            case 'add_size_by_size':
              return [node.data.size_to_model_after]
            case 'merge_sizes':
              return [
                node.data.existing_size_1,
                node.data.existing_size_2
              ]
            case 'split_sizes':
              return [node.data.existing_size]
            case 'remove_sizes':
              return this.alterations.alterations.sizes_to_remove
            default:
              return [size]
          }
        }
        const sizesToCheck = getSizeToCheck(alteration)

        sizesToCheck.forEach(s => {
          console.log('--> alteration: ', alteration)
          console.log('--> size to check:  ', s)
          console.log(
            '--> alteration 2: ',
            this.alterationsFunctionErrors
          )

          if (
            value &&
                        value.length > 0 &&
                        ((typeof value === 'string' && value === s) ||
                            (Array.isArray(value) && value.includes(s)))
          ) {
            if (
              !this.alterationsFunctionErrors[errorKey].includes(
                s
              ) &&
                            node.data.alteration !== null
            ) {
              console.log(
                '--> add error size: ',
                this.alterationsFunctionErrors
              )

              this.alterationsFunctionErrors[errorKey].push(s)
            }
          }
        })
      })
    }

    switch (formName) {
      case 'add_size':
        this.alterationsFunctionErrors[formName] = status !== 'VALID'
        break
      case 'add_size_by_size':
        if (controlName === 'pct_of_model') {
          this.alterationsFunctionErrors[`${formName}_pct`] =
                        status !== 'VALID'
        } else if (controlName === 'size_to_model_after') {
          const errorKey = `${formName}_model_size`
          checkAppliedAlterations(errorKey)
        }
        break
      case 'remove_sizes':
        checkAppliedAlterations(formName)
        break
      case 'split_sizes':
        if (controlName === 'split_sizes') {
          checkAppliedAlterations(formName)
        }
        break
      case 'merge_sizes':
        if (
          controlName === 'existing_size_1' ||
                    controlName === 'existing_size_2'
        ) {
          checkAppliedAlterations(`${formName}_${controlName}`)
        }
        break
    }
  }

  async onSubmitForm ($event: any, alteration: string, controls: string[]) {
    console.log('---> controls: ', controls)
    console.log('---> alteration: ', alteration)
    // console.log('---> THIS: ', this[`${alteration}_form`].getValue() )

    const formData = cloneDeep({
      ...this[`${alteration}_form`].getValue()
    })

    const size = formData[alteration].currentVal
    let alterationInfo = {}
    // console.log("size: ", size)

    try {
      console.log(`---> Submitting ${alteration} Form Data: `, formData)
      alterationInfo = {
        is_locked: false,
        alteration
      }
      // console.log(
      //           `---> Submitting ${alteration}  Alteration Info: `,
      //           alterationInfo
      // )
      // console.log('---> THIS: ', ...this[`${alteration}_form`].getValue() )

      await controls.forEach(
        control =>
          (alterationInfo[control] = formData[control].currentVal)
      )
      if (alteration !== 'split_sizes') {
        await this.alterations_gridApi.applyTransactionAsync({
          add: [{ ...alterationInfo, size, existing_size: false }]
        })

        // Set existing sizes alterations to merge
        if (alteration === 'merge_sizes') {
          await this.alterations_gridApi.forEachNode(node => {
            if (
              node?.data.size ===
                                alterationInfo['existing_size_1'] ||
                            node?.data.size ===
                                alterationInfo['existing_size_2']
            ) {
              this.alterations_gridApi.applyTransactionAsync({
                update: [{ ...node.data, ...alterationInfo }]
              })
            }
          })
        }
      } else if (alteration === 'split_sizes') {
        const existingSize = this.alterations_gridApi.getRowNode(size)
        await this.alterations_gridApi.applyTransactionAsync({
          add: [
            {
              ...alterationInfo,
              size: alterationInfo['new_size_1'],
              existing_size: false
            },
            {
              ...alterationInfo,
              size: alterationInfo['new_size_2'],
              existing_size: false
            }
          ],
          update: [
            {
              ...existingSize.data,
              size: alterationInfo['split_sizes'],
              ...alterationInfo
            }
          ]
        })
      }
    } finally {
      this.updateFormSettings('All')
      this.alterations.alterations[alteration][size] = alterationInfo
      console.log('---> Alterations to submit: ', this.alterations)
      ResetFormData(
        {
          form: this[`${alteration}_form`],
          currentFormData: formData,
          originalFormData:
                        this.default_SRA_formSettings[`${alteration}_config`]
        },
        true
      )
    }
  }

  onRemoveSizes (event, formName) {
    console.log('---> Removing Sizes: ', this[`_${formName}`])

    const { currentVal } = this[`_${formName}`].remove_sizes
    const formData = { ...this[formName].getValue() }
    const removeSizes = this.alterations.alterations.sizes_to_remove

    if (currentVal) {
      currentVal.forEach(size => {
        const rowNode = this.alterations_gridApi.getRowNode(size)
        if (removeSizes.includes(size)) {
          this.alterations.alterations.sizes_to_remove.splice(
            removeSizes.indexOf(size),
            1
          )
          this.alterations_gridApi.applyTransactionAsync({
            update: [{ ...rowNode.data, alteration: null }]
          })
        } else {
          this.alterations.alterations.sizes_to_remove.push(size)
          this.alterations_gridApi.applyTransactionAsync({
            update: [{ ...rowNode.data, alteration: 'remove' }]
          })
        }
      })
      this.updateFormSettings('All')
      ResetFormData(
        {
          form: this[formName],
          currentFormData: formData,
          originalFormData:
                        this.default_SRA_formSettings[
                            `${formName.replace('form', 'config')}`
                        ]
        },
        true
      )
    }
  }

  // Removing alterations from the summary grid
  async onDeleteAlteration (event) {
    console.log('---> Deleting Size Alteration: ', event)
    console.log('---> Alterations to submit: ', this.alterations)
    const { size } = event.data
    const rowNode = event.api.getRowNode(size)
    const { alteration, existing_size } = rowNode.data
    // console.log('---> Row Node: ', rowNode);
    // console.log('---> Is Existing Size: ', existing_size);
    // console.log('---> Alteration: ', alteration);
    try {
      if (size && alteration) {
        switch (alteration) {
          case 'split_sizes':
            const { new_size_1, new_size_2, split_sizes } =
                            rowNode.data
            const existingSplitSize = existing_size
              ? rowNode
              : event.api.getRowNode(split_sizes)
            this.alterations_gridApi.applyTransactionAsync({
              update: [
                { ...existingSplitSize.data, alteration: null }
              ],
              remove: [
                { size: new_size_1 },
                { size: new_size_2 }
              ]
            })
            delete this.alterations.alterations[alteration][
              split_sizes
            ]
            break
          case 'merge_sizes':
            const {
              existing_size_1,
              existing_size_2,
              merge_sizes
            } = rowNode.data
            const exSize_1_node =
                            existing_size_1 === size
                              ? rowNode
                              : event.api.getRowNode(existing_size_1)
            const exSize_2_node =
                            existing_size_2 === size
                              ? rowNode
                              : event.api.getRowNode(existing_size_2)

            this.alterations_gridApi.applyTransactionAsync({
              update: [
                {
                  ...exSize_1_node.data,
                  alteration: null
                },
                {
                  ...exSize_2_node.data,
                  alteration: null
                }
              ],
              remove: [{ size: merge_sizes }]
            })
            delete this.alterations.alterations[alteration][
              merge_sizes
            ]
            break
          default:
            if (existing_size) {
              if (alteration === 'remove') {
                const removeSizes =
                                    this.alterations.alterations
                                      .sizes_to_remove
                if (removeSizes.includes(size)) {
                  this.alterations.alterations.sizes_to_remove.splice(
                    removeSizes.indexOf(size),
                    1
                  )
                }
              }

              this.alterations_gridApi.applyTransactionAsync({
                update: [{ ...rowNode.data, alteration: null }]
              })
            } else {
              delete this.alterations.alterations[alteration][
                size
              ]
              this.alterations_gridApi.applyTransactionAsync({
                remove: [{ size }]
              })
            }

            break
        }
      }
      this.updateFormSettings('All')
    } finally {
      const getSize = () => {
        switch (alteration) {
          case 'merge_sizes':
            return rowNode.data.merge_sizes
          case 'split_sizes':
            return rowNode.data.split_sizes
          default:
            return size
        }
      }
      const altSize = getSize()
      // Check to make sure all duplicates are removed from the object that gets sent to the backend
      if (
        [...new Set(this.duplicateIds)].length > 0 &&
                this.duplicateIds.includes(altSize)
      ) {
        const a = this.alterations.alterations
        Object.keys(a).forEach(key => {
          if (Array.isArray(a[key]) && a[key].includes(altSize)) {
            this.alterations.alterations[key].splice(
              a[key].indexOf(altSize),
              1
            )
          } else if (
            typeof a[key] === 'object' &&
                        IsKeyInObj(a[key], altSize)
          ) {
            delete this.alterations.alterations[key][altSize]
          }
        })
      }
    }
  }

  onLockAlteration (event) {
    console.log('---> Locking Size Alteration: ', event)
    console.log('---> Alterations to submit: ', this.alterations)
    const { size } = event.data
    const lockedSizes = this.alterations.alterations.locked_sizes
    if (lockedSizes.includes(size)) {
      this.alterations.alterations.locked_sizes.splice(
        lockedSizes.indexOf(size),
        1
      )
      this.allSizesLocked = false
    } else {
      this.alterations.alterations.locked_sizes.push(size)
    }
  }

  mapValues = key => Object.values(key).map(val => val)

  async onSubmitAlterations (event) {
    console.log('---> Submitting Size Range Alterations')
    console.log('---> Alterations to submit: ', this.alterations)
    let rowCount = null
    try {
      await this.alterations_gridApi.setFilterModel(null)
      rowCount = this.alterations_gridApi.getDisplayedRowCount()
    } finally {
      if (
        rowCount &&
                rowCount !== this.alterations.alterations.locked_sizes.length
      ) {
        const { add_size, add_size_by_size, merge_sizes, split_sizes } =
                    this.alterations.alterations

        this.loadingMessage = 'submitting'
        this.submittingData = true
        const formattedData = {
          ...this.alterations,
          alterations: {
            ...this.alterations.alterations,
            add_size: this.mapValues(add_size),
            add_size_by_size: this.mapValues(add_size_by_size),
            merge_sizes: this.mapValues(merge_sizes),
            split_sizes: this.mapValues(split_sizes)
          }
        }
        await this.sizeRangeAlterationsAPIs
          .SubmitSizeAlterations(formattedData)
          .then(res => {
            if (res.is_success) {
              this.close({
                message: 'success',
                formattedData
              })
            } else {
              this.close()
            }
            this.submittingData = false
            this.loadingMessage = 'loading'
          })
      } else {
        this.allSizesLocked = true
      }
    }
  }

  close (data?) {
    if (this.ref) this.ref.close(data || null)
  }

  async ngOnDestroy () {
    const forms = [
      'add_size_form',
      'add_size_by_size_form',
      'remove_sizes_form',
      'merge_sizes_form',
      'split_sizes_form'
    ]
    forms.forEach(async form => {
      const formSettings =
                this.default_SRA_formSettings[
                    `${form.replace('form', 'config')}`
                ]
      await ResetFormData(
        {
          form: this[form],
          currentFormData: formSettings,
          originalFormData: formSettings
        },
        true
      )
    })
  }
}
