// Angular Imports
// =========================================================
import { Component, Input, type OnInit } from '@angular/core'
// Moment Imports
// =========================================================
import moment from 'moment'
// AG Grid Imports
// =========================================================
import { ICellRendererParams } from 'ag-grid-community'

@Component({
  selector: 'app-job-management-status-alert',
  templateUrl: './job-management-status-alert.component.html',
  styleUrls: ['./job-management-status-alert.component.scss']
})
export class JobManagementStatusAlertComponent implements OnInit {
  @Input() cellParams: ICellRendererParams
  @Input() alertKey: string

  alertLabel: string = ''
  timeOfError: string = null
  ngOnInit (): void {
    // console.log('Job Management Alert Popover: ', this.cellParams)
    // console.log('Alert Popover Key: ', this.alertKey)
    this.timeOfError =
            moment(this.cellParams?.data?.finished_at).format('LLL') ||
            'No results found.'
    switch (this.alertKey) {
      case 'failed':
        this.alertLabel = 'WARNING'
        break
      case 'completed with errors':
        this.alertLabel = 'JOB COMPLETED WITH ERRORS'
        this.cellParams.data.error_message =
                    'Select the error icon to view the job exceptions report.'
        break
      default:
        this.alertLabel = 'JOB COMPLETED WITH ERRORS'
    }
  }
}
