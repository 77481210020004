// Angular and RJX Imports
// =========================================================
import { Component, type OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { cloneDeep } from 'lodash'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { DistroBulkOverrideFormSettings } from 'src/app/03_shared-components/forms/form-data/distro-bulk-override'
import { SizeDistroManagementSummaryAPIs } from 'src/app/core/apis/size-distro-management-summary_api-calls'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
@Component({
  selector: 'app-bulk-overrides-modal',
  templateUrl: './bulk-overrides-modal.component.html',
  styleUrls: ['./bulk-overrides-modal.component.scss']
})
export class BulkOverridesModalComponent implements OnInit {
  loading: boolean = true
  loadingMessage: string = 'loading'

  // Import the original form settings and track updates
  formData = new BehaviorSubject<any>(DistroBulkOverrideFormSettings)
  get _formData () {
    return this.formData.getValue()
  }

  completedBulkOverride: any = {
    action: 'Size Distro To Size Distro',
    distro_ids: [], // ONLY SEND for store to store
    source: null, // string (singular)
    destination: null // array
  }

  parentGridData: any
  overrideActions: string[] = [
    'Size Distro To Size Distro',
    'Store To Store'
  ]

  overrideAction: string = null
  // Locations For Store to Store Option ONLY
  storeLocationOptions: string[] = []
  // Track status types to disable the 'store to store' action if a row was selected with a status of approved
  rowDataIncludesApprovedStatus: boolean = false

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private readonly sizeDistroManagementSummaryAPIs: SizeDistroManagementSummaryAPIs,
    public triggerApiCallsService: TriggerApiCallsService,
    public toastAlert: ToastAlertComponent
  ) {
    console.log('---> Bulk Override Modal: ', config.data)
    this.parentGridData = {
      ...this.config?.data?.componentParent,
      rowData: config?.data.rowData.map(data => {
        this.completedBulkOverride.distro_ids.push(data.distro_id)
        if (data?.status?.toLowerCase() === 'approved') {
          this.rowDataIncludesApprovedStatus = true
        }

        return data
      })
    }
  }

  async ngOnInit () {
    try {
      if (this.parentGridData.rowData.length === 1) {
        this.overrideAction = 'Store To Store'
        if (this.rowDataIncludesApprovedStatus) {
          this.toastAlert.showWarn(
            'Unable to use distros with a status of approved.'
          )
        } else {
          await this.getLocations()
        }
      } else {
        this.overrideAction = 'Size Distro To Size Distro'
      }
    } catch (error) {
      console.error('---> Error Setting Bulk Override Data')
    } finally {
      // Update Form settings
      this.onOverrideActionChanged({ value: this.overrideAction })
    }
  }

  async getLocations () {
    const distroIds =
            await this.sizeDistroManagementSummaryAPIs.GetDistroLocations({
              distro_ids: this.completedBulkOverride.distro_ids
            })
    if (distroIds && distroIds?.data.length > 0) {
      this.storeLocationOptions = distroIds?.data
      // Custom sorting function
      this.storeLocationOptions.sort((a, b) => {
        if (a === '-1' && b === '-1') {
          return 0 // Keep -1 in its current position
        } else if (a === '-1') {
          return -1 // Move -1 to the top
        } else if (b === '-1') {
          return 1 // Move -1 to the top
        } else {
          // Use localeCompare for mixed type comparison
          return String(a).localeCompare(String(b), undefined, {
            numeric: true
          })
        }
      })
      return true
    }
    return true
  }

  async onOverrideActionChanged (event) {
    console.log('---> Override Action Changed: ', event.value)
    const action = event.value
    const tempForm = this._formData
    this.completedBulkOverride.action = action
    this.loading = true
    try {
      console.log('---> Current Form Settings: ', tempForm)
      console.log('---> Data to submit: ', this.completedBulkOverride)

      if (action === 'Size Distro To Size Distro') {
        let sizeRangeArr
        let startSize
        let endSize

        tempForm.source = {
          ...tempForm.source,
          label: 'Source STYC',
          disabled: false,
          currentVal: null,
          // selectionOptions: this.completedBulkOverride.distro_ids
          selectionOptions: this.parentGridData.rowData.map(
            (d, i) => {
              if (i === 0) {
                sizeRangeArr = d.size_range.includes('|')
                  ? d.size_range.split('|')
                  : [d.size_range]
                startSize = sizeRangeArr[0]
                endSize =
                                    sizeRangeArr.length > 1
                                      ? sizeRangeArr[sizeRangeArr.length - 1]
                                      : startSize
              }
              return `${d.distro_id}; ${d.buying_season}; ${d.styc_id} - ${d.styc_name}; ${startSize}-${endSize} (${sizeRangeArr.length})`
            }
          )
        }
        tempForm.destination = {
          ...tempForm.destination,
          disabled: true,
          currentVal: null,
          selectionOptions: []
        }
        this.formData.next(tempForm)
      } else if (action === 'Store To Store') {
        const locationsPopulated =
                    this.storeLocationOptions.length === 0
                      ? await this.getLocations()
                      : true

        if (locationsPopulated) {
          tempForm.source = {
            ...tempForm.source,
            label: 'Source Store',
            disabled: this.storeLocationOptions.length === 0,
            currentVal: null,
            selectionOptions: this.storeLocationOptions
          }
          tempForm.destination = {
            ...tempForm.destination,
            disabled: true,
            currentVal: null,
            selectionOptions: []
          }
          this.formData.next(tempForm)
        }
      }
    } finally {
      this.loading = false
    }
  }

  updateFormValue (form, index?) {
    const field = form.controlName
    const value = cloneDeep(form.value)
    console.log('---> Bulk Override Form Settings:', this._formData)

    this.completedBulkOverride[field] = value

    // Remove the Selected source from the destination options
    if (
      field === 'source' &&
            this._formData[field].selectionOptions.length > 0 &&
            this._formData[field].currentVal !== value
    ) {
      const tempForm = this._formData
      this.completedBulkOverride.destination = null
      tempForm[field].currentVal = value
      tempForm.destination = {
        ...tempForm.destination,
        disabled: false,
        currentVal: null,
        selectionOptions: value
          ? tempForm[field].selectionOptions.filter(
            opt => opt !== value
          )
          : []
      }

      console.log('---> Updating Bulk Override Form:', tempForm)
      this.formData.next(tempForm)
    }
  }

  // Submit distros to copy
  async onSubmitBulkOverride (event) {
    this.loadingMessage = 'submitting'
    this.loading = true
    const resetLoader = () => {
      this.loading = false
      this.loadingMessage = 'loading'
    }

    console.log('---> Submit Bulk Override: ')
    const { distro_ids, source, destination } = this.completedBulkOverride

    switch (this.overrideAction) {
      case 'Size Distro To Size Distro':
        this.sizeDistroManagementSummaryAPIs
          .onSubmitBulkOverrideSize({
            source_distro_id: source.split(';')[0],
            target_distro_ids: destination.map(
              dest => dest.split(';')[0]
            )
          })
          .then(res => {
            if (res?.is_success) {
              this.close({
                message: 'success',
                data: this.completedBulkOverride
              })
            } else {
              resetLoader()
              this.close()
            }
          })

        break
      case 'Store To Store':
        console.log(
          '---> Store to store Distros to send: ',
          distro_ids
        )
        this.sizeDistroManagementSummaryAPIs
          .onSubmitBulkOverrideStore({
            source_location_id: source,
            target_location_ids: destination,
            distro_ids
          })
          .then(res => {
            if (res?.is_success) {
              this.close({
                message: 'success',
                data: this.completedBulkOverride
              })
            } else {
              resetLoader()
              this.close()
            }
          })
        break
      default:
        console.log('---> Unknown Action')
    }
  }

  close (data?: any) {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.ref) this.ref.close(data || null)
  }
}
