<div class="main-ag-grid-container flex-column">
    <mat-divider> </mat-divider>

    <mat-card class="h-100">
        <!-- User management -->
        <ag-grid-angular
            class="ag-theme-alpine ag-grid-container"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)"
            [postProcessPopup]="postProcessPopup"
        >
        </ag-grid-angular>
    </mat-card>
</div>
