// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
// Custom Imports
// =========================================================
import { type JobManagementsGridRowInterface } from '../interfaces/data-expected-from-backend/job-management-interfaces'

@Injectable({
  providedIn: 'root'
})
export class JobManagementCreatorService {
  private readonly selectedJobDetails = new BehaviorSubject<any>({})

  public selectedJobDetails$ = this.selectedJobDetails.asObservable()
  _selectedJobDetails: JobManagementsGridRowInterface

  constructor () {
    this.selectedJobDetails.subscribe({
      next: data => {
        if (!data || Object.keys(data).length === 0) {
          this.updateSessionStorage({})
        } else {
          this._selectedJobDetails = data
        }
      }
    })
  }

  onSelectedJobDetails (jobDetails) {
    console.log('---> Updating Selected Job Details')
    this._selectedJobDetails = jobDetails?.data || {}
    this.updateSessionStorage(this._selectedJobDetails)
    return this.selectedJobDetails.next(this._selectedJobDetails)
  }

  getSelectedJobDetails () {
    return this.selectedJobDetails$
  }

  // Use session storage to track the selected row incase of refresh - if no row Data exists the user should be directed back to the management screen
  updateSessionStorage (newData) {
    console.log('---> Updating Job Management Session Storage')

    const storedData = sessionStorage.getItem('selected_job')

    if (storedData) {
      const updatedData = {
        ...JSON.parse(storedData),
        ...newData
      }
      sessionStorage.setItem('selected_job', JSON.stringify(updatedData))
      this.selectedJobDetails.next(updatedData)
    } else {
      sessionStorage.setItem('selected_job', JSON.stringify(newData))
    }
  }
}
