<app-modal-header
    modalTitle="Size Range Alterations"
    [subTitle]="subTitle"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div
    class="modal-content"
    [attr.loading]="loading || submittingData"
    [attr.flexDirection]="'row'"
    [attr.size]="'large'"
>
    <!-- Summary -->
    <section id="summary-container" *ngIf="!loading && !submittingData">
        <app-basic-headers
            [sectionName]="'Summary'"
            [color]="'lte'"
        ></app-basic-headers>
        <!-- Grids Wrapper -->
        <div class="custom-vertical-scroll content-wrapper">
            <!-- Selected Distros -->
            <app-accordion-headers
                [panelOpenState]="false"
                sectionName="Selected Distros"
                id="selected-distros-grid"
            >
                <!-- Selected Distros Grid -->
                <div class="small-grid-container">
                    <ag-grid-angular
                        id="sra-selected-distros-grid"
                        class="ag-theme-alpine ag-grid-container"
                        [gridOptions]="distros_gridOptions"
                        (gridReady)="onGridReady($event, 'distros')"
                    ></ag-grid-angular>
                </div>
            </app-accordion-headers>
            <!-- Size Range Alterations -->
            <app-accordion-headers
                [panelOpenState]="true"
                sectionName="Size Range Alterations"
                id="size-alterations-grid"
            >
                <div class="small-grid-container">
                    <ag-grid-angular
                        id="sra-size-range-alerations-grid"
                        class="ag-theme-alpine ag-grid-container"
                        [gridOptions]="alterations_gridOptions"
                        (gridReady)="onGridReady($event, 'alterations')"
                    ></ag-grid-angular>
                </div>
            </app-accordion-headers>
        </div>
    </section>
    <!-- Alterations to Apply -->
    <section
        id="apply-alterations-container"
        *ngIf="!loading && !submittingData"
    >
        <app-basic-headers
            [sectionName]="'Alterations To Apply'"
            [color]="'lte'"
        ></app-basic-headers>

        <div class="custom-vertical-scroll content-wrapper">
            <!-- Add Size -->
            <app-accordion-headers
                id="add-size-by-pct"
                [panelOpenState]="true"
                sectionName="Add Size"
            >
                <!-- Form Fields -->
                <app-form-fields
                    *ngFor="
                        let field of ObjectKeys(
                            default_SRA_formSettings.add_size_config
                        )
                    "
                    [formParams]="add_size_form"
                    [controlName]="field"
                    (onFormFieldUpdated)="onFormUpdated($event, 'add_size')"
                >
                </app-form-fields>
                <button
                    mat-icon-button
                    class="submit-form-btn"
                    color="accent"
                    [disabled]="
                        !_add_size_form.add_size.currentVal ||
                        _add_size_form.add_size.currentVal === '' ||
                        alterationsFunctionErrors.add_size ||
                        _add_size_form.pct.currentVal === null ||
                        _add_size_form.pct.currentVal === ''
                    "
                    (click)="
                        onSubmitForm(
                            $event,
                            'add_size',
                            ObjectKeys(default_SRA_formSettings.add_size_config)
                        )
                    "
                >
                    <!-- _add_size_form.pct.currentVal === '' -->

                    <mat-icon>add</mat-icon>
                </button>
            </app-accordion-headers>
            <!-- Add Size By Size -->
            <app-accordion-headers
                id="add-size-by-size"
                [panelOpenState]="false"
                sectionName="Add Size By Size"
            >
                <!-- Form Fields -->
                <app-form-fields
                    *ngFor="
                        let field of ObjectKeys(
                            default_SRA_formSettings.add_size_by_size_config
                        )
                    "
                    [formParams]="add_size_by_size_form"
                    [controlName]="field"
                    (onFormFieldUpdated)="
                        onFormUpdated($event, 'add_size_by_size')
                    "
                >
                </app-form-fields>
                <button
                    mat-icon-button
                    class="submit-form-btn"
                    color="accent"
                    [disabled]="
                        !_add_size_by_size_form.add_size_by_size.currentVal ||
                        _add_size_by_size_form.add_size_by_size.currentVal ===
                            '' ||
                        !_add_size_by_size_form.size_to_model_after
                            .currentVal ||
                        _add_size_by_size_form.size_to_model_after
                            .currentVal === '' ||
                        alterationsFunctionErrors.add_size_by_size_model_size
                            .length > 0 ||
                        _add_size_by_size_form.pct_of_model.currentVal ===
                            null ||
                        _add_size_by_size_form.pct_of_model.currentVal === '' ||
                        alterationsFunctionErrors.add_size_by_size_pct
                    "
                    (click)="
                        onSubmitForm(
                            $event,
                            'add_size_by_size',
                            ObjectKeys(
                                default_SRA_formSettings.add_size_by_size_config
                            )
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
                <span
                    *ngIf="
                        _add_size_by_size_form.size_to_model_after.currentVal &&
                        alterationsFunctionErrors.add_size_by_size_model_size
                            .length > 0
                    "
                >
                    <p class="text-warn">
                        *Warning: The selected size to model after has an
                        alteration or is being referenced by another size:{{
                            _add_size_by_size_form.size_to_model_after
                                .currentVal
                        }}.
                    </p>
                </span>
            </app-accordion-headers>
            <!-- Remove Sizes -->
            <app-accordion-headers
                id="remove-size"
                [panelOpenState]="false"
                sectionName="Remove Size(s)"
            >
                <!-- Form Fields -->
                <app-form-fields
                    *ngFor="
                        let field of ObjectKeys(
                            default_SRA_formSettings.remove_sizes_config
                        )
                    "
                    [formParams]="remove_sizes_form"
                    [controlName]="field"
                    (onFormFieldUpdated)="onFormUpdated($event, 'remove_sizes')"
                    style="width: calc(100% - 48px)"
                >
                </app-form-fields>
                <button
                    mat-icon-button
                    color="accent"
                    class="submit-form-btn"
                    [disabled]="
                        !_remove_sizes_form.remove_sizes.currentVal ||
                        _remove_sizes_form?.remove_sizes.currentVal?.length ===
                            0 ||
                        alterationsFunctionErrors.remove_sizes.length > 0
                    "
                    (click)="onRemoveSizes($event, 'remove_sizes_form')"
                >
                    <mat-icon>add</mat-icon>
                </button>
                <!-- error message -->
                <span
                    *ngIf="
                        _remove_sizes_form?.remove_sizes.currentVal?.length >
                            0 &&
                        alterationsFunctionErrors.remove_sizes.length > 0
                    "
                >
                    <p class="text-warn">
                        *Warning: The selected size{{
                            alterationsFunctionErrors.remove_sizes.length > 1
                                ? 's to remove have alterations'
                                : ' to remove has an alteration'
                        }}
                        or is being referenced by another size:
                        {{ alterationsFunctionErrors.remove_sizes.join(', ') }}.
                    </p></span
                >
            </app-accordion-headers>
            <!-- Merge Sizes -->
            <app-accordion-headers
                id="merge-sizes"
                [panelOpenState]="false"
                sectionName="Merge Sizes"
            >
                <!-- Form Fields -->
                <app-form-fields
                    *ngFor="
                        let field of ObjectKeys(
                            default_SRA_formSettings.merge_sizes_config
                        )
                    "
                    [formParams]="merge_sizes_form"
                    [controlName]="field"
                    (onFormFieldUpdated)="onFormUpdated($event, 'merge_sizes')"
                >
                </app-form-fields>
                <button
                    mat-icon-button
                    color="accent"
                    class="submit-form-btn"
                    [disabled]="
                        !_merge_sizes_form.existing_size_1.currentVal ||
                        _merge_sizes_form.existing_size_1.currentVal === '' ||
                        alterationsFunctionErrors.merge_sizes_existing_size_1
                            .length > 0 ||
                        !_merge_sizes_form.existing_size_2.currentVal ||
                        _merge_sizes_form.existing_size_2.currentVal === '' ||
                        alterationsFunctionErrors.merge_sizes_existing_size_2
                            .length > 0 ||
                        _merge_sizes_form.existing_size_1.currentVal ===
                            _merge_sizes_form.existing_size_2.currentVal ||
                        !_merge_sizes_form.merge_sizes.currentVal ||
                        _merge_sizes_form.merge_sizes.currentVal === ''
                    "
                    (click)="
                        onSubmitForm(
                            $event,
                            'merge_sizes',
                            ObjectKeys(
                                default_SRA_formSettings.merge_sizes_config
                            )
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
                <!-- error messages -->
                <!-- Duplicate existing sizes -->
                <span
                    *ngIf="
                        _merge_sizes_form.existing_size_1.currentVal &&
                        _merge_sizes_form.existing_size_2.currentVal &&
                        _merge_sizes_form.existing_size_1.currentVal ===
                            _merge_sizes_form.existing_size_2.currentVal
                    "
                >
                    <p class="text-warn">
                        *Warning: The selected size for Existing Size 1 and
                        Existing Size 2 must be unique to merge:
                        {{ _merge_sizes_form.existing_size_1.currentVal }}.
                    </p></span
                >
                <!-- Duplicate alteration for existing size  1 -->
                <span
                    *ngIf="
                        _merge_sizes_form.existing_size_1.currentVal &&
                        alterationsFunctionErrors.merge_sizes_existing_size_1
                            .length > 0
                    "
                >
                    <p class="text-warn">
                        *Warning: The selected size for Existing Size 1 to merge
                        has an alteration or is being referenced by another
                        size:
                        {{ _merge_sizes_form.existing_size_1.currentVal }}.
                    </p></span
                >
                <!-- Duplicate alteration for existing size 2 -->
                <span
                    *ngIf="
                        _merge_sizes_form.existing_size_2.currentVal &&
                        alterationsFunctionErrors.merge_sizes_existing_size_2
                            .length > 0
                    "
                >
                    <p class="text-warn">
                        *Warning: The selected size for Existing Size 2 to merge
                        has an alteration or is being referenced by another
                        size:
                        {{ _merge_sizes_form.existing_size_2.currentVal }}.
                    </p></span
                >
            </app-accordion-headers>
            <!-- Split Sizes -->
            <app-accordion-headers
                id="split-sizes"
                [panelOpenState]="false"
                sectionName="Split Size"
            >
                <!-- Form Fields -->
                <app-form-fields
                    *ngFor="
                        let field of ObjectKeys(
                            default_SRA_formSettings.split_sizes_config
                        )
                    "
                    [formParams]="split_sizes_form"
                    [controlName]="field"
                    (onFormFieldUpdated)="onFormUpdated($event, 'split_sizes')"
                >
                </app-form-fields>
                <button
                    mat-icon-button
                    color="accent"
                    class="submit-form-btn"
                    [disabled]="
                        !_split_sizes_form.new_size_1.currentVal ||
                        _split_sizes_form.new_size_1.currentVal === '' ||
                        !_split_sizes_form.new_size_2.currentVal ||
                        _split_sizes_form.new_size_2.currentVal === '' ||
                        _split_sizes_form.new_size_1.currentVal ===
                            _split_sizes_form.new_size_2.currentVal ||
                        !_split_sizes_form.split_sizes.currentVal ||
                        _split_sizes_form.split_sizes.currentVal === '' ||
                        alterationsFunctionErrors.split_sizes.length > 0
                    "
                    (click)="
                        onSubmitForm(
                            $event,
                            'split_sizes',
                            ObjectKeys(
                                default_SRA_formSettings.split_sizes_config
                            )
                        )
                    "
                >
                    <mat-icon>add</mat-icon>
                </button>
                <!-- error messages -->
                <!-- Duplicate new sizes -->
                <span
                    *ngIf="
                        _split_sizes_form.new_size_1.currentVal &&
                        _split_sizes_form.new_size_2.currentVal &&
                        _split_sizes_form.new_size_1.currentVal ===
                            _split_sizes_form.new_size_2.currentVal
                    "
                >
                    <p class="text-warn">
                        *Warning: The selected size for New Size 1 and New Size
                        2 must be unique to split:
                        {{ _split_sizes_form.new_size_1.currentVal }}.
                    </p></span
                >
                <!-- Duplicate alteration for existing size -->
                <span
                    *ngIf="
                        _split_sizes_form.split_sizes.currentVal &&
                        alterationsFunctionErrors.split_sizes.length > 0
                    "
                >
                    <p class="text-warn">
                        *Warning: The selected size to split has an alteration
                        or is being referenced by another size:
                        {{ _split_sizes_form.split_sizes.currentVal }}.
                    </p></span
                >
            </app-accordion-headers>
        </div>
    </section>
    <!-- Loading Component -->
    <app-loader
        *ngIf="submittingData || loading"
        [loadingMessage]="loadingMessage"
    ></app-loader>
</div>
<app-modal-footer
    (closeModal)="close()"
    [showUpdateBtn]="true"
    (update)="onSubmitAlterations($event)"
    [closeDisabled]="submittingData"
    [disabled]="
        loading ||
        submittingData ||
        alterationsDataError ||
        allSizesLocked ||
        (ObjectKeys(alterations?.alterations?.add_size_by_size).length === 0 &&
            ObjectKeys(alterations?.alterations?.add_size).length === 0 &&
            ObjectKeys(alterations?.alterations?.merge_sizes).length === 0 &&
            ObjectKeys(alterations?.alterations?.split_sizes).length === 0 &&
            alterations?.alterations?.sizes_to_remove.length === 0 &&
            alterations?.alterations?.locked_sizes.length === 0)
    "
>
    <caption
        *ngIf="alterationsDataError || allSizesLocked"
        class="modal-footer-warning"
    >
        {{
            alterationsDataError
                ? 'Duplicate size alteration.'
                : 'All are sizes locked, please unlock at least 1 size.'
        }}
    </caption>
</app-modal-footer>
