// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { OverlayPanel } from 'primeng/overlaypanel'

@Injectable({
  providedIn: 'root'
})
export class PrimeNgOverlayPanelFixService {
  constructor () {
    this.installOverlayPanelFix()
  }

  private installOverlayPanelFix () {
    const onAlignSource: Function = OverlayPanel.prototype.align
    OverlayPanel.prototype.align = function (this: OverlayPanel) {
      const _this = this
      if (_this.container != null && _this.target != null) {
        onAlignSource.call(_this)
      }
    }

    const onBindDocumentClickListenerSource: Function =
            OverlayPanel.prototype.bindDocumentClickListener
    OverlayPanel.prototype.bindDocumentClickListener = function (
      this: OverlayPanel
    ) {
      const _this = this
      if (_this.container != null && _this.target != null) {
        onBindDocumentClickListenerSource.call(_this)
      }
    }

    OverlayPanel.prototype.hide = function (this: OverlayPanel) {
      const _this = this
      _this.render = false
      _this.overlayVisible = false
    }

    const onAnimationEndSource: Function =
            OverlayPanel.prototype.onAnimationEnd
    OverlayPanel.prototype.onAnimationEnd = function (
      this: OverlayPanel,
      event: any
    ) {
      const _this = this
      onAnimationEndSource.call(_this, event)
      if (event.toState === 'close') {
        _this.render = true
      }
    }
  }
}
