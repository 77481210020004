// Custom Imports
// =========================================================

import { FormatKey } from 'src/app/utils/global_functions'
import { CellClassRules } from '../02_global-settings/cell-class-rules'
import { GenerateDefaultColDefs } from '../02_global-settings/global-cell-formatter'

export interface AttributeUpload_GridDataParams {
  mainColDefs: any[]
  // detialColDefs: any[];
}

export async function AttributeUpload_GenerateGridData (
  params: AttributeUpload_GridDataParams
) {
  const {
    mainColDefs
    // detailColDefs
  } = params

  const GenerateCols = async (data: any[], gridToRender: string) => {
    const mappedCols = {}

    Object.entries(data).map(([colKey, colVal]) => {
      const colId = colVal.refData?.custom_field || FormatKey(colKey)

      const obj = {
        ...GenerateDefaultColDefs(colKey, { colId, ...colVal })
      }
      Object.assign(mappedCols, {
        [obj.colId]: AttributeUpload_customCellFormatting(
          obj,
          gridToRender
        )
      })
    })

    return Object.values(mappedCols)
  }

  const getResults = async () => {
    const mainCols = await GenerateCols(mainColDefs, 'main')

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    return data
  }
  return await getResults()
}

export const AttributeUpload_customCellFormatting = (col, gridToRender) => {
  const hasValue = val =>
    val && ((val !== '' && val !== undefined) || val !== null)
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      switch (col.colId) {
        case 'status':
          return {
            valueFormatter: params => {
              if (params?.data?.error_reason) {
                return params?.data?.error_reason
              }
              return params.value
            }
          }
        case 'delete':
          return {
            cellRendererParams: params => {
              return {
                disabled: false,
                toolTipMessage: 'Delete Attribute Details',
                onClick: params =>
                  params.context.onDeleteRow(params)
              }
            }
          }
      }
    }
    // Detail Grid Settings
    // if (gridToRender === 'detail') {
    // }
  }
  const updatedCol = {
    ...col,
    ...getParams(),
    cellClassRules: {
      ...CellClassRules,
      'highlight-cell': params => {
        // console.log("highlight cell: ", params)
        return (
          params.context.componentParent.requiredFields.includes(
            col.colId
          ) && !hasValue(params.value)
        )
      }
    }
  }
  return updatedCol
}
