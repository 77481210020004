<!-- Group: Carton Parameters -->
<app-accordion-headers
    [panelOpenState]="panelOpenOnLoad"
    sectionName="Carton Parameters"
>
    <!-- Form: Allow Holes in Cartons -->
    <app-form-fields
        [formParams]="formData"
        controlName="allow_holes_in_cartons"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
    <!-- Form: Every Size in Every Pack -->
    <app-form-fields
        [formParams]="formData"
        controlName="every_size_in_every_pack"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
    <!-- Form: Carton Sizes -->
    <app-form-fields
        [formParams]="formData"
        controlName="carton_sizes"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
</app-accordion-headers>
<!-- Group: General Parameters -->
<app-accordion-headers
    [panelOpenState]="panelOpenOnLoad"
    sectionName="General Parameters"
>
    <!-- Form: Allow Cartons in Flows -->
    <app-form-fields
        [formParams]="formData"
        controlName="allow_cartons_in_flows"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>

    <!-- Form: Apply Min to Flows -->
    <app-form-fields
        [formParams]="formData"
        controlName="apply_mins_to_flows"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
    <!-- Form: Apply Max to Flows -->
    <app-form-fields
        [formParams]="formData"
        controlName="apply_maxes_to_flows"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
    <!-- Form: Allow Bulk in Set -->
    <app-form-fields
        [formParams]="formData"
        controlName="allow_bulk_in_set"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
    <!-- Form: Bulk Multiple -->
    <app-form-fields
        [formParams]="formData"
        controlName="bulk_multiple"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
    <!-- Form: Deviation from Buy Qty -->
    <app-form-fields
        [formParams]="formData"
        controlName="deviation_from_buy_qty"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
    <!-- Form: Min Order Qty for Each Pack -->
    <app-form-fields
        [formParams]="formData"
        controlName="min_order_qty_for_each_pack"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
    <!-- Form: Number of Cartons -->
    <app-form-fields
        [formParams]="formData"
        controlName="number_of_cartons"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
</app-accordion-headers>
<!-- Group: Min/Max -->
<app-accordion-headers [panelOpenState]="panelOpenOnLoad" sectionName="Min/Max">
    <!-- Form: Min Units Per Styc Per Store -->
    <app-form-fields
        [formParams]="formData"
        controlName="min_units_per_styc_per_store"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
    <!-- Form: Max Units Per Styc Per Store -->
    <app-form-fields
        [formParams]="formData"
        controlName="max_units_per_styc_per_store"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
    <!-- Form: Min By Size -->
    <app-form-fields
        [formParams]="formData"
        controlName="min_by_size"
        (onFormFieldUpdated)="updateFormValue($event)"
    >
    </app-form-fields>
</app-accordion-headers>
