<!-- Modal Header -->
<div class="alert-header" [attr.alertKey]="alertKey">
    <h2><span class="material-icons error-icon">error_outline</span></h2>
    <h2 class="m-0">{{ alertLabel }}</h2>
</div>
<div class="job-management-status-content-container">
    <!-- Job Name -->
    <app-form-wrapper [labelText]="'Job Name'">
        <p>{{ cellParams?.data?.job_name }}</p>
    </app-form-wrapper>
    <!-- Time of Error -->
    <app-form-wrapper [labelText]="'Time of Error'">
        <p>{{ timeOfError }}</p>
    </app-form-wrapper>
    <!-- For Failed Only -->
    <app-form-wrapper
        [labelText]="'Failure Reason'"
        *ngIf="alertKey === 'failed'"
    >
        <div class="failed-reason-container">
            <p>
                {{ cellParams?.data?.error_message | slice : 0 : 120 }}
                <span *ngIf="cellParams?.data?.error_message.length > 120"
                    >...</span
                >
            </p>
        </div>
    </app-form-wrapper>
    <!-- For Failed with Errors Only-->
    <app-form-wrapper
        [labelText]="'Error Reason'"
        *ngIf="alertKey === 'completed with errors'"
    >
        <p>
            {{ cellParams?.data?.error_message }}
        </p>
    </app-form-wrapper>
</div>
