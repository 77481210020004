// Angular and RJX Imports
// =========================================================
import { Component, type OnInit } from '@angular/core'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { SizeDistroDefaultParametersAPIs } from 'src/app/core/apis/size-distro-default-parameters_api-calls'
import { type ProdHierTreeNodeInterface } from 'src/app/core/interfaces/data-expected-from-backend/prod-hier-tree-node-interface'
import { type ProxyProductAttrsInterface } from 'src/app/core/interfaces/data-expected-from-backend/proxy-product-attrs-interface'

@Component({
  selector: 'app-proxy-product-details',
  templateUrl: './proxy-product-details.component.html',
  styleUrls: ['./proxy-product-details.component.scss']
})
export class ProxyProductDetailsComponent implements OnInit {
  modalTitle: string = 'Proxy Product Details'
  selectedNode: ProdHierTreeNodeInterface

  loadingProductAttrs: boolean = true
  productAttrData: ProxyProductAttrsInterface
  eligibleSizes: string = ''

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private readonly sizeDistroDefaultParametersAPIs: SizeDistroDefaultParametersAPIs
  ) {
    const { data } = this.config
    console.log('---- Proxy Product Details Modal ---- ', data)
    this.selectedNode = data
    this.modalTitle = `${this.modalTitle}: ${data.label}`
    // Get the product details for the slected node
    this.getProxyProductAttributes(data.key)
  }

  ngOnInit () {}

  getProxyProductAttributes (prodId: string) {
    console.log('get proxy product: ', prodId)
    this.sizeDistroDefaultParametersAPIs
      .GetProxyProductAttributes(prodId)
      .then(res => {
        console.log(
          '---- Product Attributes for proxy product ---- ',
          res.data
        )
        console.log('size: ', res.data)
        this.eligibleSizes = res.data[0].sizes.join('|')
        this.productAttrData = res.data[0]
        this.loadingProductAttrs = false
      })
  }

  close () {
    if (this.ref) this.ref.close()
  }
}
