import { CustomGridOptions } from 'src/app/05_ag-grid-configs/02_global-settings/grid-options'
import { IsKeyInObj } from 'src/app/utils/global_functions'
import { v4 as uuidv4 } from 'uuid'

export const RO_SharedGridOptions = tabKey => {
  return {
    aggFuncs: {
      ...CustomGridOptions.aggFuncs,
      buySummChannel: params => {
        if (params.rowNode.level === 0) {
          return 'Total'
        } else return params.values[0]
      },
      inputLinesStatus: params => {
        // console.log("inputlinesstatus params", params)
        if (params.rowNode.level == 1) {
          // console.log('row is one so returning', params.rowNode.allLeafChildren[0].data.status)
          return params.rowNode.allLeafChildren[0]?.data[
            params.colDef.colId
          ]
        } else if (params.rowNode.level == 0) {
          const statuses = [
            ...new Set(
              params.rowNode.allLeafChildren.map(
                row => row.data[params.colDef.colId]
              )
            )
          ]
          if (statuses.length > 1) {
            if (params.colDef.colId == 'channel') {
              return statuses.join(', ')
            } else {
              return '--'
            }
          } else {
            return statuses[0]
          }
        }
      }
    },
    masterDetail: false,
    rowSelection:
            tabKey === 'buy_summary' || tabKey === 'source_to_dest_summary'
              ? 'single'
              : 'multiple',
    suppressContextMenu: false,

    getRowHeight: params => {
      if (params.node && params.node.detail) return 430
    },
    getRowClass: params => {
      // console.log('---> get row class params', params.node)
      if (params.node.allChildrenCount) {
        switch (params.node.level) {
          case 0:
          case 1:
            return 'cell-text-bold'
        }
      }
    },
    getRowId: node => {
      const { data } = node
      return data.job_id
        ? `${data.job_id} - ${data.delivery_id}`
        : data.grid_row_id // uuidv4()
    }
  }
}

export const TreeDataGridOptions = (tabKey: string, treeDataParams) => {
  const { groupLevelExpanded, auto_group_name, hideLowestLevel } =
        treeDataParams
  return {
    groupMultiAutoColumn: false,
    suppressAggAtRootLevel: true,
    groupSelectsChildren: true,
    suppressRowClickSelection: true,
    groupDefaultExpanded: groupLevelExpanded,
    // Custom Auto Group Column Definition -> menu/filtering is not allowed for groups
    autoGroupColumnDef: {
      headerName: auto_group_name,
      filter: false,
      pinned: 'left',
      flex: 2,
      sortable: false,
      showDisabledCheckboxes: false,
      // suppressMenu: true,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        suppressCount: true,
        checkbox: params => {
          // console.log("---> Review Orders Checkbox Params: ", params)
          if (tabKey === 'user_input' && params.node.group) {
            return (
              !params.node.key.includes('RUNNING') &&
                            !params.node.key.includes('FAILED')
            )
          } else {
            return false
          }
        }
      },
      valueFormatter: params => {
        const value = params.value

        if (value !== null) {
          if (!params.node.group && hideLowestLevel) {
            return ''
          } else if (
            tabKey === 'buy_summary' &&
                        params.node.level === 0
          ) {
            return 'Total'
          } else if (value.includes('~')) {
            return value.split('~')[1]
          } else {
            return value
          }
        } else {
          return '--'
        }
      },
      valueGetter: params => {
        // Update group keys while generating the cell value to create a unique key based on the hierarchy order to prevent duplicate group ids
        if (params.node?.level && params.node.group) {
          const route = [
            params.node.rowIndex,
            ...new Set(params.node.getRoute())
          ]
          const strRoute = [
            ...new Set(route.join('|').split('|'))
          ].join('|')

          const updatedKey =
                        params.node.level === 0 ? params.node.key : strRoute

          if (params.node.level === 0) {
            return updatedKey
          } else {
            if (route[params.node.level] !== strRoute) {
              params.node.key = updatedKey
            }
            return updatedKey.split('|')[
              updatedKey.split('|').length - 1
            ]
          }
        } else {
          return params.node.key
        }
      }
    },
    getDataPath: (data: any) => {
      // console.log("get data path: ", data.file_path)
      const res =
                data.file_path.length > 0
                  ? data.file_path.map(path => path || '...enter a value')
                  : []

      if (data.grid_row_id) {
        // for leaf nodes we add the id to make them unique if the current id does not exist
        if (res && !res.includes(data.grid_row_id)) {
          res.push(data.grid_row_id)
        }
      }
      return res
    }
  }
}
