<mat-card id="main-stepper-container">
    <mat-stepper
        #stepper
        labelPosition="bottom"
        (selectionChange)="onStepperChange($event)"
    >
        <!-- Step 1: Scope -->
        <mat-step
            [stepControl]="step_1_FormGroup"
            errorMessage="Job scope is required."
        >
            <ng-template matStepLabel>{{ step_1_JobScopeTitle }}</ng-template>
            <ng-template matStepContent>
                <form [formGroup]="step_1_FormGroup">
                    <!-- If loading Selection Options -->
                    <app-loader
                        *ngIf="loadingSelectionOptions"
                        class="loading-container"
                        [loadingMessage]="'loading'"
                    ></app-loader>
                    <div
                        class="form-content-container"
                        *ngIf="!loadingSelectionOptions"
                    >
                        <!-- Initial Data -->
                        <div class="step-1-container-1">
                            <!-- Job Name -->
                            <p class="text-lte form-text-lte">Job Name*</p>
                            <app-form-fields
                                class="jobName"
                                [formParams]="formData"
                                [controlName]="'job_name'"
                                (onFormFieldUpdated)="
                                    onUpdate_step_1_FormValue($event)
                                "
                                [hideLabel]="true"
                                [hintColor]="'lte'"
                            >
                            </app-form-fields>
                            <!-- Job Tag -->
                            <p class="text-lte form-text-lte">Job Tag</p>
                            <app-form-fields
                                class="jobTag"
                                [formParams]="formData"
                                [controlName]="'job_tag'"
                                (onFormFieldUpdated)="
                                    onUpdate_step_1_FormValue($event)
                                "
                                [hideLabel]="true"
                            >
                            </app-form-fields>
                        </div>

                        <!-- Bottom inputs container -->
                        <div class="step-1-container-2">
                            <!-- Section: Channel -->
                            <app-accordion-headers
                                [panelOpenState]="true"
                                sectionName="Channel"
                                [color]="
                                    step_1_FormErrors?.channel ? 'warn' : 'lte'
                                "
                                [sectionName]="
                                    step_1_FormErrors?.channel
                                        ? 'Channel - Please select a channel.'
                                        : 'Channel'
                                "
                            >
                                <app-form-fields
                                    class="channel"
                                    [formParams]="formData"
                                    [controlName]="'channel'"
                                    (onFormFieldUpdated)="
                                        onUpdate_step_1_FormValue($event)
                                    "
                                    [ngStyle]="{
                                        width: _formData.channel.formDisplay
                                            .formWidth
                                    }"
                                >
                                </app-form-fields>
                            </app-accordion-headers>
                            <!-- Section: Sizes -->
                            <app-accordion-headers
                                [panelOpenState]="true"
                                sectionName="Sizes"
                                [color]="
                                    step_1_FormErrors?.sizes ? 'warn' : 'lte'
                                "
                                [sectionName]="
                                    step_1_FormErrors?.sizes
                                        ? 'Sizes - Please select at least 1 size.'
                                        : 'Sizes'
                                "
                            >
                                <app-form-fields
                                    class="sizes"
                                    [formParams]="formData"
                                    [controlName]="'sizes'"
                                    (onFormFieldUpdated)="
                                        onUpdate_step_1_FormValue($event)
                                    "
                                    [ngStyle]="{
                                        width: _formData.sizes.formDisplay
                                            .formWidth
                                    }"
                                >
                                </app-form-fields>
                            </app-accordion-headers>
                            <!-- Section: Seasons -->
                            <app-accordion-headers
                                [panelOpenState]="true"
                                [sectionName]="
                                    step_1_FormErrors?.buying_season ||
                                    step_1_FormErrors?.prior_or_custom_season
                                        ? 'Seasons - Please complete the required fields.'
                                        : 'Seasons'
                                "
                                [color]="
                                    step_1_FormErrors?.buying_season ||
                                    step_1_FormErrors?.prior_or_custom_season
                                        ? 'warn'
                                        : 'lte'
                                "
                            >
                                <!-- Season / Custom Season Selection Container -->
                                <div class="season-selection-container">
                                    <!-- Seasons -->
                                    <app-form-fields
                                        class="priorSellingSeasons"
                                        [formParams]="formData"
                                        [controlName]="'prior_selling_seasons'"
                                        [dynamicValue]="
                                            priorSellingSeasonsArray
                                        "
                                        (onFormFieldUpdated)="
                                            onUpdate_step_1_FormValue($event)
                                        "
                                        id="seasons-control"
                                    >
                                    </app-form-fields>
                                    <h3>OR*</h3>
                                    <app-form-fields
                                        [formParams]="formData"
                                        [controlName]="'custom_season'"
                                        [dynamicValue]="
                                            jobFormEdits?.custom_season
                                        "
                                        (onFormFieldUpdated)="
                                            onUpdate_step_1_FormValue($event)
                                        "
                                    >
                                    </app-form-fields>
                                </div>
                                <!-- Buying Season -->
                                <app-form-fields
                                    class="buyingSeason"
                                    [formParams]="formData"
                                    [controlName]="'buying_season'"
                                    (onFormFieldUpdated)="
                                        onUpdate_step_1_FormValue($event)
                                    "
                                    style="width: calc(50% - 25px)"
                                >
                                </app-form-fields>
                            </app-accordion-headers>
                            <!-- Section: Weighting -->
                            <app-accordion-headers
                                [panelOpenState]="
                                    jobFormEdits?.prior_selling_seasons
                                        ?.length > 1
                                "
                                [sectionName]="
                                    jobFormEdits?.prior_selling_seasons
                                        ?.length > 0 &&
                                    step_1_FormErrors?.season_weighting
                                        ? 'Season Weighting - The sum of the weights do not equal 100%.'
                                        : 'Season Weighting'
                                "
                                [color]="
                                    step_1_FormErrors?.season_weighting
                                        ? 'warn'
                                        : jobFormEdits?.prior_selling_seasons
                                              ?.length > 1
                                        ? 'lte'
                                        : 'disabled'
                                "
                                [disabled]="
                                    !jobFormEdits ||
                                    (jobFormEdits &&
                                        jobFormEdits?.prior_selling_seasons
                                            ?.length <= 1)
                                "
                            >
                                <span
                                    *ngFor="
                                        let key of jobFormEdits?.prior_selling_seasons;
                                        let i = index
                                    "
                                    id="season-weight-inputs-wrapper"
                                >
                                    <app-form-fields
                                        [formParams]="formData"
                                        [controlName]="'season'"
                                        [dynamicValue]="key.season"
                                        (onFormFieldUpdated)="
                                            onUpdate_step_1_FormValue($event, i)
                                        "
                                        style="width: calc(48% - 20px)"
                                    >
                                    </app-form-fields>
                                    <app-form-fields
                                        [formParams]="formData"
                                        [controlName]="'weight'"
                                        [dynamicValue]="key.weight"
                                        (onFormFieldUpdated)="
                                            onUpdate_step_1_FormValue($event, i)
                                        "
                                        style="width: calc(48% - 20px)"
                                    >
                                    </app-form-fields>
                                    <!-- Delete Attribute Pair -->
                                    <button
                                        mat-icon-button
                                        (click)="
                                            onDeleteSeasonWeighting($event, i)
                                        "
                                        color="warn"
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </span>
                            </app-accordion-headers>
                        </div>
                    </div>
                    <!-- Footer Container -->
                    <div class="stepper-button-container-bottom">
                        <!-- TODO: V2 Update: Reset Button is disabled for version 1 -->
                        <button
                            mat-button
                            color="warn"
                            (click)="stepper.reset(); onResetJobDetails($event)"
                            [disabled]="true"
                        >
                            Reset
                        </button>
                        <button
                            mat-button
                            color="accent"
                            matStepperNext
                            class="nextStep1Btn"
                        >
                            Next
                        </button>
                    </div>
                </form>
            </ng-template>
        </mat-step>
        <!-- Step 2: Products -->
        <mat-step
            [stepControl]="step_2_FormGroup"
            errorMessage="Product selection needs review."
        >
            <ng-template matStepLabel>Products</ng-template>
            <ng-template matStepContent>
                <form [formGroup]="step_2_FormGroup">
                    <!-- <div class="form-content-container"> -->
                    <div
                        class="step-2-container-1"
                        style="height: calc(100% - 47px)"
                    >
                        <!-- Left Side: Product Hierarchy -->
                        <div
                            style="
                                flex-basis: 55%;
                                display: flex;
                                flex-direction: column;
                                height: 100%;
                            "
                        >
                            <app-basic-headers
                                [sectionName]="'Product Hierarchy Selection'"
                                color="lte"
                            ></app-basic-headers>
                            <app-product-hierarchy
                                [treeDataParams]="treeDataParams"
                                [selectionMode]="'single'"
                                [selectedValues]="defaultSelected"
                                [filtersToDisplay]="[]"
                                [showEligibleProxySizes]="false"
                                [allowNodeDeletion]="false"
                                (onNodeSelected)="onNodeSelected($event)"
                                [loading]="loadingProdHier"
                                style="flex: 2; padding: 0px 10px"
                                [disabled]="
                                    selectedJob && !selectedJob?.is_editable
                                "
                            ></app-product-hierarchy>
                        </div>
                        <!-- Right Side: Selected Nodes -->
                        <div
                            class="selected-nodes-wrapper"
                            style="flex-basis: 42%; overflow: hidden"
                        >
                            <app-basic-headers
                                [sectionName]="
                                    'Selected Node: ' +
                                    (selectedHierarchyNode?.label
                                        ? selectedHierarchyNode?.label
                                        : 'No Nodes Selected') +
                                    ' (Max ' + maxStycCount + ' stylecolors)'
                                "
                                color="lte"
                                [textEllipsis]="true"
                            ></app-basic-headers>
                            <div>
                                <h4 class="check-selection-title">
                                    Style-Colors To Include:
                                </h4>
                                <div class="styc-counter">
                                    {{
                                        jobFormEdits.styc_count
                                            ? jobFormEdits.styc_count
                                            : 0
                                    }}
                                    of
                                    {{ ObjectKeys(prodHiersSelected).length }}
                                    selected
                                </div>
                            </div>

                            <div
                                class="flex-center-all loading-wrapper"
                                *ngIf="loadingStycs"
                            >
                                <app-loader
                                    loadingMessage="loading"
                                ></app-loader>
                            </div>
                            <div class="checkbox-selection-container">
                                <span class="checkbox-list-section">
                                    <div
                                        class="form-content-container selected-stycs-wrapper"
                                        *ngIf="
                                            ObjectKeys(prodHiersSelected)
                                                .length > 0 && !loadingStycs
                                        "
                                    >
                                        <mat-checkbox
                                            (click)="toggleAllSelection()"
                                            color="accent"
                                            [disabled]="
                                                selectedJob &&
                                                !selectedJob?.is_editable
                                            "
                                            [checked]="allSelected"
                                        >
                                            Select All
                                        </mat-checkbox>

                                        <mat-checkbox
                                            *ngFor="
                                                let selectedNode of paginatedData
                                            "
                                            color="accent"
                                            [disabled]="
                                                selectedJob &&
                                                !selectedJob?.is_editable
                                            "
                                            [value]="
                                                prodHiersSelected[selectedNode]
                                            "
                                            [checked]="
                                                prodHiersSelected[selectedNode]
                                            "
                                            (click)="
                                                $event.stopPropagation();
                                                updateStyleColorSelection(
                                                    $event,
                                                    selectedNode
                                                )
                                            "
                                        >
                                            {{ selectedNode }}
                                        </mat-checkbox>
                                    </div>
                                    <span
                                        *ngIf="
                                            ObjectKeys(prodHiersSelected)
                                                .length === 0 && !loadingStycs
                                        "
                                    >
                                        <p class="no-prods-selected-text">
                                            No Products Selected.
                                        </p>
                                    </span>
                                    <mat-paginator
                                        [length]="
                                            ObjectKeys(prodHiersSelected).length
                                        "
                                        [pageSize]="pageSize"
                                        (page)="onPageChange($event)"
                                        *ngIf="
                                            ObjectKeys(prodHiersSelected)
                                                .length > 0
                                        "
                                    >
                                    </mat-paginator>
                                    <div></div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                    <!-- Footer Container -->
                    <div class="stepper-button-container-bottom">
                        <button mat-button color="accent" matStepperPrevious>
                            Back
                        </button>
                        <button
                            mat-button
                            color="accent"
                            matStepperNext
                            class="nextStep2Btn"
                        >
                            Next
                        </button>
                    </div>
                </form>
            </ng-template>
        </mat-step>
        <!-- Step 3: Parameters (Optional) -->
        <mat-step [stepControl]="step_3_FormGroup" optional>
            <ng-template matStepLabel>Parameters</ng-template>
            <ng-template matStepContent>
                <form [formGroup]="step_3_FormGroup">
                    <app-basic-headers
                        [sectionName]="'Default Parameters (Optional)'"
                        color="lte"
                    ></app-basic-headers>
                    <!-- Default Parameters Options -->
                    <div
                        id="default-param-opts-container"
                        class="form-content-container"
                    >
                        <app-default-parameters-input-fields
                            [productDetails]="productDetails"
                            (onFormValueChanged)="
                                onProductDetailsChanged($event)
                            "
                            [clearFormGroup]="clearGeneralProductDetails"
                            (onFormReset)="onParamFormReset($event)"
                            panelOpenOnLoad="true"
                        ></app-default-parameters-input-fields>
                    </div>
                    <!-- Footer Container -->
                    <div class="stepper-button-container-bottom">
                        <button mat-button matStepperPrevious color="accent">
                            Back
                        </button>
                        <button
                            mat-raised-button
                            color="warn"
                            [disabled]="
                                activeScreen !== 'creation-screen' ||
                                (activeScreen === 'creation-screen' &&
                                    jobFormEdits?.params_changes?.length === 0)
                            "
                            (click)="resetDefaultParameters($event)"
                        >
                            Reset Parameters
                        </button>
                        <button
                            mat-button
                            matStepperNext
                            color="accent"
                            class="nextStep3Btn"
                        >
                            Next
                        </button>
                    </div>
                </form>
            </ng-template>
        </mat-step>

        <!-- Step 4: Review and Submit -->
        <mat-step>
            <app-confirm-popup
                #confirmPopup
                (confirmModal)="onConfirmPopupEvent($event)"
            ></app-confirm-popup>
            <ng-template matStepLabel>Review and Submit</ng-template>
            <ng-template matStepContent>
                <form>
                    <!-- If loading Selection Options -->
                    <app-loader
                        *ngIf="submittingData"
                        class="loading-container"
                        [loadingMessage]="'submitting'"
                    ></app-loader>
                    <div
                        *ngIf="!submittingData"
                        class="form-content-container review-job-details-container"
                    >
                        <!-- Results Container -->
                        <div id="review-job-details-results-container">
                            <!-- Loop through and display specific results -->
                            <app-form-wrapper
                                *ngFor="let result of resultsToDisplay"
                                [labelText]="result.label"
                            >
                                <p *ngIf="result.key !== 'sizes'">
                                    {{
                                        jobFormEdits[result.key]
                                            ? jobFormEdits[result.key]
                                            : 'No results found.'
                                    }}
                                </p>
                                <p *ngIf="result.key === 'sizes'">
                                    {{
                                        jobFormEdits[result.key] &&
                                        jobFormEdits[result.key].length > 0
                                            ? jobFormEdits[result.key]
                                            : 'No results found.'
                                    }}
                                </p>
                            </app-form-wrapper>
                        </div>
                        <!-- Save Jobs -->
                        <span id="review-job-details-btn-container">
                            <button
                                class="createJobBtn"
                                mat-raised-button
                                color="accent"
                                (click)="
                                    activeScreen === 'creation-screen' ||
                                    activeScreen === 'copy-screen'
                                        ? createJobAPI(false)
                                        : confirmPopup.confirm(
                                              $event,
                                              'Edit Job'
                                          )
                                "
                                [disabled]="
                                    !step_1_FormGroup.valid ||
                                    !step_2_FormGroup.valid
                                "
                            >
                                {{
                                    activeScreen === 'creation-screen' ||
                                    activeScreen === 'copy-screen'
                                        ? 'Create Job'
                                        : 'Save Job'
                                }}
                            </button>
                            <!-- Run Jobs -->
                            <button
                                class="runJobBtn"
                                mat-raised-button
                                color="primary"
                                (click)="onRunJob($event)"
                                [disabled]="
                                    !step_1_FormGroup.valid ||
                                    !step_2_FormGroup.valid
                                "
                            >
                                Run Job
                            </button>
                        </span>
                    </div>
                    <!-- Footer Container -->
                    <div
                        *ngIf="!submittingData"
                        class="stepper-button-container-bottom"
                    >
                        <button
                            mat-button
                            matStepperPrevious
                            color="accent"
                            class="jobCreationBackBtn"
                        >
                            Back
                        </button>
                        <!-- TODO: V2 Update: Reset Button is disabled for version 1 -->
                        <button
                            mat-button
                            (click)="stepper.reset(); onResetJobDetails($event)"
                            color="warn"
                            [disabled]="true"
                        >
                            Reset
                        </button>
                    </div>
                </form>
            </ng-template>
        </mat-step>
    </mat-stepper>
</mat-card>
