[
    {
        "Proxy Product ID": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Attribute Family": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Attribute Value": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Status": {
            "editable": false,
            "refData": {
                "datatype": "string",
                "showToolTip": false
            },
            "wrapText": true,
            "autoHeight": true,
            "rowGroup": false,
            "hide": false
        },
        "Delete": {
            "editable": false,
            "pinned": "right",
            "refData": {
                "datatype": "action-button|delete",
                "_confirm_action": false,
                "displayBlankHeader": true,
                "colSize": 90
            },
            "rowGroup": false,
            "hide": false,
            "sortable": false,
            "maxWidth": 90
        }
    }
]
