<div
    [ngClass]="{
        'form-container': true,
        'form-container-condensed': condensed
    }"
>
    <!-- Input  Name -->
    <span
        *ngIf="labelText !== null"
        [ngClass]="{
            'label-container-normal': !condensed,
            'label-container-condensed': condensed
        }"
        [ngStyle]="labelWidth ? labelWidth : {}"
    >
        <h4
            [ngClass]="{
                'form-label': true,
                'size-l': condensed,
                'capitalize-Text': true,
                'm-0': true
            }"
        >
            {{ labelText }}:
        </h4>
        <h5 class="m-0 m-r-10">{{ subtext }}</h5>
    </span>
    <!-- Form content -->
    <span
        [ngClass]="{
            'capitalize-Text': true,
            'form-field': true,
            'size-s': formSize === 'small',
            'size-m': formSize === 'medium' || !formSize,
            'size-l': formSize === 'large',
            'w-80': formSize === 'large'
        }"
        [ngStyle]="formWidth ? formWidth : {}"
    >
        <ng-content></ng-content>
    </span>
</div>
