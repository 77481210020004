import { translate as e, DefaultTexts as t } from "../../i18n/translations.mjs";
const T = {
    amazon: "Amazon",
    apple: "Apple",
    facebook: "Facebook",
    google: "Google"
  },
  n = {
    getBackToSignInText: () => e(t.BACK_SIGN_IN),
    getChangePasswordText: () => e(t.CHANGE_PASSWORD),
    getChangingText: () => e(t.CHANGING_PASSWORD),
    getConfirmText: () => e(t.CONFIRM),
    getConfirmingText: () => e(t.CONFIRMING),
    getCopyText: () => e(t.UPPERCASE_COPY),
    getHidePasswordText: () => e(t.HIDE_PASSWORD),
    getLoadingText: () => e(t.LOADING),
    getOrText: () => e(t.OR),
    getResendCodeText: () => e(t.RESEND_CODE),
    getSendCodeText: () => e(t.SEND_CODE),
    getSendingText: () => e(t.SENDING),
    getShowPasswordText: () => e(t.SHOW_PASSWORD),
    getSubmitText: () => e(t.SUBMIT),
    getSubmittingText: () => e(t.SUBMITTING),
    getSignInTabText: () => e(t.SIGN_IN_TAB),
    getSignUpTabText: () => e(t.CREATE_ACCOUNT),
    getForgotPasswordText: T => e(T ? t.FORGOT_PASSWORD : t.FORGOT_YOUR_PASSWORD),
    getSigningInText: () => e(t.SIGNING_IN_BUTTON),
    getSignInText: () => e(t.SIGN_IN_BUTTON),
    getCreatingAccountText: () => e(t.CREATING_ACCOUNT),
    getCreateAccountText: () => e(t.CREATE_ACCOUNT),
    getDeliveryMessageText: T => {
      const {
          DeliveryMedium: n,
          Destination: g
        } = null != T ? T : {},
        S = "EMAIL" === n,
        o = "SMS" === n,
        i = e(t.CODE_ARRIVAL);
      if (!S && !o) return `${e(t.CODE_SENT)}. ${i}.`;
      return `${e(S ? t.CODE_EMAILED : t.CODE_TEXTED)} ${g}. ${i}.`;
    },
    getDeliveryMethodText: T => {
      const {
          DeliveryMedium: n
        } = null != T ? T : {},
        g = "EMAIL" === n;
      return e(g || "SMS" !== n ? g ? t.WE_EMAILED : t.WE_TEXTED : t.WE_SENT_CODE);
    },
    getChallengeText: T => {
      switch (T) {
        case "SMS_MFA":
          return e(t.CONFIRM_SMS);
        case "SOFTWARE_TOKEN_MFA":
          return e(t.CONFIRM_TOTP);
        default:
          throw new Error(`${e("Unexpected challengeName encountered in ConfirmSignIn:")} ${T}`);
      }
    },
    getResetYourPasswordText: () => e(t.RESET_PASSWORD),
    getSetupTOTPText: () => e(t.SETUP_TOTP),
    getSetupTOTPInstructionsText: () => e("Copy and paste the secret key below into an authenticator app and then enter the code in the text field below."),
    getCopiedText: () => e("COPIED"),
    getSignInWithFederationText: (t, n) => e(`Sign ${"signIn" === t ? "In" : "Up"} with ${T[n]}`),
    getSkipText: () => e(t.SKIP),
    getVerifyText: () => e(t.VERIFY),
    getVerifyContactText: () => e(t.VERIFY_CONTACT),
    getAccountRecoveryInfoText: () => e(t.VERIFY_HEADING)
  };
export { n as authenticatorTextUtil };