<mat-accordion id="section-header-accordion" [attr.color]="color">
    <mat-expansion-panel
        [disabled]="disabled"
        [expanded]="panelOpenState"
        (opened)="onPanelStateChanged('opened')"
        (closed)="onPanelStateChanged('closed')"
        [hideToggle]="displayActionRow"
    >
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ sectionName }}
            </mat-panel-title>
            <mat-panel-description *ngIf="sectionDescription">
                {{ sectionDescription }}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- Panel Content -->
        <div class="panel-content-container">
            <ng-content></ng-content>
        </div>
        <mat-action-row *ngIf="displayActionRow && !lastStep">
            <!-- Previous Step -->
            <button
                *ngIf="stepNumber !== 0"
                mat-icon-button
                color="warn"
                (click)="prevStep()"
                #tooltip="matTooltip"
                matTooltip="Previous"
                matTooltipClass="below"
            >
                <mat-icon>arrow_backward</mat-icon>
            </button>
            <button
                *ngIf="!lastStep"
                mat-icon-button
                color="accent"
                (click)="nextStep()"
                #tooltip="matTooltip"
                matTooltip="Next"
                matTooltipClass="below"
            >
                <mat-icon>arrow_forward</mat-icon>
            </button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
