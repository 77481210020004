// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import {
  ExecuteDeleteAPI,
  ExecuteGetAPI,
  ExecutePostAPI
} from './execute_api-calls'
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { TriggerApiCallsService } from '../services/cancel-api-call'
import { type SaveStoreListInterface } from '../interfaces/data-expected-from-backend/drill-in-filtering_interface'
import { FormatKey } from 'src/app/utils/global_functions'

@Injectable()
export class DrillInFilteringAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Get All Store Lists
  async GetAllStoreLists () {
    // the api call path with the parameter string is needed
    const path = '/store_lists'
    const messages = {
      success: '---> API Call Successful: Retrieved All Store Lists',
      error: 'Error retrieving all store lists.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success) {
      if (response.data && response.data.length > 0) {
        const sortedLists = response.data.sort((a, b) =>
          FormatKey(a.store_list) > FormatKey(b.store_list) ? 0 : -1
        )
        return {
          allLists: sortedLists,
          listNames: sortedLists.map(list =>
            FormatKey(list.store_list)
          )
        }
      } else {
        return {
          allLists: [],
          listNames: []
        }
      }
    } else {
      return {
        allLists: [],
        listNames: []
      }
    }
  }

  // Get All Stores
  async GetAllStores () {
    // the api call path with the parameter string is needed
    const path = '/stores'
    const messages = {
      success: '---> API Call Successful: Retrieved a List of All Stores',
      error: 'Error retrieving a list of all stores.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success) {
      const data = response.data['stores'] || response.data
      return data.sort((a, b) => (a.id > b.id ? 0 : -1))
    } else {
      return []
    }
  }

  async GetStoresByStoreListID (store_list_id: string) {
    // the api call path with the parameter string is needed
    const path = `/store_list/stores/${store_list_id.replaceAll(
            '/',
            '%2F'
        )}`
    const messages = {
      success: `---> API Call Successful: Retrieved Store List: ${store_list_id}.`,
      error: `Error retrieving store list: ${store_list_id}.`
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success) {
      return response.data
    } else {
      return []
    }
  }

  // On Save Store List
  async OnSaveStoreList (data: SaveStoreListInterface) {
    // the api call path with the parameter string is needed
    const path = '/store_list/save'
    const messages = {
      success: `Successfully saved store list: ${data.store_list}.`,
      error: `Error saving store list: ${data.store_list}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async OnDeleteStoreList (store_list: SaveStoreListInterface) {
    // the api call path with the parameter string is needed
    const path = `/store_list/delete/${store_list.id.replaceAll(
            '/',
            '%2F'
        )}`
    const messages = {
      success: `Successfully deleted store list: ${store_list.store_list}.`,
      error: `Error deleting store list: ${store_list.store_list}.`,
      showSuccessToast: true
    }

    return await ExecuteDeleteAPI(path, this, messages)
  }
}
