const t = t => {
  var r;
  const n = Object.assign({
    default: void 0
  }, t);
  return null !== (r = n.default) && void 0 !== r ? r : n;
};
function r(t) {
  return null != t && !Array.isArray(t) && "object" == typeof t;
}
function n(t) {
  return "string" == typeof t || "object" == typeof t && "[object String]" === Object.prototype.toString.call(t);
}
function e(t) {
  return r(t) && "[object Map]" === Object.prototype.toString.call(t);
}
function o(t) {
  return r(t) && "[object Set]" === Object.prototype.toString.call(t);
}
function u(t) {
  return void 0 === t;
}
function c(t) {
  return null == t;
}
function i(t) {
  if (null == t) return !0;
  if (r(t) && (e(t) || o(t))) return !t.size;
  if (r(t) && (n(t) || Array.isArray(t))) return !t.length;
  for (const r in t) if (s(t, r)) return !1;
  return !0;
}
function f(t) {
  return Array.isArray(t) && i(t);
}
function l(...t) {
  return t.every(f);
}
function a(t) {
  return r(t) && i(t);
}
function p(...t) {
  return t.every(a);
}
function y(t) {
  return n(t) ? t.charAt(0).toUpperCase() + t.slice(1) : "";
}
function s(t, r) {
  return null != t && Object.prototype.hasOwnProperty.call(t, r);
}
function b(t) {
  return "function" == typeof t;
}
const j = (t, r) => r ? `${t}--${r}` : "",
  g = (t, r, n) => n ? `${t}--${r}` : "";
export { l as areEmptyArrays, p as areEmptyObjects, y as capitalize, j as classNameModifier, g as classNameModifierByFlag, s as has, i as isEmpty, b as isFunction, e as isMap, c as isNil, r as isObject, o as isSet, n as isString, u as isUndefined, t as sanitizeNamespaceImport };