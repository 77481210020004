<span
    class="cell-icon-row"
    [attr.hasValue]="value && value !== '' ? 'true' : 'false'"
    #iconRow
>
    <p>{{value}}</p>
    <!-- TODO: modify cursor style based on if it can be edited -->
    <!-- Static Icons -->
    <span
        [ngSwitch]="iconName"
        style="height: 100%; display: flex; align-items: center"
        matTooltip="{{ iconName }}"
        matTooltipClass="below"
        *ngIf="displayStaticIcon && staticIcon"
    >
        <!-- Lock Icon -->
        <span
            *ngSwitchCase="'lock'"
            class="material-icons lock-icon align-icon-center"
            style="font-size: 16px"
        >
            lock
        </span>
        <!-- Unlock Icon -->
        <span
            *ngSwitchCase="'unlock'"
            class="material-icons lock_open-icon align-icon-center"
            style="font-size: 16px"
        >
            lock_open
        </span>
    </span>
    <!-- Button Icons -->
    <button
        mat-icon-button
        [ngSwitch]="iconName"
        style="display: flex"
        [disableRipple]="true"
        (mouseenter)="cellPopoverEvent ? alertPopup.show($event, iconRow)  : '' "
        (mouseenter)="cellPopoverEvent ? onDisplayAlert($event, iconRow)  : '' "
        (mouseleave)="cellPopoverEvent ? alertPopup.hide() : ''"
        (mouseleave)="cellPopoverEvent ? onHideAlert($event, 'warn') : ''"
        *ngIf="(!cellPopoverEvent && !customAlertCompRef && !staticIcon) || cellPopoverEvent"
        (click)="cellPopoverEvent && alertLink ? onAlertAction($event) : ''"
        [color]="iconName !== 'error_outline'? 'accent' : ''"
    >
        <!-- Edit Icon -->
        <span
            *ngSwitchCase="'edit'"
            class="icon-button material-icons edit-icon align-icon-center"
        >
            edit
        </span>
        <!-- Select Icon -->
        <span
            *ngSwitchCase="'select'"
            class="icon-button material-icons arrow_drop_down align-icon-center"
            style="height: fit-content; width: fit-content; font-size: 24px"
        >
            arrow_drop_down
        </span>
        <!-- Warn Icon -->
        <span
            *ngSwitchCase="'error_outline'"
            class="icon-button material-icons error_outline align-icon-center"
        >
            error_outline
        </span>
    </button>
    <p-overlayPanel
        #alertPopup
        [ngStyle]="{'opacity': displayAlert ? 1 : 0}"
        *ngIf="cellPopoverEvent"
    >
        <ng-template pTemplate [ngSwitch]="customAlertCompRef">
            <!-- Popover Alert Handler for the Job Management Grid -->
            <app-job-management-status-alert
                *ngSwitchCase="'job-management-status-alert'"
                [cellParams]="params"
                [alertKey]="alertKey"
            >
            </app-job-management-status-alert>
        </ng-template>
    </p-overlayPanel>
</span>
