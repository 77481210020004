// Angular and RJX Imports
// =========================================================
import { Component, type OnDestroy, type OnInit } from '@angular/core'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ReviewOrdersAPIs } from 'src/app/core/apis/review-orders_api-calls'
import { DefaultCartonizationSettingsInputs } from 'src/app/core/interfaces/data-expected-from-backend/cartonization-settings-inputs-interface'
import { type ReviewOrdersParametersInterface } from 'src/app/core/interfaces/review-orders-interface'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'

@Component({
  selector: 'app-user-input-config',
  templateUrl: './user-input-config.component.html',
  styleUrls: ['./user-input-config.component.scss']
})
export class UserInputConfigSettingsComponent implements OnInit, OnDestroy {
  loading: boolean = true
  loadingMessage: string = 'loading'

  ObjectKeys = Object.keys
  parentGrid: any

  cartonSettings: ReviewOrdersParametersInterface = null
  ogCartonSettings: ReviewOrdersParametersInterface = null // monitor changes
  cartonSettingsToSubmit: ReviewOrdersParametersInterface = {
    job_id: 0,
    channel: null,
    product_id: null,
    id: null,
    ...DefaultCartonizationSettingsInputs
  }

  clearCartonSettingsForm: boolean
  changesMade: string[] = []

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    private readonly reviewOrdersAPIs: ReviewOrdersAPIs,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    console.log('---> User Input Config Modal: ', config.data)
    this.parentGrid = config.data
  }

  ngOnInit () {
    this.getCartonConfigParams()
  }

  getCartonConfigParams () {
    const { id } = this.parentGrid

    this.reviewOrdersAPIs.GetParameterSettings(id).then(res => {
      if (res.is_success) {
        this.loading = false
        this.cartonSettingsToSubmit = res.data
        this.cartonSettingsToSubmit.job_id = id

        this.ogCartonSettings = { ...res.data }
        this.cartonSettings = {
          editable: true,
          ...res.data
        }
      } else {
        // If there is an error getting data close the modal -> an error modal will be displayed
        this.close()
      }
    })
  }

  onFormValueChanged (form) {
    const field = form.controlName
    const knownChanges = this.changesMade.includes(field)
    const removeChange = () =>
      this.changesMade.splice(this.changesMade.indexOf(field), 1)
    console.log('--> Form value changed in Review Orders: ', form)
    console.log('--> Original Carton Settings: ', this.ogCartonSettings)

    // Update the data to send to the BE
    this.cartonSettings[field] = form.value
    this.cartonSettingsToSubmit[field] = form.value
    // Monitor Changes
    if (
      (this.ogCartonSettings &&
                this.ogCartonSettings[field] !== '' &&
                this.ogCartonSettings[field] != form.value) ||
            (this.ogCartonSettings[field] === '' &&
                form.value !== null &&
                form.value !== '')
    ) {
      // console.log('changes made: ', field)
      if (!knownChanges) this.changesMade.push(field)
    } else if (knownChanges) {
      removeChange()
    }
    console.log(': ', this.changesMade)
  }

  // Save configuration settings
  onSaveConfig (event) {
    console.log('---> Saving Configuration Settings')
    this.loading = true
    this.loadingMessage = 'updating'
    this.reviewOrdersAPIs
      .SubmitConfigurationSettings(this.cartonSettingsToSubmit)
      .then(res => {
        if (res?.is_success) {
          // console.log('suc')
          this.close({ parameters: this.cartonSettingsToSubmit })
        }
        this.loading = false
        this.loadingMessage = 'loading'
      })
  }

  // Track when the Default Parameters screen has finished updating
  onCartonSettingsFormReset (event) {
    this.clearCartonSettingsForm = false
  }

  close (data?: any) {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.ref) this.ref.close(data || null)
  }

  ngOnDestroy (): void {
    this.clearCartonSettingsForm = true
    this.cartonSettingsToSubmit = {
      job_id: 0,
      product_id: null,
      channel: null,
      id: null,
      ...DefaultCartonizationSettingsInputs
    }

    // this.triggerApiCallsService.onTriggerApiCalls({
    //   clear_api_calls: false
    // })
  }
}
