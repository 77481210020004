<div id="advanced-filter-toolpanel">
    <app-accordion-headers
        [panelOpenState]="true"
        sectionName="Product Filters"
        (panelStateChanged)="onPanelStateChanged($event)"
        [attr.panelFlex]="
            (openPanels.includes('product_filters') &&
                openPanels.length === 1) ||
            openPanels.length === 0
                ? 2
                : 1
        "
    >
        <!-- Reset Buying Season Filters -->
        <span class="reset-button-wrapper">
            <button
                mat-button
                color="warn"
                (click)="onResetProductFilters($event)"
                [disabled]="treeDataInitializing || loadingProdHier"
            >
                Clear
            </button>
        </span>
        <!-- Loading Placeholder -->
        <div *ngIf="treeDataInitializing || loadingProdHier">
            <app-loader
                class="loading-container"
                [loadingMessage]="'loading'"
            ></app-loader>
        </div>
        <!-- Tree Data -->
        <div *ngIf="!treeDataInitializing && !loadingProdHier">
            <p-tree
                #prodHierTreeData
                [value]="treeData"
                [(selection)]="allSelectedProductNodes"
                class="w-full"
                selectionMode="checkbox"
                (onNodeSelect)="onNodeSelectionChange($event, true)"
                (onNodeUnselect)="onNodeSelectionChange($event, false)"
                (onNodeExpand)="toggleNodeExpanded($event)"
                [loading]="loadingProdHier"
                [metaKeySelection]="false"
            >
                <!-- Empty message -->
                <ng-template
                    pTemplate="empty"
                    id="product-filter-empty-message"
                >
                    <p>No Products Found.</p>
                </ng-template>
                <!-- Node Display -->
                <ng-template let-node pTemplate="default">
                    <span class="custom-prod-node-wrapper">
                        <p class="node-item">
                            {{ node.label }}
                        </p>
                    </span>
                </ng-template>
            </p-tree>
        </div>
    </app-accordion-headers>
    <app-accordion-headers
        [panelOpenState]="true"
        sectionName="Buying Season Filters"
        (panelStateChanged)="onPanelStateChanged($event)"
        [attr.panelFlex]="
            (openPanels.includes('buying_season_filters') &&
                openPanels.length === 1) ||
            openPanels.length === 0
                ? 2
                : 1
        "
    >
        <!-- Reset Buying Season Filters -->
        <span class="reset-button-wrapper">
            <button
                mat-button
                color="warn"
                (click)="onResetBuyingSeasonFilters($event)"
                [disabled]="loadingBuyingSeasonFilters"
            >
                Clear
            </button>
        </span>
        <!-- Loading Placeholder -->
        <div *ngIf="loadingBuyingSeasonFilters">
            <app-loader
                class="loading-container"
                [loadingMessage]="'loading'"
            ></app-loader>
        </div>
        <!-- Buying Season Filter Selection -->
        <div
            class="filter-list-container custom-vertical-scroll"
            id="buying-season-filter-container"
            *ngIf="!loadingBuyingSeasonFilters"
        >
            <mat-checkbox
                *ngFor="
                    let filter of ObjectKeys(parentGrid.buyingSeasonFilters)
                "
                class="multi-checkbox"
                name="checkbox"
                [value]="parentGrid.buyingSeasonFilters[filter]"
                [checked]="parentGrid.buyingSeasonFilters[filter]"
                (change)="onValueSelected($event, filter)"
            >
                {{ FormatText(filter).toUpperCase() }}
            </mat-checkbox>
        </div>
    </app-accordion-headers>
    <!-- Filter Buttons -->
    <span class="filter-footer">
        <!-- Apply Filters -->
        <button
            mat-raised-button
            color="accent"
            (click)="onApplyAllFilters($event)"
            [disabled]="
                loadingBuyingSeasonFilters ||
                loadingProdHier ||
                treeDataInitializing
            "
        >
            Apply All
        </button>
        <!-- Cancel Filters -->
        <button
            mat-button
            color="warn"
            (click)="onResetAllFilters($event)"
            [disabled]="
                loadingBuyingSeasonFilters ||
                loadingProdHier ||
                treeDataInitializing
            "
        >
            Clear All
        </button>
    </span>
</div>
