// Angular Imports
// =========================================================
import { Component } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ITooltipAngularComp } from 'ag-grid-angular'
import { type ITooltipParams } from 'ag-grid-community'

@Component({
  selector: 'app-review-orders-carton_id-tooltip',
  templateUrl: './review-orders-carton_id-tooltip.component.html',
  styleUrls: ['./review-orders-carton_id-tooltip.component.scss']
})
export class CartonIDSizesTooltip implements ITooltipAngularComp {
  sizes: any[] = []
  value: any
  isHeaderCell: boolean = false
  packConfig: any

  constructor () {}

  agInit (params: ITooltipParams): void {
    console.log('carton config tooltip params', params)
    this.isHeaderCell = params.location === 'header'
    this.value = params.value
    if (!this.isHeaderCell) {
      console.log(params.context.getPackConfigs())
      this.packConfig =
                params.context.getPackConfigs()[
                  params.data.job_id +
                        '_' +
                        params.data.styc_id +
                        '_' +
                        this.value
                ]
      this.sizes = Object.keys(this.packConfig)
    }
  }
}
