<!-- Top Nav Bar -->
<mat-toolbar color="primary" class="toolbar">
    <!-- Left Side Nav Features -->
    <div>
        <a
            id="logo-link-wrapper"
            routerLink="/size-intelligence-modules-dashboard"
            (click)="
                onScreenChanged(
                    $event,
                    null,
                    '/size-intelligence-modules-dashboard'
                )
            "
        >
            <img
                class="sizeo-logo"
                alt="sizeo Logo"
                [src]="
                    environment.assets + '/icons/logos/Sizeo_logo_no_hanger.svg'
                "
            />
        </a>

        <button
            mat-icon-button
            (click)="drawer.toggle()"
            aria-label="Menu Options"
        >
            <mat-icon class="nav-bar-icon">menu</mat-icon>
        </button>
    </div>
    <!-- Right Side Nav Features -->
    <div>
        <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Account Details"
        >
            <mat-icon class="nav-bar-icon">account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="account-menu">
            <!-- User Details Container -->
            <span>
                <p class="menu-label">Username:&nbsp;</p>
                <p>{{ userFirstName }} {{ userLastName }}</p>
            </span>

            <!-- Session Time -->
            <span>
                <p class="menu-label">Session Time Remaining:</p>
                <p
                    id="session-time-counter"
                    [attr.session-ending]="sessionEnding"
                >
                    {{ minsRemaining + ':' + secsRemaining }}
                </p>
            </span>

            <mat-divider></mat-divider>
            <!-- Sign Out -->
            <button
                mat-menu-item
                (click)="onLogout($event)"
                class="sign-out-menu-btn"
                color="accent"
            >
                <mat-icon> logout </mat-icon>
                <p>Sign Out</p>
            </button>
        </mat-menu>
    </div>
</mat-toolbar>
<!-- Side Nav panel / page navigation -->
<mat-drawer-container class="container" autosize>
    <mat-drawer
        #drawer
        class="sidenav"
        mode="side"
        [opened]="currentScreen.name !== 'Dashboard'"
    >
        <!-- Return To Dashboard -->
        <a
            *ngIf="currentScreen.name !== 'Dashboard'"
            class="floating-nav-links-container"
            aria-label="Return To Dashboard"
            routerLink="/size-intelligence-modules-dashboard"
            (click)="
                onScreenChanged(
                    $event,
                    null,
                    '/size-intelligence-modules-dashboard'
                )
            "
        >
            <mat-icon aria-hidden="false" aria-label="Return To Dashboard"
                >house</mat-icon
            >
            <p class="text-lte m-letter-spacing">Dashboard</p>
        </a>
        <!-- Categories & links -->
        <div class="categories-links-container">
            <div *ngFor="let category of $ApplicationSections[0]">
                <h3 class="category-title">
                    {{ category.name }}
                </h3>
                <div
                    class="categorgy-children-container"
                    *ngFor="let section of category.children; let i = index"
                >
                    <!-- Accordion Sections -->
                    <span *ngIf="section.level === 'section'">
                        <mat-accordion id="side-nav-accordion">
                            <mat-expansion-panel
                                [disabled]="
                                    !section.is_enabled ||
                                    section.children.length === 0
                                "
                                [expanded]="
                                    currentScreen.section === section.name
                                "
                            >
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <!-- SubMenu -->
                                        <div
                                            fxLayout="row"
                                            fxLayoutAlign="space-between center"
                                            class="m-letter-spacing"
                                        >
                                            {{ section.name }}
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <span
                                    *ngFor="let screen of section.children"
                                    class="screen-link"
                                >
                                    <a
                                        mat-list-item
                                        [routerLink]="screen.route"
                                        (click)="
                                            onScreenChanged(
                                                $event,
                                                screen,
                                                section.name
                                            )
                                        "
                                    >
                                        <!-- Links -->
                                        <div
                                            [attr.active]="
                                                currentScreen.route ===
                                                screen.route
                                            "
                                            class="nav-link"
                                        >
                                            <p
                                                class="text-lte m-letter-spacing"
                                            >
                                                {{ screen.name }}
                                            </p>
                                        </div>
                                    </a>
                                </span>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </span>
                    <!-- Links to single screens -->
                    <a
                        *ngIf="section.level === 'screen'"
                        [routerLink]="section.route"
                        (click)="
                            section.is_enabled
                                ? onScreenChanged($event, section, section.name)
                                : ''
                        "
                        class="no-section-screen"
                        [attr.disabled]="!section.is_enabled"
                        [attr.active]="currentScreen.route === section.route"
                    >
                        <p
                            class="text-lte m-letter-spacing"
                            [attr.active]="
                                currentScreen.route === section.route
                            "
                            [attr.data-index]="i"
                        >
                            {{ section.name }}
                        </p>
                    </a>
                </div>
            </div>
        </div>
        <!-- Sign Out -->
        <a
            id="sign-out-container"
            class="floating-nav-links-container"
            aria-label="Sign Out"
            (click)="onLogout($event)"
        >
            <mat-icon aria-hidden="false" aria-label="Sign Out"
                >logout</mat-icon
            >
            <p class="text-lte m-letter-spacing">Sign Out</p>
        </a>
    </mat-drawer>

    <div class="sidenav-content">
        <!-- Section Information -->
        <div *ngIf="currentScreen.route && currentScreen.name !== 'Dashboard'">
            <h1>{{ currentScreen?.section }}</h1>
        </div>
        <!-- Main Content -->
        <div id="main-app-content-container">
            <ng-content></ng-content>
        </div>
    </div>
</mat-drawer-container>
