<app-modal-header
    modalTitle="Custom Store List"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div class="modal-content" [attr.size]="'large'" [attr.flexDirection]="'row'">
    <section class="primary-section-container" style="flex-basis: 25%">
        <app-basic-headers [sectionName]="'Saved Store Lists'" color="lte">
        </app-basic-headers>
        <div
            class="card flex justify-content-center"
            style="padding: 0px 10px; height: calc(100% - 24px); width: 100%"
        >
            <p-listbox
                #listBox
                [options]="savedStoreList"
                [(ngModel)]="selectedStoreList"
                optionLabel="store_list"
                [filter]="true"
                [style]="{ width: '100%', height: '100%' }"
                filterPlaceHolder="Search..."
                id="custom-store-list-saved-stores-container"
                (onChange)="onStoreSelectionChange($event)"
                [disabled]="loadingStores"
            >
                <ng-template let-storeList pTemplate="item">
                    <div
                        class="flex align-items-center"
                        class="store-list-option"
                        [attr.isSelected]="
                            selectedStoreList?.store_list ===
                            storeList?.store_list
                        "
                        style="justify-content: space-between; width: 100%"
                    >
                        <p class="m-0">{{ storeList?.store_list }}</p>
                        <div
                            *ngIf="
                                selectedStoreList?.store_list ===
                                storeList?.store_list
                            "
                        >
                            <app-confirm-popup
                                #confirmPopup
                                (confirmModal)="
                                    onDeleteStoreList($event, storeList)
                                "
                            ></app-confirm-popup>
                            <button
                                mat-icon-button
                                matTooltip="{{
                                    'Delete ' + storeList?.store_list
                                }}"
                                matTooltipClass="lastColumn"
                                color="warn"
                                (click)="
                                    $event.stopPropagation();
                                    confirmPopup.confirm(
                                        $event,
                                        'deleteStoreList'
                                    )
                                "
                            >
                                <span
                                    class="material-icons delete-icon align-icon-center"
                                >
                                    delete
                                </span>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </p-listbox>
        </div>
    </section>
    <section style="flex: 2" class="primary-section-container">
        <app-basic-headers [sectionName]="'Store List Details'" color="lte">
        </app-basic-headers>
        <div
            class="section-body-container"
            id="custom-store-list-editor-container"
        >
            <span
                style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid lightgrey;
                    margin-bottom: 20px;
                    flex-wrap: wrap;
                    column-gap: 70px;
                "
            >
                <!-- Store List Selection -->
                <span
                    style="
                        width: calc(50% - 35px);
                        padding-right: 10px;
                        min-width: 300px;
                    "
                >
                    <span
                        id="store-list-name-form"
                        style="column-gap: 10px; width: 100%"
                    >
                        <p class="form-title">Store List Name:</p>
                        <app-form-fields
                            [formParams]="formData"
                            [controlName]="'store_list'"
                            (onFormFieldUpdated)="updateFormValue($event)"
                            [hideLabel]="true"
                            [dynamicValue]="completedStoreList?.store_list"
                            style="width: calc(100% - 126px)"
                            [disabled]="loadingStores"
                        ></app-form-fields>
                    </span>
                </span>
                <!-- Action Buttons -->
                <span
                    style="
                        display: flex;
                        justify-content: flex-end;
                        padding: 0px 10px;
                        margin-bottom: 19px;
                        column-gap: 10px;
                    "
                >
                    <button
                        mat-button
                        color="warn"
                        (click)="onResetStoreList($event)"
                        [disabled]="
                            (!completedStoreList?.store_list &&
                                completedStoreList.stores.length === 0) ||
                            loadingStores
                        "
                    >
                        Reset
                    </button>

                    <button
                        id="store-list-save"
                        mat-raised-button
                        color="accent"
                        (click)="onSaveStoreList($event)"
                        [disabled]="
                            !completedStoreList?.store_list ||
                            completedStoreList?.store_list === '' ||
                            completedStoreList.stores.length === 0 ||
                            loadingStores ||
                            formDataErrors.length > 0
                        "
                    >
                        Save
                    </button>
                </span>
            </span>

            <p-pickList
                #pickList
                [source]="loadingStores ? [] : availableStores"
                [target]="loadingStores ? [] : completedStoreList.stores"
                sourceHeader="Available"
                targetHeader="Selected"
                [dragdrop]="true"
                [responsive]="false"
                [sourceStyle]="{ height: 'calc(100% - 117px)', border: 'none' }"
                [targetStyle]="{ height: 'calc(100% - 117px)', border: 'none' }"
                sourceFilterPlaceholder="Search by Store ID..."
                targetFilterPlaceholder="Search by Store ID..."
                [keepSelection]="false"
                [showSourceControls]="false"
                [showTargetControls]="false"
                [stripedRows]="true"
                (onMoveToSource)="sortStoreLists($event, 'source')"
                (onMoveAllToSource)="sortStoreLists($event, 'source')"
                (onSourceReorder)="sortStoreLists($event, 'source')"
                (onMoveAllToTarget)="sortStoreLists($event, 'target')"
                (onMoveToTarget)="sortStoreLists($event, 'target')"
                (onTargetReorder)="sortStoreLists($event, 'target')"
                [disabled]="loadingStores"
                rightButtonAriaLabel="Add store to the store list."
                leftButtonAriaLabel="Remove store from store list."
                allRightButtonAriaLabel="Add all stores to the store list."
                allLeftButtonAriaLabel="Remove all stores from the store list"
                filterBy="store_id"
            >
                <!-- [showSourceFilter]="true"
            [showTargetFilter]="true" -->
                <!-- Available Stores Header -->
                <ng-template pTemplate="sourceHeader">
                    <app-basic-headers
                        [sectionName]="'Available Stores'"
                        color="lte"
                    ></app-basic-headers>
                </ng-template>
                <!-- Available Stores Empty Placeholder -->
                <ng-template pTemplate="emptymessagesource">
                    <div class="flex-center-all">
                        <p *ngIf="!loadingStores">No stores available.</p>
                        <app-loader
                            *ngIf="loadingStores"
                            [loadingMessage]="loadingMessage"
                        ></app-loader>
                    </div>
                </ng-template>
                <!-- Target Stores Header -->

                <ng-template pTemplate="targetHeader">
                    <app-basic-headers
                        id="paste-zone-container"
                        [sectionName]="'Selected Stores'"
                        color="lte"
                    >
                        <span
                            id="paste-zone-icon"
                            class="material-icons content_paste_go-icon align-icon-center"
                            matTooltip="Click the icon then Ctrl + V to paste in an external list of stores."
                            [matTooltipClass]="'below'"
                        >
                            content_paste_go
                        </span>
                    </app-basic-headers>
                </ng-template>
                <!-- Available Stores Empty Placeholder -->
                <ng-template pTemplate="emptymessagetarget">
                    <div class="flex-center-all">
                        <p *ngIf="!loadingStores">No stores selected.</p>
                        <app-loader
                            *ngIf="loadingStores"
                            [loadingMessage]="loadingMessage"
                        ></app-loader>
                    </div>
                </ng-template>

                <ng-template let-store pTemplate="item">
                    <div
                        *ngIf="!loadingStores"
                        class="flex flex-wrap p-2 align-items-center gap-3"
                    >
                        <div class="flex-1 flex flex-column gap-2">
                            <span>{{ store.store_id }}</span>
                        </div>
                    </div>
                </ng-template>
            </p-pickList>
        </div>
    </section>
</div>

<app-modal-footer
    (closeModal)="close()"
    [showUpdateBtn]="false"
    [closeDisabled]="loadingStores"
>
</app-modal-footer>
