// Angular and RJX Imports
// =========================================================
import {
  Component,
  type OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core'
import { map, startWith } from 'rxjs/operators'
import {
  FormBuilder,
  type FormGroup,
  Validators,
  NgForm,
  FormControl
} from '@angular/forms'
import { Observable } from 'rxjs'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { cloneDeep } from 'lodash'
// Angular Material Imports
// =========================================================
import {
  MatAutocomplete,
  type MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete'
import { type MatRadioChange } from '@angular/material/radio'
import { type MatSelectChange } from '@angular/material/select'
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  type MatCheckboxDefaultOptions
} from '@angular/material/checkbox'
import { type MatChipInputEvent } from '@angular/material/chips'
// Custom Imports
// =========================================================
import {
  type FormFieldInterface,
  type FormGroupInterface
} from 'src/app/core/interfaces/form-field-interface'
import { GetFormErrorMessage } from '../form-error-handling/form-error-messages'
import { FormatKey, IsKeyInObj } from 'src/app/utils/global_functions'
import {
  CheckForDuplicates,
  CheckForEmailValidationCharacters,
  CheckForExistingValue,
  CheckForSpecialCharacters,
  CheckIsInteger,
  CheckS3FilePathForSpecialCharacters
} from '../form-error-handling/form-validators'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'

@Component({
  selector: 'app-form-fields',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./form-fields.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
    }
  ]
})
export class FormFieldsComponent implements OnInit {
  checkAutoSelectVal: boolean = false
  // Multi Selection Form with filter
  multiSelectOptions: string[] = []
  // Auto-Selection Form
  filteredColumns: Observable<string[]>
  mappedSelectionOptions: string[] = []
  selectedChipItems: any[] = null
  @Input('selectedChipItems')
  set _selectedChipItems (data: any) {
    this.selectedChipItems = data
  }

  @Input() formParams: Observable<FormGroupInterface>
  @Input() controlName: string
  @Input() hideLabel: boolean = false
  @Input() hintColor: string
  @Input() controlKey: string = null
  @Input() formName: string = null

  // The dynamic value is used for fields that are dynamically created
  dynamicValue: any = null
  @Input('dynamicValue')
  set _dynamicValue (data: any) {
    console.log('---> Dynamic Value Input data: ', data)
    // console.log('---> Dynamic Value Input dynamicValue: ', this.dynamicValue)

    if (data !== null && this.dynamicValue !== data) {
      this.dynamicValue = data
      // console.log("this.controlName: ", this.controlName)

      if (data && typeof data === 'object' && !Array.isArray(data)) {
        const resetData = [
          ...new Set(Object.values(data).map(val => val))
        ]

        if (resetData.length === 1 && resetData[0] === null) {
          this.selectionForm.reset()
          // console.log("this.form CONTROLS: ", this.selectionForm.value)
        } else {
          const valueID = this._formParams?.valueKey || null
          if (
            this.selectionForm.controls[this.controlName] &&
                        !valueID
          ) {
            this.selectionForm.controls[this.controlName].setValue(
              data
            )

            if (Object.keys(this.selectionForm.value).length > 1) {
              const tempObj = this.selectionForm.value
              delete tempObj[this.controlName]

              Object.keys(tempObj).forEach(key => {
                if (!tempObj[key] && data[key]) {
                  this.selectionForm.controls[key].setValue(
                    data[key]
                  )
                }
              })
            }
          }
        }
      } else {
        if (this.selectionForm.controls[this.controlName]) {
          if (
            this.selectionForm.controls[this.controlName].value !=
                        data
          ) {
            this.selectionForm.controls[this.controlName].setValue(
              data
            )
          }
        }
      }
    } else if (data === null || data === '') {
      this.dynamicValue = null
      this.selectionForm.reset()
    }
  }

  disabled: boolean
  @Input('disabled')
  set _disabled (isDisabled: boolean) {
    this.isFieldEnabled(isDisabled)
  }

  @Output() onFormFieldUpdated: EventEmitter<any> = new EventEmitter<any>()
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>()

  _formParams: FormFieldInterface

  separatorKeysCodes: number[] = [ENTER, COMMA] // Angular material auto-select chip list
  selectionForm: FormGroup
  loadingMessage: string = ''
  hasErrors: any = null

  @ViewChild('columns') matAutocomplete: MatAutocomplete
  @ViewChild('formDirective') formDirective: NgForm
  @ViewChild('chipSelectionInput')
    chipSelectionInput: ElementRef<HTMLInputElement>

  constructor (
    private readonly formBuilder: FormBuilder,
    private readonly generalAPIs: GeneralAPIs
  ) {
    this.selectionForm = this.formBuilder.group({
      [this.controlName]: ['', []]
    })
  }

  ngOnInit (): void {
    this.loadingMessage = 'Options'

    this.formParams.subscribe((data: FormGroupInterface) => {
      const controlName = data[this.controlName].controlName

      if (!this._formParams) {
        this._formParams = cloneDeep({ ...data[this.controlName] })
        this.multiSelectOptions =
                    this._formParams?.selectionOptions || []
        // console.log('Create Form: ', data[this.controlName])
        // console.log('Form Name: ', this.controlName)
        // console.log('_formParams: ', this._formParams)

        if (!this.selectionForm.controls[this.controlName]) {
          this.selectionForm = this.formBuilder.group({
            [this.controlName]: [
              {
                value:
                                    this.dynamicValue !== null
                                      ? this.dynamicValue
                                      : this._formParams.currentVal,
                disabled: this._formParams.disabled
              },
              []
            ]
          })
          if (this._formParams.formType === 'date-range-picker') {
            const value =
                            this._formParams.currentVal ||
                            this.dynamicValue ||
                            null
            const disabled = this._formParams.disabled

            this.selectionForm.addControl(
              'start',
              new FormControl(
                { value: value?.start, disabled },
                []
              )
            )
            this.selectionForm.addControl(
              'end',
              new FormControl(
                { value: value?.end, disabled },
                []
              )
            )
          }
          if (
            this._formParams.formType === 'auto-selection' &&
                        this._formParams.selectMultiple
          ) {
            this.selectedChipItems =
                            this._formParams.currentVal || this.dynamicValue
          }
        } else {
          // this.isFieldEnabled(this._formParams.disabled)
        }
      } else if (this._formParams.controlName === controlName) {
        this.checkUpdates({ ...data[this.controlName] })
      }
    })
  }

  checkUpdates (formData: FormFieldInterface) {
    const { validatorOptions } = { ...formData }
    const previousData = { ...this._formParams }
    // console.log('---> checking For Updates: ', formData)

    const monitorChanges = [
      'disabled',
      'selectionOptions',
      'loading',
      'defaultVal',
      'currentVal',
      'reset',
      'validatorOptions'
    ]

    if (!this.selectionForm.controls[this.controlName]) {
      this.selectionForm = this.formBuilder.group({
        [this.controlName]: ['', []]
      })
    }
    const currentFormVal =
            this.selectionForm.controls[this.controlName].value

    monitorChanges.forEach(async (key, i) => {
      const currentVal = { ...formData }[key]
      // console.log('change: ', key)
      // console.log('previous value data: ', previousData[key])
      // console.log('current value: ', currentVal)

      if (
        (IsKeyInObj(formData, key) &&
                    currentVal !== previousData[key]) ||
                (key === 'currentVal' && currentVal !== currentFormVal) ||
                (key === 'reset' && formData[key])
      ) {
        switch (key) {
          case 'disabled':
            this.isFieldEnabled(currentVal)
            break
          case 'selectionOptions':
            // this.selectionOptions = currentVal;
            this.multiSelectOptions = currentVal
            this._formParams.selectionOptions = currentVal
            // console.log(
            //   '----> Selection Options: ',
            //   this.multiSelectOptions
            // )
            break
          case 'loading':
            // this.loading = currentVal;
            break
          case 'defaultVal':
          case 'currentVal':
            // console.log('---- value change ----- ')
            // console.log('change: ', key)
            // console.log('previous value: ', previousData[key])
            // console.log('current value: ', currentVal)
            // console.log('current value: ', this.dynamicValue)
            // console.log('----------------------- ')
            if (this.dynamicValue === null) {
              if (currentVal === null || currentVal === '') {
                this.selectionForm.controls[
                  this.controlName
                ].setValue(null)
                this.selectedChipItems = []
                this.selectionForm.reset()
              } else {
                this.selectionForm.controls[
                  this.controlName
                ].setValue(currentVal)

                if (
                  this._formParams.formType ===
                                        'auto-selection' &&
                                    this._formParams.selectMultiple
                ) {
                  this.selectedChipItems = currentVal
                }
              }
            }
            break
          case 'reset':
            if (
              this.selectionForm.controls[this.controlName].value
            ) {
              this.selectionForm.reset()
              this.selectedChipItems = []
            }
            break
          case 'validatorOptions':
            this.checkValidation(currentVal)
        }
      }
      if (monitorChanges.length === i + 1) {
        this._formParams = { ...formData }
      }
    })
  }

  isFieldEnabled (disabled) {
    // console.log(
    //   'disabled this.selectionForm: ',
    //   this.selectionForm.controls
    // )
    // console.log('disabled param: ', disabled)
    if (this.selectionForm.controls[this.controlName]) {
      if (disabled) {
        this.selectionForm.controls[this.controlName].disable()
      } else {
        this.selectionForm.controls[this.controlName].enable()
      }
    }
  }

  // checkAsyncValidation (duplicates: string[]) {
  //   // console.log('type of validatorOptions: ', validatorOptions)
  //   if (duplicates.length > 0) {
  //     this.selectionForm?.controls[this.controlName].setAsyncValidators(
  //       validators
  //     )
  //     return validators
  //   } else {
  //     return []
  //   }
  // }

  checkValidation (validatorOptions, hasDuplicates = false) {
    console.log('type of validatorOptions: ', validatorOptions)
    if (
      validatorOptions !== null &&
            !Array.isArray(validatorOptions) &&
            typeof validatorOptions === 'object'
    ) {
      const validators = Object.entries(validatorOptions)
        .filter(
          ([key, val]) =>
            key !== 'required' || (key === 'required' && val)
        )
        .map(([key, val]) => {
          switch (key) {
            case 'minLength':
            case 'minlength':
              return Validators.minLength(Number(val))
            case 'maxlength':
            case 'maxLength':
              return Validators.maxLength(Number(val))
            case 'required':
              return Validators.required
            case 'checkForDuplicates':
              const arr = Array.isArray(val)
                ? val.map(v => FormatKey(v))
                : []
              return CheckForDuplicates(arr)
            case 'checkForExistingValue':
              return CheckForExistingValue(
                this.mappedSelectionOptions
              )
            case 'min':
              // console.log('min value: ', Number(val))
              return Validators.min(Number(val))
            case 'max':
              return Validators.max(Number(val))
              // case 'pattern':
              //   return Validators.pattern(`${val}`)
            case 'checkForEmailValidationCharacters':
              return CheckForEmailValidationCharacters()
            case 'checkIsInteger':
              return CheckIsInteger()
            case 'checkForSpecialCharacters':
              return CheckForSpecialCharacters()
            case 'checkS3FilePathForSpecialCharacters':
              // console.log("VALIDATORS: ", val)
              // console.log("VALIDATORS: ", CheckS3FilePathForSpecialCharacters(val))
              return CheckS3FilePathForSpecialCharacters(val)
            default:
          }
        })
      if (validators.length > 0) {
        this.selectionForm?.controls[this.controlName].setValidators(
          validators
        )
        return validators
      } else {
        return []
      }
    } else if (Array.isArray(validatorOptions)) {
      this.selectionForm?.controls[this.controlName].setAsyncValidators(
        validatorOptions
      )
      return validatorOptions
    }
  }

  setFilters (customFilter?: string) {
    const control = customFilter || this.controlName

    this.filteredColumns = this.selectionForm.controls[
      control
    ].valueChanges.pipe(
      startWith(null),
      map((value: string | null) => {
        const name =
                    value && typeof value === 'object'
                      ? [value][this._formParams?.displayKey]
                      : value
        // console.log('name: ', name)
        // console.log('filter list: ', this._formParams.selectionOptions)
        // console.log('this._formParams: ', this._formParams.selectionOptions)

        return name
          ? this._filter(name || '')
          : this._formParams?.selectionOptions?.slice()
      })
    )
  }

  private _filter (value: string): string[] {
    const isValTypeString = typeof value === 'string'

    if (
      this._formParams.formType === 'auto-selection' &&
            (isValTypeString || typeof value === 'number')
    ) {
      this.mappedSelectionOptions = this._formParams.selectionOptions.map(
        opt =>
          FormatKey(
            this._formParams?.displayKey
              ? opt[this._formParams?.displayKey].toString()
              : opt.toString()
          )
      )
      const filterValue = isValTypeString ? value.toLowerCase() : value

      return this._formParams.selectionOptions.filter(option => {
        const opt = this._formParams?.displayKey
          ? option[this._formParams?.displayKey]
          : option
        return isValTypeString && isNaN(Number(opt))
          ? opt.toLowerCase().includes(filterValue)
          : opt.toString().includes(filterValue.toString())
      })
    }
  }

  setColumn (data: any, value) {
    const requiredField =
            this._formParams?.validatorOptions?.required || false
    console.log('---> Setting Value: ', data, value)
    this.checkAutoSelectVal = value === 'value-update'

    const setErrors = () => {
      console.log('not valid')
      this.hasErrors = { invalid: true }
      this.selectionForm.controls[this.controlName].setErrors(
        this.hasErrors
      )
      this.emitChange('auto-select', data)
    }
    if (value !== this.selectionForm.controls[this.controlName].value) {
      if (
        this.mappedSelectionOptions.length > 0 &&
                ((data && data !== undefined) || !requiredField)
      ) {
        const name =
                    data && typeof data === 'object'
                      ? data[this._formParams?.displayKey]
                      : data
        const existingValue = this.mappedSelectionOptions.includes(
          isNaN(name) ? FormatKey(name) : `${name}`
        )
        console.log('---> auto input value: ', name)
        // console.log("---> mappedSelectionOptions: ", this.mappedSelectionOptions)
        // console.log("---> existing value: ", existingValue)
        // console.log('---> this.hasErrors: ', this.hasErrors)
        // console.log('filter list: ', this._formParams.selectionOptions)

        if (
          existingValue ||
                    (!requiredField && (!name || name === ''))
        ) {
          console.log('---> valid input')
          this.emitChange('auto-select', data)
        } else {
          setErrors()
        }
      } else if (
        this._formParams?.validatorOptions.required &&
                this.mappedSelectionOptions.length > 0
      ) {
        setErrors()
      } else if (
        this.mappedSelectionOptions.length === 0 &&
                !requiredField
      ) {
        this.emitChange('auto-select', data)
      }
    }
  }

  displayFn (data: any) {
    if (data != null && data && data !== undefined) {
      console.log('display fn: ', data)
      console.log(
        '---> Selection Form Value: ',
        this.selectionForm.controls[this.controlName].value
      )

      if (
        this.hasErrors &&
                !this.selectionForm.get(this.controlName).errors
      ) {
        this.selectionForm.controls[this.controlName].setErrors(
          this.hasErrors
        )
      }

      if (this.selectionForm.hasError('required')) {
        delete this.selectionForm.errors.required
        this.selectionForm.updateValueAndValidity()
      }

      return typeof data === 'object'
        ? data[this._formParams?.displayKey]
        : data
    } else {
      return ''
    }
  }

  // For Chip List Only -> For Creating Custom chip items
  addCustomChip (event: MatChipInputEvent): void {
    // console.log('---> Adding Custom Chip: ', event)
    const value = (event?.value || '').trim()

    // Add
    if (value && !this.selectedChipItems.includes(value)) {
      this.selectedChipItems.push(value)
    }
    // Clear the input value
    event.chipInput.clear()
  }

  // For Chip List Only
  selectedChip (event: MatAutocompleteSelectedEvent): void {
    console.log('---> chip selected: ', event)
    if (
      !this.selectedChipItems ||
            (this.selectedChipItems &&
                !this.selectedChipItems.includes(event.option.viewValue))
    ) {
      // Setting the selected chips
      this.selectedChipItems
        ? this.selectedChipItems.push(event.option.viewValue)
        : (this.selectedChipItems = [event.option.viewValue])
      // Updating the form object
      this.selectionForm.controls[this.controlName].setValue(
        this.selectedChipItems
      )
      // Updating the formParams & Emitting the changes to the parent component
      this._formParams.currentVal = this.selectedChipItems

      this.onFormFieldUpdated.emit({
        formType: 'chip-selection',
        controlName: this.controlName,
        form: this.selectionForm,
        value: this.selectedChipItems,
        status:
                    this.selectedChipItems.length > 0
                      ? 'VALID'
                      : this.selectionForm.get(this.controlName).status
      })
    }
    // Resetting the native input value
    this.chipSelectionInput.nativeElement.value = ''
  }

  // For Chip List Only
  removeSelectedChip (item: string) {
    const index = this.selectedChipItems.indexOf(item)

    if (index >= 0) {
      this.selectedChipItems.splice(index, 1)
      // Updating the form object
      this.selectionForm.controls[this.controlName].setValue(
        this.selectedChipItems
      )
      console.log('---> chip selected: ', this.selectedChipItems)

      this._formParams.currentVal = this.selectedChipItems
      return true
    } else {
      return false
    }
  }

  onRemoveChipListItem (event, selectedColumn) {
    console.log('---> Removing Chip list Item: ', selectedColumn)
    const chipRemoved = this.removeSelectedChip(selectedColumn)

    if (chipRemoved) {
      this.onFormFieldUpdated.emit({
        formType: 'chip-selection',
        controlName: this.controlName,
        form: this.selectionForm,
        value:
                    this.selectedChipItems.length > 0
                      ? this.selectedChipItems
                      : null,
        status:
                    this.selectedChipItems.length > 0
                      ? 'VALID'
                      : this.selectionForm.get(this.controlName).status
      })
    }
  }

  onLeaveChipInput (event): void {
    console.log('---> leaving chip input: ', event)
    if (
      this._formParams.validatorOptions.required &&
            (!this.selectedChipItems || this.selectedChipItems.length === 0)
    ) {
      this._formParams.currentVal = null
      this.onFormFieldUpdated.emit({
        formType: 'chip-selection',
        controlName: this.controlName,
        form: this.selectionForm,
        value: null,
        status: 'INVALID'
      })
    }
  }

  setFormError (controlName, form) {
    const params = {
      controlName,
      form,
      customMessages: {}
    }

    const errors = form.controls[controlName].errors
    // console.error('Form Validation Errors controlName: ', controlName);
    // console.error('Form Validation Errors form: ', form)

    if (errors && this.hasErrors !== errors) {
      // console.error('Form Validation Errors: ', errors)
      this.hasErrors = errors
    }

    return GetFormErrorMessage(params)
  }

  onSelectionChange (event: MatSelectChange) {
    console.log('---> Selection Changed: ', event)
    this.emitChange('selection', event.value)
  }

  onInputChange (value: string) {
    let formattedVal: any =
            value && value?.length > 1 ? value?.trim() : value
    formattedVal =
            formattedVal === null ||
            formattedVal === '' ||
            isNaN(Number(formattedVal))
              ? formattedVal
              : Number(formattedVal)
    console.log('----> Input update: ', value)
    const validation = this.checkValidation(
      this._formParams.validatorOptions
    )

    if (validation) {
      this.emitChange('input', formattedVal)
    }
  }

  onRadioChange (event: MatRadioChange) {
    // console.log("Radio Value Changed: ", event)
    this.emitChange('radio', event.value)
  }

  onCheckboxChange (event) {
    // NOTE: need the value variable set as below to account for the case of null
    const value = !!this._formParams.currentVal
    console.log('---> checkbox change event: ', event.target.value)
    this._formParams.currentVal = !value
    this.emitChange('checkbox', !value)
  }

  onDateRangeChange (form, date) {
    const dates = { ...form }
    if (dates[this.controlName]) delete dates[this.controlName]

    console.log('---> Date Range Updated: ', date)
    console.log('---> Current form Data: ', this.selectionForm)
    this.selectionForm.controls[this.controlName].setValue({ ...dates })
    this.emitChange('date-range-picker', dates)
  }

  emitChange (formType, value) {
    // this.checkValidation(this._formParams.validatorOptions)

    this.formIsValid()
    this.onFormFieldUpdated.emit({
      formType,
      controlName: this.controlName,
      form: this.selectionForm,
      value,
      status:
                this.selectionForm.get(this.controlName).touched ||
                !this.selectionForm.get(this.controlName).pristine
                  ? this.selectionForm.get(this.controlName).status
                  : 'VALID'
    })
  }

  formIsValid () {
    if (this.selectionForm.status === 'VALID') {
      this.hasErrors = null
    }
  }
}
