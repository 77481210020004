// Angular and RJX Imports
// =========================================================
import {
  Component,
  type AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  ElementRef
} from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ICellEditorAngularComp } from 'ag-grid-angular'
import 'ag-grid-enterprise'
import { type FirstDataRenderedEvent, type GridApi } from 'ag-grid-community'
// Custom Imports
// =========================================================
import { FormatJSONColData } from '../../02_global-settings/01_transformJSONColData'
import { CustomGridOptions } from 'src/app/05_ag-grid-configs/02_global-settings/grid-options'

@Component({
  selector: 'multi-selection',
  // encapsulation: ViewEncapsulation.None, // This was causing UI issues with other components that used forms
  host: {
    style: `position: absolute;
					left: 0px;
					top: 0px;
                    background-color: transparent;
                    z-index: 3000;
                    `
  },
  templateUrl: './multi-selection-cell-renderer.html',
  styleUrls: ['./multi-selection-cell-renderer.scss']
})
export class MultiSelectionCellRenderer
implements ICellEditorAngularComp, AfterViewInit {
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this
      // pageTitle: ''
    }
  }

  // variables for agGrid
  gridOptions: any = {}
  public params: any
  private gridApi: GridApi
  public rowData: any
  private orgRowData: any
  public columnDefs: any[]

  // variables for component
  public returnObject: boolean
  public cellValue: string
  public inputValue: string
  public gridHeight: number = 160
  public gridWidth: number
  public propertyName: string
  public isCanceled: boolean = true
  public selectedObject: any = {}
  selectedItems: any[] = []

  @ViewChild('input') input: ElementRef

  constructor () {
    this.gridOptions = {
      ...this.customGridOptions,
      statusBar: {
        statusPanels: null
      },
      onRowSelected: event => this.onRowSelected(event),
      onFirstDataRendered: params => this.onFirstDataRendered(params)
    }
  }

  // Update checkboxes based on users products
  onFirstDataRendered (params: FirstDataRenderedEvent) {
    params.api.forEachNode(node => {
      return node.setSelected(
        this.selectedItems.includes(node.data.products)
      )
    })
  }

  ngAfterViewInit () {
    window.setTimeout(() => {
      if (this.inputValue == this.cellValue) {
        this.input.nativeElement.select()
      } else {
        this.input.nativeElement.focus()
      }
    })
  }

  // ICellEditorAngularComp functions
  async agInit (params: any) {
    this.params = params
    console.log('---> Multi select params: ', params)
    // Set the dynamic property name
    const colId = params.propertyRendered
    this.propertyName = colId
    // set this.params.data[this.propertyName] to empty array if null detected
    if (this.params.data[this.propertyName][0] === null) {
      this.params.data[this.propertyName] = []
    }
    // Set the column Definitions
    const mainCols: any = await FormatJSONColData(
      { [params.columnDefs[0].headerName]: params.columnDefs[0] },
      'main',
      null
    )
    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)
    this.columnDefs = results[m]

    this.cellValue = params.value[this.propertyName]
    this.returnObject = params.returnObject

    if (this.selectedItems.length === 0) {
      this.selectedItems = params.data[this.propertyName] || []
    }
    // Configure the selection options
    const multiSelectOptions =
            params.context.componentParent.multiSelectOptions[
              this.propertyName
            ]
    // Configure the rowData
    const rowData =
            multiSelectOptions?.length === 0
              ? []
              : multiSelectOptions.map((opt, i) => {
                const data =
                          typeof opt === 'object'
                            ? { ...opt }
                            : { [colId]: opt }
                return { id: i, ...data }
              })
    this.orgRowData = [...rowData]
    this.rowData = rowData

    params.colDef.cellEditorParams.rowData = rowData

    console.log('---> property name: ', this.propertyName)

    if (!params.charPress) {
      if (this.cellValue) this.inputValue = this.cellValue
    } else {
      this.inputValue = params.charPress
    }
  }

  getValue (): any {
    if (!this.returnObject) return this.selectedObject[this.propertyName]
    return this.selectedObject
  }

  isPopup (): boolean {
    return true
  }

  isCancelAfterEnd (): boolean {
    return this.isCanceled
  }

  // ag-Grid functions
  onGridReady (params) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
  }

  //  Filter out based on user input
  async updateFilter (event?) {
    try {
      if (
        this.inputValue === '' ||
                this.inputValue === null ||
                this.inputValue === undefined
      ) {
        this.gridApi.setRowData(this.orgRowData)
      } else {
        const filteredRows = this.orgRowData.filter(data =>
          data[this.propertyName].includes(this.inputValue)
        )
        this.gridApi.setRowData(filteredRows)
      }
    } catch (error) {}
  }

  navigateGrid () {
    if (
      this.gridApi.getFocusedCell() == null ||
            this.gridApi.getDisplayedRowAtIndex(
              this.gridApi.getFocusedCell().rowIndex
            ) == null
    ) {
      // check if no cell has focus, or if focused cell is filtered
      this.gridApi.setFocusedCell(
        this.gridApi.getDisplayedRowAtIndex(0).rowIndex,
        this.propertyName
      )
      this.gridApi
        .getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex)
        .setSelected(true)
    } else {
      this.gridApi.setFocusedCell(
        this.gridApi.getFocusedCell().rowIndex,
        this.propertyName
      )
      this.gridApi
        .getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex)
        .setSelected(true)
    }
  }

  navigateMainGrid () {
    this.params.api.tabToNextCell()
  }

  onRowSelected (event) {
    console.log('---> Row Selected Event: ', event)
    // console.log('---> Selected Items: ', this.selectedItems)
    // console.log('---> Node Selected: ', event.node.selected)
    // console.log('---> property name: ', this.propertyName)
    // console.log('---> value: ', event.data[this.propertyName])
    const { selected } = event.node
    const currentValue = event.data[this.propertyName]

    if (selected && !this.selectedItems.includes(currentValue)) {
      console.log('---> add to selection')
      this.selectedItems = [...this.selectedItems, currentValue]
      this.params.node.setDataValue(
        [this.propertyName],
        this.selectedItems
      )
    }

    if (!selected && this.selectedItems.includes(currentValue)) {
      console.log('---> remove from selection')

      this.selectedItems = this.selectedItems.filter(product => {
        return product !== currentValue
      })

      // this.params.disabled = true
      // event.node.disabled = true
      this.params.node.setDataValue(
        [this.propertyName],
        this.selectedItems
      )
      console.log('---> Setting parent row selection: ', this.params)
    }
  }

  closeGrid () {
    console.log('---> Close Grid')
    this.params.api.stopEditing()
  }
}
