// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// AWS Imports
// =========================================================
import { API, graphqlOperation } from 'aws-amplify'
import { type GraphQLSubscription } from '@aws-amplify/api'
// Axios Imports
// =========================================================
import axios from 'axios'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import {
  ExecuteDeleteAPI,
  ExecuteGetAPI,
  ExecutePostAPI
} from './execute_api-calls'

import {
  type BulkOverrideStoreInterface,
  type BulkOverrideSizeInterface,
  type DistroLocationsInterface,
  type DistroCopyBuyingSeasonInterface,
  type GetAllSizeDistrosInterface,
  type AutoSaveDistroTagInterface,
  type SizeDistroInterface,
  type DistroCopyProductInterface
} from '../interfaces/data-expected-from-backend/size-distro-management-summary'
import { TriggerApiCallsService } from '../services/cancel-api-call'
import { type Response_alterationsSubscription } from '../../../API'
import * as subscriptions from '../../../graphql/subscriptions'

@Injectable()
export class SizeDistroManagementSummaryAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Get All Size Distros
  async GetAllSizeDistros (data: GetAllSizeDistrosInterface) {
    console.log('Size Distros', data)
    // the api call path with the parameter string is needed
    const path = '/size_distro/grid'
    const messages = {
      success:
                '---> API Call Successful: Retrieved Size Distro Summary Data File Path',
      error: 'Error retrieving rowData for the Size Distro Summary Grid.'
    }

    const dataParams = await ExecutePostAPI(path, this, data, messages)

    if (dataParams?.is_success) {
      return await axios
        .get(dataParams.data, {})
        .then(response => {
          return response.data
        })
        .catch(error => {
          console.error(
            'Error retrieving size distro summary data: ',
            error
          )
          this.toastAlert.showError('Error Loading Data')
          throw error
        })
    } else {
      return {}
    }
  }

  // Auto save edited distro tag
  async AutoSaveDistroTag (data: AutoSaveDistroTagInterface) {
    const tagObject = {
      distro_tag: data.distro_tag,
      distro_id: data.distro_id
    }
    // the api call path with the parameter string is needed
    const path = '/size_distro/tag'
    const messages = {
      success: `Successfully updated the distro tag for ${data.distro_id}.`,
      error: `Error updating the distro tag for ${data.distro_id}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, tagObject, messages)
  }

  // On approve distro
  async OnApprove (data: SizeDistroInterface) {
    // Get all the approved distro_ids
    const distroIds = {
      distro_ids: data.distro_ids
    }
    const totalNumOfDistros = data.distro_ids.length
    const suffix = totalNumOfDistros > 1 ? 's' : ''
    // the api call path with the parameter string is needed
    const path = '/size_distro/approve'
    const messages = {
      success: `Successfully approved ${totalNumOfDistros} distro${suffix}.`,
      error: `Error approving distro${suffix}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, distroIds, messages)
  }

  // On unapprove distro
  async OnUnapprove (data: SizeDistroInterface) {
    // Get all the unapproved distro_ids
    const distroIds = {
      distro_ids: data.distro_ids
    }
    const totalNumOfDistros = data.distro_ids.length
    const suffix = totalNumOfDistros > 1 ? 's' : ''
    // the api call path with the parameter string is needed
    const path = '/size_distro/unapprove'
    const messages = {
      success: `Successfully unapproved ${totalNumOfDistros} distro${suffix}.`,
      error: `Error unapproving ${totalNumOfDistros} distro${suffix}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, distroIds, messages)
  }

  // On revert To The System Distros
  async OnRevertToTheSystemDistros (data: SizeDistroInterface) {
    // Get all the revert distro_ids
    const distroIds = {
      distro_ids: data.distro_ids
    }
    const totalNumOfDistros = data.distro_ids.length
    const suffix = totalNumOfDistros > 1 ? 's' : ''
    // the api call path with the parameter string is needed
    const path = '/size_distro/summary/revert'

    const messages = {
      success: `Successfully reverted ${totalNumOfDistros} distro${suffix} to system values.`,
      error: `Error reverting ${totalNumOfDistros} distro${suffix} to system values.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, distroIds, messages)
  }

  // On delete distro
  async OnDeleteDistro (data: SizeDistroInterface) {
    // Get all the deleted distro_ids
    const distroIds = {
      distro_ids: data.distro_ids
    }
    const totalNumOfDistros = data.distro_ids.length
    const suffix = totalNumOfDistros > 1 ? 's' : ''
    // the api call path with the parameter string is needed
    const path = '/size_distro'

    const messages = {
      success: `Successfully deleted ${totalNumOfDistros} distro${suffix}.`,
      error: `Error deleting ${totalNumOfDistros} distro${suffix}.`,
      showSuccessToast: true
    }
    return await ExecuteDeleteAPI(path, this, messages, distroIds)
  }

  // On Copy Product distro
  // For the Distro Copy Product Modal
  async OnDistroCopyProduct (data: DistroCopyProductInterface) {
    // the api call path with the parameter string is needed
    const path = '/size_distro/copy_product'
    const messages = {
      success: `Successfully copied ${data.target.label} to new product.`,
      error: `Error copying ${data.target.label} to new product.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  // For the Distro Copy Buying Season Modal
  // TODO : confirm if we are allowing for multiple distros to be copied Juhi 8/23
  async OnDistroCopyBuyingSeason (data: DistroCopyBuyingSeasonInterface) {
    const path = '/size_distro/copy_buying_season'
    const messages = {
      success: `Successfully copied distro to new buying season: ${data.new_buying_season}.`,
      error: `Error copying distro to new buying season: ${data.new_buying_season}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async GetBuyingSeasonsFilter () {
    // the api call path with the parameter string is needed
    const path = '/size_distro/grid/filter'
    const messages = {
      success:
                '---> API Call Successful: Retrieved Buying Season Filters',
      error: 'Error retrieving buying season filters.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success) {
      return response
    } else {
      return []
    }
  }

  async GetProductStyleFilters () {
    // the api call path with the parameter string is needed
    const path = '/products/style_level'
    const messages = {
      success: '---> API Call Successful: Retrieved Product Filters',
      error: 'Error retrieving product filters.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    console.log('response: ', response)
    if (response && response?.is_success) {
      return await axios
        .get(response.data, {})
        .then(res => {
          console.log('res: ', res)
          return res.data
        })
        .catch(error => {
          console.error(
            'Error retrieving products style level data: ',
            error
          )
          this.toastAlert.showError('Error Loading Data')
          throw error
        })
    } else {
      console.error('Error retrieving products style level file')
      this.toastAlert.showError('Error Loading Data')
      return []
    }
  }

  // For the Bulk Override Modal
  async GetDistroLocations (data: DistroLocationsInterface) {
    const path = '/size_distro/locations'
    const messages = {
      success: '---> API Call Successful: Retrieved Distro Locations',
      error: 'Error retrieving distro locations.'
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async onSubmitBulkOverrideSize (data: BulkOverrideSizeInterface) {
    const path = '/size_distro/bulk/override/size'
    const messages = {
      success:
                'Successfully submitted bulk override size distro to size distro data.',
      error: 'Error submitting bulk override size distro to size distro data.',
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async onSubmitBulkOverrideStore (data: BulkOverrideStoreInterface) {
    const path = '/size_distro/bulk/override/store'
    const messages = {
      success:
                'Successfully submitted bulk override store distro to size distro data.',
      error: 'Error submitting bulk override size distro to store distro data.',
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async SizeAlterationsSubscription (gridApi) {
    console.log('inside SizeAlterationsSubscription')

    const subscription = API.graphql<
    GraphQLSubscription<Response_alterationsSubscription>
    >(graphqlOperation(subscriptions.response_alterations, {})).subscribe({
      next: async ({ provider, value }) => {
        console.log('inside subscriber next', value)

        const signed_url = value.data?.response_alterations?.signed_url
        const status = value.data?.response_alterations?.status
        console.log('signed_url', signed_url)

        if (signed_url && status) {
          return await axios
            .get(signed_url, {})
            .then(response => {
              let new_alt_rows = null

              // The new rows that need to be inserted in the grid
              if (!new_alt_rows) {
                new_alt_rows = response.data

                const rowsToAdd = [
                  ...new Set(
                    response.data.map(row => {
                      const isExistingRow =
                                                gridApi.getRowNode(
                                                  row.distro_id
                                                ) || null
                      if (!isExistingRow) {
                        return row
                      } else {
                        return null
                      }
                    })
                  )
                ]
                if (
                  rowsToAdd[0] &&
                                    typeof rowsToAdd[0] === 'object'
                ) {
                  const res = gridApi.applyTransaction({
                    addIndex: 0,
                    add: new_alt_rows
                  })
                  console.log(
                    '---> new_alt_rows added: ',
                    res
                  )
                } else {
                  console.log(
                    '---> Cannot add new rows, the rows currently exist: ',
                    response.data
                  )
                  return {}
                }
              }
            })
            .catch(error => {
              console.error(
                'Error retrieving size distro altered rows: ',
                error
              )
              this.toastAlert.showError('Error Loading Data')
              return {}
            })
        }
      },
      error: error => console.warn(error)
    })
  }
}
