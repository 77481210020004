<app-modal-header
    [modalTitle]="modalTitle"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div
    class="modal-content"
    [attr.loading]="loading"
    [attr.flexDirection]="'row'"
    [attr.size]="'large'"
>
    <!-- Main section -->
    <section
        *ngIf="!loading"
        id="store-index-output-tabs-container"
        class="primary-section-container w-100"
    >
        <mat-tab-group
            #changeEvent
            id="output-modal-tab-group"
            color="accent"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            [selectedIndex]="activeTab"
            (selectedIndexChange)="tabChanged($event)"
            preserveContent="true"
        >
            <!-- Store Index Table data Tab -->
            <mat-tab label="Table">
                <ng-template matTabContent>
                    <div id="output-grid-container">
                        <span class="output-grid-button-wrapper">
                            <button
                                mat-raised-button
                                color="accent"
                                (click)="onRebalanceRows($event)"
                                [disabled]="
                                    loading ||
                                    (!isRebalancedEnabled &&
                                        gridOptions?.context.percentageTotal ===
                                            100) ||
                                    (!isRebalancedEnabled &&
                                        gridOptions?.context.percentageTotal ===
                                            0)
                                "
                            >
                                Rebalance
                            </button>
                        </span>

                        <ag-grid-angular
                            id="store-index-output-grid"
                            [columnDefs]="columnDefs"
                            class="ag-theme-alpine ag-grid-container"
                            [gridOptions]="gridOptions"
                        ></ag-grid-angular>
                    </div>
                </ng-template>
            </mat-tab>
            <!-- Store Index Charts data Tab -->
            <mat-tab
                label="Charts"
                [disabled]="
                    loading || gridOptions.context.percentageTotal === 0
                "
            >
                <ng-template matTabContent>
                    <div id="main-chart-wrapper">
                        <!-- Doughnut Chart Section -->
                        <section id="doughnut-section">
                            <app-basic-headers
                                [sectionName]="'Channel Split'"
                                color="lte"
                            ></app-basic-headers>
                            <div
                                class="section-body-container chartWrapper custom-vertical-scroll"
                            >
                                <p-chart
                                    class="chart"
                                    id="store-index-output-doughnut-chart"
                                    type="doughnut"
                                    [data]="doughnutChartConfig"
                                    [options]="doughnutChartOptions"
                                >
                                </p-chart>
                            </div>
                        </section>
                        <!-- Bar Chart Section -->
                        <section id="bar-chart-section">
                            <app-basic-headers
                                [sectionName]="
                                    'Store Split - Original vs Current'
                                "
                                color="lte"
                            ></app-basic-headers>
                            <div
                                class="section-body-container chartWrapper custom-vertical-scroll"
                            >
                                <p-chart
                                    id="mainBarChart"
                                    class="chart"
                                    type="bar"
                                    model="chartView.horizontalBarModel"
                                    [data]="barChartConfig"
                                    [options]="barChartOptions"
                                    [width]="parentWidth"
                                    height="300px"
                                    [responsive]="false"
                                >
                                </p-chart>
                                <canvas
                                    id="barChartAxis"
                                    height="300"
                                    width="0"
                                    style="height: 300px"
                                ></canvas>
                                <!-- Legend Container -->
                                <div id="bar-chart-custom-legend-container">
                                    <div
                                        id="#current-changes-btn-wrapper"
                                        class="legend-container"
                                    >
                                        <!-- color block -->
                                        <div
                                            class="legend-color-block"
                                            [attr.label]="'original'"
                                        ></div>
                                        <p>Original Values</p>
                                    </div>
                                    <div
                                        id="#original-values-btn-wrapper"
                                        class="legend-container"
                                    >
                                        <!-- color block -->
                                        <div
                                            class="legend-color-block"
                                            [attr.label]="'current'"
                                        ></div>
                                        <p>Current Values</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </section>
    <!-- Loading Component -->
    <app-loader *ngIf="loading" [loadingMessage]="loadingMessage"></app-loader>
</div>

<app-modal-footer
    (closeModal)="close()"
    [showUpdateBtn]="true"
    (update)="onSaveOutputChanges($event)"
    confirmBtnText="Save"
    [disabled]="
        loading ||
        activeTab === 1 ||
        isRebalancedEnabled ||
        gridOptions?.context.percentageTotal !== 100 ||
        !rebalanceExecuted
    "
>
</app-modal-footer>
