export var CONTROL_MSG;
(function (CONTROL_MSG) {
  CONTROL_MSG["CONNECTION_CLOSED"] = "Connection closed";
  CONTROL_MSG["CONNECTION_FAILED"] = "Connection failed";
  CONTROL_MSG["REALTIME_SUBSCRIPTION_INIT_ERROR"] = "AppSync Realtime subscription init error";
  CONTROL_MSG["SUBSCRIPTION_ACK"] = "Subscription ack";
  CONTROL_MSG["TIMEOUT_DISCONNECT"] = "Timeout disconnect";
})(CONTROL_MSG || (CONTROL_MSG = {}));
/** @enum {string} */
export var ConnectionState;
(function (ConnectionState) {
  /*
   * The connection is alive and healthy
   */
  ConnectionState["Connected"] = "Connected";
  /*
   * The connection is alive, but the connection is offline
   */
  ConnectionState["ConnectedPendingNetwork"] = "ConnectedPendingNetwork";
  /*
   * The connection has been disconnected while in use
   */
  ConnectionState["ConnectionDisrupted"] = "ConnectionDisrupted";
  /*
   * The connection has been disconnected and the network is offline
   */
  ConnectionState["ConnectionDisruptedPendingNetwork"] = "ConnectionDisruptedPendingNetwork";
  /*
   * The connection is in the process of connecting
   */
  ConnectionState["Connecting"] = "Connecting";
  /*
   * The connection is not in use and is being disconnected
   */
  ConnectionState["ConnectedPendingDisconnect"] = "ConnectedPendingDisconnect";
  /*
   * The connection is not in use and has been disconnected
   */
  ConnectionState["Disconnected"] = "Disconnected";
  /*
   * The connection is alive, but a keep alive message has been missed
   */
  ConnectionState["ConnectedPendingKeepAlive"] = "ConnectedPendingKeepAlive";
})(ConnectionState || (ConnectionState = {}));
