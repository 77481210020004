// Custom Imports
// =========================================================

import { IsKeyInObj } from 'src/app/utils/global_functions'

export interface ErrorParams {
  controlName: string
  form: any
  customMessages?: any
}
export const GetFormErrorMessage = (formDetails: ErrorParams) => {
  const { controlName, form, customMessages } = formDetails
  const errors = form.get(controlName).errors || {}
  const message = []

  // console.error('Form Validation Errors: ', errors);

  Object.keys(errors).map((error: string) => {
    const getDefault = () => {
      switch (error) {
        case 'required':
          return 'Please enter a value.'
        case 'min':
          return `Value must be >= ${errors[error].min}.`
        case 'max':
          return `Value must be <= ${errors[error].max}.`
        case 'minlength':
        case 'minLength':
          return `Minimum of ${errors[error].requiredLength} characters required.`
        case 'maxlength':
        case 'maxLength':
          return `Value must be less than ${errors[error].maxLength}.`
        case 'duplicateValue':
          return `${form.controls[controlName].value} already exists. Value must be unique.`
        case 'formulaInvalid':
          return `Invalid Function: ${form.controls[controlName].value
                        .join(',')
                        .replaceAll(',', ' ')}`
        case 'pattern':
          return 'Value is not valid.'
        case 'invalidInteger':
          return 'Value must be an integer.'
        case 'specialCharacters':
          return 'Invalid character.'
        case 'emailValidationCharacters':
          return 'Email not valid.'
        case 'invalidUserName':
          return 'Invalid user name.'
        default:
          return 'Invalid value.'
      }
    }
    const customM =
            IsKeyInObj(customMessages, error) &&
            IsKeyInObj(customMessages[error], controlName)
    const defaultM = customM
      ? customMessages[error][controlName]
      : getDefault()

    return message.push(defaultM)
  })
  return message.join(' ')
}
