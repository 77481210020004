// Angular and RJX Imports
// =========================================================
import { Component, ElementRef, ViewChild } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type IHeaderAngularComp } from 'ag-grid-angular'
import { type IHeaderParams } from 'ag-grid-community'

@Component({
  selector: 'custom-header',
  templateUrl: './custom-header-renderer.html',
  styleUrls: ['./custom-header-renderer.scss']
})
export class CustomHeaderRenderer implements IHeaderAngularComp {
  public params!: IHeaderParams
  iconName: string = ''
  displayName: string = ''
  // public ascSort: string
  // public descSort: string
  // public noSort: string
  sortApplied = null
  toolTipMessage: string = ''
  toolTipPosition: string = ''

  @ViewChild('menuButton', { read: ElementRef }) public menuButton

  agInit (params: any): void {
    console.log('---> Header Renderer params: ', params)
    // console.log('---> Header Menu Enabled: ', params.enableMenu);
    // console.log('---> Header Sort Enabled: ', params.enableSorting);
    const { refData } = params.column.colDef
    this.params = params
    if (!refData.displayBlankHeader) {
      this.iconName =
                refData.headerRendererIcon || params.column.colDef.colId
      this.displayName = params.displayName
      this.toolTipMessage = refData?.toolTipMessage || this.iconName

      const colState = params.columnApi.getColumnState()
      this.toolTipPosition =
                colState[colState.length - 1].colId ===
                params.column.colDef.colId
                  ? 'lastColumn'
                  : 'below'

      params.column.addEventListener(
        'sortChanged',
        this.onSortChanged.bind(this)
      )
      params.column.addEventListener('filterChanged', function () {
        // when filter changes on the col, this will print one of [true,false]
        // console.log('filter of column is ' + params.column.isFilterActive());
      })

      this.onSortChanged()
    }
  }

  onMenuClicked (event) {
    this.params.showColumnMenu(this.menuButton.nativeElement)
  }

  onSortChanged () {
    if (this.params.column.isSortAscending()) {
      this.sortApplied = 'asc'
    } else if (this.params.column.isSortDescending()) {
      this.sortApplied = 'desc'
    } else {
      this.sortApplied = null
    }
  }

  onSortRequested (event: any) {
    const sort = this.sortApplied
      ? this.sortApplied === 'asc'
        ? 'desc'
        : null
      : 'asc'
    this.params.setSort(sort, event.shiftKey)
  }

  refresh (params: IHeaderParams) {
    return false
  }
}
