// Custom Imports
// =========================================================

import { type AgGridColDefsInterface } from 'src/app/core/interfaces/ag-grid-colDef-interface'
import { FormatKey, IsKeyInObj } from 'src/app/utils/global_functions'
import { CellClassRules } from '../02_global-settings/cell-class-rules'
import { GenerateDefaultColDefs } from '../02_global-settings/global-cell-formatter'

export interface JobManagement_GridDataParams {
  mainColDefs: any[]
}

export async function JobManagement_GenerateGridData (
  params: JobManagement_GridDataParams
) {
  const {
    mainColDefs
    // detailColDefs
  } = params
  const GenerateCols = async (data: any[], gridToRender: string) => {
    const mapCols = (colData: AgGridColDefsInterface[]) =>
      Object.entries(colData).map(([colKey, colVal], groupI) => {
        const customField = 'custom_field'
        const colId = colVal?.refData[customField] || FormatKey(colKey)
        const isGroupCol = IsKeyInObj(colVal, 'children')

        const primaryDefs = {
          ...GenerateDefaultColDefs(colKey, { colId, ...colVal })
        }
        const cellRender = {
          ...primaryDefs,
          ...JobManagement_customCellFormatting(
            primaryDefs,
            gridToRender
          )
        }

        if (isGroupCol && cellRender?.children.length > 0) {
          return {
            ...cellRender,
            children: cellRender?.children.flatMap(child =>
              mapCols(child)
            )
          }
        } else {
          return cellRender
        }
      })

    return mapCols(data)
  }

  const getResults = async () => {
    const mainCols = await GenerateCols(mainColDefs, 'main')

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    console.log('Column Defs: ', results[m])
    return data
  }
  return await getResults()
}

export const JobManagement_customCellFormatting = (col, gridToRender) => {
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      switch (col.colId) {
        case 'copy':
          return {
            cellRendererParams: params => {
              return {
                disabled:
                                    !params.data.is_editable ||
                                    params.data.status === 'RUNNING',
                disabledKeys: ['is_editable', 'status'],
                toolTipMessage: `Copy Job: ${params.data.job_name}`,
                onClick: params =>
                  params.context.onEnterJobCreatorScreen(
                    params,
                    col.colId
                  )
              }
            }
          }
        case 'edit':
          return {
            cellRendererParams: params => {
              return {
                disabled:
                                    params.data.status === 'RUNNING' ||
                                    params.data.status === 'QUEUED',
                disabledKeys: ['status'],
                toolTipMessage: `${
                                    params.data.is_editable
                                        ? 'View/Edit'
                                        : 'View'
                                } Job: ${params.data.job_name} `,
                onClick: params =>
                  params.context.onEnterJobCreatorScreen(
                    params,
                    col.colId
                  )
              }
            }
          }
      }
    }
  }
  const updatedCol = {
    ...col,
    ...getParams(),
    cellClassRules: {
      ...CellClassRules,
      'cell-warning': params => {
        const val = params.value
          ? params.value.toString().toLowerCase()
          : params.value
        // console.log("highlight cell: ", params)
        return col.colId === 'status' && val === 'failed'
      },
      'cell-alert': params => {
        const val = params.value
          ? params.value.toString().toLowerCase()
          : params.value
        return (
          col.colId === 'status' && val === 'completed with errors'
        )
      }
    }
  }
  return updatedCol
}
