<app-modal-header
    modalTitle="Configuration Settings"
    [showCloseBtn]="false"
></app-modal-header>
<!-- Modal Content -->
<div
    class="modal-content custom-vertical-scroll"
    [attr.loading]="loading"
    [attr.size]="'medium'"
>
    <section *ngIf="!loading" class="primary-section-container">
        <app-cartonization-settings-input-fields
            [cartonSettings]="cartonSettings"
            (onFormValueChanged)="onFormValueChanged($event)"
            [clearFormGroup]="clearCartonSettingsForm"
            (onFormReset)="onCartonSettingsFormReset($event)"
            panelOpenOnLoad="true"
        ></app-cartonization-settings-input-fields>
    </section>

    <!-- Loading Component -->
    <app-loader *ngIf="loading" [loadingMessage]="loadingMessage"></app-loader>
</div>
<!-- Modal Footer -->
<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="true"
    (update)="onSaveConfig($event)"
    [closeDisabled]="loadingMessage === 'updating'"
    [disabled]="changesMade.length === 0 || loading"
></app-modal-footer>
