// Angular and RJX Imports
// =========================================================
import {
  ChangeDetectorRef,
  Component,
  type OnInit,
  NgZone
} from '@angular/core'
import { v4 as uuidv4 } from 'uuid'
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RoutesRecognized
} from '@angular/router'
// Amplify / Log Rocket Imports
// =========================================================
import awsconfig from '../aws-exports'
import { AuthenticatorService } from '@aws-amplify/ui-angular'
import { API, Amplify, Auth } from 'aws-amplify'
import LogRocket from 'logrocket'
// Custom Imports
// =========================================================

import {
  DynamicDialogRef,
  DialogService,
  DynamicDialogConfig
} from 'primeng/dynamicdialog'
import {
  BehaviorSubject,
  filter,
  pairwise,
  fromEvent,
  merge,
  timer,
  interval,
  type Observable,
  type Subscription
} from 'rxjs'
import { CognitoService } from './core/services/auth/auth-cognito.service'
import { environment } from 'src/environments/environment'
import { redirectSignIn, redirectSignOut } from './utils/auth-functions'
import { PrimeNgOverlayPanelFixService } from './core/services/overlay.service'

import { switchMap, take, skipWhile, tap } from 'rxjs/operators'
import { RouterHistoryService } from './core/services/router-history-service'

if (environment.production) {
  LogRocket.init('sb46sp/sizeo-9wlvy', {
    console: {
      isEnabled: true
    }
  })
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService]
})
export class AppComponent implements OnInit {
  title = 'sizeo-v2'

  //  routing variables
  previousUrlViaNavigationEnd$ = new BehaviorSubject<string>(null)
  currentUrlViaNavigationEnd$ = new BehaviorSubject<string>(null)
  previousUrlViaRoutesRecognized$ = new BehaviorSubject<string>(null)
  currentUrlViaRoutesRecognized$ = new BehaviorSubject<string>(null)

  // Via RouterHistoryService
  previousUrlViaRouterHistoryService$ =
    this.routerHistoryService.previousUrl$

  currentUrlViaRouterHistoryService$ = this.routerHistoryService.currentUrl$

  //  authentication variables
  user: any
  logs: Array<{ event: string, message: string }> = []

  constructor (
    public router: Router,
    public authenticator: AuthenticatorService,
    public primeNgOverlayPanelFixService: PrimeNgOverlayPanelFixService,
    public cognitoService: CognitoService,
    public ref: DynamicDialogRef,
    public dialogService: DialogService,
    public config: DynamicDialogConfig,
    public _ngZone: NgZone,
    public _cd: ChangeDetectorRef,
    private readonly routerHistoryService: RouterHistoryService
  ) {
    // Event logging only
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.logs.push({
          event: 'NavigationStart',
          message: JSON.stringify(event)
        })
      }
      if (event instanceof NavigationEnd) {
        this.logs.push({
          event: 'NavigationEnd',
          message: JSON.stringify(event)
        })
      }
    })
    // Via Navigation End Event
    this.currentUrlViaNavigationEnd$.next(router.url)
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrlViaNavigationEnd$.next(
          this.currentUrlViaNavigationEnd$.value
        )
        this.currentUrlViaNavigationEnd$.next(event.urlAfterRedirects)
      }
    })
    // Via RoutesRecognized
    router.events
      .pipe(
        filter(evt => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe(
        ([previous, current]: [RoutesRecognized, RoutesRecognized]) => {
          this.previousUrlViaRoutesRecognized$.next(
            previous.urlAfterRedirects
          )
          this.currentUrlViaRoutesRecognized$.next(
            current.urlAfterRedirects
          )
        }
      )
    this.cognitoService.triggerUserUpdate$.subscribe(user => {
      console.log('---> User Details: ', user)

      if (this.user !== user) {
        this.user = user
      }
      if (!this.user) {
        this.router.navigateByUrl('/')
        this.cognitoService.authFederatedSignIn()
      }

      // Configure User Details for Log Rocket
      if (environment.production) {
        LogRocket.identify(uuidv4(), {
          name: user.sub,
          email: user.email
        })
      }
    })

    this.cognitoService.hubListener()
  }

  ngOnInit () {
    // Initialize LogRocket --> Then update the user's details once they are authorized through amplify/cognito
    if (environment.production) {
      LogRocket.identify(uuidv4(), {
        name: 'dev-user'
      })
    }
    const aws_auth = {
      Auth: {
        region: awsconfig.aws_cognito_region,
        userPoolId: awsconfig.aws_user_pools_id,
        userPoolWebClientId: awsconfig.aws_user_pools_web_client_id
      },
      ...awsconfig,
      oauth: {
        ...awsconfig.oauth,
        redirectSignIn: redirectSignIn(awsconfig),
        redirectSignOut: redirectSignOut(awsconfig)
      },
      endpoints: [
        {
          name: `${environment.stage}-sizeo-service`,
          endpoint: `https://${environment.apiServiceId}.execute-api.us-east-1.amazonaws.com/${environment.stage}`
        }
      ]
    }
    Auth.configure(aws_auth)
    API.configure(aws_auth)
  }
}
