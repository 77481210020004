// Custom Imports
// =========================================================
import { type AgGridColDefsInterface } from 'src/app/core/interfaces/ag-grid-colDef-interface'
import { FormatKey, IsKeyInObj } from 'src/app/utils/global_functions'
import { GenerateDefaultColDefs } from './global-cell-formatter'

export const FormatJSONColData = async (
  data: any,
  gridToRender: string,
  customCellFormattingFunc,
  isSize = false
) => {
  console.log('gridToRender', gridToRender)
  const mapCols = (colData: AgGridColDefsInterface[]) =>
    Object.entries(colData).map(([colKey, colVal], groupI) => {
      let colId = null
      if (isSize) {
        colId = colVal?.refData['custom_field'] || colKey
      } else {
        colId = colVal?.refData['custom_field'] || FormatKey(colKey)
      }

      const isGroupCol = IsKeyInObj(colVal, 'children')
      let primaryDefs = null
      if (isSize) {
        primaryDefs = {
          ...GenerateDefaultColDefs(
            colKey,
            { colId, ...colVal },
            isSize
          )
        }
      } else {
        primaryDefs = {
          ...GenerateDefaultColDefs(colKey, { colId, ...colVal })
        }
      }
      const customCellFormatting =
                (customCellFormattingFunc &&
                    customCellFormattingFunc(primaryDefs, gridToRender)) ||
                {}

      const cellRender = {
        ...primaryDefs,
        ...customCellFormatting
      }
      if (isGroupCol && cellRender?.children.length > 0) {
        return {
          ...cellRender,
          children: cellRender?.children.flatMap(child =>
            mapCols(child)
          )
        }
      } else {
        return cellRender
      }
    })

  return mapCols(data)
}
