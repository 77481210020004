// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// AWS Imports
// =========================================================
import { API, graphqlOperation } from 'aws-amplify'
import { type GraphQLSubscription } from '@aws-amplify/api'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Axios Imports
// =========================================================
import axios from 'axios'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import {
  ExecuteGetAPI,
  ExecutePostAPI,
  ExecuteDeleteAPI
} from './execute_api-calls'
import { type ResponseSubscription } from '../../../API'
import * as subscriptions from '../../../graphql/subscriptions'
import { FormatKey } from 'src/app/utils/global_functions'
import { type JobCreationInterface } from '../interfaces/data-expected-from-backend/job-creation-interface'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class ManagementJobsAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Get All Jobs
  async GetAllJobs () {
    // the api call path with the parameter string is needed
    const path = '/jobs/properties'
    const messages = {
      success: '---> API Call Successful: Retrieved All Jobs',
      error: 'Error retrieving rowData for the Size Distro Execution - Job Management Grid.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success) {
      return await axios
        .get(response.data, {})
        .then(res => {
          console.log('res: ', res)
          return res.data
        })
        .catch(error => {
          console.error(
            'Error retrieving job management grid data: ',
            error
          )
          this.toastAlert.showError('Error Loading Data')
          throw error
        })
    } else {
      return []
    }
  }

  // Run Jobs
  async RunJobs (data: string[], queue = true) {
    // the api call path with the parameter string is needed
    const path = '/jobs/qip'
    const totalNumOfData = data.length
    const suffix = totalNumOfData > 1 ? 's' : ''
    const messages = {
      success: `Successfully ran ${totalNumOfData} size distro job${suffix}.`,
      error: `Error running ${totalNumOfData} size distro job${suffix}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(
      path,
      this,
      { job_names: data, queue },
      messages
    )
  }

  // Delete jobs
  async DeleteJobs (jobs: string[]) {
    // the api call path with the parameter string is needed
    const path = '/jobs'
    const totalNumOfJobs = jobs.length
    const suffix = totalNumOfJobs > 1 ? 's' : ''
    const messages = {
      success: `Successfully deleted ${totalNumOfJobs} size distro job${suffix}.`,
      error: `Error deleting ${totalNumOfJobs} size distro job${suffix}.`,
      showSuccessToast: true
    }

    return await ExecuteDeleteAPI(path, this, messages, { jobs })
  }

  async JobGridDataSubscription (gridApi) {
    console.log('inside JobGridDataSubscription')
    // console.log("inside jobsToSubscribeTo", jobsToSubscribeTo)
    const subscription = API.graphql<
    GraphQLSubscription<ResponseSubscription>
    >(graphqlOperation(subscriptions.response, {})).subscribe({
      next: ({ provider, value }) => {
        console.log('inside subscriber next', value)
        // if the result of the subscription has the same job_name that we triggered the subscription for

        // get the data
        console.log('inside job_name', value.data?.response?.job_name)
        console.log('inside status', value.data?.response?.status)
        console.log(
          'error_message',
          value.data?.response?.error_message
        )
        console.log('finished_at', value.data?.response?.finished_at)

        if (gridApi && !gridApi['destroyCalled']) {
          const rowData = gridApi.getRowNode(
            FormatKey(value?.data?.response?.job_name)
          )
          if (rowData) {
            gridApi.applyTransactionAsync({
              update: [
                {
                  ...rowData.data,
                  status: value?.data?.response?.status,
                  error_message:
                                        value?.data?.response?.error_message,
                  finished_at:
                                        value?.data?.response?.finished_at
                }
              ]
            })
          }
        }
      },
      error: error => console.warn(error)
    })
  }
}
