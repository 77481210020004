// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Axios Imports
// =========================================================
import axios from 'axios'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { ExecutePostAPI } from './execute_api-calls'
import {
  type SaveChangesInterface,
  type GetDynamicColDefsAndRowDataInterface,
  type RevertToSystemDistrosInterface
} from '../interfaces/data-expected-from-backend/size-distro-managment-detail-grid-interface'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class SizeDistroManagementDetailGridAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Save Current Parameter changes
  async GetDynamicColDefsAndRowData (
    parameters: GetDynamicColDefsAndRowDataInterface
  ) {
    // the api call path with the parameter string is needed
    const path = '/size_distro/drill_in'
    const messages = {
      success: `---> API Call Successful: Retrieved Dynamic ColDefs and RowData for: ${JSON.stringify(
                parameters
            )}.`,
      error: 'Error retrieving dynamic colDefs and rowData for the Style Color Store Detail Grid.'
    }
    const drillInData = await ExecutePostAPI(
      path,
      this,
      parameters,
      messages
    )
    console.log('drillInData: ', drillInData)
    if (drillInData?.is_success) {
      return await axios
        .get(drillInData.data, {})
        .then(response => {
          console.log('response: ', response)
          return response
        })
        .catch(error => {
          console.error(
            'Error retrieving drill in grid data: ',
            error
          )
          this.toastAlert.showError('Error Loading Data')
          throw error
        })
    } else {
      console.error('Error retrieving drill in grid file')
      this.toastAlert.showError('Error Loading Data')
      return {
        status: null,
        data: []
      }
    }
  }

  async SaveChanges (data: SaveChangesInterface) {
    const path = '/size_distro/grid/save_changes'
    const numOfContrPcts = data.row_data.length
    const suffix = numOfContrPcts > 1 ? 's' : ''
    const messages = {
      success: `Successfully saved contribution pcts for ${numOfContrPcts} distro${suffix}.`,
      error: `Error saving contribution pcts for ${numOfContrPcts} distro${suffix}.`,
      showSuccessToast: true
    }
    return await ExecutePostAPI(path, this, data, messages)
  }

  async RevertToSystemDistros (data: RevertToSystemDistrosInterface[]) {
    const path = '/size_distro/details/revert'
    const numOfContrPcts = data.length
    const suffix = numOfContrPcts > 1 ? 's' : ''
    const messages = {
      success: `Successfully reverted contribution pcts for ${numOfContrPcts} distro${suffix} to system values.`,
      error: `Error reverting contribution pcts for ${numOfContrPcts} distro${suffix} to system values.`,
      showSuccessToast: true
    }
    return await ExecutePostAPI(path, this, data, messages)
  }
}
