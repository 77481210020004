// Angular Imports
// =========================================================
import {
  Component,
  type OnDestroy,
  type OnInit,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'
// Angular Material Imports
// =========================================================
import { MatMenuTrigger } from '@angular/material/menu'
// Prime NG Imports
// =========================================================
import { type TreeNode } from 'primeng/api'
// Custom Imports
// =========================================================
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component'
import { SizeDistroDefaultParametersAPIs } from 'src/app/core/apis/size-distro-default-parameters_api-calls'
import { cloneDeep } from 'lodash'
import { BuyingDefaultCartonizationFormSettings } from 'src/app/03_shared-components/forms/form-data/buying-default-cartonization-form'
import {
  type CartonizationSettingsInputsInterface,
  GetCartonizationSettingsInputs
} from 'src/app/core/interfaces/data-expected-from-backend/cartonization-settings-inputs-interface'
import { BuyingStrategyAPIs } from 'src/app/core/apis/buying-strategy_api-calls'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { type UpdateBuyingParametersInterface } from 'src/app/core/interfaces/data-expected-from-backend/buying-strategy-interface'
import { IsKeyInObj } from 'src/app/utils/global_functions'
import { type TreeDataParamsInterface } from 'src/app/core/interfaces/data-expected-from-backend/default-parameters-inputs-interface'

@Component({
  selector: 'app-buying-strategy',
  templateUrl: './buying-strategy.component.html',
  styleUrls: ['./buying-strategy.component.scss']
})
export class BuyingStrategyComponent implements OnInit, OnDestroy {
  // Params for Product Hierarchy Section
  treeDataParams: TreeDataParamsInterface = {
    queryString: '?for_buying=true&channel=ecomm',
    selectedProd: 'all'
  }

  loadingProdHier: boolean = true
  selectedHierarchyNode: TreeNode = null
  defaultSelected: TreeNode[] = []

  $buyingDefaultCartonizationFormSettings =
    BuyingDefaultCartonizationFormSettings

  formData = new BehaviorSubject<any>(BuyingDefaultCartonizationFormSettings)

  get _formData () {
    return this.formData.getValue()
  }

  cartonSettings: CartonizationSettingsInputsInterface = null
  ogCartonSettings: CartonizationSettingsInputsInterface = null // monitor changes
  changesMade: string[] = []
  clearCartonSettingsForm: boolean
  cartonSettingsToSubmit: UpdateBuyingParametersInterface = {
    product_id: null,
    channel: null,
    parameters: {
      ...GetCartonizationSettingsInputs(false)
    }
  }

  defaultHierFilters: string[] = ['proxy', 'new', 'changes']
  hierFiltersToDisplay: string[] = [...this.defaultHierFilters]
  // TODO: add interface for filters applied
  hierFiltersApplied: any = {}

  isNodeEditable: boolean = false

  ObjectKeys = Object.keys
  initRender: boolean
  @ViewChild('confirmPopup') confirmPopup: ConfirmPopupComponent
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger

  constructor (
    private readonly sizeDistroDefaultParametersAPIs: SizeDistroDefaultParametersAPIs,
    private readonly buyingStrategyAPIs: BuyingStrategyAPIs,
    public triggerApiCallsService: TriggerApiCallsService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit (): void {
    this.initRender = true
    this.hierFiltersToDisplay = this.defaultHierFilters
  }

  ngAfterContentChecked () {
    this.cdr.detectChanges()
  }

  async getChannelOptions () {
    const channels = await this.buyingStrategyAPIs.OnGetAllChannels()
    console.log('---> channels: ', channels)
    console.log(
      '---> current channels: ',
      this.cartonSettingsToSubmit.channel
    )

    if (channels) {
      const tempForm = this.$buyingDefaultCartonizationFormSettings
      const currentChannel =
                this?.cartonSettingsToSubmit?.channel || channels[0]
      tempForm.channel = {
        ...tempForm.channel,
        selectionOptions: channels,
        currentVal: currentChannel,
        disabled: false,
        loading: false
      }
      this.formData.next(tempForm)
      if (this.selectedHierarchyNode) {
        this.onGetBuyingStrategyParameters(currentChannel)
      }
      this.cartonSettingsToSubmit.channel = currentChannel
      // this.treeDataParams = {
      //   ...this.treeDataParams,
      //   queryString: '?for_buying=true&channel=' + this.cartonSettingsToSubmit.channel
      // }
      return currentChannel
    }
  }

  async onNodeSelected (event) {
    console.log('---> Buying Strategy Params Hier Node Selected: ', event)

    try {
      if (
        !this.selectedHierarchyNode ||
                (this.selectedHierarchyNode &&
                    this.selectedHierarchyNode.key !== event.node.key)
      ) {
        this.selectedHierarchyNode = event.node
        this.loadingProdHier = true
        this.changesMade = []
        await this.getChannelOptions()
      }
    } finally {
      if (
        !this.selectedHierarchyNode ||
                Object.keys(this.selectedHierarchyNode).length === 0 ||
                this.selectedHierarchyNode.key == event.node.key
      ) {
        this.selectedHierarchyNode = event.node
        this.onGetBuyingStrategyParameters(
          this.cartonSettingsToSubmit.channel
        )
      }
    }
  }

  emitConfirmation (event) {
    console.log('confirm modal: ', event)
    // Close the menu panel
    this.trigger.closeMenu()

    if (event.userInput === 'except') {
      const dataToSend = {
        ...this.cartonSettingsToSubmit
      }

      const prodDetailsToSend = {
        channel: dataToSend.channel,
        product_id: dataToSend.product_id
      }

      const onAPICallSuccessful = () => {
        this.loadingProdHier = true
        this.clearCartonSettingsForm = true

        this.treeDataParams = {
          queryString: '?for_buying=true&channel=' + this.cartonSettingsToSubmit.channel,
          selectedProd: dataToSend.product_id
        }
        this.changesMade = []
      }

      // If editable exists in the parameter object -> remove it
      if (IsKeyInObj(dataToSend.parameters, 'editable')) {
        delete dataToSend.parameters.editable
      }
      if (IsKeyInObj(dataToSend.parameters, 'parent_product_id')) {
        delete dataToSend.parameters.parent_product_id
      }
      // if (IsKeyInObj(dataToSend.parameters, 'product_id')) {
      //   delete dataToSend.parameters.product_id
      // }
      if (dataToSend.parameters.carton_sizes) {
        dataToSend.parameters.carton_sizes += ''
      }

      switch (event.command) {
        case 'Save':
          console.log('---> Execute save changes')
          console.log('---> Data To Send: ', dataToSend)
          this.buyingStrategyAPIs
            .SaveBuyingParametersChanges(dataToSend)
            .then(res => {
              if (res?.is_success) onAPICallSuccessful()
            })
          break
        case 'Revert To Inheritance':
          console.log('---> Revert To Inheritance')
          console.log('---> Data To Send: ', prodDetailsToSend)
          this.buyingStrategyAPIs
            .RevertBuyingParametersToInheritance(prodDetailsToSend)
            .then(res => {
              if (res?.is_success) {
                this.hierFiltersApplied = {
                  has_changes: false
                }
                onAPICallSuccessful()
              }
            })

          break
        case 'Override All':
          console.log('---> Override All')
          console.log('---> Data To Send: ', prodDetailsToSend)
          this.buyingStrategyAPIs
            .OverrideAllBuyingParameters(prodDetailsToSend)
            .then(res => {
              if (res?.is_success) {
                this.hierFiltersApplied = {
                  has_changes: false
                }
                onAPICallSuccessful()
              }
            })

          break
      }
    }
  }

  // If The Channel Has Been changed
  onUpdateChannel (form) {
    console.log('---> Form Updated: ', form)

    const field = form.controlName
    const value = cloneDeep(form.value)

    if (form.status === 'VALID') {
      console.log('field is valid')
      this.onGetBuyingStrategyParameters(value)
      this.cartonSettingsToSubmit.parameters[field] = value
      this.treeDataParams = {
        ...this.treeDataParams,
        queryString: '?for_buying=true&channel=' + value
      }
    } else if (form.status === 'DISABLED') {
      console.log('field is disabled: ', form)
    } else {
      // console.log('field status: ', form.status);
      console.error('field error: ', form)
    }
  }

  // Get Buying Season Params based on prodId and channel
  async onGetBuyingStrategyParameters (channel?: string) {
    const prodId = this.selectedHierarchyNode.key.toString()

    this.loadingProdHier = false
    this.hierFiltersToDisplay = [...this.defaultHierFilters, channel]
    this.cartonSettingsToSubmit.channel = channel
    this.cartonSettingsToSubmit.product_id = prodId
    // this.ogCartonSettings
    this.buyingStrategyAPIs
      .GetBuyingStrategyParameters({
        product_id: prodId,
        channel
      })
      .then(res => {
        this.changesMade = []
        this.isNodeEditable =
                    this.selectedHierarchyNode?.data?.editable || false

        this.ogCartonSettings = cloneDeep({ ...res.data })
        this.cartonSettingsToSubmit.parameters = cloneDeep({
          ...res.data
        })
        this.cartonSettings = {
          editable: this.isNodeEditable,
          parent_product_id:
                        this.selectedHierarchyNode.key.toString(),
          ...cloneDeep({ ...res.data })
        }
      })
  }

  onFormValueChanged (form) {
    const field = form.controlName
    const knownChanges = this.changesMade.includes(field)
    const removeChange = () =>
      this.changesMade.splice(this.changesMade.indexOf(field), 1)
    console.log('Form value changed in buying strategy: ', form)
    console.log('Original Carton Settings: ', this.ogCartonSettings)
    // Update the data to send to the BE
    this.cartonSettings[field] = form.value
    this.cartonSettingsToSubmit.parameters[field] = form.value
    // Monitor Changes
    if (
      (this.ogCartonSettings &&
                this.ogCartonSettings[field] !== '' &&
                this.ogCartonSettings[field] != form.value) ||
            (this.ogCartonSettings[field] === '' &&
                form.value !== null &&
                form.value !== '')
    ) {
      if (!knownChanges) this.changesMade.push(field)
    } else if (knownChanges) {
      removeChange()
    }
    console.log('has changes: ', this.changesMade)
  }

  // Track when the Default Parameters screen has finished updating
  onCartonSettingsFormReset (event) {
    this.clearCartonSettingsForm = false
  }

  async resetFormData (maintainTreeData?: boolean) {
    try {
      this.isNodeEditable = false
      this.cartonSettingsToSubmit = {
        product_id: null,
        channel: null,
        parameters: {
          ...GetCartonizationSettingsInputs(false)
        }
      }
      console.log(
        '---> Resetting Form Data: ',
        this.cartonSettingsToSubmit
      )

      this.changesMade = []
      this.clearCartonSettingsForm = true
      if (!maintainTreeData) {
        this.selectedHierarchyNode = {}
        this.defaultSelected = []
        this.loadingProdHier = true
      }
    } catch (error) {
      console.log('Error resetting Proxy Product Hierarchy Form')
    } finally {
      return true
    }
  }

  ngOnDestroy (): void {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
  }
}
