<ng-container *ngIf="authenticator.route === 'authenticated'">
    <app-nav-bar>
        <router-outlet></router-outlet>
    </app-nav-bar>
</ng-container>
<!-- Render loading if authStatus is still configuring  -->
<ng-container *ngIf="authenticator.authStatus === 'configuring'">
    Loading...
</ng-container>
<p-toast position="top-right" [preventOpenDuplicates]="true"></p-toast>
