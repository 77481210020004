<div
    *ngIf="
        params.location !== 'columnToolPanelColumn' &&
        params.value !== 'COPY' &&
        params.value !== 'EDIT' &&
        params.value !== 'SAVE' &&
        params.value !== 'OUTPUT' &&
        params.value !== 'DELETE'
    "
    class="custom-tooltip below text-center"
>
    <p class="text-center">
        {{ message }}
    </p>
</div>
