// Angular and RJX Imports
// =========================================================
import {
  Component,
  type OnInit,
  ViewChild,
  type OnDestroy,
  HostListener
} from '@angular/core'
import { Router } from '@angular/router'
import { cloneDeep } from 'lodash'
import { BehaviorSubject } from 'rxjs'
// JSON Data
// =========================================================
import $sizeDistroManagementSummaryJSON from '../../../05_ag-grid-configs/01_json-grid-configs/colDefs-size-distro-management-summary.json'
// Ag Grid Imports
// =========================================================
import {
  type GridReadyEvent,
  type GridApi,
  type ColumnApi,
  type SelectionChangedEvent,
  type CellValueChangedEvent
} from 'ag-grid-community'
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog'
import { type TreeNode } from 'primeng/api'
import { OverlayPanel } from 'primeng/overlaypanel'
// Custom Imports
// =========================================================
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { SizeDistroManagementSummaryAPIs } from 'src/app/core/apis/size-distro-management-summary_api-calls'
import { SizeDistroManagementSummary_GenerateGridData } from '../../../05_ag-grid-configs/04_generate-colDefs/size-distro-management-summary-colDefs'
import {
  CustomGridOptions,
  DefaultGridContextMenu
} from 'src/app/05_ag-grid-configs/02_global-settings/grid-options'
import { SideBarPanels } from 'src/app/05_ag-grid-configs/02_global-settings/grid-variables'
import { BulkOverridesModalComponent } from 'src/app/04_modals/size-distro-management/bulk-overrides-modal/bulk-overrides-modal.component'
import { DistroCopyBuyingSeasonModalComponent } from 'src/app/04_modals/size-distro-management/distro-copy-buying-season-modal/distro-copy-buying-season-modal.component'
import { DistroCopyNewProductModalComponent } from 'src/app/04_modals/size-distro-management/distro-copy-new-product-modal/distro-copy-new-product-modal.component'
import { SizeRangeAlterationsModalComponent } from 'src/app/04_modals/size-distro-management/size-range-alterations-modal/size-range-alterations-modal.component'
import { type AGGrigSavedSettingsInterface } from 'src/app/core/interfaces/ag-grid-saved-settings-interface'
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component'
import { SizeDistroManagementSummaryToolPanelComponent } from 'src/app/05_ag-grid-configs/03_custom-components/custom-tool-panels/size-distro-management-summary-tool-panel/size-distro-management-summary-tool-panel.component'
import {
  GetJSONCircularReplacer,
  IsKeyInObj,
  FormatKey
} from 'src/app/utils/global_functions'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { SaveGridState } from 'src/app/05_ag-grid-configs/02_global-settings/grid-functions-general'
import { DrillInFilteringComponent } from 'src/app/04_modals/size-distro-management/drill-in-filtering/drill-in-filtering.component'
import { SDS_DrillInFiltersFormSettings } from 'src/app/03_shared-components/forms/form-data/size-distro-summary-drill-in-filters'
import { DrillInFilteringAPIs } from 'src/app/core/apis/drill-in-filtering_api-calls'
import { type StoreListIDInterface } from 'src/app/core/interfaces/data-expected-from-backend/drill-in-filtering_interface'
import { SizeDistroDefaultParametersAPIs } from 'src/app/core/apis/size-distro-default-parameters_api-calls'
import { type TreeDataParamsInterface } from 'src/app/core/interfaces/data-expected-from-backend/default-parameters-inputs-interface'

@Component({
  selector: 'app-size-distro-management-summary',
  templateUrl: './size-distro-management-summary.component.html',
  styleUrls: ['./size-distro-management-summary.component.scss']
})
export class SizeDistroManagementSummaryComponent implements OnInit, OnDestroy {
  eventPointer: any
  private readonly sideBarPanels: any = SideBarPanels()

  public sizeDistroManagementSummary = $sizeDistroManagementSummaryJSON

  // Ag Grid Configuration
  gridApi: GridApi
  columnApi: ColumnApi
  gridOptions: any = {}
  isAgGridLoading: boolean = true
  filtersApplied = {}
  // Grid Data
  columnDefs: any[] = []
  gridName = 'size_distro_management_summary_view'

  treeDataParams: TreeDataParamsInterface = {
    queryString: '?for_size_distro_filter=true',
    selectedProd: null
  }

  // Variables to monitor data from the selected grid rows
  selectedDistros: any[] = []
  trackedGridSelections = {
    status: [],
    size_range: [],
    channel: []
  }

  // Global Vars
  processingData: boolean = false
  storedGridSettings: AGGrigSavedSettingsInterface = {}
  buyingSeasonFilters: {} = {}
  productFilters: any = []
  productTreeData: TreeNode[] = []
  isLoadingSavedView: boolean = true
  showMoreOptions: boolean = false

  drillInFilters: { send_neg_1: boolean, store_list: StoreListIDInterface } =
    {
      send_neg_1: false,
      store_list: { store_list: null, id: null }
    }

  drillInFiltersStoreList: {
    allLists: StoreListIDInterface[]
    listNames: string[]
  } = { allLists: [], listNames: [] }

  // Ag Grid Configuration
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: 'Size Distro Management Summary',
      providers: [SizeDistroManagementSummaryAPIs],
      allowSaveView: true,
      gridName: this.gridName,
      externalFilters: [
        'buyingSeasonFilters',
        'productFilters',
        'drillInFilters'
      ]
    }
  }

  @ViewChild('moreOptions') trigger: OverlayPanel
  @ViewChild('confirmPopupDelete') confirmPopupDelete: ConfirmPopupComponent
  @ViewChild('confirmPopupRevert') confirmPopupRevert: ConfirmPopupComponent
  // Import the original form settings and track updates
  defaultSDS_DrillInFiltersFormSettings = cloneDeep({
    ...SDS_DrillInFiltersFormSettings
  })

  formData = new BehaviorSubject<any>(
    this.defaultSDS_DrillInFiltersFormSettings
  )

  get _formData () {
    return this.formData.getValue()
  }

  constructor (
    private readonly router: Router,
    private ref: DynamicDialogRef,
    public dialogService: DialogService,
    private readonly generalAPIs: GeneralAPIs,
    private readonly sizeDistroManagementSummaryAPIs: SizeDistroManagementSummaryAPIs,
    private readonly sizeDistroDefaultParametersAPIs: SizeDistroDefaultParametersAPIs,
    public triggerApiCallsService: TriggerApiCallsService,
    public drillInFilteringAPIs: DrillInFilteringAPIs
  ) {
    // Set AG Grid Options
    this.gridOptions = {
      ...this.customGridOptions,
      sideBar: {
        toolPanels: [
          this.sideBarPanels.columnPanel,
          {
            id: 'sdmSummaryExternalFilters',
            labelDefault: 'Advanced Filtering',
            labelKey: 'sdmSummaryExternalFilters',
            iconKey: 'filter',
            toolPanel:
                            SizeDistroManagementSummaryToolPanelComponent
          }
        ],
        ...this.sideBarPanels.defaultLayout
      },
      onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
      // custom context Menu Items
      getContextMenuItems: params =>
        this.sizeDistroManagementGridContextMenu(params),
      onSelectionChanged: (event: SelectionChangedEvent) =>
        this.onSelectedDistroChange(event),
      // On cell value changed to track any cell changes
      onCellValueChanged: (event: CellValueChangedEvent) => {
        // console.log('Cell Value: changed: ', event)
        const colDef = event.colDef.colId

        if (event.newValue !== event.oldValue) {
          if (event.colDef.colId === 'distro_tag') {
            this.autoSaveDistroTag(event)
          }
        }
        if (colDef === 'status') {
          event.api.refreshCells({ force: true })
        }
      },
      getRowId: node => node.data.distro_id
    }

    // Event is fired when the user right-clicks on an element to open the context menu
    document.addEventListener('contextmenu', event => {
      if (event.button === 2) {
        this.eventPointer = event
      }
    })

    // Event listener for screen resize to make sure that the split button menu panel closes on resize
    window.addEventListener('resize', event => {
      if (
        document.getElementById('sdm-more-options-panel').style
          .display === 'block' ||
                this.showMoreOptions
      ) {
        this.onToggleMoreOptions(false)
      }
    })
  }

  ngOnInit () {}
  // Set the Detail Grid Filters form field selection and validation options
  setSelectionOptions () {
    const tempForm = cloneDeep({
      ...this.defaultSDS_DrillInFiltersFormSettings
    })

    Object.keys(tempForm).forEach((field, i) => {
      tempForm[field].loading = false
      tempForm[field].disabled = false
      tempForm[field].currentVal = this.drillInFilters[field]

      if (field === 'store_list') {
        tempForm[field].selectionOptions =
                    this.drillInFiltersStoreList.allLists
      }

      if (Object.keys(tempForm).length === i + 1) {
        this.formData.next(tempForm)
        console.log(
          '---> Updating Form Selection Options and Validation: ',
          tempForm
        )
      }
    })
  }

  onGridReady (event: GridReadyEvent) {
    this.gridApi = event.api
    this.columnApi = event.columnApi
    this.isAgGridLoading = false
    this.gridApi.closeToolPanel()

    // Get all size distros to render data
    this.getAllSizeDistros(null)
  }

  // Render AG Grid Column Definitions
  async renderGrid (mainColDefs, rowData) {
    console.log('rendergrid')
    this.selectedDistros = []
    const params = {
      mainColDefs
    }
    const gridData = await SizeDistroManagementSummary_GenerateGridData(
      params
    )
    if (gridData) {
      this.columnDefs = gridData.mainColDefs

      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          if (this.gridApi && !this.gridApi['destroyCalled']) {
            console.log(
              '---> Rendering Size Distro Management Summary Grid: '
            )
            console.log('rowdata', rowData)
            this.gridApi?.setColumnDefs(gridData.mainColDefs)
            this.gridApi?.setRowData(rowData)

            this.sizeDistroManagementSummaryAPIs.SizeAlterationsSubscription(
              this.gridApi
            )
            // Set the stored column state from the backend
            if (
              Object.keys(this.storedGridSettings).length > 0 &&
                            this.storedGridSettings[this.gridName]
            ) {
              console.log(
                '---> Stored Grid Settings: ',
                this.storedGridSettings
              )
              const { colDefs, filters } =
                                this.storedGridSettings[this.gridName]
              // Apply the saved column state
              this.gridOptions?.columnApi?.applyColumnState({
                state: colDefs
              })
              // Apply the column order
              this.gridOptions?.columnApi?.moveColumns(
                colDefs.map(col => col.colId),
                0
              )
              // Apply the saved filter state
              this.gridOptions?.api?.setFilterModel(filters)
              // Clear the drill in grid's rowData
              this.storedGridSettings.sdm_detail_grid = {
                send_neg_1: false,
                distro_ids: []
              }
              sessionStorage.setItem(
                'ag_grid_settings',
                JSON.stringify(
                  {
                    sdm_detail_grid:
                                            this.storedGridSettings
                                              .sdm_detail_grid
                  },
                  GetJSONCircularReplacer()
                )
              )
            }
          }
          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  async getDefaultBuyingSeasonFilters () {
    const BSFValues = await [
      ...new Set([
        ...Object.values(this.buyingSeasonFilters).map(value => value)
      ])
    ]
    // If all buying season filters have a value of false -> pull the default buying season filter from the BE otherwise maintain the saved filters
    const currentBuyingSeasonFilters =
            (await BSFValues.length) === 1 && BSFValues[0] === false
              ? {}
              : cloneDeep({
                ...this.buyingSeasonFilters
              })

    return await this.sizeDistroManagementSummaryAPIs
      .GetBuyingSeasonsFilter()
      .then(res => {
        if (res.is_success) {
          console.log(
            'success getting buyingseasonfilters',
            res.data
          )
          const filter = res?.data?.buying_season_filters || {}
          const filterKeys = Object.keys(filter)
          const savedKeys = Object.keys(currentBuyingSeasonFilters)

          this.buyingSeasonFilters = {}

          if (filterKeys.length > 0) {
            filterKeys.forEach((key, i) => {
              this.buyingSeasonFilters[key] = savedKeys.includes(
                key
              )
                ? currentBuyingSeasonFilters[key]
                : filter[key]
              if (i + 1 === filterKeys.length) {
                return this.buyingSeasonFilters
              }
            })
          } else {
            return this.buyingSeasonFilters
          }
        } else {
          return this.buyingSeasonFilters
        }
      })
  }

  // Get all size distros
  async getAllSizeDistros (filters?: boolean) {
    console.log('---> Apply local filters      : ', filters)

    try {
      await this.getGridState(filters)

      if (!filters) {
        // Get and set the buying season filters
        await Promise.all([this.getDefaultBuyingSeasonFilters(),
          this.sizeDistroDefaultParametersAPIs.GetAllProducts(this.treeDataParams)])
          .then(data => {
            this.productTreeData = data[1].data
          })
      }
    } catch (error) {
      console.error(
        '---> Error Generating Data For the Size Distro Summary Grid',
        error
      )
    } finally {
      // Check if the buying season is not empty
      console.log('buyingseasonfilters', this.buyingSeasonFilters)
      if (
        this.buyingSeasonFilters != null &&
                Object.keys(this.buyingSeasonFilters).length > 0
      ) {
        await this.sizeDistroManagementSummaryAPIs
          .GetAllSizeDistros({
            filter: {
              buying_season: this.buyingSeasonFilters,
              product_filter: this.productFilters
            }
          })
          .then((res: any[]) => {
            // Render the grid
            this.renderGrid(
              this.sizeDistroManagementSummary[0],
              res && res.length > 0 ? res : []
            )
          })
      } else {
        console.error(
          'Error Generating Data For the Size Distro Summary Grid'
        )
        this.renderGrid(this.sizeDistroManagementSummary[0], [])
      }
    }
  }

  // Update the visibility of the more options filter panel
  onToggleMoreOptions (showOptions) {
    // console.log('--> toggle panel visibility: ', showOptions);

    this.showMoreOptions = showOptions
    document.getElementById('sdm-more-options-panel').style.display =
            showOptions === true ? 'block' : 'none'
  }

  // Sets the context menu in the grid
  sizeDistroManagementGridContextMenu (params) {
    console.log('---> Viewing Context Menu: ', params)
    if (params.column) {
      const selectedRow = params.api.getSelectedNodes()

      // Drill action button
      const drillInButton = [
        {
          name:
                        selectedRow.length > 0
                          ? 'Drill in'
                          : 'Drill in (No Distro(s) Selected)',
          action: () => this.onDrillIn(params),
          disabled: selectedRow.length === 0,
          cssClasses: ['text-accent']
        }
      ]
      // Approve action button
      const approveButton = [
        {
          name:
                        selectedRow.length > 0
                          ? `Approve ${selectedRow.length} Distro${
                                  selectedRow.length === 1 ? '' : 's'
                              }`
                          : 'Approve (No Distro(s) Selected)',
          action: async () => await this.onApprove(params),
          disabled:
                        selectedRow.length === 0 ||
                        this.trackedGridSelections.status.includes('APPROVED'),
          cssClasses: ['text-accent']
        }
      ]
      // Un approve action button
      const unApproveButton = [
        {
          name:
                        selectedRow.length > 0
                          ? `Unapprove ${selectedRow.length} Distro${
                                  selectedRow.length === 1 ? '' : 's'
                              }`
                          : 'Unapprove (No Distro(s) Selected)',
          action: async () => await this.onUnapprove(params),
          disabled: selectedRow.length === 0,
          cssClasses: ['text-accent']
        }
      ]
      // Revert to the system distro action button
      const RevertToTheSystemDistrosButton = [
        {
          name:
                        selectedRow.length > 0
                          ? `Revert ${selectedRow.length} Distro${
                                  selectedRow.length === 1 ? '' : 's'
                              } To System Distro`
                          : 'Revert To System Distro (No Distro(s) Selected)',
          action: async () =>
            await this.confirmPopupRevert.confirm(
              this.eventPointer,
              'Revert Distros'
            ),
          disabled:
                        selectedRow.length === 0 ||
                        this.trackedGridSelections.status.includes('APPROVED'),
          cssClasses: ['text-accent']
        }
      ]
      // Delete action button
      const deleteSizeDistroButton = [
        {
          name:
                        selectedRow.length > 0
                          ? `Delete ${selectedRow.length} Distro${
                                  selectedRow.length === 1 ? '' : 's'
                              }`
                          : 'Delete (No Distro(s) Selected)',
          action: async () =>
            await this.confirmPopupDelete.confirm(
              this.eventPointer,
              'Delete Distro'
            ),
          disabled: selectedRow.length === 0,
          cssClasses: ['text-warn']
        }
      ]

      const result = [
        ...drillInButton,
        // ...drillInMinusOneButton,
        'separator',
        ...approveButton,
        ...unApproveButton,
        ...RevertToTheSystemDistrosButton,
        ...deleteSizeDistroButton,
        'separator',
        ...DefaultGridContextMenu(params)
      ]
      return result
    } else {
      return DefaultGridContextMenu(params)
    }
  }

  // Auto save edited distro tag
  autoSaveDistroTag (event) {
    console.log('--> Auto Saving Distro Tag: ', event)
    // Save the current grid state
    SaveGridState(this.gridOptions, this.gridName)
    // Display the grid loading overlay
    this.gridApi.showLoadingOverlay()

    // An API call to save the edited distro tag
    this.sizeDistroManagementSummaryAPIs
      .AutoSaveDistroTag(event.data)
      .then(res => {
        if (res?.is_success) {
          // Call getAllSizeDistros to get the most updated data
          this.getAllSizeDistros(null)
        } else {
          this.gridApi.hideOverlay()
        }
      })
  }

  // Track Row Selection Changes
  onSelectedDistroChange (event) {
    console.log('row selection: changed: ', this.selectedDistros)
    this.selectedDistros = event.api.getSelectedNodes()

    if (this.selectedDistros.length > 0) {
      this.selectedDistros.forEach((distro, i) => {
        Object.keys(this.trackedGridSelections).forEach(key => {
          const cellValue = distro.data[key]

          if (i === 0) {
            this.trackedGridSelections[key] = [cellValue]
          } else if (
            !this.trackedGridSelections[key].includes(cellValue)
          ) {
            this.trackedGridSelections[key].push(cellValue)
          }
        })
      })
    } else {
      // Clear the stored selection
      Object.keys(this.trackedGridSelections).forEach(
        key => (this.trackedGridSelections[key] = [])
      )
    }
  }

  // Get grid view
  async getGridState (preserveCurrentFilters?: boolean) {
    this.isLoadingSavedView = true
    return await Promise.all([
      this.drillInFilteringAPIs.GetAllStoreLists(),
      this.generalAPIs.GetGridView(this.gridOptions.context.gridName)
    ]).then(data => {
      this.drillInFiltersStoreList = data[0]
      const res = data[1]
      try {
        this.storedGridSettings[this.gridName] = res?.is_success
          ? res.data
          : {
              colDefs: [],
              filters: [],
              externalFilters: null
            }

        if (
          !preserveCurrentFilters &&
                    this.storedGridSettings[this.gridName]?.externalFilters
        ) {
          this.gridOptions.context.externalFilters.forEach(filter => {
            const savedFilter =
                            this.storedGridSettings[this.gridName]
                              ?.externalFilters[filter]
            if (savedFilter && savedFilter !== undefined) {
              const filtersToApply =
                                this.storedGridSettings[this.gridName]
                                  ?.externalFilters[filter]
              if (
                filter === 'drillInFilters' &&
                                !this.drillInFiltersStoreList.listNames.includes(
                                  FormatKey(
                                    filtersToApply.store_list.store_list
                                  )
                                )
              ) {
                this[filter].store_list = {
                  store_list: null,
                  id: null
                }
              } else {
                this[filter] = filtersToApply
              }
              console.log(
                                `----> ${filter} - filters to apply: `,
                                filtersToApply
              )
            }
          })
        }
      } finally {
        this.isLoadingSavedView = false

        this.setSelectionOptions()

        return this.storedGridSettings
      }
    })
  }

  // Save current grid settings
  async saveGridStateAndFilters () {
    const rowData = this.getMappedRowData()

    // Set the rowData for the drill in grid to session storage
    if (rowData) {
      console.log('---> Setting Drill In data: ', rowData)
      const ag_grid_settings = {
        sdm_detail_grid: {
          distro_ids: rowData
            ? rowData.map(row => row.distro_id)
            : [],
          ...this.drillInFilters
        }
      }
      // Store the Size Distro Management selected distro id's for the drill in screen
      sessionStorage.setItem(
        'ag_grid_settings',
        JSON.stringify(ag_grid_settings, GetJSONCircularReplacer())
      )
    }
    await SaveGridState(this.gridOptions, this.gridName)
  }

  // Update the form values for the drill in filter options
  updateFormValue (form, index?) {
    const field = form.controlName
    const value = cloneDeep(form.value)

    console.log('---> Drill In Filters Updated: ', form)
    if (form.status === 'VALID') {
      if (
        field === 'store_list' &&
                (value.store_list === '' ||
                    value.store_list === null ||
                    value === '')
      ) {
        this.drillInFilters[field] = { store_list: null, id: null }
      } else {
        this.drillInFilters[field] = value
      }
    } else if (form.status === 'DISABLED') {
      console.log('field is disabled: ', form)
    } else {
      console.error('field error: ', form)
    }
  }

  // Access the DrillIn Grid
  onDrillIn (event) {
    console.log('---> Drilling Send -1: ', this.drillInFilters.send_neg_1)

    this.saveGridStateAndFilters()

    this.router.navigateByUrl(
      'size-distro-management/drill-in-size-summary'
    )
  }

  getMappedRowData = () => this.selectedDistros.map(node => node.data)
  // Size Range Alterations
  onOpenSizeRangeAlterationsModal (event) {
    console.log('open size range alterations modal')
    // Save Grid State
    SaveGridState(this.gridOptions, this.gridName)
    // Open the modal
    this.ref = this.dialogService.open(SizeRangeAlterationsModalComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      width: '100%',
      height: '100%',
      contentStyle: { overflow: 'auto' },
      styleClass: 'large-modal',
      data: {
        rowData: this.getMappedRowData(),
        componentParent: this
      }
    })
    // Modal Close Data
    this.ref.onClose.subscribe(data => {
      console.log('Size Range Alteration Modal Closed: ', data)
      // Allow new api calls once the modal is closed
      this.triggerApiCallsService.onTriggerApiCalls({
        clear_api_calls: false
      })

      this.gridApi.deselectAll()
    })
  }

  // Bulk Override Distros Modal
  onOpenBulkOverrideDistrosModal (event) {
    console.log('open bulk override distros modal')
    // Save Grid State
    SaveGridState(this.gridOptions, this.gridName)
    // Open the modal
    this.ref = this.dialogService.open(BulkOverridesModalComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'small-modal',
      data: {
        rowData: this.getMappedRowData(),
        componentParent: this
      }
    })
    // Modal Close Data
    this.ref.onClose.subscribe(data => {
      // Allow new api calls once the modal is closed
      this.triggerApiCallsService.onTriggerApiCalls({
        clear_api_calls: false
      })

      if (data) {
        console.log('Bulk Override Distros Modal Closed')
        this.gridApi.showLoadingOverlay()
        this.gridApi.deselectAll()
        this.getAllSizeDistros(null)
      }
    })
  }

  // Bulk Override Distros Modal
  onOpenDrillInFiltersModal (event) {
    // Save Grid State
    SaveGridState(this.gridOptions, this.gridName)
    // Open the modal
    this.ref = this.dialogService.open(DrillInFilteringComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'large-modal',
      data: {
        // rowData: this.getMappedRowData(),
        componentParent: this,
        selectedStoreList: this.drillInFilters,
        storeLists: this.drillInFiltersStoreList
      }
    })
    // Modal Close Data
    this.ref.onClose.subscribe(async data => {
      // Allow new api calls once the modal is closed
      this.triggerApiCallsService.onTriggerApiCalls({
        clear_api_calls: false
      })

      if (IsKeyInObj(data, 'updatedStoreLists')) {
        const { allLists, listNames } = data['updatedStoreLists']
        const { id, store_list } = this.drillInFilters.store_list
        try {
          console.log('Drill In Filtering Modal Closed: ', data)
          this.drillInFiltersStoreList = { allLists, listNames }
          await this.getGridState()
        } finally {
          this._formData.store_list.selectionOptions = allLists
          this.formData.next(this._formData)

          if (id) {
            const updatedList =
                            allLists.find(
                              list =>
                                list.id === id &&
                                    FormatKey(list.store_list) !==
                                        FormatKey(store_list)
                            ) || null
            console.log('---> saved list: ', updatedList)
            if (updatedList) {
              this._formData.store_list.currentVal = updatedList
              this.formData.next(this._formData)

              this.drillInFilters.store_list = updatedList
              this.saveGridStateAndFilters()
            }
          }
        }
      }
      this.gridApi.deselectAll()
    })
  }

  // Copy Product Modal
  onOpenCopyProductModal (event) {
    console.log('open copy product modal')
    // Save Grid State
    SaveGridState(this.gridOptions, this.gridName)
    // Open the modal
    this.ref = this.dialogService.open(DistroCopyNewProductModalComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'hierarchy-modal',
      data: {
        rowData: this.getMappedRowData(),
        componentParent: this
      }
    })
    // Modal Close Data
    this.ref.onClose.subscribe(data => {
      console.log('Copy Product Modal Closed: ', data)
      // Allow new api calls once the modal is closed
      this.triggerApiCallsService.onTriggerApiCalls({
        clear_api_calls: false
      })
      if (data) {
        this.gridApi.showLoadingOverlay()
        this.gridApi.deselectAll()

        this.getAllSizeDistros(null)
      }
    })
  }

  // New Buying Season Modal
  onOpenNewBuyingSeasonModal (event) {
    console.log('open new buying season modal')
    // Save Grid State
    SaveGridState(this.gridOptions, this.gridName)
    // Open the modal
    this.ref = this.dialogService.open(
      DistroCopyBuyingSeasonModalComponent,
      {
        showHeader: false,
        closeOnEscape: true,
        dismissableMask: false,
        styleClass: 'medium-short-modal',
        data: {
          rowData: this.getMappedRowData(),
          componentParent: this
        }
      }
    )
    // Modal Close Data
    this.ref.onClose.subscribe(data => {
      // Allow new api calls once the modal is closed
      this.triggerApiCallsService.onTriggerApiCalls({
        clear_api_calls: false
      })

      if (data) {
        console.log('New Buying Season Modal Closed')
        this.gridApi.showLoadingOverlay()
        this.gridApi.deselectAll()
        this.getAllSizeDistros(null)
      }
    })
  }

  async getMappedDataToSubmit () {
    const dataToSubmit = {
      job_names: [],
      distro_ids: []
    }
    try {
      await this.selectedDistros.forEach(distro => {
        const { job_name, distro_id } = distro.data
        dataToSubmit.job_names.push(job_name)
        dataToSubmit.distro_ids.push(distro_id)
      })
    } finally {
      this.gridApi.deselectAll()
      return dataToSubmit
    }
  }

  // On Approve action button
  async onApprove (event) {
    console.log('On approve clicked', this.selectedDistros)

    try {
      //  Show loading overlay
      this.gridApi.showLoadingOverlay()
      // Save Grid State
      await SaveGridState(this.gridOptions, this.gridName)
    } finally {
      const dataToSubmit = await this.getMappedDataToSubmit()
      // An API call to approve distro
      await this.sizeDistroManagementSummaryAPIs
        .OnApprove(dataToSubmit)
        .then(res => {
          if (res?.is_success) {
            // Call getAllSizeDistros to get the most updated data
            this.getAllSizeDistros(null)
          } else {
            // Hide loading overlay
            this.gridApi.hideOverlay()
            // update the grid anyways
            this.getAllSizeDistros(null)
          }
        })
    }
  }

  // On Unapprove action button
  async onUnapprove (event) {
    console.log('On unapproved clicked', this.selectedDistros)
    try {
      //  Show loading overlay
      this.gridApi.showLoadingOverlay()
      // Save Grid State
      await SaveGridState(this.gridOptions, this.gridName)
    } finally {
      const dataToSubmit = await this.getMappedDataToSubmit()

      // An API call to unapprove distro
      await this.sizeDistroManagementSummaryAPIs
        .OnUnapprove(dataToSubmit)
        .then(res => {
          if (res?.is_success) {
            // Call getAllSizeDistros to get the most updated data
            this.getAllSizeDistros(null)
          } else {
            // Hide loading overlay
            this.gridApi.hideOverlay()
          }
        })
    }
  }

  // On revert to the system distros action button
  async onRevertToTheSystemDistros (event) {
    console.log(
      'On revert to system distros clicked',
      this.selectedDistros
    )

    // Check for the confirm popup
    if (event.userInput === 'except') {
      if (event.command === 'Revert Distros') {
        try {
          //  Show loading overlay
          this.gridApi.showLoadingOverlay()
          // Save Grid State
          await SaveGridState(this.gridOptions, this.gridName)
        } finally {
          const dataToSubmit = await this.getMappedDataToSubmit()
          // An API call to revert distro
          await this.sizeDistroManagementSummaryAPIs
            .OnRevertToTheSystemDistros(dataToSubmit)
            .then(res => {
              if (res?.is_success) {
                // Call getAllSizeDistros to get the most updated data
                this.getAllSizeDistros(null)
              } else {
                // Hide loading overlay
                this.gridApi.hideOverlay()
              }
            })
        }
      }
    }
  }

  // On delete distro action button
  async onDeleteDistro (event) {
    console.log('--> Deleting Distro', this.selectedDistros)

    // Check for the confirm popup
    if (event.userInput === 'except') {
      if (event.command === 'Delete Distro') {
        try {
          //  Show loading overlay
          this.gridApi.showLoadingOverlay()
          // Save Grid State
          await SaveGridState(this.gridOptions, this.gridName)
        } finally {
          const dataToSubmit = await this.getMappedDataToSubmit()

          // An API call to delete distro
          await this.sizeDistroManagementSummaryAPIs
            .OnDeleteDistro(dataToSubmit)
            .then(res => {
              if (res?.is_success) {
                // Call getAllSizeDistros to get the most updated data
                this.getAllSizeDistros(null)
              } else {
                // Hide loading overlay
                this.gridApi.hideOverlay()
              }
            })
        }
      }
    }
  }

  @HostListener('unloaded')
  ngOnDestroy () {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })
    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions()
      this.gridApi.expireValueCache()
      this.gridApi = null
    }
  }
}
