// Angular and RJX Imports
// =========================================================
import { Component, type OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { cloneDeep } from 'lodash'
// JSON Data
// =========================================================
import $jobUploadJSON from '../../../05_ag-grid-configs/01_json-grid-configs/colDefs-main-job-upload.json'
// Custom Imports
// =========================================================
import { GridFileUploadService } from 'src/app/core/services/grid-file-upload-service.service'
import { type GridFileUploadInterface } from 'src/app/core/interfaces/grid-file-upload-interface'
import { JobUploadAPIs } from 'src/app/core/apis/job-upload_api-calls'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { ManagementJobsAPIs } from 'src/app/core/apis/job-management_api-calls'
@Component({
  selector: 'app-job-upload',
  templateUrl: './job-upload.component.html',
  styleUrls: ['./job-upload.component.scss'],
  providers: [JobUploadAPIs]
})
export class JobUploadComponent implements OnInit {
  public jobUpload = $jobUploadJSON

  processingData: boolean = false
  canSubmitGridData: boolean = false
  existingProdIds: string[] = []
  checkExistingAPIFunc = null
  availableSizes: string[] = []
  selectionOptions: any
  selectionValues: any
  gridDetails: {
    ['Job Upload']: GridFileUploadInterface
  } = {
      'Job Upload': null
    }

  constructor (
    private readonly generalAPIs: GeneralAPIs,
    private readonly gridFileUploadService: GridFileUploadService,
    private readonly jobUploadAPIs: JobUploadAPIs,
    private readonly router: Router,
    private readonly managementJobsAPIs: ManagementJobsAPIs
  ) {
    this.checkExistingAPIFunc = this.generalAPIs.CheckProdIdDuplicates
    this.gridFileUploadService
      .getRowDataUpdates()
      .subscribe((res: GridFileUploadInterface) => {
        console.log(
                    `Job Upload Component - ${res.gridTitle} Grid: `,
                    res
        )

        console.log('---> Grid Details: ', this.gridDetails)
        if (res) {
          this.gridDetails[res.gridTitle] = res
          if (!this.gridDetails['Job Upload']?.rowDataIncomplete) {
            this.canSubmitGridData = false
          } else {
            this.canSubmitGridData = true
          }
        }
      })
  }

  async ngOnInit () {
    console.log('---- Inside Job Upload Component ----')
    this.getSelectionOptions()
    this.selectionValues =
            await this.generalAPIs.GetAllJobSelectionOptions()
  }

  async getSelectionOptions () {
    this.processingData = true
    try {
      this.availableSizes = await this.generalAPIs.GetAllSizesAndOrder()
      // this.existingProdIds = await this.generalAPIs.GetListOfAllProdIds()
    } finally {
      // Settings for the job upload grid
      this.selectionOptions = {
        size_list: this.availableSizes
        // product_node: this.existingProdIds
      }
      this.processingData = false
    }
  }

  async uploadJobAPI (queue: boolean) {
    console.log('---> Creating Job(s): ', this.gridDetails)

    this.gridDetails['Job Upload'].agGrid.showLoadingOverlay()
    // Format data for the backend
    const dataToSend = this.gridDetails['Job Upload']?.rowData.map(
      object => {
        const tempData = cloneDeep(object)
        // Rename size range
        tempData.size_range = object['size_range_(_|_)']
        // Delete the old name
        delete tempData['size_range_(_|_)']
        return tempData
      },
      queue
    )
    console.log('---> Data Reformatted For the backend: ', dataToSend)

    this.jobUploadAPIs.JobUpload(dataToSend, queue).then(res => {
      if (res?.is_success) {
        this.onAPISubmitComplete()
      } else {
        this.gridDetails['Job Upload'].agGrid.hideOverlay()
      }
    })
  }

  runJobAPI (newJobsToRun) {
    console.log('---> Running Job(s): ', this.gridDetails)

    this.managementJobsAPIs.RunJobs(newJobsToRun).then(res => {
      if (res?.is_success) {
        this.onAPISubmitComplete()
      } else {
        this.gridDetails['Job Upload'].agGrid.hideOverlay()
      }
    })
  }

  onAPISubmitComplete () {
    this.router.navigateByUrl('/size-distro-execution/job-management')
  }
}
