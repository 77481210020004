// Angular and RJX Imports
// =========================================================
import { Component, type OnInit } from '@angular/core'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import {
  DefaultChartOptions,
  StandardChartColors
} from 'src/app/utils/global-chart-settings'
@Component({
  selector: 'app-mismatch-chart',
  templateUrl: './mismatch-chart.component.html',
  styleUrls: ['./mismatch-chart.component.scss']
})
export class MismatchChartComponent implements OnInit {
  loading: boolean = false
  loadingMessage: string = 'loading'

  modalTitle: string

  data: any
  options: any
  level: number = 0
  sizes: any[] = []
  sizeDataPoints: any[] = []

  metric: string
  isBuy: boolean

  isMismatch: boolean

  colors = {
    over: StandardChartColors.primary,
    under: StandardChartColors.warn,
    over2: StandardChartColors.primary_lte,
    under2: StandardChartColors.warn_lte,
    buy: StandardChartColors.primary,
    buy2: StandardChartColors.secondary
  }

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService
  ) {
    console.log('---> Mismatch Charts Modal: ', config.data)
  }

  ngOnInit () {
    this.modalTitle = 'Target vs Buy Quantity'
  }

  // Function to calculate the bar chart data points
  calcChartData (data) {
    console.log('metric', this.metric)
    console.log('level', this.level)
    console.log('data', data)
    const getLowercaseSize = size =>
      isNaN(Number(size)) ? size.toLowerCase() : `${size}`
    const chartConfig = []
    let chartData
    let buy_total = 0

    if (this.isBuy) {
      switch (this.level) {
        case 0:
        case 1:
          this.sizes.forEach((size, i) => {
            buy_total += data[0][getLowercaseSize(size)].qty_bought

            if (this.sizes.length === i + 1) {
              chartData = this.sizes.map(s => {
                return {
                  x: s,
                  y:
                                        data[0][s.toLowerCase()].qty_bought /
                                        buy_total
                }
              })
            }
          })
          // Update the chart config
          chartConfig.push({
            ...this.getChartItem({
              backgroundColor: this.colors.buy,
              data: chartData,
              i: 0,
              metric: 'channel',
              label: `${data[0].channel}`
            })
          })
          break
          // case 1:
          //     this.sizes.forEach((size, i) => {
          //       buy_total += data[0][size.toLowerCase()].qty_bought;

          //       if (this.sizes.length === i + 1) {
          //         chartData = this.sizes.map(size => {
          //           return {
          //             x: size,
          //             y:
          //               data[0][size.toLowerCase()].qty_bought /
          //               buy_total,
          //           };
          //         });
          //       };

          //     });
          //     // Update the chart config
          //     chartConfig.push({
          //         ...this.getChartItem({
          //             backgroundColor: this.colors.buy,
          //             data: chartData,
          //             i: 0,
          //             metric: 'channel',
          //             label: `${data[0].channel}`,
          //         }),
          //     });
          //     break;
        case 2:
          chartData = {
            dataStore: [],
            dataChannel: []
          }
          let store_buy_total = 0
          let channel_buy_total = 0
          const getFormattedSize = (size, index) =>
            data[index][getLowercaseSize(size)]

          this.sizes.forEach((size, i) => {
            const size_vals = getFormattedSize(size, 0)
            channel_buy_total += getFormattedSize(
              size,
              1
            ).qty_bought

            if (size_vals) {
              store_buy_total += size_vals.qty_bought
            }

            if (this.sizes.length === i + 1) {
              this.sizes.forEach(s => {
                const s_vals = getFormattedSize(s, 0)
                chartData.dataStore.push({
                  x: s,
                  y: s_vals
                    ? s_vals.qty_bought / store_buy_total
                    : 0
                })

                chartData.dataChannel.push({
                  x: s,
                  y:
                                        data[1][s.toLowerCase()].qty_bought /
                                        channel_buy_total
                })
              })

              chartConfig.push(
                {
                  ...this.getChartItem({
                    backgroundColor: this.colors.buy,
                    data: chartData.dataStore,
                    i: 0,
                    metric: 'store',
                    label: `${data[0].store_id}`
                  })
                },
                {
                  ...this.getChartItem({
                    backgroundColor: this.colors.buy2,
                    data: chartData.dataChannel,
                    i: 1,
                    metric: 'channel',
                    label: `${data[0].channel}`
                  })
                }
              )
            }
          })

          break
        default:
          console.error('invalid level', this.level)
          break
      }
    } else {
      switch (this.level) {
        case 0:
          chartData = {
            dataTotalOver: [],
            dataTotalUnder: []
          }
          this.sizes.forEach(size => {
            const obj = data[0][getLowercaseSize(size)]
            chartData.dataTotalOver.push({
              y: size,
              x: obj.qty_over
            })
            chartData.dataTotalUnder.push({
              y: size,
              x: -obj.qty_under
            })
          })
          chartConfig.push(
            {
              ...this.getChartItem({
                backgroundColor: this.colors.over,
                data: chartData.dataTotalOver,
                i: 0,
                metric: 'channel',
                label: `${data[0].channel} - Over`
              })
            },
            {
              ...this.getChartItem({
                backgroundColor: this.colors.under,
                data: chartData.dataTotalUnder,
                i: 0,
                metric: 'channel',
                label: `${data[0].channel} - Under`
              })
            }
          )
          break
        case 1:
          chartData = {
            dataChannelOver: [],
            dataChannelUnder: []
          }

          this.sizes.forEach(size => {
            const obj = data[0][getLowercaseSize(size)]
            chartData.dataChannelOver.push({
              y: size,
              x: obj ? obj.qty_over : 0
            })
            chartData.dataChannelUnder.push({
              y: size,
              x: -(obj ? obj.qty_under : 0)
            })
          })
          chartConfig.push(
            {
              ...this.getChartItem({
                backgroundColor: this.colors.over,
                data: chartData.dataChannelOver,
                i: 0,
                metric: 'channel',
                label: `${data[0].channel} - Over`
              })
            },
            {
              ...this.getChartItem({
                backgroundColor: this.colors.under,
                data: chartData.dataChannelUnder,
                i: 0,
                metric: 'channel',
                label: `${data[0].channel} - Under`
              })
            }
          )
          break
        case 2:
          chartData = {
            dataStoreOver: [],
            dataStoreUnder: [],
            dataChannelOver: [],
            dataChannelUnder: []
          }
          data.forEach(item => {
            this.sizes.forEach(size => {
              const obj = item[getLowercaseSize(size)]
              if (item.store_id === '--') {
                chartData.dataChannelOver.push({
                  y: size,
                  x: obj ? obj.qty_over : 0
                })
                chartData.dataChannelUnder.push({
                  y: size,
                  x: -(obj ? obj.qty_under : 0)
                })
              } else {
                chartData.dataStoreOver.push({
                  y: size,
                  x: obj ? obj.qty_over : 0
                })

                chartData.dataStoreUnder.push({
                  y: size,
                  x: -(obj ? obj.qty_under : 0)
                })
              }
            })
          })

          chartConfig.push(
            {
              ...this.getChartItem({
                stack: 'store',
                backgroundColor: this.colors.over,
                data: chartData.dataStoreOver,
                i: 0,
                metric: 'store',
                label: `${data[0].store_id} - Over`
              })
            },
            {
              ...this.getChartItem({
                stack: 'store',
                backgroundColor: this.colors.under,
                data: chartData.dataStoreUnder,
                i: 0,
                metric: 'store',
                label: `${data[0].store_id} - Under`
              })
            },
            {
              ...this.getChartItem({
                stack: 'channel',
                backgroundColor: this.colors.over2,
                data: chartData.dataChannelOver,
                i: 1,
                metric: 'channel',
                label: `${data[0].channel} - Over`
              })
            },
            {
              ...this.getChartItem({
                stack: 'channel',
                backgroundColor: this.colors.under2,
                data: chartData.dataChannelUnder,
                i: 1,
                metric: 'channel',
                label: `${data[0].channel} - Under`
              })
            }
          )
          break
        default:
          console.error('invalid level', this.level)
          break
      }
    }
    console.log('chartConfig: ', chartConfig)
    this.updateChart(chartConfig)
  }

  getChartItem (values) {
    const { data, i, label, backgroundColor, stack } = values
    return {
      label,
      borderColor: backgroundColor,
      backgroundColor,
      stack,
      order: i,
      padding: '20px',
      data,
      borderWidth: 1,
      fill: false,
      tension: 0.1
    }
  }

  updateChart (chartConfig) {
    console.log('---> Updating Chart Data: ', chartConfig)
    this.data = {
      datasets: chartConfig.filter(e => e)
    }
    this.options = {
      ...DefaultChartOptions,
      indexAxis: 'y',
      // hover: {
      //   // Overrides the global setting
      //   mode: 'point',
      //   intersect: false
      // },
      // responsive: true,
      // maintainAspectRatio: false,
      // title: {
      //   display: false
      // },
      // legend: {
      //   position: 'top',
      //   align: 'center' // start or end
      // },
      scales: this.isBuy
        ? {
            x: {
              type: 'category',
              labels: this.sizes
            },
            y: {
              ticks: {
                padding: 10,
                callback: function (value, index, ticks) {
                  return Math.round(value * 100) + '%'
                }
              },
              type: 'linear',
              position: 'left',
              min: 0
            }
          }
        : {
            x: {
              ticks: { padding: 10 },
              type: 'linear'
            },
            y: {
              stacked: true,
              type: 'category',
              labels: this.sizes,
              position: 'left'
            }
          },
      //   scales: {
      //     xAxes: {
      //         ticks: { padding: 10, stepSize: this.isMismatch ? undefined : 1 },
      //         stacked: true
      //     },
      //     yAxes: {
      //         ticks: { },
      //         stacked: true
      //     }
      // },
      plugins: {
        tooltip: {
          ...DefaultChartOptions.plugins.tooltip,
          callbacks: {
            ...DefaultChartOptions.plugins.tooltip.callbacks,
            title: context => {
              // console.log('TITLE context', context)
              // let title =  data?.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].y
              // context.yLabel = title
              // context.label = title
              if (this.isBuy) {
                return `Size: ${context[0].formattedValue}`
              } else {
                return `Size: ${context[0].label}`
              }
            },
            label: context => {
              // console.log('LABEL context', context)
              if (this.isBuy) {
                return `${context.dataset.label}: ${(
                                    parseFloat(context.label) * 100
                                ).toFixed(2)}%`
              } else {
                // remove negative sign
                let value = context.formattedValue
                value = value.startsWith('-')
                  ? value.substring(1)
                  : value
                return `${context.dataset.label}: ${value}`
              }
            }
            // afterBody: function(t, d) {
            //   return 'loss 15%';  // return a string that you wish to append
            // }
          }
        }
      }
    }
    this.loading = false
  }

  close (data?: any) {
    if (this.ref) this.ref.close(data || null)
  }
}
