// Angular and RJX Imports
// =========================================================
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  type OnInit,
  type SimpleChanges,
  type OnDestroy
} from '@angular/core'
import * as XLSX from 'xlsx'
import { v4 as uuidv4 } from 'uuid'
// Ag Grid Imports
// =========================================================
import {
  type GridReadyEvent,
  type GridApi,
  type ColumnApi,
  type CellEditingStoppedEvent,
  type RowDataUpdatedEvent,
  type ModelUpdatedEvent
} from 'ag-grid-community'
// Custom Imports
// =========================================================
import { CustomGridOptions } from 'src/app/05_ag-grid-configs/02_global-settings/grid-options'
import { ProductUpload_GenerateGridData } from 'src/app/05_ag-grid-configs/04_generate-colDefs/product-upload-colDefs'
import { AttributeUpload_GenerateGridData } from 'src/app/05_ag-grid-configs/04_generate-colDefs/attribute-upload-colDefs'
import {
  FormatKey,
  GetValue,
  IsKeyInObj,
  ReverseFormatKey
} from 'src/app/utils/global_functions'
import { GridFileUploadService } from 'src/app/core/services/grid-file-upload-service.service'
import { JobUpload_GenerateGridData } from 'src/app/05_ag-grid-configs/04_generate-colDefs/job-upload-colDefs'
import moment from 'moment'
import { FormatDate } from 'src/app/05_ag-grid-configs/02_global-settings/global-cell-formatter'

@Component({
  selector: 'app-upload-grids',
  templateUrl: './upload-grids.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./upload-grids.component.scss']
})
export class UploadGridsComponent implements OnInit, OnDestroy {
  // Ag Grid Configurations
  public popupParent: HTMLElement | null = document.querySelector('body')
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      onDeleteRow: params => this.onDeleteRow(params),
      pageTitle: ''
    }
  }

  @Input() colDefJSONSettings: any
  @Input() generateGridColumnsFunc: string
  @Input() gridTitle: string
  @Input() displayHeader: boolean
  @Input() exportFileName: string
  @Input() addRowEnabled: boolean
  @Input() autoSizeColumns: boolean

  autoSelectOptions: any // the key must match a grid column key and the value must be a string array
  // Set the auto select options
  @Input('autoSelectOptions')
  set _autoSelectOptions (data: any) {
    console.log('---> autoSelectOptions updated: ', data)
    this.autoSelectOptions = data
  }

  noDuplicates: any // the key must match a grid column key and the value must be a string array
  @Input('noDuplicates')
  set _noDuplicates (data: any) {
    console.log('---> noDuplicates allowed updated: ', data)
    this.noDuplicates = data
  }

  checkDuplicatesAPIFunc: any
  @Input('checkDuplicatesAPIFunc')
  set _checkDuplicatesAPIFunc (data: any) {
    console.log('---> checkDuplicatesAPIFunc allowed updated: ', data)
    this.checkDuplicatesAPIFunc = data
  }

  uploadIsEnabled: boolean
  @Input('uploadIsEnabled')
  set _uploadIsEnabled (toggleUpload: boolean) {
    // console.log('---> upload button state toggleUpload: ', toggleUpload)
    this.uploadIsEnabled = toggleUpload
  }

  processingData: boolean
  @Input('processingData')
  set _processingData (processing: boolean) {
    console.log('---> Processing Data: ', processing)
    this.processingData = processing
  }

  selectionValues: any
  @Input('selectionValues')
  set _selectionValues (data: any) {
    console.log('---> SelectionValues Data: ', data)
    this.selectionValues = data
  }

  @Output() onGridDataUpdated: EventEmitter<any> = new EventEmitter<any>()
  // Data set by the incoming column definitions
  requiredFields: string[]
  requiredFieldsFormatted: string[]
  optionalInputs: string[] = []
  blankRowData: any = {}
  downloadMessage: string =
    '** Please complete all of the mandatory fields below for all rows before uploading. **'

  // Ag Grid Configuration
  public gridApi: GridApi
  columnApi: ColumnApi
  gridOptions: any = {}
  isAgGridLoading: boolean = true
  // Grid Data
  columnDefs: any[] = []
  rowData = []

  // File upload settings
  fileData: any = []
  headers: any[] = []
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' }
  fileName: string = 'SheetJS.xlsx'
  dlmOpts: string[] = ['DLM = 1', 'DLM = 2', 'DLM = 3', 'DLM = 4']

  tableResetDisabled: boolean = true

  @ViewChild('fileUpload')
    fileUpload

  constructor (private readonly gridFileUploadService: GridFileUploadService) {
    this.gridOptions = {
      ...this.customGridOptions,
      rowClassRules: {
        'parent-row-warning': params => {
          const status =
                        (params.data?.status &&
                            params.data.status.toLowerCase()) ||
                        null
          return (
            status === 'failed' ||
                        (status && status.includes('error'))
          )
        }
      },
      onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
      getRowId: node => node?.data.id,
      onCellEditingStopped: async (event: CellEditingStoppedEvent) =>
        this.checkRowData(event),
      onModelUpdated: (event: ModelUpdatedEvent) => {
        const rowsAvailable = event.api.getDisplayedRowCount() === 0
        const el = <HTMLInputElement>(
                    document.getElementById(this.gridTitle + '-reset')
                )
        el.disabled = rowsAvailable
        this.tableResetDisabled = rowsAvailable
      },
      onRowDataUpdated: async (event: RowDataUpdatedEvent) => {
        if (this.autoSizeColumns) event.columnApi.autoSizeAllColumns()
        event.columnApi.autoSizeColumn('status')
        await this.checkRowData(event)
      },
      overlayNoRowsTemplate: `
      <div class="ag-overlay-loading-center">
        <div class="far fa-frown">
        Please do one of the following:
          <ol style="text-align: start;
          padding-right: 20px;"
      }>
              <li> Upload a .csv or .xlsx file </li>
              <li> Download the correctly formatted template </li>
          </ol>
        </div>
      </div>`
    }
  }

  ngOnInit () {
    // Update the grid name
    this.gridOptions.context.pageTitle = this.exportFileName

    this.requiredFields = Object.keys(this.colDefJSONSettings[0]).filter(
      (field, i) => {
        const f = FormatKey(field)
        if (this.colDefJSONSettings[0][field]?.refData?.optionalInput) {
          this.optionalInputs.push(f)
        }
        return f !== 'delete' && f !== 'status'
      }
    )
    // Define Blank Row Data
    this.requiredFieldsFormatted = this.requiredFields.map(field => {
      this.blankRowData[field] = ''
      return FormatKey(field)
    })
    // console.log('required fields: ', this.requiredFields);
    // console.log('requiredFieldsFormatted: ', this.requiredFieldsFormatted);
    // console.log('blank row Data: ', this.blankRowData);
  }

  ngOnChanges (changes: SimpleChanges): void {
    // console.log('CHANGES: ', changes)
  }

  // Render AG Grid Column Definitions
  async renderGrid (mainColDefs) {
    const params = {
      mainColDefs
    }
    const getGridData = async () => {
      switch (this.generateGridColumnsFunc) {
        case 'ProductUpload_GenerateGridData':
          return await ProductUpload_GenerateGridData(params)
        case 'AttributeUpload_GenerateGridData':
          return await AttributeUpload_GenerateGridData(params)
        case 'JobUpload_GenerateGridData':
          return await JobUpload_GenerateGridData(params)
      }
    }
    const gridData = await getGridData()

    if (gridData) {
      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          if (this.gridApi && !this.gridApi['destroyCalled']) {
            console.log(
                            `---> Upload Grid Column: ${this.generateGridColumnsFunc}`,
                            gridData.mainColDefs
            )

            this.columnDefs = gridData.mainColDefs
            this.gridApi?.setColumnDefs(gridData.mainColDefs)
            this.gridApi?.setRowData([])
            this.columnApi.autoSizeColumn('status')
          }
          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  onGridReady (event: GridReadyEvent) {
    this.gridApi = event.api
    this.columnApi = event.columnApi
    this.isAgGridLoading = false

    this.gridApi.showLoadingOverlay()

    this.renderGrid(this.colDefJSONSettings[0])
  }

  @HostListener('keydown', ['$event'])
  onKeydown (event) {
    event.stopPropagation()
    if (event.key == 'Escape') {
      return false
    }

    if (event.key == 'Enter' || event.key == 'Tab') {
      return false
    }

    if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
      return false
    }
  }

  onUploadFile (event) {
    if (this.fileUpload) {
      this.fileUpload.basicFileInput.nativeElement.click()
    }
  }

  onFileChange (event: any, fileUpload) {
    // format fields for error messages

    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event
    Object.keys(event.currentFiles).forEach(key => {
      const reader: FileReader = new FileReader()

      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.currentTarget.result
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          FS: ','
        })

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0]
        const ws: XLSX.WorkSheet = wb.Sheets[wsname]

        /* save data */
        const fileData = <any>(
                    XLSX.utils.sheet_to_json(ws, { header: 1, raw: false })
                )
        if (fileData[0].length === 1) {
          fileData.splice(0, 1)
        }

        /* Transform data  */
        if (fileData) {
          this.fileData = fileData
          console.log('Form Data: ', this.fileData)
          // check to make sure that the headers from the file match the required fields for the grid
          const formattedHeaders = this.fileData[0].map(header =>
            FormatKey(header?.trim())
          )
          const correctHeaders = formattedHeaders.filter(header => {
            return this.requiredFieldsFormatted.includes(header)
          })
          console.log('requiredFields: ', this.requiredFields)
          console.log('formattedHeaders: ', formattedHeaders)
          console.log('correctHeaders: ', correctHeaders)

          if (this.requiredFields.length === correctHeaders.length) {
            // Format data for AG Grid table
            const mappedData = Object.entries(this.fileData).map(
              ([key, value], rowI) => {
                // The headers will always be the first item in the array
                // if ((key === '0') || (key === '1' && this.fileData[0] === this.downloadMessage) ) {
                if (key === '0') {
                  return { headers: correctHeaders }
                } else {
                  const tempRow = {
                    status: null,
                    error_reason: null,
                    id: uuidv4()
                  }
                  // Loop through the required fields to make sure that the correct information is present
                  this.requiredFields.map((field, i) => {
                    const formattedField = FormatKey(field)
                    const fieldIndex =
                                            formattedHeaders.indexOf(
                                              formattedField
                                            )
                    console.log(
                      'formattedField: ',
                      formattedField
                    )
                    console.log('fieldIndex: ', fieldIndex)
                    const cellValue = value[fieldIndex]
                      ? value[fieldIndex]
                        .toString()
                        .replaceAll(/"/g, '')
                        .trim()
                      : value[fieldIndex]
                    console.log(
                      'cellValue: ',
                      cellValue,
                      i
                    )

                    if (
                      formattedField ===
                                                'prior_selling_season' &&
                                            cellValue !== undefined
                    ) {
                      this.optionalInputs =
                                                this.optionalInputs.filter(
                                                  v =>
                                                    v !==
                                                        'prior_selling_season'
                                                )
                    } else if (
                      formattedField ===
                                                'prior_selling_season' &&
                                            cellValue === undefined
                    ) {
                      this.optionalInputs =
                                                this.optionalInputs.includes(
                                                  'prior_selling_season'
                                                )
                                                  ? this.optionalInputs
                                                  : [
                                                      ...this
                                                        .optionalInputs,
                                                      'prior_selling_season'
                                                    ]
                    }

                    // Function to generate error messages if data is missing or incorrect data is trying to be uploaded
                    const setRowDataUploadError = () => {
                      tempRow[formattedField] = null
                      tempRow.status = 'Upload Error'
                      // Set Error Message
                      if (tempRow.error_reason) {
                        tempRow.error_reason =
                                                    tempRow.error_reason +
                                                    `, ${field} = ${cellValue}`
                      } else {
                        tempRow.error_reason = `Invalid data for the following field(s): ${field} = ${cellValue}`
                      }
                    }
                    console.log(
                      'cellValue: ',
                      cellValue,
                      i
                    )
                    if (
                      cellValue === undefined ||
                                            cellValue === '' ||
                                            cellValue === null
                    ) {
                      if (
                        !this.optionalInputs.includes(
                          formattedField
                        )
                      ) {
                        setRowDataUploadError()
                      } else {
                        tempRow[formattedField] = null
                      }
                    } else {
                      // All required data cells except for sizeRange have a datatype of selectionAutoComplete meaning that their values must be included in their respective key from the autoSelectOptions vairiable
                      // the autoSelectOptions is populated with data from the backend
                      if (
                        formattedField ===
                                                    'eligible_sizes_(_|_)' ||
                                                formattedField ===
                                                    'size_range_(_|_)'
                      ) {
                        const sizesArray =
                                                    cellValue.length === 1
                                                      ? cellValue
                                                      : cellValue.includes(
                                                        '|'
                                                      )
                                                        ? cellValue.split('|')
                                                        : cellValue.split(',')
                        const incorrectSizes =
                                                    sizesArray.filter(
                                                      size =>
                                                        !this.autoSelectOptions?.size_list.includes(
                                                          size
                                                        )
                                                    )
                        console.log(
                          '---- Size Validator ----: ',
                          incorrectSizes
                        )
                        if (
                          incorrectSizes.length === 0
                        ) {
                          tempRow[formattedField] =
                                                        this.autoSelectOptions?.size_list.filter(
                                                          size =>
                                                            sizesArray.includes(
                                                              size
                                                            )
                                                        )
                        } else {
                          setRowDataUploadError()
                        }
                      } else if (
                        formattedField ===
                                                'buying_season'
                      ) {
                        if (
                          this.selectionValues.buying_seasons.some(
                            season =>
                              season.season ===
                                                            cellValue
                          )
                        ) {
                          tempRow[formattedField] =
                                                        cellValue
                        } else {
                          setRowDataUploadError()
                        }
                      } else if (
                        formattedField === 'channel'
                      ) {
                        if (
                          this.selectionValues.channels.some(
                            channel =>
                              channel ===
                                                            cellValue
                          )
                        ) {
                          tempRow[formattedField] =
                                                        cellValue
                        } else {
                          setRowDataUploadError()
                        }
                      } else if (
                        formattedField ===
                                                'prior_selling_season'
                      ) {
                        if (
                          this.selectionValues.prior_selling_seasons.some(
                            season =>
                              season.season ===
                                                            cellValue
                          )
                        ) {
                          tempRow[formattedField] =
                                                        cellValue
                        } else {
                          setRowDataUploadError()
                        }
                      } else if (
                        IsKeyInObj(
                          this.autoSelectOptions,
                          formattedField
                        )
                      ) {
                        const availOptions =
                                                    this.autoSelectOptions[
                                                      formattedField
                                                    ] || null
                        if (availOptions) {
                          const foundUpperCase =
                                                        availOptions.includes(
                                                          isNaN(
                                                            Number(
                                                              cellValue
                                                            )
                                                          )
                                                            ? cellValue.toUpperCase()
                                                            : cellValue
                                                        )
                          const foundLowerCase =
                                                        availOptions.includes(
                                                          cellValue
                                                        )
                          console.log(
                            'foundUpperCase',
                            foundUpperCase
                          )
                          console.log(
                            'foundLowerCase',
                            foundLowerCase
                          )
                          if (
                            foundUpperCase ||
                                                        foundLowerCase
                          ) {
                            tempRow[
                              formattedField
                            ] = foundUpperCase
                              ? cellValue.toUpperCase()
                              : cellValue
                          } else {
                            setRowDataUploadError()
                          }
                        } else {
                          setRowDataUploadError()
                        }
                      } else {
                        tempRow[formattedField] =
                                                    cellValue
                      }
                    }
                  })
                  return tempRow
                }
              }
            )
            // filter out extra headers if they are present
            const tableData = mappedData.filter(
              (item, i) => i !== 0
            )
            console.log('table data: ', tableData)
            // append the imported data to the grid
            this.gridApi.applyTransaction({
              add: tableData,
              addIndex: 0
            })

            // Clear Upload form
            fileUpload.clear()
          } else {
            console.error('Unable to process file upload')
            this.onAddRow({
              status: 'Upload Error',
              error_reason:
                                'Unable to process file. Files can only be read if the delimiter is a comma. Please make sure the column names are presented in the following manner: Season, Channel, Product ID, Size Offering.'
            })
            // Clear Upload form
            fileUpload.clear()
          }
        }
      }
      reader.readAsBinaryString(target.files[0])
    })
  }

  onAddRow (error?) {
    const setError = error || {}

    this.gridApi.applyTransaction({
      add: [{ ...this.blankRowData, id: uuidv4(), ...setError }],
      addIndex: 0
    })
  }

  onResetTable () {
    this.gridApi.setRowData([])
  }

  onDeleteRow (params) {
    console.log('delete a row: ', params)
    this.gridApi.applyTransaction({
      remove: [{ id: params.node.data.id }]
    })
  }

  onDownloadTemplate () {
    this.gridApi.exportDataAsExcel({
      columnKeys: this.requiredFieldsFormatted,
      allColumns: false,
      fileName: `${this.exportFileName}_` + moment().format('LLL'),
      skipHeader: false,
      onlySelected: true,
      prependContent: [
        {
          cells: [
            {
              data: {
                value: this.downloadMessage,
                type: 'String'
              },
              mergeAcross:
                                this.requiredFieldsFormatted.length - 1,
              styleId: 'upload-grid-header'
            }
          ]
        }
      ],
      pageSetup: {
        orientation: 'Landscape',
        pageSize: 'Letter'
      },
      margins: {
        top: 1,
        right: 1,
        bottom: 1,
        left: 1,
        header: 0.5,
        footer: 0.5
      }
    })
  }

  checkRowData (event) {
    console.log('---> Validating Row Data: ', event)
    const updatedRowData = {}
    this.rowData = []
    this.gridApi.forEachLeafNode(node => {
      const data = node.data
      if (data) {
        // Track rowData to be updated
        updatedRowData[data.id] = data
        // Track current rowData
        this.rowData.push({ ...data })
      }
    })
    // get all proxy products
    console.log('updatedrowdata', JSON.stringify(updatedRowData))
    if (
      this.gridTitle == 'Job Upload' &&
            this.requiredFieldsFormatted.includes('product_node')
    ) {
      const allProductNodes = Object.entries(updatedRowData).map(
        ([key, value]) => {
          return value['product_node']
        }
      )
      this.checkDuplicatesAPIFunc([allProductNodes]).then(data => {
        const missingProductNodes = Object.fromEntries(
          Object.entries(updatedRowData).filter(([key, value]) => {
            return !data.data.includes(value['product_node']) // collect missing nodes
          })
        )
        this.checkRowDataCallback(
          event,
          updatedRowData,
          missingProductNodes
        )
      })
    } else if (this.requiredFieldsFormatted.includes('parent_product_id')) {
      const allProductNodes = Object.entries(updatedRowData).map(
        ([key, value]) => {
          return value['parent_product_id']
        }
      )
      this.checkDuplicatesAPIFunc([allProductNodes]).then(data => {
        const missingProductNodes = Object.fromEntries(
          Object.entries(updatedRowData).filter(([key, value]) => {
            return !data.data.includes(value['parent_product_id']) // collect missing nodes
          })
        )
        this.checkRowDataCallback(
          event,
          updatedRowData,
          missingProductNodes
        )
      })
    } else if (this.requiredFieldsFormatted.includes('proxy_product_id')) {
      const allProxyProds = Object.entries(updatedRowData).map(
        ([key, value]) => {
          return value['proxy_product_id']
        }
      )
      this.checkDuplicatesAPIFunc([allProxyProds]).then(data => {
        const duplicateProxyProds = Object.fromEntries(
          Object.entries(updatedRowData).filter(([key, value]) => {
            return data.data.includes(value['proxy_product_id'])
          })
        )
        this.checkRowDataCallback(
          event,
          updatedRowData,
          duplicateProxyProds
        )
      })
    } else {
      this.checkRowDataCallback(event, updatedRowData)
    }
    if (
      this.gridTitle == 'Job Upload' &&
            this.requiredFieldsFormatted.includes('job_name')
    ) {
      const allJobNames = Object.entries(updatedRowData).map(
        ([key, value]) => {
          return value['job_name']
        }
      )
      const seen = new Set()
      const duplicateJobNamesSet = new Set()

      allJobNames.forEach(item => {
        if (seen.has(item)) {
          duplicateJobNamesSet.add(item)
        } else {
          seen.add(item)
        }
      })

      const duplicateJobNames = Object.fromEntries(
        Object.entries(updatedRowData).filter(([key, value]) => {
          return Array.from(duplicateJobNamesSet).includes(
            value['job_name']
          )
        })
      )
      this.checkRowDataCallback(
        event,
        updatedRowData,
        {},
        duplicateJobNames,
        true
      )
    }
  }

  checkRowDataCallback (
    event,
    updatedRowData,
    productObj = {},
    duplicateJobNames = {},
    checkDuplicates = false
  ) {
    let rowDataIncomplete = false

    try {
      // After the most current row data is collected => validate the data
      if (Object.keys(updatedRowData).length > 0) {
        const updatedRows = Object.entries(updatedRowData).map(
          ([key, value]) => {
            const dup = 'duplicate_data'
            const idString = 'id'
            const id = value[idString]
            const data = value
            const incompleteFields = []
            //
            this.requiredFieldsFormatted.forEach(
              async (field, i) => {
                // if data is missing from any of the required fields -> rowdata is incomplete
                if (
                  (!data[field] || data[field] === '') &&
                                    !this.optionalInputs.includes(field)
                ) {
                  rowDataIncomplete = true
                  incompleteFields.push(field)
                }
                if (
                  IsKeyInObj(this.noDuplicates, field) &&
                                    this.noDuplicates[field].includes(
                                      data[field]
                                    )
                ) {
                  rowDataIncomplete = true
                  Object.assign(updatedRowData[id], {
                    error_reason: `${ReverseFormatKey(
                                            field
                                        ).toUpperCase()}: ${
                                            data[field]
                                        } Already exists. Please delete this row to continue.`,
                    status: 'Error'
                  })
                }
                if (
                  this.gridTitle == 'Job Upload' &&
                                    field == 'job_name' &&
                                    duplicateJobNames[id]
                ) {
                  rowDataIncomplete = true
                  Object.assign(updatedRowData[id], {
                    error_reason: `${ReverseFormatKey(
                                            field
                                        ).toUpperCase()}: ${
                                            data[field]
                                        } Already exists. Please delete this row to continue.`,
                    status: 'Error'
                  })
                }

                if (
                  this.gridTitle == 'Job Upload' &&
                                    checkDuplicates &&
                                    field == 'job_name' &&
                                    Object.keys(duplicateJobNames).length ===
                                        0 &&
                                    updatedRowData[id].error_reason &&
                                    updatedRowData[id].error_reason.includes(
                                      'Already exists. Please delete this row to continue'
                                    )
                ) {
                  Object.assign(updatedRowData[id], {
                    error_reason: null,
                    status: null
                  })
                }
                if (
                  this.gridTitle == 'Job Upload' &&
                                    this.requiredFieldsFormatted.includes(
                                      'product_node'
                                    )
                ) {
                  if (
                    productObj[id] &&
                                        field == 'product_node'
                  ) {
                    rowDataIncomplete = true
                    Object.assign(updatedRowData[id], {
                      error_reason: `${ReverseFormatKey(
                                                field
                                            ).toUpperCase()}: ${
                                                data[field]
                                            } Does not exist. Please delete this row to continue.`,
                      status: 'Error'
                    })
                  }
                } else if (
                  this.requiredFieldsFormatted.includes(
                    'parent_product_id'
                  )
                ) {
                  if (
                    productObj[id] &&
                                        field == 'parent_product_id'
                  ) {
                    rowDataIncomplete = true
                    Object.assign(updatedRowData[id], {
                      error_reason: `${ReverseFormatKey(
                                                field
                                            ).toUpperCase()}: ${
                                                data[field]
                                            } Does not exist. Please delete this row to continue.`,
                      status: 'Error'
                    })
                  }
                } else if (
                  this.requiredFieldsFormatted.includes(
                    'proxy_product_id'
                  )
                ) {
                  if (
                    productObj[id] &&
                                        field == 'proxy_product_id'
                  ) {
                    rowDataIncomplete = true
                    Object.assign(updatedRowData[id], {
                      error_reason: `${ReverseFormatKey(
                                                field
                                            ).toUpperCase()}: ${
                                                data[field]
                                            } Already exists. Please delete this row to continue.`,
                      status: 'Error'
                    })
                  }
                }
                if (i + 1 === this.requiredFields.length) {
                  // Prevent Duplicate data from being sent to the backend
                  const matches = Object.values(
                    updatedRowData
                  ).filter(key => {
                    const hasMatch = [
                      ...new Set(
                        this.requiredFieldsFormatted.map(
                          rff => {
                            return (
                              key[rff] ===
                                                            data[rff]
                            )
                          }
                        )
                      )
                    ]
                    return (
                      key[idString] !== data[idString] &&
                                            !hasMatch.includes(false)
                    )
                  })

                  if (matches.length > 0) {
                    rowDataIncomplete = true

                    if (incompleteFields.length === 0) {
                      const ids = matches.map(
                        row => row[idString]
                      )
                      const errorObject = {
                        duplicate_data: [
                          data[idString],
                          ...ids
                        ],
                        error_reason:
                                                    'Duplicated row data',
                        status: 'Error'
                      }
                      errorObject.duplicate_data.forEach(
                        dup => {
                          Object.assign(
                            updatedRowData[dup],
                            errorObject
                          )
                        }
                      )
                    }
                  } else if (
                    matches.length === 0 &&
                                        data[dup] &&
                                        data[dup].length > 0
                  ) {
                    // Remove the duplicate id from all rows
                    data[dup].forEach(dups => {
                      // Check to make sure all duplicate rows still exists (some may have been deleted)
                      const index = IsKeyInObj(
                        updatedRowData,
                        dups
                      )
                        ? updatedRowData[dups][
                          dup
                        ].indexOf(id)
                        : -1

                      if (index > -1) {
                        Object.assign(
                          updatedRowData[dups],
                          {
                            duplicate_data: null,
                            error_reason: null,
                            status: null
                          }
                        )
                      }
                    })
                  }
                  // check for updates to upload errors
                  if (
                    i + 1 === this.requiredFields.length &&
                                        updatedRowData[id]?.status &&
                                        updatedRowData[id]?.status ===
                                            'Upload Error' &&
                                        incompleteFields.length === 0
                  ) {
                    if (
                      !updatedRowData[id][
                        'error_reason'
                      ].includes('Prior Selling Season')
                    ) {
                      Object.assign(updatedRowData[id], {
                        error_reason: null,
                        status: null
                      })
                    }
                  }
                }
              }
            )
            return value
          }
        )
        console.log('updatedRows', JSON.stringify(updatedRows))

        // Update Ag Grid with the new data
        if (updatedRows) {
          const updateRows = Object.values(updatedRowData)
          console.log(
            'stringified row data',
            JSON.stringify(this.rowData)
          )
          console.log(
            'stringified updaterows',
            JSON.stringify(updateRows)
          )
          if (
            JSON.stringify(this.rowData) !==
                        JSON.stringify(updateRows)
          ) {
            console.log('assigning updaterows to this.rowdata')
            this.rowData = updateRows
            this.gridApi.applyTransaction({ update: updateRows })
          }
        }
      }
    } catch (error) {
    } finally {
      const noRows = this.rowData.length === 0
      // Disable the submit functionality if the rowData is incomplete
      rowDataIncomplete = noRows ? true : rowDataIncomplete

      console.log('--> Uploaded Row Data: ', this.rowData)
      console.log('---> Row Data is Incomplete: ', rowDataIncomplete)

      this.gridFileUploadService.onUpdateRowDataErrors({
        rowDataIncomplete,
        rowData: this.rowData,
        gridTitle: this.gridTitle,
        agGrid: this.gridApi
      })
    }
  }

  ngOnDestroy () {
    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions()
      this.gridApi.expireValueCache()
      this.gridApi = null
    }
  }
}
