import { clone } from 'lodash'

// Data object expected from the backend
export interface DefaultParametersInputsInterface {
  editable?: boolean
  attribute_family: string
  curve_smoothing: boolean
  smoothing_strength: string
  blending_strength: string
  min_size_sales: number
  min_store_sales: number
  product_id: string
  starting_node_level: number
  top_node_level: number
  use_aug_sales: boolean
  vg_count: number
}

export const GetDefaultParametersDefaultInputs = (editable: boolean) => {
  return {
    editable,
    ...clone(DefaultParametersDefaultInputs)
  }
}
export const DefaultParametersDefaultInputs = {
  attribute_family: null,
  curve_smoothing: false,
  smoothing_strength: null,
  blending_strength: null,
  min_size_sales: null,
  min_store_sales: null,
  product_id: null,
  starting_node_level: null,
  top_node_level: null,
  use_aug_sales: false,
  vg_count: null
}

export interface TreeDataParamsInterface {
  queryString?: string
  selectedProd?: string
  selectedFilters?: any
}
