// Angular and RJX Imports
// =========================================================
import {
  Component,
  type OnInit,
  ViewChild,
  type OnDestroy,
  HostListener
} from '@angular/core'
import { cloneDeep } from 'lodash'
// JSON Data
// =========================================================
import $storeIndexJSON from 'src/app/05_ag-grid-configs/01_json-grid-configs/store-index/colDefs-main-store-index.json'
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog'
// Ag Grid Imports
// =========================================================
import {
  type GridReadyEvent,
  type GridApi,
  type ColumnApi,
  type SelectionChangedEvent,
  type RowDataUpdatedEvent
} from 'ag-grid-community'
// Angular Material Imports
// =========================================================
import { MatMenuTrigger } from '@angular/material/menu'
// Custom Imports
// =========================================================
import { CopyStoreIndexJobModalComponent } from 'src/app/04_modals/store-indexes/copy-store-index-job-modal/copy-store-index-job-modal.component'
import {
  CustomGridOptions,
  DefaultGridContextMenu
} from 'src/app/05_ag-grid-configs/02_global-settings/grid-options'
import { CreateEditStoreIndexJobModalComponent } from 'src/app/04_modals/store-indexes/create-edit-store-index-job-modal/create-edit-store-index-job-modal.component'
import { OutputGridDataModalComponent } from 'src/app/04_modals/store-indexes/output-grid-data-modal/output-grid-data-modal.component'
import { StoreIndexAPIs } from 'src/app/core/apis/store-index_api-calls'
import { ManagementJobsAPIs } from 'src/app/core/apis/job-management_api-calls'
import { SideBarPanels } from 'src/app/05_ag-grid-configs/02_global-settings/grid-variables'
import { StoreIndex_GenerateGridData } from 'src/app/05_ag-grid-configs/04_generate-colDefs/store-index-colDefs'
import { type AGGrigSavedSettingsInterface } from 'src/app/core/interfaces/ag-grid-saved-settings-interface'
import { ConfirmPopupComponent } from 'src/app/03_shared-components/01_alerts/confirm-popup/confirm-popup.component'
import { TriggerApiCallsService } from 'src/app/core/services/cancel-api-call'
import { FormatKey } from 'src/app/utils/global_functions'
import { GeneralAPIs } from 'src/app/core/apis/general_api-calls'
import { SaveGridState } from 'src/app/05_ag-grid-configs/02_global-settings/grid-functions-general'

@Component({
  selector: 'app-store-index',
  templateUrl: './store-index.component.html',
  styleUrls: ['./store-index.component.scss']
})
export class StoreIndexComponent implements OnInit, OnDestroy {
  // The SideBarPanels class is used to create and manage the sidebar panels
  private readonly sideBarPanels: any = SideBarPanels()

  gridName: string = 'store_index_management_view'
  // Ag Grid Configuration
  private readonly customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      allowSaveView: true,
      gridName: this.gridName,
      columnsToAutoSize: [
        'job_name',
        'product_node',
        'buying_season',
        'status',
        'edited',
        'last_modified'
      ],
      pageTitle: 'Store Index Management',
      onOpenOutputModal: params => this.onOpenOutputModal(params),
      onOpenEditJobModal: params =>
        this.onOpenCreateEditJobsModal(params, 'edit'),
      providers: [StoreIndexAPIs, ManagementJobsAPIs]
    }
  }

  // Ag Grid Configuration
  gridApi: GridApi
  columnApi: ColumnApi
  gridOptions: any = {}
  isAgGridLoading: boolean = true

  // Grid Data
  columnDefs: any[] = []

  eventPointer: any
  // Variables to monitor data from the selected grid rows
  selectedStoreIndexes: any[] = []
  public storeIndex = $storeIndexJSON
  storedGridSettings: AGGrigSavedSettingsInterface
  // Enable and disable grid actions based on specific rules
  gridActionsEnabled = {
    approve: false,
    unapprove: false,
    run: false,
    delete: false,
    copy: false
  }

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger
  @ViewChild('confirmPopupDelete') confirmPopupDelete: ConfirmPopupComponent

  constructor (
    private ref: DynamicDialogRef,
    public dialogService: DialogService,
    private readonly storeIndexAPIs: StoreIndexAPIs,
    public triggerApiCallsService: TriggerApiCallsService,
    private readonly generalAPIs: GeneralAPIs
  ) {
    this.storedGridSettings =
            JSON.parse(sessionStorage.getItem('ag_grid_settings')) || {}
    //  Set AG Grid Options
    this.gridOptions = {
      ...this.customGridOptions,
      sideBar: {
        toolPanels: [
          this.sideBarPanels.columnPanel,
          this.sideBarPanels.filterPanel
        ],
        ...this.sideBarPanels.defaultLayout
      },
      onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
      getRowId: node => FormatKey(node?.data?.job_name),
      // custom context Menu Items
      getContextMenuItems: params =>
        this.storeIndexJobsGridContextMenu(params),
      onSelectionChanged: (event: SelectionChangedEvent) =>
        this.onSelectedStoreIndexChange(event),
      onRowDataUpdated: (event: RowDataUpdatedEvent) => {
        event.api.redrawRows()
      }
      // onFirstDataRendered: (event) => {   this.columnApi?.autoSizeColumns(['prior_selling_seasons', 'use_aug_sales', 'vg_count', 'edited', 'last_modified'], true)}
    }

    // Event is fired when the user right-clicks on an element to open the context menu
    document.addEventListener('contextmenu', event => {
      if (event.button === 2) {
        this.eventPointer = event
        // console.log('Right click', event);
      }
    })
  }

  ngOnInit () {
    // Call getAllStoreIndexes to get the most updated data
    this.getAllStoreIndexes(false)
  }

  onGridReady (event: GridReadyEvent) {
    this.gridApi = event.api
    this.columnApi = event.columnApi
    this.isAgGridLoading = false
    this.gridApi.closeToolPanel()
  }

  // Render AG Grid Column Definitions
  async renderGrid (mainColDefs, rowData) {
    this.selectedStoreIndexes = []
    const params = {
      mainColDefs
    }
    let gridData
    try {
      await this.getGridState()
      gridData = await StoreIndex_GenerateGridData(params)
    } finally {
      this.columnDefs = gridData.mainColDefs

      // function to check if AgGrid is loading
      const checkAgGrid = () => {
        if (!this.isAgGridLoading) {
          if (this.gridApi && !this.gridApi['destroyCalled']) {
            this.gridApi?.setColumnDefs(gridData.mainColDefs)

            this.gridApi?.setRowData(rowData)
            console.log(
              '==============================================================================================, JobGridDataSubscription'
            )
            // Set the subscription for APSYNC
            this.storeIndexAPIs.JobGridDataSubscription(
              this.gridApi
            )
            // Apply the saved column state
            if (
              Object.keys(this.storedGridSettings).length > 0 &&
                            this.storedGridSettings[this.gridName]
            ) {
              const { colDefs, filters } =
                                this.storedGridSettings[this.gridName]
              // Apply the saved column state
              this.gridOptions?.columnApi?.applyColumnState({
                state: colDefs
              })
              // Apply the column order
              this.gridOptions?.columnApi?.moveColumns(
                colDefs.map(col => col.colId),
                0
              )
              // Apply the saved filter state
              this.gridOptions?.api?.setFilterModel(filters)
            }
          }
          clearInterval(setData)
        } else {
          console.log('Ag grid is loading')
        }
      }
      const setData = setInterval(checkAgGrid, 100)
    }
  }

  // Get grid view
  async getGridState () {
    return await this.generalAPIs
      .GetGridView(this.gridOptions.context.gridName)
      .then(res => {
        this.storedGridSettings[this.gridOptions.context.gridName] =
                    res?.is_success
                      ? res.data
                      : {
                          colDefs: [],
                          filters: []
                        }
      })
  }

  // Track Row Selection Changes
  onSelectedStoreIndexChange (event) {
    console.log('row selection: changed: ', this.selectedStoreIndexes)
    const rows = event.api.getSelectedNodes()
    const isRowSelected = rows.length > 0
    this.selectedStoreIndexes = rows

    // Reset the status tracker
    this.gridActionsEnabled = {
      approve: false,
      unapprove: false,
      run: false,
      delete: false,
      copy: false
    }
    if (isRowSelected) {
      const selectedStatuses = {
        generated: false,
        approved: false,
        running: false,
        failed: false,
        pending: false
      }
      // Update Tracked statuses
      rows.forEach((row, i) => {
        if (row.data) {
          const status = row?.data?.status.toLowerCase()
          if (!selectedStatuses[status]) {
            selectedStatuses[status] = true
          }
        }
        if (rows.length === i + 1) {
          const { running, approved, generated, failed, pending } =
                        selectedStatuses
          this.gridActionsEnabled = {
            approve: !approved && !running && !failed && !pending,
            unapprove:
                            !generated && !running && !failed && !pending,
            run: !running && !approved,
            delete: !running,
            copy: !running && !failed
          }
          console.log(
            'gridActions enabled: ',
            this.gridActionsEnabled
          )
        }
      })
    }
  }

  // Sets the context menu in the grid
  storeIndexJobsGridContextMenu (params) {
    console.log('---> Viewing Context Menu: ', params)
    if (params.column) {
      const selectedRow = params.api.getSelectedNodes()
      // Certain Actions should be disabled based on the statuses of selected rows
      const { approve, unapprove, run } = this.gridActionsEnabled
      // Approve store index action button
      const approveStoreIndexButton = [
        {
          name:
                        selectedRow.length > 0
                          ? `Approve ${selectedRow.length} Job${
                                  selectedRow.length === 1 ? '' : 's'
                              }`
                          : 'Approve (No Jobs Selected)',
          action: async () => await this.onApproveJobs(params),
          // disabled: selectedRow.length === 0 || approved || running,
          disabled: !approve,
          cssClasses: ['text-accent']
        }
      ]
      // Un approve store index action button
      const unApproveStoreIndexButton = [
        {
          name:
                        selectedRow.length > 0
                          ? `Unapprove ${selectedRow.length} Job${
                                  selectedRow.length === 1 ? '' : 's'
                              }`
                          : 'Unapprove (No Jobs Selected)',
          action: async () => await this.onUnapproveJobs(params),
          // disabled: selectedRow.length === 0 || generated || running,
          disabled: !unapprove,
          cssClasses: ['text-accent']
        }
      ]
      // Un approve store index action button
      const runStoreIndexButton = [
        {
          name:
                        selectedRow.length > 0
                          ? `Run ${selectedRow.length} Job${
                                  selectedRow.length === 1 ? '' : 's'
                              }`
                          : 'Run (No Jobs Selected)',
          action: async () => await this.onRunJobs(params),
          // disabled: selectedRow.length === 0 || running || approved,
          disabled: !run,
          cssClasses: ['text-accent']
        }
      ]
      // Delete store index action button
      const deleteStoreIndexButton = [
        {
          name:
                        selectedRow.length > 0
                          ? `Delete ${selectedRow.length} Job${
                                  selectedRow.length === 1 ? '' : 's'
                              }`
                          : 'Delete (No Jobs Selected)',
          action: async () =>
            await this.confirmPopupDelete.confirm(
              this.eventPointer,
              'Delete Job'
            ),
          // disabled: selectedRow.length === 0 || running,
          disabled: !this.gridActionsEnabled.delete,
          cssClasses: ['text-warn']
        }
      ]

      const result = [
        ...approveStoreIndexButton,
        ...unApproveStoreIndexButton,
        ...runStoreIndexButton,
        ...deleteStoreIndexButton,
        'separator',
        ...DefaultGridContextMenu(params)
      ]

      return result
    } else {
      return DefaultGridContextMenu(params)
    }
  }

  // On Approve Jobs
  async onApproveJobs (event) {
    console.log('---> Approving Job: ', this.selectedStoreIndexes)
    const index_ids = this.selectedStoreIndexes.map(
      store => store.data.job_name
    )
    try {
      //  Show loading overlay
      this.gridApi.showLoadingOverlay()
      // Save Grid State
      await SaveGridState(this.gridOptions, this.gridName)
    } finally {
      // An API call to approve jobs
      await this.storeIndexAPIs.OnApproveJobs({ index_ids }).then(res => {
        if (res?.is_success) {
          console.log('Successfully Approved Distro')
          // Call getAllStoreIndexes to get the most updated data
          this.getAllStoreIndexes(true)
        } else {
          // Hide loading overlay
          this.gridApi.hideOverlay()
        }
      })
    }
  }

  // On Unapprove Jobs
  async onUnapproveJobs (event) {
    console.log('---> Unapproving Job: ', this.selectedStoreIndexes)
    const index_ids = this.selectedStoreIndexes.map(
      store => store.data.job_name
    )
    try {
      //  Show loading overlay
      this.gridApi.showLoadingOverlay()
      // Save Grid State
      await SaveGridState(this.gridOptions, this.gridName)
    } finally {
      // An API call to unapprove jobs
      await this.storeIndexAPIs
        .OnUnapproveJobs({ index_ids })
        .then(res => {
          if (res?.is_success) {
            console.log('Successfully Unapproved Distro')
            // Call getAllStoreIndexes to get the most updated data
            this.getAllStoreIndexes(true)
          } else {
            // Hide loading overlay
            this.gridApi.hideOverlay()
          }
        })
    }
  }

  // On run jobs
  async onRunJobs (event) {
    console.log('---> Running Job: ', this.selectedStoreIndexes)
    const job_names = this.selectedStoreIndexes.map(
      store => store.data.job_name
    )

    try {
      // Save Grid State
      await SaveGridState(this.gridOptions, this.gridName)
    } finally {
      // An API call to on run jobs
      await this.storeIndexAPIs.OnRunJobs({ job_names }).then(res => {
        if (res?.is_success) {
          console.log('Successfully Jobs Running')
          // Call getAllStoreIndexes to get the most updated data
          // this.getAllStoreIndexes(true)
          this.gridOptions.api.deselectAll()
          this.selectedStoreIndexes = []
        } else {
          this.gridApi.hideOverlay()
        }
      })
    }
  }

  async onDeleteJobs (event) {
    console.log('---> Deleting Job: ', this.selectedStoreIndexes)
    this.trigger.closeMenu()
    // Check for the confirm popup
    if (event.userInput === 'except') {
      if (event.command === 'Delete Job') {
        const store_index_job_names = this.selectedStoreIndexes.map(
          store => store.data.job_name
        )
        try {
          this.gridApi.showLoadingOverlay()
          // Save Grid State
          await SaveGridState(this.gridOptions, this.gridName)
        } finally {
          // An API call to delete store index
          await this.storeIndexAPIs
            .OnDeleteJobs({ store_index_job_names })
            .then(res => {
              if (res?.is_success) {
                console.log('Successfully Deleted Job')
                // Call getAllStoreIndexes to get the most updated data
                this.getAllStoreIndexes(true)
              } else {
                // Hide loading overlay
                this.gridApi.hideOverlay()
              }
            })
        }
      }
    }
  }

  onOpenOutputModal (data: any) {
    // Save Grid State
    SaveGridState(this.gridOptions, this.gridName)
    // Open the modal
    this.ref = this.dialogService.open(OutputGridDataModalComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      contentStyle: { overflow: 'auto' },
      styleClass: 'large-modal',
      data: {
        componentParent: this,
        rowData: data
      }
    })
    // Modal Close Data
    this.ref.onClose.subscribe(data => {
      // Allow new api calls once the modal is closed
      this.triggerApiCallsService.onTriggerApiCalls({
        clear_api_calls: false
      })

      if (data) {
        console.log('Copy Store Index Job Modal Closed: ', data)
        // Call getAllStoreIndexes to get the most updated data
        this.getAllStoreIndexes(true)
      }
    })
  }

  onOpenCreateEditJobsModal (data: any, action: string) {
    // Save Grid State
    SaveGridState(this.gridOptions, this.gridName)
    // Open the modal
    this.ref = this.dialogService.open(
      CreateEditStoreIndexJobModalComponent,
      {
        showHeader: false,
        closeOnEscape: true,
        dismissableMask: false,
        styleClass: 'large-modal',
        data: {
          componentParent: this,
          rowData: cloneDeep(data),
          action
        }
      }
    )
    // Modal Close Data
    this.ref.onClose.subscribe(data => {
      // Allow new api calls once the modal is closed
      this.triggerApiCallsService.onTriggerApiCalls({
        clear_api_calls: false
      })
      if (data) {
        console.log('Copy Store Index Job Modal Closed: ', data)
        this.getAllStoreIndexes(true)
      }
    })
  }

  onOpenCopyJobsModal (event) {
    // Save Grid State
    SaveGridState(this.gridOptions, this.gridName)
    // Open the modal
    this.ref = this.dialogService.open(CopyStoreIndexJobModalComponent, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: false,
      styleClass: 'medium-short-modal',
      data: {
        componentParent: this,
        rowData:
                    this.selectedStoreIndexes.length > 0
                      ? this.selectedStoreIndexes.map(node => node.data)
                      : []
      }
    })
    // Modal Close Data
    this.ref.onClose.subscribe(data => {
      // Allow new api calls once the modal is closed
      this.triggerApiCallsService.onTriggerApiCalls({
        clear_api_calls: false
      })
      if (data) {
        console.log('Copy Store Index Job Modal Closed: ', data)
        this.getAllStoreIndexes(true)
      }
    })
  }

  // Get all store indexes
  async getAllStoreIndexes (resetGridData: boolean) {
    try {
      if (resetGridData) {
        this.gridOptions.api.deselectAll()
        this.gridApi.setRowData([])
        this.selectedStoreIndexes = []
        this.gridApi.showLoadingOverlay()
      }
    } finally {
      await this.storeIndexAPIs.GetAllStoreIndexes().then(res => {
        // Render the grid
        if (res && res.length > 0) {
          this.renderGrid(this.storeIndex[0], res)
        } else if (res.length === 0) {
          this.gridApi.setRowData([])
        } else if (resetGridData) {
          this.gridApi.hideOverlay()
        }
      })
    }
  }

  @HostListener('unloaded')
  ngOnDestroy () {
    this.triggerApiCallsService.onTriggerApiCalls({
      clear_api_calls: true
    })

    if (this.gridApi) {
      this.gridApi.flushAsyncTransactions()
      this.gridApi.expireValueCache()
      this.gridApi = null
    }
  }
}
