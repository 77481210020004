<app-modal-header
    modalTitle="Copy Store Index Job"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div
    class="modal-content"
    [attr.loading]="loading"
    [attr.flexDirection]="'row'"
    [attr.size]="'medium'"
>
    <!-- Selected Jobs Section -->
    <section
        *ngIf="!loading"
        id="job-scope-selection"
        class="primary-section-container"
    >
        <app-basic-headers
            [sectionName]="'Selected Job(s) To Copy'"
            [color]="'lte'"
        >
        </app-basic-headers>
        <!-- Checkbox list of selected Jobs -->
        <div class="section-body-container">
            <span class="checkbox-list-section custom-vertical-scroll">
                <!-- Selected Style Colors list -->
                <ul
                    class="form-content-container"
                    *ngIf="ObjectKeys(JobIdsToCopy).length > 0"
                >
                    <li *ngFor="let selectedJob of ObjectKeys(JobIdsToCopy)">
                        <mat-checkbox
                            color="accent"
                            [value]="JobIdsToCopy[selectedJob].checked"
                            [checked]="JobIdsToCopy[selectedJob].checked"
                            (click)="
                                $event.stopPropagation();
                                onUpdateJobIdSelection($event, selectedJob)
                            "
                        >
                            {{
                                selectedJob +
                                    '; ' +
                                    JobIdsToCopy[selectedJob].season
                            }}
                        </mat-checkbox>
                    </li>
                </ul>
                <!-- If there are no selected Jobs -->
                <span *ngIf="ObjectKeys(JobIdsToCopy).length === 0">
                    <p class="m-t-10">** No Jobs Selected **</p>
                </span>
            </span>
            <!-- Selected Jobs Footer -->
            <div class="selection-summary-text-container">
                <!-- Original Selection From the grid -->
                <span>
                    <p class="summary-title"><b>Selected Jobs(s):</b></p>
                    <p>
                        {{ selectedJobIds.length }}
                    </p></span
                >
                <!-- Total Jobs to Copy -->
                <span>
                    <p class="summary-title"><b>Total Job(s) To Copy:</b></p>
                    <p>
                        {{
                            completedJobsCopy?.jobs_to_copy?.length ||
                                'No Jobs Selected'
                        }}
                    </p>
                </span>
            </div>
        </div>
    </section>
    <!-- Job Scope Section -->
    <section
        id="job-scope-forms"
        *ngIf="!loading"
        class="primary-section-container"
    >
        <app-basic-headers
            [sectionName]="'Job Scope'"
            [color]="'lte'"
        ></app-basic-headers>

        <div class="section-body-container">
            <!-- Prior Selling Season -->
            <app-form-fields
                [formParams]="formData"
                [controlName]="'prior_selling_season'"
                (onFormFieldUpdated)="updateFormValue($event)"
            >
            </app-form-fields>
            <!-- New Buying Season -->
            <app-form-fields
                [formParams]="formData"
                [controlName]="'buying_seasons'"
                (onFormFieldUpdated)="updateFormValue($event)"
            >
            </app-form-fields>
        </div>
    </section>
    <!-- Loading Component -->
    <app-loader *ngIf="loading" [loadingMessage]="loadingMessage"></app-loader>
</div>

<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="true"
    submitBtnColor="accent"
    (update)="onCreateIndexJobs($event)"
    [disabled]="
        loading ||
        completedJobsCopy.jobs_to_copy.length === 0 ||
        completedJobsCopy.buying_seasons === null
    "
    [closeDisabled]="loadingMessage === 'submitting'"
>
</app-modal-footer>
