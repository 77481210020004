// Amplify Imports
// =========================================================
import { API, Auth } from 'aws-amplify'
// Prime NG Imports
// =========================================================
import { type DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { handleError } from 'src/app/utils/error_handling'
import { IsKeyInObj } from 'src/app/utils/global_functions'
import { environment } from 'src/environments/environment'
import {
  type ApiMessagesInterface,
  type ApiDataInterface
} from '../interfaces/data-expected-from-backend/api-data-interface'

// Set the api Name
export const apiName = `${environment.stage}-sizeo-service`

export const displayUserFeedback = (
  response,
  componentParent: any,
  messages: ApiMessagesInterface
) => {
  if (response?.is_success) {
    console.log(`${messages.success}`, response)
    if (messages.showSuccessToast) {
      // Get and use from the parent component showSuccess
      componentParent.toastAlert.showSuccess(messages.success)
    }
  } else {
    console.error(`${messages.error}`, response)

    if (messages.showErrorToast) {
      // Get and use from the parent component showError
      componentParent.toastAlert.showError(messages.error)
    } else {
      if (IsKeyInObj(response, 'system_code')) {
        handleError(
          componentParent.dialogService,
          {
            status: response.system_code,
            statusText: messages.error
          },
          response.details
        )
      } else {
        handleError(
          componentParent.dialogService,
          {
            status: 403,
            statusText: 'API Response Error'
          },
          messages.error + '. ' + 'Please contact an administrator.'
        )
      }
    }
  }
}

// Execute Get Requests
export const ExecuteGetAPI = async (
  path: string,
  componentParent: any,
  messages: ApiMessagesInterface
) => {
  if (!componentParent.clearApiCalls) {
    // console.log('component parent: ', componentParent)
    const initBody = {
      headers: {
        // in the Authorization with the Bearer we need to send the IdToken
        Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
      }
    }

    return await API.get(apiName, path, initBody)
      .then((response: ApiDataInterface) => {
        // in the response we have: data, is_success, system_code, timestamp and details
        displayUserFeedback(response, componentParent, messages)
        return response
      })
      .catch(error => {
        const fError = IsKeyInObj(error, 'response')
          ? error?.response?.data
          : error
        displayUserFeedback(fError, componentParent, messages)
        return fError
      })
  } else {
    return null
  }
}
// Execute Post Requests
export const ExecutePostAPI = async (
  path: string,
  componentParent,
  body: any,
  messages: ApiMessagesInterface
) => {
  if (!componentParent.clearApiCalls) {
    const initBody = {
      headers: {
        // in the Authorization with the Bearer we need to send the IdToken
        Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
      },
      body
    }

    return await API.post(apiName, path, initBody)
      .then((response: ApiDataInterface) => {
        // in the response we have: data, is_success, system_code, timestamp and details
        displayUserFeedback(response, componentParent, messages)
        return response
      })
      .catch(error => {
        const fError = IsKeyInObj(error, 'response')
          ? error?.response?.data
          : error
        displayUserFeedback(fError, componentParent, messages)
        return fError
      })
  } else {
    return null
  }
}

// Execute Put Requests
export const ExecutePutAPI = async (
  path: string,
  componentParent,
  body: any,
  messages: ApiMessagesInterface
) => {
  if (!componentParent.clearApiCalls) {
    const initBody = {
      headers: {
        // in the Authorization with the Bearer we need to send the IdToken
        Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
      },
      body
    }

    return await API.put(apiName, path, initBody)
      .then((response: ApiDataInterface) => {
        // in the response we have: data, is_success, system_code, timestamp and details
        displayUserFeedback(response, componentParent, messages)
        return response
      })
      .catch(error => {
        const fError = IsKeyInObj(error, 'response')
          ? error?.response?.data
          : error
        displayUserFeedback(fError, componentParent, messages)
        return fError
      })
  } else {
    return null
  }
}
// Execute Delete Requests
export const ExecuteDeleteAPI = async (
  path: string,
  componentParent,
  messages: ApiMessagesInterface,
  body?: any
) => {
  if (!componentParent.clearApiCalls) {
    const initBody = {
      headers: {
        // in the Authorization with the Bearer we need to send the IdToken
        Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
      },
      body: body || null
    }
    console.log('initBody', initBody)

    return await API.del(apiName, path, initBody)
      .then(response => {
        displayUserFeedback(response, componentParent, messages)
        return response
      })
      .catch(error => {
        const fError = IsKeyInObj(error, 'response')
          ? error?.response?.data
          : error
        displayUserFeedback(fError, componentParent, messages)
        return fError
      })
  } else {
    return null
  }
}
