[
    {
        "Email": {
            "editable": false,
            "minWidth": 300,
            "refData": {
                "datatype": "string"
            },
            "rowGroup": false,
            "hide": false,
            "sort": "asc"
        },

        "Products": {
            "editable": true,
            "refData": {
                "datatype": "multi-selection-auto-complete",
                "showToolTip": false
            },
            "rowGroup": false,
            "hide": false
        },
        "Admin permissions": {
            "editable": false,
            "refData": {
                "datatype": "multi-checkbox-column",
                "showToolTip": false,
                "displayHeaderName": true,
                "colSize": 200
            },
            "maxWidth": 200,
            "rowGroup": false,
            "hide": false
        },
        "Edited": {
            "editable": false,
            "refData": {
                "datatype": "boolean"
            },
            "rowGroup": false,
            "hide": true
        },
        "Save": {
            "editable": false,
            "refData": {
                "datatype": "action-button|save",
                "displayBlankHeader": true
            },
            "rowGroup": false,
            "hide": false
        }
    }
]
