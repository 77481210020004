// Angular Imports
// =========================================================
import { Component, Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { MessageService, PrimeNGConfig } from 'primeng/api'

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-toast-alert',
  templateUrl: './toast-alert.component.html',
  styleUrls: ['./toast-alert.component.scss'],
  providers: [MessageService]
})
export class ToastAlertComponent {
  constructor (
    private readonly messageService: MessageService,
    private readonly primengConfig: PrimeNGConfig
  ) {}

  ngOnInit () {
    this.primengConfig.ripple = true
  }

  showSuccess (message: string) {
    // console.log('show success: ', message)
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message
    })
  }

  showInfo (message: string) {
    this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: message
    })
  }

  showWarn (message: string, sticky?: boolean) {
    this.messageService.add({
      severity: 'warn',
      summary: 'Warn',
      detail: message,
      sticky
    })
  }

  showError (message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    })
  }

  showSessionEnding (message: string, severity: string, sticky: boolean) {
    this.messageService.add({
      severity,
      // summary: 'Time Remaining',
      detail: message,
      icon: 'pi-clock',
      sticky
    })
  }

  // showMultiple() {
  //     this.messageService.addAll([
  //         {severity:'success', summary:'Message 1', detail:'Message Content'},
  //         {severity:'info', summary:'Message 2', detail:'Message Content'},
  //         {severity:'warn', summary:'Message 3', detail:'Message Content'}
  //     ]);
  // }
  // onConfirm() {
  //     this.messageService.clear('c');
  // }

  // onReject() {
  //     this.messageService.clear('c');
  // }

  clear () {
    this.messageService.clear()
  }
}
