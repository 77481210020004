// Grid Functions
// =========================================================

import { CustomGridOptions } from './grid-options'

// Default Detail grid settings
export const DefaultDetailGridOptions = {
  defaultColDef: CustomGridOptions.defaultColDef,
  columnTypes: CustomGridOptions.columnTypes,
  aggFuncs: CustomGridOptions.aggFuncs,
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
  enableRangeSelection: true,
  columnDefs: [],
  onCellClicked: params => console.log('detail grid cell clicked: ', params),
  rowClassRules: CustomGridOptions.rowClassRules

  // pagination: true,
  // paginationAutoPageSize: true,
}
// Grid side panels
export const SideBarPanels = () => {
  const filters = {
    id: 'filters',
    labelDefault: 'Filters',
    labelKey: 'filters',
    iconKey: 'filter',
    toolPanel: 'agFiltersToolPanel',
    toolPanelParams: {
      suppressExpandAll: true,
      suppressFilterSearch: false
    }
  }
  const columns = {
    id: 'columns',
    labelDefault: 'Table Config',
    labelKey: 'columns',
    iconKey: 'columns',
    toolPanel: 'agColumnsToolPanel',
    toolPanelParams: {
      // suppressColumnExpandAll: true,
      // contractColumnSelection: true,
      suppressValues: true,
      suppressColumnSelectAll: true,
      suppressPivotMode: true,
      suppressRowGroups: true
    }
  }

  return {
    defaultLayout: {
      position: 'left',
      defaultToolPanel: 'columns'
    },
    filterPanel: filters,
    columnPanel: columns,
    allPanels: [columns, filters]
  }
}
