// Custom Imports
// =========================================================
import { FormatKey } from 'src/app/utils/global_functions'
import { GenerateDefaultColDefs } from '../02_global-settings/global-cell-formatter'

export interface PackSummaryConfig_GridDataParams {
  mainColDefs: any[]
}

export async function PackSummaryConfig_GenerateGridData (
  params: PackSummaryConfig_GridDataParams
) {
  const {
    mainColDefs
    // detailColDefs
  } = params
  const GenerateCols = async (data: any[], gridToRender: string) => {
    const mappedCols = {}

    Object.entries(data).map(([colKey, colVal]) => {
      const colId = colVal.refData?.custom_field || FormatKey(colKey)

      const obj = {
        ...GenerateDefaultColDefs(colKey, { colId, ...colVal })
      }
      Object.assign(mappedCols, {
        [obj.colId]: PackSummaryConfig_customCellFormatting(
          obj,
          gridToRender
        )
      })
    })

    return Object.values(mappedCols)
  }

  const getResults = async () => {
    const mainCols = await GenerateCols(mainColDefs, 'main')

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    return data
  }
  return await getResults()
}

export const PackSummaryConfig_customCellFormatting = (col, gridToRender) => {
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      // console.log('col.colId', col.colId)
      switch (col.colId) {
        case 'product_size':
          return {
            valueGetter: params => {
              if (params.node.rowPinned) {
                let total = 0
                params.api.forEachNode(node => {
                  total += Number(node.data.inner_carton_qty)
                })
                return `Total Qty: ${total}`
              } else {
                return params.value
                  ? params.value
                  : params.data.product_size
              }
            },
            colSpan: params => (params.node.rowPinned ? 2 : 1)
          }
        default:
          return {}
      }
    }
  }
  const updatedCol = {
    ...col,
    ...getParams()
  }
  return updatedCol
}
