import { type RowNode } from 'ag-grid-community'
import { IsKeyInObj } from 'src/app/utils/global_functions'

// AG Grid external filter function
export const IsExternalFilterPresent = filtersApplied => {
  // console.log("isexternalfitleprresent", filtersApplied)
  if (filtersApplied !== null) {
    const filters = Object.keys(filtersApplied).filter(
      key => filtersApplied[key].length > 0
    )
    console.log('filters', filters)
    return filters.length > 0
  }
  return false
}

// AG Grid external filter function
export const DoesExternalFilterPass = (node, filtersApplied) => {
  // console.log("doesexternalfilterpass")
  // console.log('with node', node)
  // console.log('and filters', filtersApplied)
  const allFilters = Object.keys(filtersApplied).filter(
    key => filtersApplied[key].length > 0
  )
  const filters = allFilters.filter(key => {
    const isGroupRow = node.group
    const isTrue =
            !isGroupRow &&
            filtersApplied[key].map(e => e.data).includes(node.data[key])
    return isTrue
  })

  return filters.length === allFilters.length
}

export const ExpandRowById = (gridApi, rowIds) => {
  gridApi.forEachNode((node: RowNode) => {
    if (rowIds.includes(node.id)) return node.setExpanded(true)
    return node.setExpanded(false)
  })
}

export const CollapseRowById = (gridApi, rowIds) => {
  gridApi.forEachNode((node: RowNode) => {
    if (rowIds.includes(node.id)) return node.setExpanded(false)
    return node.expanded
  })
}

export const CollapsedAllRows = gridApi => {
  gridApi.forEachNode((node: RowNode) => {
    return node.setExpanded(false)
  })
}

// Save current grid settings
export const SaveGridState = async (
  gridOptions: any,
  gridName: string,
  displayToast: boolean = false
) => {
  console.log('---> Saving Grid State for: ', gridName)
  const savedState = gridOptions.columnApi.getColumnState()
  const appliedFilters = gridOptions.api.getFilterModel()
  // General APIs must be loaded into the main component that is triggering this function
  const {
    context: {
      componentParent: { generalAPIs },
      componentParent
    },
    context
  } = gridOptions

  const getExternalFilters = () => {
    if (componentParent && IsKeyInObj(context, 'externalFilters')) {
      const externalFilters = {}
      context.externalFilters.forEach(
        filter => (externalFilters[filter] = componentParent[filter])
      )
      return { externalFilters }
    }
    return {}
  }
  const dataToSend = {
    gridName,
    columnState: {
      colDefs: savedState,
      filters: appliedFilters,
      ...getExternalFilters()
    }
  }
  console.log('---> Saving Grid State: ', dataToSend)

  await generalAPIs.SaveGridView(dataToSend, displayToast)
}

// Save current grid settings, but already supply the state and filters to be saved
export const SaveGridStateWithData = async (
  gridOptions: any,
  savedState: any,
  appliedFilters: any,
  gridName: string,
  displayToast: boolean = false
) => {
  console.log('---> Saving Grid State for: ', gridName)
  // const savedState = gridOptions.columnApi.getColumnState()
  // const appliedFilters = gridOptions.api.getFilterModel()
  // General APIs must be loaded into the main component that is triggering this function
  const {
    context: {
      componentParent: { generalAPIs },
      componentParent
    },
    context
  } = gridOptions

  const getExternalFilters = () => {
    if (componentParent && IsKeyInObj(context, 'externalFilters')) {
      const externalFilters = {}
      context.externalFilters.forEach(
        filter => (externalFilters[filter] = componentParent[filter])
      )
      return { externalFilters }
    }
    return {}
  }
  const dataToSend = {
    gridName,
    columnState: {
      colDefs: savedState,
      filters: appliedFilters,
      ...getExternalFilters()
    }
  }
  console.log('---> Saving Grid State: ', dataToSend)

  await generalAPIs.SaveGridView(dataToSend, displayToast)
}
