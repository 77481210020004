import {
  DefaultParametersDefaultInputs,
  type DefaultParametersInputsInterface
} from './default-parameters-inputs-interface'

export interface JobCreationInterface {
  buying_season: string
  channel: string
  error_message: string
  finished_at: string
  job_name: string
  job_tag: string
  parameters: DefaultParametersInputsInterface
  product_id: string
  run_at: string
  prior_selling_seasons?: JobCreationSeasonsInterface[]
  custom_season?: JobCreationCustomSeasonsInterface
  sizes: string[]
  styc_count: number
  stycs: string[]
  params_changes: string[]
  original_job_name: string | null
}

export interface JobCreationSeasonsInterface {
  season: string
  weight: number
}
export interface JobCreationCustomSeasonsInterface {
  start: string
  end: string
}

export const DefaultJobCreationForm = {
  buying_season: null,
  channel: null,
  error_message: null,
  finished_at: null,
  job_name: null,
  job_tag: null,
  parameters: DefaultParametersDefaultInputs,
  product_id: null,
  run_at: null,
  prior_selling_seasons: null,
  custom_season: {
    start: null,
    end: null
  },
  sizes: null,
  styc_count: null,
  stycs: null,
  params_changes: [],
  original_job_name: null
}

export interface JobResultsToDisplayInterface {
  label: string
  key: string
}
export const JobResultsToDisplay = [
  {
    label: 'Job Name',
    key: 'job_name'
  },
  {
    label: 'Channel',
    key: 'channel'
  },
  {
    label: 'Product Node',
    key: 'product_id'
  },
  {
    label: 'Sizes',
    key: 'sizes'
  },
  {
    label: 'Buying Season',
    key: 'buying_season'
  }
]
