// Angular and RJX Imports
// =========================================================
import { Component, Input, type OnInit } from '@angular/core'

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() loadingMessage: string

  isLoading: boolean = false
  isExporting: boolean = false
  isUpdating: boolean = false
  isSubmitting: boolean = false

  // constructor () {}

  ngOnInit () {
    switch (this.loadingMessage) {
      case 'exporting':
        this.isExporting = true
        break
      case 'updating':
        this.isUpdating = true
        break
      case 'submitting':
        this.isSubmitting = true
        break
      case 'loading':
      default:
        this.isLoading = true
        break
    }
    // console.log('loading render: ', this.loadingMessage);
  }
}
