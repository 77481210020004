// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// AWS Imports
// =========================================================
import { API, graphqlOperation } from 'aws-amplify'
import { type GraphQLSubscription } from '@aws-amplify/api'
// Axios Imports
// =========================================================
import axios from 'axios'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import {
  ExecuteDeleteAPI,
  ExecuteGetAPI,
  ExecutePostAPI
} from './execute_api-calls'
import { TriggerApiCallsService } from '../services/cancel-api-call'
import {
  type ReviewOrdersOutputInterface,
  type ApproveOrdersInterface,
  type MarkAsPendingReviewInterface,
  type ReviewOrdersParametersInterface,
  type ExportBreaksInterface,
  type CalculateBreaksInterface,
  type DeleteBuyGroupInterface
} from '../interfaces/review-orders-interface'
import { type ResponseSubscription } from '../../../API'
import * as subscriptions from '../../../graphql/subscriptions'

@Injectable()
export class ReviewOrdersAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Get All Grid Data for Review Orders
  async GetAllReviewOrdersUserInputData () {
    // the api call path with the parameter string is needed
    const path = '/review_orders/input'
    const messages = {
      success:
                '---> API Call Successful: Retrieved Review Orders Input Grid Data',
      error: 'Error retrieving review orders input grid data.'
    }

    return await ExecuteGetAPI(path, this, messages)
  }

  // Get All Grid Data for Review Orders
  async GetAllReviewOrdersOutputData (data: ReviewOrdersOutputInterface) {
    console.log('getallreviewordersoutputdata', data)
    // the api call path with the parameter string is needed
    const path = '/review_orders/output'
    const messages = {
      success:
                '---> API Call Successful: Retrieved Review Orders Output Grid Data',
      error: 'Error retrieving review orders output grid data.'
    }

    const reviewOrdersOutputData = await ExecutePostAPI(
      path,
      this,
      {
        job_ids: data.ids
      },
      messages
    )
    console.log('reviewOrdersOutputData: ', reviewOrdersOutputData)
    if (reviewOrdersOutputData?.is_success) {
      return await axios
        .get(reviewOrdersOutputData.data, {})
        .then(response => {
          console.log('response: ', response)
          return response
        })
        .catch(error => {
          console.error(
            'Error retrieving review order output grid data: ',
            error
          )
          this.toastAlert.showError('Error Loading Data')
          throw error
        })
    } else {
      console.error('Error retrieving review order output grid file')
      this.toastAlert.showError('Error Loading Data')
      return {
        status: null,
        data: []
      }
    }
    // return {
    //   status: 200,
    //   data: {
    //     buy_summary: {
    //       row_data: [
    //         {
    //           job_id: 1,
    //           styc_id: 'Styc123',
    //           styc_name: 'suzette-brown',
    //           size_range: 'S-XXL',
    //           store_distro: 'All Stores',
    //           delivery_date: '07/24/2024',
    //           unit_qty: 1259,
    //           delivery_type: 'Set',
    //           chart: {},
    //           detailGridData: [{
    //             channel: 'stores',
    //             store_id: 'MS-001',
    //             index_pct: 0.01,
    //             target_qty: 13.7,
    //             buy_qty: 13,
    //             mismatch: 1.31,
    //             s_l: {
    //               buy: 3,
    //               target: 2
    //             },
    //             m_l: {
    //               buy: 4,
    //               target: 4
    //             },
    //             l_l: {
    //               buy: 11,
    //               target: 12.31
    //             },
    //             xl_l: {
    //               buy: 8,
    //               target: 8
    //             },
    //             xxl_l: {
    //               buy: 6,
    //               target: 5
    //             },
    //             target_vs_buy_chart: {},
    //             detail_row_sizes: [
    //               'S_L', 'M_L', 'L_L', 'XL_L', 'XXL_L'
    //             ]
    //           }],
    //           main_row_sizes: [
    //             'S_L', 'M_L', 'L_L', 'XL_L', 'XXL_L'
    //           ]
    //         }
    //       ]
    //     },
    //     additional_data: {
    //       delivery_ids: [1]
    //     }
    //   }
    // }
  }

  // Approve Orders
  async ApproveOrders (data: ApproveOrdersInterface) {
    // the api call path with the parameter string is needed
    const path = '/review_orders/approve'
    const numOfJobs = data.job_ids.length
    const suffix = numOfJobs > 1 ? 's' : ''
    const messages = {
      success: `Successfully approved ${numOfJobs} order${suffix}.`,
      error: `Error approving ${numOfJobs} order${suffix}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  // Mark as Pending Review
  async OnMarkAsPendingReview (data: MarkAsPendingReviewInterface) {
    // the api call path with the parameter string is needed
    const path = '/review_orders/pending_review'
    const numOfJobs = data.job_ids.length
    const suffix = numOfJobs > 1 ? 's' : ''
    const messages = {
      success: `Successfully unapproved ${numOfJobs} order${suffix}.`,
      error: `Error unapproving ${numOfJobs} order${suffix}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  // On Calculate breaks
  async OnCalculateBreaks (rows: CalculateBreaksInterface) {
    // the api call path with the parameter string is needed
    const path = '/review_orders/calculate_breaks'
    const messages = {
      success: 'Successfully ran calculate breaks.',
      error: 'Error running calculate breaks.',
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, rows, messages)
  }

  JobGridDataSubscription (gridApi, context) {
    console.log('inside JobGridDataSubscription review orders')
    const subscription = API.graphql<
    GraphQLSubscription<ResponseSubscription>
    >(graphqlOperation(subscriptions.response, {})).subscribe({
      next: ({ provider, value }) => {
        console.log('inside subscriber next', value)
        let shouldUpdateGrid = false
        gridApi.forEachNode((rowNode, index) => {
          rowNode.data.detail_data.forEach(detailNode =>
            console.log(detailNode.status, detailNode.id)
          )
          if (
            rowNode.data.detail_data.filter(
              detailNode =>
                detailNode.id ==
                                    value.data?.response?.job_name &&
                                detailNode.status !==
                                    value.data?.response?.status
            ).length > 0
          ) {
            console.log('we have a matching node node ')
            shouldUpdateGrid = true
          }
        })

        // get the data
        console.log('inside job_name', value.data?.response?.job_name)
        console.log(
          'inside status review orders',
          value.data?.response?.status
        )
        console.log(
          'error_message',
          value.data?.response?.error_message
        )
        if (gridApi && !gridApi['destroyCalled']) {
          if (shouldUpdateGrid) {
            // gridApi.showLoadingOverlay()
            context.configureUserInputGridData()
          }
        }
      },
      error: error => console.warn(error)
    })
  }

  // On Export Orders
  async OnExportBreaks (data: ExportBreaksInterface) {
    console.log('---> On Export Orders API Call: ', data)

    // the api call path with the parameter string is needed
    const path = '/review_orders/export'
    const messages = {
      success: 'Successfully exported orders.',
      error: 'Error exporting orders.',
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }

  async OnDeleteBuyGroup (data: DeleteBuyGroupInterface) {
    console.log('---> On Delete Buy Group API Call: ', data)

    // the api call path with the parameter string is needed
    const path = `/review_orders/buy_groups/${(
            data.buy_group_id + ''
        ).replaceAll('/', '%2F')}`
    const messages = {
      success: 'Successfully deleted buy group.',
      error: 'Error deleting buy group.',
      showSuccessToast: true
    }

    return await ExecuteDeleteAPI(path, this, messages)
  }

  // Get Configuration Settings
  // For the User input -> settings modal
  async GetParameterSettings (rowID: string) {
    console.log('---> On Get Parameter Settings API Call: ', rowID)

    // the api call path with the parameter string is needed
    const path = `/review_orders/${rowID}`
    const messages = {
      success: `---> API Call Successful: Retrieved Parameter Settings For Order: ${rowID}.`,
      error: `Error retrieving parameter settings for order: ${rowID}.`
    }

    return await ExecuteGetAPI(path, this, messages)
  }

  // Submit Configuration Settings
  // For the User input -> settings modal
  async SubmitConfigurationSettings (data: ReviewOrdersParametersInterface) {
    console.log('---> On Submit Configuration Settings API Call: ', data)

    // the api call path with the parameter string is needed
    const path = '/review_orders/create'
    const messages = {
      success: `Successfully saved parameter settings for job: ${data.job_id}.`,
      error: `Error saving parameter settings for job: ${data.job_id}.`,
      showSuccessToast: true
    }

    return await ExecutePostAPI(path, this, data, messages)
  }
}
