<!-- Modal Header -->
<div class="error-modal-header">
    <h2><span class="material-icons error-icon">error_outline</span></h2>
    <h2 class="m-0">Warning</h2>
</div>
<!-- Modal Body -->
<div class="modal-content error-modal-body">
    <span style="height: 34px">
        <p class="error-title">
            {{ timeStamp }} |
            <b class="warning" style="text-overflow: ellipsis">{{
                title.length > 100 ? title.substr(0, 100) + '...' : title
            }}</b>
        </p>
    </span>
    <div class="error-message-container custom-vertical-scroll">
        <p>{{ errorMessage }}</p>
    </div>
</div>
<!-- Modal Footer -->
<app-modal-footer
    [showUpdateBtn]="false"
    [closeBtnText]="'Close'"
    (closeModal)="close($event)"
>
</app-modal-footer>
