// Angular and RJX Imports
// =========================================================
import {
  Component,
  EventEmitter,
  Input,
  type OnInit,
  Output,
  type OnDestroy
} from '@angular/core'
import { cloneDeep } from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'
// Custom Imports
// =========================================================
import { CartonizationFormSettings } from 'src/app/03_shared-components/forms/form-data/cartonization-settings-form'
import { ResetFormData } from 'src/app/03_shared-components/forms/form-data/form-data-reset'
import { CartonizationSettingsInputsInterface } from 'src/app/core/interfaces/data-expected-from-backend/cartonization-settings-inputs-interface'
import { type FormGroupInterface } from 'src/app/core/interfaces/form-field-interface'
import { IsKeyInObj } from 'src/app/utils/global_functions'

@Component({
  selector: 'app-cartonization-settings-input-fields',
  templateUrl: './cartonization-settings-input-fields.component.html',
  styleUrls: ['./cartonization-settings-input-fields.component.scss']
})
export class CartonizationSettingsInputFieldsComponent
implements OnInit, OnDestroy {
  //
  refCartonizationFormSettings: FormGroupInterface = cloneDeep(
    CartonizationFormSettings
  )

  currentProductId: string = null
  @Input() panelOpenOnLoad: boolean = true

  //
  cartonSettings: CartonizationSettingsInputsInterface
  @Input('cartonSettings')
  set _cartonizationSettings (
    cartonSettings: CartonizationSettingsInputsInterface
  ) {
    const tempForm = this.refCartonizationFormSettings
    const parentId = cartonSettings?.parent_product_id || null
    // console.log(
    //   '---> Setting Cartonization Inputs: ',
    //   JSON.stringify(cartonSettings)
    // )
    console.log('---> Setting Cartonization Inputs: ', cartonSettings)
    console.log('---> Setting currentProductId: ', this.currentProductId)
    console.log('---> Setting parentId: ', parentId)

    if (cartonSettings) {
      const setFormValues = async () => {
        if (
          this.currentProductId &&
                    this.currentProductId !== parentId
        ) {
          console.log('---> Resetting Carton Settings')
          const reset = await ResetFormData(
            {
              form: this.formData,
              currentFormData: this._formData,
              // originalFormData: CartonizationFormSettings
              originalFormData: this.refCartonizationFormSettings
            },
            true
          )

          if (reset) {
            this.currentProductId = parentId
            setFormValues()
          }
        } else {
          Object.entries(cartonSettings).forEach(
            ([key, value], i) => {
              if (IsKeyInObj(tempForm, key)) {
                tempForm[key].disabled =
                                    !cartonSettings.editable

                tempForm[key].currentVal = value
              }
              if (Object.keys(cartonSettings).length === i + 1) {
                this.refCartonizationFormSettings = tempForm
                this.formData.next(tempForm)

                this.currentProductId = parentId
              }
            }
          )
        }
      }

      setFormValues()
    } else {
      console.log('---- Reset Product Details ----: ', cartonSettings)
    }
  }

  // Import the original form settings and track updates
  formData = new BehaviorSubject<FormGroupInterface>(
    CartonizationFormSettings
  )

  get _formData () {
    return this.formData.getValue()
  }

  // Clear the current form data
  clearFormGroup: boolean
  @Input('clearFormGroup')
  set _clearFormGroup (clearForm: boolean) {
    if (clearForm) {
      const resetForm = async () => {
        this.currentProductId = null
        const reset = await ResetFormData(
          {
            form: this.formData,
            currentFormData: this._formData,
            originalFormData: CartonizationFormSettings
          },
          true
        )
        console.log('---> Resetting Default Parameter Inputs:', reset)
        this.onFormReset.emit(true)
      }
      resetForm()
    }
  }

  @Output() onFormReset: EventEmitter<any> = new EventEmitter<any>()
  @Output() onFormValueChanged: EventEmitter<any> = new EventEmitter<any>()

  ngOnInit () {}

  updateFormValue (form) {
    const value = cloneDeep(form.value)
    console.log('Form Value Updated: ', value)

    switch (form.status) {
      case 'VALID':
        this.onFormValueChanged.emit(form)
        break
      case 'INVALID':
        console.error('field error: ', form)
        break
      default:
        console.error(`Form Updated - ${form.status}: `, form)
    }
  }

  ngOnDestroy (): void {
    const resetForm = async () => {
      const reset = await ResetFormData(
        {
          form: this.formData,
          currentFormData: this.refCartonizationFormSettings,
          originalFormData: CartonizationFormSettings
        },
        true
      )
      console.log('---> Destroying Default Parameter Inputs:', reset)
    }
    resetForm()
  }
}
