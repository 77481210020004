// Custom Imports
// =========================================================
import { IsKeyInObj } from 'src/app/utils/global_functions'
import { SuffixCellIconRenderer } from '../03_custom-components/suffix-cell-icon-renderer/suffix-cell-icon-renderer'
import { FormatJSONColData } from '../02_global-settings/01_transformJSONColData'
import { CellClassRules } from '../02_global-settings/cell-class-rules'

export interface StoreIndexOutputGrid_GridDataParams {
  mainColDefs: any
}

export async function StoreIndexOutputGrid_GenerateGridData (
  params: StoreIndexOutputGrid_GridDataParams
) {
  const { mainColDefs } = params

  const getResults = async () => {
    const mainCols = await FormatJSONColData(
      mainColDefs,
      'main',
      StoreIndexOutput_customCellFormatting
    )

    const results = await Promise.all([mainCols])
    const m = results.indexOf(mainCols)

    const data = {
      mainColDefs: results[m]
    }
    return data
  }
  return await getResults()
}

export const StoreIndexOutput_customCellFormatting = (
  col: any,
  gridToRender: string
) => {
  const getParams = () => {
    // Main Grid Settings
    if (gridToRender === 'main') {
      switch (col?.colId) {
        case 'index':
          return {
            editable: params => {
              const { rowData } =
                                params.context.componentParent.parentGridData
              return (
                !params.node.rowPinned &&
                                !params.data.is_locked &&
                                rowData?.is_editable &&
                                rowData?.status !== 'APPROVED'
              )
            },
            valueSetter: params => {
              const newValue = Number(params.newValue)
              const oldValue = Number(
                Number(params.oldValue).toFixed(2)
              )
              if (newValue !== oldValue) {
                params.data.index = newValue
                return true
              } else {
                return false
              }
            }
          }
        case 'store_id':
          return {
            valueGetter: params => {
              if (params.node.rowPinned) {
                let total = 0
                params.api.forEachNode(node => {
                  total += Number(node.data.index)
                })

                const roundedTotal = Number(
                  parseFloat(total.toFixed(4))
                )
                params.context['percentageTotal'] =
                                    roundedTotal
                return `Store Index (%) Total: ${roundedTotal}`
              } else {
                return params.value
                  ? params.value
                  : params.data.store_id
              }
            },
            colSpan: params => (params.node.rowPinned ? 4 : 1)
          }
        case 'is_locked':
          return {
            cellRendererParams: params => {
              const { rowData } =
                                params.context.componentParent.parentGridData
              return {
                disabled:
                                    !rowData?.is_editable ||
                                    rowData?.status === 'APPROVED'
              }
            }
          }
        default:
          return {}
      }
    }
  }
  const updatedCol = {
    ...col,
    ...getParams(),
    cellClassRules: {
      ...CellClassRules,
      'cell-warning': params => {
        return (
          params.node.rowPinned &&
                    params.context['percentageTotal'] !== 100
        )
      },
      'footer-cell': params => {
        return params.node.rowPinned
      },
      'cell-text-bold': params => {
        return (
          (params.node.rowPinned &&
                        params.context['percentageTotal'] !== 100) ||
                    params.context.editedStores.includes(params.node.id)
        )
      }
    }
  }
  return updatedCol
}
