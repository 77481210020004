// Angular Imports
// =========================================================
import { Component } from '@angular/core'
// AG Grid Imports
// =========================================================
import { type ITooltipAngularComp } from 'ag-grid-angular'
import { ITooltipParams, TooltipRendererParams } from 'ag-grid-community'
import { isArray } from 'lodash'

@Component({
  selector: 'app-array-custom-tooltip',
  templateUrl: './array-custom-tooltip.component.html',
  styleUrls: ['./array-custom-tooltip.component.scss']
})
export class ArrayCustomTooltipComponent implements ITooltipAngularComp {
  params: any
  data: any
  value: any
  isHeaderCell: boolean

  message: string = ''
  configData: any[] = []

  delim: string = '/'

  agInit (params): void {
    console.log('ag init params', params)
    if (params.colDef.field === 'size_ranges') {
      this.delim = '||'
    }
    this.params = params
    const value = params?.value
    this.isHeaderCell = params.location === 'header'
    if (params.colDef.field === 'size_range') {
      this.delim = '|'
    }
    if (!isArray(value)) {
      console.log('value is not array')
      this.value = value.split(this.delim).map(val => val.replaceAll('||', '/'))
    } else {
      this.value = value.map(val => val.replaceAll('||', '/'))
    }
    // console.log('tool tip params: ', params)
    this.message = value && isNaN(value) ? value : Number(value).toFixed(2)
  }
}
