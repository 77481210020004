export const DistroCopyToNewBuyingSeasonFormSettings = {
  new_buying_season: {
    controlName: 'new_buying_season',
    label: 'New Buying Season',
    formType: 'auto-selection',
    datatype: 'string',
    selectMultiple: false,
    selectionOptions: [],
    // displayKey: ;
    // valueKey: ;
    loading: false,
    disabled: true,
    autoComplete: false,
    reset: false,
    defaultVal: false,
    currentVal: '',
    validatorOptions: {
      required: true
    },
    formDisplay: {
      formWidth: '50%'
    }
  }
}
