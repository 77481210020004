import {
  type DialogService,
  type DynamicDialogRef
} from 'primeng/dynamicdialog'
import { ErrModalComponent } from '../04_modals/03_error-modal/err-modal.component'

let modalOpen = false
let ref: DynamicDialogRef

export function handleError (
  dialog: DialogService,
  error: any,
  message: string
) {
  console.error(message, ': ', error)

  if (modalOpen) {
    ref.close()
    ref.destroy()
    modalOpen = false
  }
  const title =
        error.status && error.statusText
          ? `${error.status} ${error.statusText}`
          : `${error.status}`
            ? error.status
            : 'Error'
  const standardError = {
    title,
    errorStatus: `${error.status}`,
    timeStamp: `${new Date().toLocaleString()}`,
    errorMessage: error.message || message,
    logout: error.status === 401 || error.status === 503,
    customMessage: message
  }
  if (message !== 'Modal' && !modalOpen) {
    modalOpen = true

    ref = dialog.open(ErrModalComponent, {
      showHeader: false,
      styleClass: 'error-modal',
      closeOnEscape: false,
      dismissableMask: false,
      data: { error: standardError }
    })
  }
}
