<!-- Modal Header -->
<app-modal-header
    modalTitle="Size Distro Bulk Override"
    [showCloseBtn]="false"
    (closeModal)="close()"
></app-modal-header>
<!-- Modal Content -->
<div
    class="modal-content custom-vertical-scroll"
    [attr.loading]="loading"
    [attr.size]="'small'"
>
    <section class="primary-section-container w-100">
        <!-- Header with Radio -->
        <span class="override-actions-container">
            <p class="form-title">Override Actions:</p>
            <!-- Override Action Options -->
            <mat-radio-group
                aria-labelledby="Override Actions"
                [(ngModel)]="overrideAction"
                (change)="onOverrideActionChanged($event)"
            >
                <mat-radio-button
                    *ngFor="let overrideAction of overrideActions"
                    [value]="overrideAction"
                    [disabled]="
                        loading ||
                        (overrideAction === 'Size Distro To Size Distro' &&
                            parentGridData?.rowData?.length === 1) ||
                        (overrideAction === 'Store To Store' &&
                            this.rowDataIncludesApprovedStatus)
                    "
                >
                    {{ overrideAction }}
                </mat-radio-button>
            </mat-radio-group>
        </span>
        <!-- Form Fields -->
        <div class="form-fields-container" *ngIf="!loading">
            <!-- Source -->
            <app-form-fields
                [formParams]="formData"
                [controlName]="'source'"
                (onFormFieldUpdated)="updateFormValue($event)"
            >
            </app-form-fields>
            <!-- Destination -->
            <app-form-fields
                [formParams]="formData"
                [controlName]="'destination'"
                (onFormFieldUpdated)="updateFormValue($event)"
            >
            </app-form-fields>
        </div>
    </section>
    <!-- Loading Component -->
    <div *ngIf="loading" class="w-100 h-100">
        <app-loader [loadingMessage]="loadingMessage"></app-loader>
    </div>
</div>

<!-- Modal Footer -->
<app-modal-footer
    (closeModal)="close()"
    [closeBtnText]="'Close'"
    [showUpdateBtn]="true"
    (update)="onSubmitBulkOverride($event)"
    [disabled]="
        !completedBulkOverride?.destination ||
        completedBulkOverride?.destination?.length === 0 ||
        !completedBulkOverride?.source ||
        completedBulkOverride?.source === ''
    "
></app-modal-footer>
