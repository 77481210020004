// Angular and RJX Imports
// =========================================================
import { Injectable } from '@angular/core'
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { ExecuteDeleteAPI, ExecuteGetAPI } from './execute_api-calls'
import { ToastAlertComponent } from 'src/app/03_shared-components/01_alerts/toast-alert/toast-alert.component'
import { TriggerApiCallsService } from '../services/cancel-api-call'

@Injectable()
export class SizeDistroExceptionsAPIs {
  clearApiCalls: boolean = false

  constructor (
    public dialogService: DialogService,
    public toastAlert: ToastAlertComponent,
    public triggerApiCallsService: TriggerApiCallsService
  ) {
    // ApiCall Service
    this.triggerApiCallsService.triggerApiCalls$.subscribe({
      next: data => {
        this.clearApiCalls = data['clear_api_calls']
      }
    })
  }

  // Get All Size Distro Exceptions
  async GetAllSizeDistroExceptions () {
    // the api call path with the parameter string is needed
    const path = '/size_distro/jobs/exceptions'
    const messages = {
      success:
                '---> API Call Successful: Retrieved All Size Distro Exceptions',
      error: 'Error retrieving rowData for the Size Distro Exceptions Grid.'
    }

    const response = await ExecuteGetAPI(path, this, messages)
    if (response && response.is_success && response.data.length > 0) {
      return response.data
    } else {
      return []
    }
  }

  // Delete Size Distro Exceptions
  async DeleteExceptions (styc_id: string, job_name: string) {
    // the api call path with the parameter string is needed
    const path = `/size_distro/jobs/exceptions/${job_name.replaceAll(
            '/',
            '%2F'
        )}/${styc_id.replaceAll('/', '%2F')}`
    const messages = {
      success: `Successfully deleted ${styc_id}, ${job_name} exceptions.`,
      error: `Error deleting ${job_name}, ${styc_id} exceptions.`,
      showSuccessToast: true
    }
    return await ExecuteDeleteAPI(path, this, messages)
  }
}
