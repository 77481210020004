// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
export var AuthErrorStrings;
(function (AuthErrorStrings) {
  AuthErrorStrings["DEFAULT_MSG"] = "Authentication Error";
  AuthErrorStrings["EMPTY_EMAIL"] = "Email cannot be empty";
  AuthErrorStrings["EMPTY_PHONE"] = "Phone number cannot be empty";
  AuthErrorStrings["EMPTY_USERNAME"] = "Username cannot be empty";
  AuthErrorStrings["INVALID_USERNAME"] = "The username should either be a string or one of the sign in types";
  AuthErrorStrings["EMPTY_PASSWORD"] = "Password cannot be empty";
  AuthErrorStrings["EMPTY_CODE"] = "Confirmation code cannot be empty";
  AuthErrorStrings["SIGN_UP_ERROR"] = "Error creating account";
  AuthErrorStrings["NO_MFA"] = "No valid MFA method provided";
  AuthErrorStrings["INVALID_MFA"] = "Invalid MFA type";
  AuthErrorStrings["EMPTY_CHALLENGE"] = "Challenge response cannot be empty";
  AuthErrorStrings["NO_USER_SESSION"] = "Failed to get the session because the user is empty";
  AuthErrorStrings["NETWORK_ERROR"] = "Network Error";
  AuthErrorStrings["DEVICE_CONFIG"] = "Device tracking has not been configured in this User Pool";
  AuthErrorStrings["AUTOSIGNIN_ERROR"] = "Please use your credentials to sign in";
})(AuthErrorStrings || (AuthErrorStrings = {}));
