// Angular and RJX Imports
// =========================================================
import { Component, type OnInit } from '@angular/core'
// Prime NG Imports
// =========================================================
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService
} from 'primeng/dynamicdialog'
// Custom Imports
// =========================================================
import { FormatKey } from 'src/app/utils/global_functions'
import {
  DefaultChartOptions,
  GenerateRandomColor,
  StandardChartColors
} from 'src/app/utils/global-chart-settings'

@Component({
  selector: 'app-store-totals-charts',
  templateUrl: './store-totals-charts.component.html',
  styleUrls: ['./store-totals-charts.component.scss']
})
export class StoreTotalsChartsComponent implements OnInit {
  loading: boolean = true
  loadingMessage: string = 'loading'

  data: any
  options: any
  sizes = []
  sizeDataPoints: any[] = []

  threshold = 0.5

  colors = {
    bad_c: StandardChartColors.accent_1,
    rad_c: StandardChartColors.accent_2,
    sales: StandardChartColors.accent_1
  }

  currentChartColors: string[] = []
  cellParams: any

  constructor (
    private readonly ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService
  ) {
    console.log('---> Store Totals Config Modal: ', config.data)
  }

  ngOnInit () {
    const { sizes, params, cellData } = this.config.data
    this.sizes = sizes
    this.cellParams = params

    this.calcChartData(cellData)
  }

  // Function to calculate the bar chart data points
  calcChartData (data) {
    console.log(
      '---> Cell data for store totals charts: ',
      this.cellParams
    )
    const { deliveryID, chartData } = this.config.data
    const chartDataToDisplay = []
    const row = data
    const level = this.cellParams.node.level
    const refData = chartData[deliveryID]
    const channel =
            level > 0
              ? typeof row.channel === 'string'
                ? row.channel
                : row.channel[0]
              : 'Total'

    // console.log('--> Ref Data: ', refData)
    // console.log('--> Row: ', row)
    // console.log('--> Channel: ', channel)
    // console.log('---> Level: ', level)

    const setChartData = (
      j: number,
      data: any,
      metric: string,
      channel: string,
      label: string,
      randomColor: boolean = false
    ) =>
      chartDataToDisplay.push({
        ...this.getChartItem(
          {
            data,
            j,
            metric,
            label: `${channel} - ${label}`
          },
          randomColor
        )
      })
    let channelBadC = []
    let channelRadC = []
    let channelSales = []

    switch (level) {
      case 0:
        Object.keys(refData).forEach((channel, j) => {
          channelBadC = []
          channelRadC = []
          channelSales = []
          this.sizes.forEach(size => {
            const sizeKey = FormatKey(size)
            const c = FormatKey(channel)

            channelBadC.push({
              x: size,
              y: refData[c][sizeKey].bad_c
            })
            channelRadC.push({
              x: size,
              y: refData[c][sizeKey].rad_c
            })
            channelSales.push({
              x: size,
              y: refData[c][sizeKey].sales_pct
            })
          })

          setChartData(
            j,
            channelRadC,
            'rad_c',
            channel,
            'contrib',
            true
          )
          setChartData(
            j,
            channelSales,
            'sales',
            channel,
            'sales',
            true
          )
        })
        break
      case 1:
        this.sizes.forEach(size => {
          const sizeKey = FormatKey(size)
          const c = FormatKey(channel)

          channelBadC.push({
            x: size,
            y: refData[c][sizeKey].bad_c
          })
          channelRadC.push({
            x: size,
            y: refData[c][sizeKey].rad_c
          })
          channelSales.push({
            x: size,
            y: refData[c][sizeKey].sales_pct
          })
        })
        setChartData(0, channelRadC, 'rad_c', channel, 'contrib')
        setChartData(0, channelSales, 'sales', channel, 'sales')
        break
      case 2:
        const storeRadC = []

        this.sizes.forEach(size => {
          const sizeKey = FormatKey(size)

          storeRadC.push({
            x: size,
            y: row[sizeKey]
          })
          channelRadC.push({
            x: size,
            y: refData[FormatKey(row.channel)][sizeKey].rad_c
          })
        })
        setChartData(0, channelRadC, 'rad_c', channel, 'contrib')
        setChartData(
          0,
          storeRadC,
          'sales',
          this.cellParams.data.store_id,
          'contrib'
        )
        break
    }

    console.log('---> Chart Data To Display: ', chartDataToDisplay)
    this.updateChart(chartDataToDisplay)
  }

  getChartItem ({ data, j, metric, label }, generateColors?) {
    const metricColor = generateColors
      ? GenerateRandomColor(this.currentChartColors)
      : this.colors[metric]
    if (!this.currentChartColors.includes(metricColor)) {
      this.currentChartColors.push(metricColor)
    }
    return {
      label,
      order: j,
      backgroundColor: metricColor,
      borderColor: metricColor,
      padding: '20px',
      data,
      borderWidth: 1,
      fill: false,
      tension: 0.1
    }
  }

  updateChart (chartData) {
    console.log('labels: ', this.sizes)
    this.data = {
      datasets: chartData.filter(e => e)
    }
    this.options = {
      ...DefaultChartOptions,
      // hover: {
      //   // Overrides the global setting
      //   mode: 'point',
      //   axis: 'x',
      //   intersect: false
      // },
      indexAxis: 'y',
      scales: {
        x: {
          type: 'category',
          labels: this.sizes
        },
        y: {
          ticks: { padding: 10 },
          type: 'linear',
          position: 'left'
        }
      },
      plugins: {
        tooltip: {
          ...DefaultChartOptions.plugins.tooltip,
          callbacks: {
            ...DefaultChartOptions.plugins.tooltip.callbacks,
            title: context => {
              // console.log('tooltip items: ', context)
              return `Size: ${context[0].formattedValue}`
            },
            label: context => {
              // console.log('tooltip items: ', context)
              return `${context.dataset.label}: ${parseFloat(
                                context.label
                            )
                                // * 100
                                .toFixed(2)} %`
              // return `${context.dataset.label}: ${context.label} %`
            }
          }
        }
      }
    }
    this.loading = false
  }

  close (data?: any) {
    if (this.ref) this.ref.close(data || null)
  }
}
